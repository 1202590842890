import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../Components/Footer';
import { SideBarContainer } from '../Components/SideBarComponents/SideBarContainer';
import DayToDayProgramModal from '../Components/Modals/DayToDayProgramModal';
import StripeConnectNotification from '../Components/StripeConnectNotification';
import { RootState } from '../store';
import { getStripeConnectedDetails } from '../store/Auth';
import SWTNavbar from '../Components/SWTNavbar';
import WhatsAppButton from '../Components/WhatsAppButton';


export const DefaultLayout: FunctionComponent = ({ children }) => {
  const dispatch = useDispatch()
  const userDetails = useSelector((rootState: RootState) => rootState.authReducer.UserDetails)
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isDetailCompleted, setIsDetailCompleted] = useState(true);

  const checkStripeAccountDetails = async () => {
    try {
      const res: any = await dispatch(getStripeConnectedDetails());
      setIsDetailCompleted(res.data.account_details.charges_enabled);
    }
    catch (e) {
      console.log("error", e)
    }
  }


  useEffect(() => {
    if (userDetails) {
      setIsAuthenticated(true)
      checkStripeAccountDetails();
    } else {
      setIsDetailCompleted(true);
    }
  }, [userDetails])


  return (
    <>
      <SWTNavbar/>
      <div className="position-relative">
        {isAuthenticated &&
          userDetails?.swt_role === "provider" && isDetailCompleted === false && <StripeConnectNotification />}
        <SideBarContainer />
        {children}
        <DayToDayProgramModal />
        <Footer />
        <WhatsAppButton/>
      </div>
    </>
  );
}