import { HomeActionTypes, HomeState, SET_HOME_CONTENT } from "./type";

const initialState: HomeState = {
    homeContent: {
        _id:'',
        Hero: {
            _id: "",
            hero_heading: '',
            hero_content: '',
            first_hero_img: {},
            second_hero_img: {},
            footer_item_3: {},
            footer_item_2: {},
            footer_item_1: {},
            project_section: {},
            vision_section: {},
          tour_section: {},
            upcoming_proj_and_tours:{},
        }
  }
};

export function homeReducer(
  state = initialState,
  action: HomeActionTypes
): HomeState {
  switch (action.type) {
    case SET_HOME_CONTENT:
      return {
        ...state,
        homeContent: action.payload
      };
    default:
      return state;
  }
}