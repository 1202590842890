import {
  RequestActionTypes,
  RequestsState,
  SET_FOR_APPROVAL_PROJECTS,
  SET_FOR_APPROVAL_TOURS,
  SET_FOR_APPROVAL_PROVIDERS,
  SET_FOR_REMOVAL_PROJECTS,
  SET_FOR_REMOVAL_TOURS,
} from "./type";

const initialState: RequestsState = {
  forApprovalProjects: [],
  forApprovalTours: [],
  forApprovalProviders: [],
  forRemovalProjects: [],
  forRemovalTours: [],
};

export function requestsReducer(
  state = initialState,
  action: RequestActionTypes
): RequestsState {
  switch (action.type) {
    case SET_FOR_APPROVAL_PROJECTS:
      return {
        ...state,
        forApprovalProjects: action.payload,
      };
    case SET_FOR_APPROVAL_TOURS:
      return {
        ...state,
        forApprovalTours: action.payload,
      };
    case SET_FOR_APPROVAL_PROVIDERS:
      return {
        ...state,
        forApprovalProviders: action.payload,
      };
    case SET_FOR_REMOVAL_PROJECTS:
      return {
        ...state,
        forRemovalProjects: action.payload,
      };
    case SET_FOR_REMOVAL_TOURS:
      return {
        ...state,
        forRemovalTours: action.payload,
      };
    default:
      return state;
  }
}
