import { SET_ALL_TOURS, SET_UPCOMING_TOURS,SET_CALENDAR_DATA, ToursActionTypes, tourState,  } from "./type";

const initialState: tourState = {
  upcomingTours: [],
  allTours: [],
  calendarData: []
};

export function tourReducer(
  state = initialState,
  action: ToursActionTypes
): tourState {
  switch (action.type) {
    case SET_ALL_TOURS:
      return {
        ...state,
        allTours: action.payload,
      };
    case SET_UPCOMING_TOURS:
      return {
        ...state,
        upcomingTours: action.payload
      };
    
    default:
      return state;
  }
}
