import dayjs from "dayjs";
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { Collapse, Modal } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { RootState } from "../../../store";
import { fetchAllProjects, Project, publishUnpublishProject } from "../../../store/Projects";
import { requestForProjectRemoval } from "../../../store/requests";
import { setCurrentDayToDayProgram } from "../../../store/SideBar";
import { getLatestBookingDate } from "../../../util";
import axiosInstance from "../../../utilities/axiosInstance";
import calculateEventPrice from "../../../utilities/calcProjectPrice";
import './projectTable.css';
const ProjectListings: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [idToDelete, setidToDelete] = useState<undefined | string>(undefined);
  const [showReservations, setShowReservations] = useState<boolean>(false);
  const [discountModal, setDiscountModal] = useState<boolean>(false);
  const [discountValue, setDiscountValue] = useState<number>(0);
  const [showTravelers, setShowTravelers] = useState<boolean>(false);
  const [voucher, setVoucher] = useState<string>('');
  const [project, setProject] = useState<Project | undefined>(undefined);
  const [travelersInfo, setTravellersInfo] = useState<undefined | any>(undefined);
  const [projReservations, setProjReservations] = useState<undefined | any>(undefined);
  const projectList = useSelector(
    (rootState: RootState) => rootState.projects.AllProjects
  );
  useEffect(() => {
    dispatch(fetchAllProjects());
  }, []);

  const confirmDelete = async () => {
    try {
      await dispatch(requestForProjectRemoval(idToDelete!));
      setidToDelete(undefined);
      toast.success("The request to cancel your event was successful");
    } catch (error: any) {
      setidToDelete(undefined);
      toast.error(error.response.data.message);
    } finally {
      dispatch(fetchAllProjects());
    }
  };

  const _HandlePublishUnPublish = async (available: boolean, projectId: string) => {
    try {
      const res: any = await dispatch(publishUnpublishProject(projectId, available));
      toast.success(res.message)
    }
    catch (e: any) {
      toast.error(e.response.data.message);
    }
    finally {
      dispatch(fetchAllProjects());
    }
  }
  const fetchReservations = async (id: string) => {
    return await axiosInstance.get(`/api/projects/reservations/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    })
  }
  const handleShowReservations = async (id: string) => {
    const res = await fetchReservations(id)
    setProjReservations(res.data.reservations)
    setShowReservations(true)
  }
  const handleShowTravellers = (travelersInfo: any) => {
    setTravellersInfo(travelersInfo)
    setShowTravelers(!showTravelers)
  }
  const hideModal = () => {
    setShowReservations(false)
    setTravellersInfo(undefined)
    setShowTravelers(!showTravelers)

  }
  const handleDiscountModal = (project: Project) => {
    setProject(project)
    setDiscountModal(true)
  }
  const handleDiscountChange = useCallback((val: string) => { setDiscountValue(Number(val)) }, [])
  const handleVoucherChange = useCallback((val: string) => { setVoucher(val.toLocaleUpperCase()) }, [])
  const handleDiscountSubmit = useCallback(async () => {

    const proj = await axiosInstance.post('/api/voucher/add-voucher', {
      voucher: voucher,
      discount: discountValue,
      id: project?.id
    }, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    })
    hideModal()
    setDiscountModal(false)
  }, [project, discountValue])
  const handleDeleteEvent = (event: any) => {
    setidToDelete(event.id)
  }
  useMemo(async () => {
    if (idToDelete) {
      const res = await fetchReservations(idToDelete)
      if (res.data.reservations.length > 0) {
        return Swal.fire({
          title: "The project/tour cannot be deleted because there are still outstanding reservations. But you can unpublish your project/tour so that it is no longer visible to customers on the platform and submit your deletion request again after the last booking has been completed.",
          showConfirmButton: true,
        }).then(() => {
          setidToDelete(undefined)
        })
      }
      return Swal.fire({
        title: 'Are you sure you want to cancel this event? Thank you very much for the trustful cooperation. We are looking forward to organize more events together in future. Anything we can help you with, please contact us at support@socialtravel.world.',
        showConfirmButton: true,
        showCancelButton: true,

      }).then((result) => {
        if (result.isConfirmed) {
          confirmDelete()
        } else {
          setidToDelete(undefined)
        }
      })
    }
  }, [idToDelete])
  const genralLan = useSelector(
    (rootState: RootState) => rootState?.language?.language?.content!
  );
  return (
    <>
      {/* {idToDelete && (
        <SweetAlert
          title={hasReservations ? "Are you sure you want to cancel the tour? Please ensure you have tried everything possible to maintain your offer to the customer's satisfaction. Anything we can help you with, please contact us at support@smallworldtravel.de"
            : 'Are you sure you want to cancel this event? Thank you very much for the trustful cooperation. We are looking forward to organize more events together in future. Anything we can help you with, please contact us at support@socialtravel.world.'}

          danger
          showCancel
          confirmBtnBsStyle="danger"
          onConfirm={confirmDelete}
          onCancel={() => setidToDelete(undefined)}
        />
      )} */}
      <div className="col-lg-9 col-12">
        <div className="section-wrap paddresup sa-myreservations">
          <section className="sa-content com-font">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <h4 className="fw700 text-red text-capitalize">
                      {genralLan?.general?.my_projects}
                    </h4>
                  </div>
                  <div className="col-lg-6">
                    <Link
                      to="/profile/projects/add"
                      type="button"
                      className="btn btnviewproj text-capitalize float-right"
                    >
                      {genralLan?.general?.add + ' ' + genralLan?.general?.project}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-wrapper">
                <BootstrapTable
                  keyField="id"
                  wrapperClasses="table-responsive projectlisttabdiv"
                  bordered={false}
                  classes="projectlisttab  borderless text-center"
                  headerWrapperClasses="table-header"
                  data={projectList.map((p, i) => ({
                    ...p,
                    index: i + 1,
                  }))}
                  columns={[
                    { text: "#", dataField: "index", sort: true },
                    {
                      text: genralLan?.general?.project + ' ' + genralLan?.general?.name,
                      dataField: "projectName",
                      sort: true,
                      formatter: (Name, project) => (
                        <a
                          href={`/events/projects/${project.id}`}
                        // onClick={() =>
                        //   dispatch(setCurrentDayToDayProgram(project))
                        // }
                        >
                          {Name}
                        </a>
                      ),
                    },
                    // { text: genralLan?.general?.cost, dataField: "consumerPricePerDay", sort: true, formatter: (consumerPricePerDay) =>
                    // Math.round(consumerPricePerDay)},
                    { 
                      text: genralLan?.general?.cost, 
                      dataField: "pricePerDay", 
                      sort: true,
                      formatter: (_, row) => {
                        // const startDate = dayjs(getLatestBookingDate(row.startDate)).toDate();
                        // const endDate = dayjs(getLatestBookingDate(row.startDate)).add(1, 'd').toDate();
                        // let { price } = calculateEventPrice('project', 1, row.category, startDate, endDate, row, 0);
                        let price = 0;
                        const airportCost = row.airportCost ? row.airportCost : 0;
                        price = row.pricePerDay + airportCost;
                        return (
                          <>
                            <p>{price}</p>
                          </>
                        );
                      },
                    },
                    {
                      text: genralLan?.general?.start + ' ' + genralLan?.general?.date,
                      dataField: "startDate",
                      sort: true,
                      formatter: (startDate) =>
                        dayjs(startDate).format("DD/MM/YYYY"),
                    },
                    { text: genralLan?.general?.status, dataField: "status", sort: true },
                    {
                      text: genralLan?.general?.publish, dataField: "available",
                      formatter: (available) =>
                        available ? "Yes" : "No"
                    },
                    {
                      text: genralLan?.general?.get + ' ' + genralLan?.general?.reservations, dataField: "available",
                      formatter: (_cellContent, row) =>
                        <button type='button' className="btn btnviewproj text-capitalize ml-1" onClick={() => handleShowReservations(row.id)}>
                          {genralLan?.general?.get + ' ' + genralLan?.general?.reservations}
                        </button>
                    },
                    {
                      isDummyField: true,
                      dataField: "feedback",
                      text: 'Feedbacks',
                      formatter: (_cellContent, row) => (
                        <Link to={`/profile/feedback/project/${row.id}`}>
                          {genralLan?.general?.view + ' ' + genralLan?.general?.feedback}
                        </Link>
                      )
                    },
                    {
                      dataField: "confirmation",
                      text: genralLan?.general?.publish,
                      formatter: (_cellContent, row) => {
                        return (
                          <>
                            {row.status === "approved" && (
                              <input type="checkbox" onClick={() => _HandlePublishUnPublish(row.available, row.id)} checked={row.available} />
                            )}
                          </>
                        );
                      },
                    },
                    // {
                    //   dataField: "df2",
                    //   isDummyField: true,
                    //   text: "Actions",
                    //   formatter: (_cellContent, row) => {
                    //     return (
                    //       <button type="button"
                    //         className="btn btnviewproj text-capitalize float-right"
                    //         onClick={() => handleDiscountModal(row)}>
                    //         {genralLan?.general?.discount} %
                    //       </button>
                    //     );
                    //   },
                    // },
                    {
                      dataField: "df1",
                      isDummyField: true,
                      text: "Actions",
                      formatter: (_cellContent, row: any) => {
                        return (
                          <>
                            <Link
                              to={`/profile/projects/${row.id}`}
                              className="btn btn-sm btn-link btnscart"
                            >
                              <img
                                alt=""
                                src="/images/edit.svg"
                                className="img-fluid carticon"
                              />
                            </Link>
                            <a
                              href="javascript:void(0)"
                              onClick={() => handleDeleteEvent(row)}
                              className="btn btn-sm btn-link btnscart "
                            >
                              <img
                                src="/images/delete_icon.svg"
                                className="img-fluid carticon"
                                alt=""
                              />
                            </a>
                          </>
                        );
                      },
                    },

                  ]}
                  pagination={paginationFactory({
                    showTotal: true,
                    sizePerPage: 5,
                    hidePageListOnlyOnePage: true,
                    sizePerPageRenderer: () => <></>,
                  })}
                />
              </div>
            </div>
          </section>
          <Modal show={discountModal} onHide={() => setDiscountModal(false)}>
            <Modal.Header closeButton  >
              {project?.projectName}
            </Modal.Header>
            <Modal.Body>
              <div >
                <label
                  className="mb-0 mt-3 sa-label small text-capitalize">
                  {genralLan?.general?.discount + ' ' + genralLan?.general?.code}
                </label>
                <input
                  className="form-control resforminp mt-0"
                  name="voucher"
                  id="voucher"
                  value={voucher}
                  onChange={(e) => handleVoucherChange(e.target.value)}
                  placeholder="Discount Voucher or code"
                />
                <input type="number" min='0' max="100" step="1"
                  className="form-control resforminp mt-0"
                  name="discountPercent"
                  id="discountPercent"
                  value={discountValue}
                  onChange={(e) => handleDiscountChange(e.target.value)}
                  placeholder="Discount in percentage"
                />
                <button type="button"
                  className="btn btnviewproj text-capitalize float-right"
                  onClick={() => handleDiscountSubmit()}>
                  {genralLan?.general?.add_discount}
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={showReservations} onHide={hideModal}>
            <Modal.Header closeButton />
            <Modal.Body >
              <BootstrapTable
                keyField="id"
                wrapperClasses="table-responsive projectlisttabdiv"
                bordered={false}
                classes="projectlisttab  borderless text-center"
                headerWrapperClasses="table-header"
                data={projReservations?.map((p: any, i: number) => ({
                  ...p,
                  index: i + 1,
                }))}
                columns={[
                  { text: "#", dataField: "index", sort: true },
                  {
                    text: genralLan?.general?.booking + ' ' + genralLan?.general?.date, dataField: "createdAt", sort: true,
                    formatter: (date) => dayjs(date).format("DD/MM/YYYY"),
                  },
                  {
                    text: genralLan?.general?.total, dataField: "total", sort: true,
                    // formatter: (date) => dayjs(date).format("DD/MM/YYYY"),
                  },
                  {
                    text: genralLan?.general?.get + ' ' + genralLan?.general?.invoice, dataField: "total", sort: true,
                    formatter: (date) => 'Get Invoice',
                  },
                  {
                    text: genralLan?.general?.paid, dataField: "paid", sort: true,
                    formatter: (paid) => paid ? 'Yes' : 'No',
                  },
                  {
                    text: genralLan?.general?.travellers, dataField: "travelersInfo", sort: true,
                    formatter: (travelersInfo) => <button
                      type="button"
                      className="btn btnviewproj text-capitalize ml-1"
                      onClick={() => handleShowTravellers(travelersInfo)}
                    >
                      {showTravelers ? genralLan?.general?.hide : genralLan?.general?.show}  {genralLan?.general?.travellers}
                    </button>,
                  },
                ]}
              />
              <Collapse in={showTravelers} timeout={100}>
                <div className="table-responsive  projectlisttabdiv  mb-2">
                  <table className="table projectlisttab  borderless text-center">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{genralLan?.general?.name}</th>
                        <th scope="col">{genralLan?.general?.phone}</th>
                        <th scope="col">{genralLan?.general?.email}</th>
                        {/* <th scope="col">Address</th>
                                <th scope="col">Country</th>
                              <th scope="col">Zip Code</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        travelersInfo?.map((traveler: any, i: number) => (

                          <tr>
                            <td>{i + 1}</td>
                            <td>
                              {traveler.gender} {traveler.firstName} {traveler.lastName}
                            </td>
                            {/* {
                              ((userDetails?.swt_role === 'provider' && reservation.paid) ||
                                (userDetails?.swt_role === 'client')) && (
                                <td>
                                  {traveler.phoneNumber}
                                </td>

                              )} */}
                            {/* {
                              ((userDetails?.swt_role === 'provider' && reservation.paid) ||
                                (userDetails?.swt_role === 'client')) && (
                                <td>{traveler.email}</td>
                              )} */}
                            {/* <td>{traveler.address1}, {traveler.address2}</td>
                                    <td>{traveler.country}</td>
                                  <td>{traveler.zip}</td> */}
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </Collapse>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ProjectListings;
