import {
  SET_CURRENT_ARTICLE,
  SET_CURRENT_DAYTODAYPROGRAM,
  SET_CURRENT_EVENT,
  SET_SIDEBAR,
  SideBarActionTypes,
  SideBarEnum,
  SideBarState,
} from "./type";

const initialState: SideBarState = {
  currentSideBar: SideBarEnum.None,
  currentEvent: undefined,
  currentArticle: undefined,
};

export function sideBarReducer(
  state = initialState,
  action: SideBarActionTypes
): SideBarState {
  switch (action.type) {
    case SET_SIDEBAR:
      return {
        ...state,
        currentSideBar: action.payload,
      };
    case SET_CURRENT_EVENT:
      return {
        ...state,
        currentSideBar: SideBarEnum.Projects,
        currentEvent: action.payload,
      };
    case SET_CURRENT_ARTICLE:
      return {
        ...state,
        currentSideBar: SideBarEnum.News,
        currentArticle: action.payload,
      };
    case SET_CURRENT_DAYTODAYPROGRAM:
      return {
        ...state,
        currentDayToDayProgram: action.payload,
      };
    default:
      return state;
  }
}
