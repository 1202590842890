import React, { FunctionComponent, useEffect, useState } from 'react'
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { ReservationsInfo, TravelersInfo } from '../types/CheckoutDetails';
import Countries from '../utilities/countries';
import { useSelector } from 'react-redux'
import { RootState } from '../store'
interface IParticpantsInput {
    participantsDetails: TravelersInfo[]
}
const particpantDetailsSchema = yup.object().shape({
    participantsDetails: yup.array().of(yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        country: yup.string().required(),
        zip: yup.number().required(),
        address1: yup.string().required(),
        address2: yup.string(),
        email: yup.string().email().required(),
        phoneNumber: yup.number().required()
    }))
});

interface TraverlersFormProps {
    reservationsInfo: ReservationsInfo,
    noOfParticipants: number,
    onSubmit: (iParticpantsInput: TravelersInfo[]) => void
}
const TraverlersForm: FunctionComponent<TraverlersFormProps> = ({ reservationsInfo, noOfParticipants, onSubmit }) => {
    const [repeatInfoChecked, setRepeatInfoChecked] = useState(true)
    const { register, handleSubmit, errors, watch, getValues, setValue } = useForm<IParticpantsInput>({
        resolver: yupResolver(particpantDetailsSchema),
        defaultValues: {
            participantsDetails: reservationsInfo.travelersInfo
        }
    })

    const firstCountry = watch('participantsDetails[0].country')
    const firstzipCode = watch('participantsDetails[0].zip')
    const firstAddress1 = watch('participantsDetails[0].address1')
    const firstAddress2 = watch('participantsDetails[0].address2')
    const firstEmail = watch('participantsDetails[0].email')
    const firstPhone = watch('participantsDetails[0].phoneNumber')

    useEffect(() => {
        setValue('participantsDetails', reservationsInfo.travelersInfo)
    }, [reservationsInfo])
    const Language = useSelector((state: RootState) => state.language.language?.content)
    const selectedLan = useSelector((state: RootState) => state.language.language?.language)

    useEffect(() => {
        if (repeatInfoChecked) {
            const allParticipants = getValues()
            if (allParticipants.participantsDetails.length > 1) {
                for (let i = 1; i < allParticipants.participantsDetails.length; i++) {
                    setValue(`participantsDetails[${i}].country`, firstCountry)
                    setValue(`participantsDetails[${i}].zip`, firstzipCode)
                    setValue(`participantsDetails[${i}].address1`, firstAddress1)
                    setValue(`participantsDetails[${i}].address2`, firstAddress2)
                    setValue(`participantsDetails[${i}].email`, firstEmail)
                    setValue(`participantsDetails[${i}].phoneNumber`, firstPhone)
                }
            }
        }
    }, [repeatInfoChecked, firstCountry, firstzipCode, firstAddress1, firstAddress2, firstEmail, firstPhone])


    const _onSubmit = (data: IParticpantsInput) => {
        onSubmit(data.participantsDetails)
    }
    return (
        <div className="row m-0">
            <h4 className="com-font fw700 text-red">{Language?.general?.travel_information}</h4>
            <div className="col-lg-12 paddlr0 com-font">
                <form onSubmit={handleSubmit(_onSubmit)}>
                    <div className="form-group">
                        {
                            [...new Array(noOfParticipants)].map((_, index) => {
                                const fieldName = `participantsDetails[${index}]`
                                return (
                                    <div className="row respadding">
                                        <div className="col-lg-12">
                                            <h5 className="com-font fw700 text-red">{Language?.general?.participant} #{index + 1}</h5>
                                        </div>
                                        <div className="col-lg-6">
                                            <input
                                                type="text"
                                                className="form-control resforminp"
                                                placeholder={Language?.travellerForm?.first_name}
                                                name={`${fieldName}.firstName`}
                                                ref={register} />
                                            {errors.participantsDetails && errors.participantsDetails![index]?.firstName && (<div className="invalid-feedback d-block">{Language?.travellerForm?.first_name_err}</div>)}
                                        </div>
                                        <div className="col-lg-6">
                                            <input
                                                type="text"
                                                className="form-control resforminp"
                                                placeholder={Language?.travellerForm?.last_name}
                                                name={`${fieldName}.lastName`}
                                                ref={register} />
                                            {errors.participantsDetails && errors.participantsDetails![index]?.lastName && (<div className="invalid-feedback d-block">{Language?.travellerForm?.last_name_err}</div>)}
                                        </div>
                                        <div className="col-lg-6" style={{ display: (index > 0 && repeatInfoChecked) ? 'none' : 'block' }}>
                                            <select className="form-control resforminp" id={`${fieldName}.country`} name={`${fieldName}.country`} ref={register}>
                                                <option disabled value="">{Language?.travellerForm?.select_country}</option>
                                                {Countries.map((c) => (
                                                    <option value={c.name}>{c.name}</option>
                                                ))}
                                            </select>
                                            {errors.participantsDetails && errors.participantsDetails![index]?.country && (<div className="invalid-feedback d-block">{Language?.travellerForm?.select_country_err}</div>)}
                                        </div>
                                        <div className="col-lg-6" style={{ display: (index > 0 && repeatInfoChecked) ? 'none' : 'block' }}>
                                            <input
                                                type="text"
                                                className="form-control resforminp"
                                                placeholder={Language?.travellerForm?.zip}
                                                name={`${fieldName}.zip`}
                                                ref={register} />
                                            {errors.participantsDetails && errors.participantsDetails![index]?.zip && (<div className="invalid-feedback d-block">{Language?.travellerForm?.zip_err}</div>)}
                                        </div>
                                        <div className="col-lg-12" style={{ display: (index > 0 && repeatInfoChecked) ? 'none' : 'block' }}>
                                            <input
                                                type="text"
                                                className="form-control resforminp"
                                                placeholder={Language?.travellerForm?.address_1}
                                                name={`${fieldName}.address1`}
                                                ref={register} />
                                            {errors.participantsDetails && errors.participantsDetails![index]?.address1 && (<div className="invalid-feedback d-block">{Language?.travellerForm?.address_1_err}</div>)}
                                        </div>
                                        <div className="col-lg-12" style={{ display: (index > 0 && repeatInfoChecked) ? 'none' : 'block' }}>
                                            <input
                                                type="text"
                                                className="form-control resforminp"
                                                placeholder={Language?.travellerForm?.address_2}
                                                name={`${fieldName}.address2`}
                                                ref={register} />
                                        </div>
                                        <div className="col-lg-6" style={{ display: (index > 0 && repeatInfoChecked) ? 'none' : 'block' }}>
                                            <input
                                                type="text"
                                                className="form-control resforminp"
                                                placeholder={Language?.travellerForm?.email}
                                                name={`${fieldName}.email`}
                                                ref={register} />
                                            {errors.participantsDetails && errors.participantsDetails![index]?.email && (<div className="invalid-feedback d-block">{Language?.travellerForm?.email_err}</div>)}
                                        </div>
                                        <div className="col-lg-6" style={{ display: (index > 0 && repeatInfoChecked) ? 'none' : 'block' }}>
                                            <input
                                                type="text"
                                                className="form-control resforminp"
                                                placeholder={Language?.travellerForm?.phone}
                                                name={`${fieldName}.phoneNumber`}
                                                ref={register} />
                                            {errors.participantsDetails && errors.participantsDetails![index]?.phoneNumber && (<div className="invalid-feedback d-block">{Language?.travellerForm?.phone_err}</div>)}
                                        </div>
                                        {
                                            index === 0 && (
                                                <div className="col-lg-12">
                                                    <div className="form-check resforminp">
                                                        <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={repeatInfoChecked} onChange={e => setRepeatInfoChecked(e.target.checked)} />
                                                        <label className="form-check-label  " htmlFor="exampleCheck1">{Language?.travellerForm?.use_info_for}</label>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            })
                        }

                        <div className="row respadding">
                            <div className="col-lg-12">
                                <button className="btn btn-default btnviewprojnext float-right" type="submit">{Language?.general?.next}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default TraverlersForm