import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
// const selectedLan = useSelector((state: RootState) => state.language.language?.language)

const Insurance: FunctionComponent = () => {
    const Insurances: any[] = useSelector((state: RootState) => state.language.language?.content?.insurance?.guides)

    return (
        <section>
            {Insurances?.map((item) => (
                <>
                    <p className="text-capitalize mb-0">
                        <a href={item?.link} target="_blank">{item?.title}</a>
                    </p>
                    <p>
                        {item?.body}
                    </p>
                </>
            ))}
        </section>
    )
}

export default Insurance;
