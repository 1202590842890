import React, { forwardRef, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { getDayCount, getDifferenceInDays, getDifferenceInDaysUsingCount, getFormatedDate, getIndexOfDayInWeek, getNewStartDateAndDay, getSpecificDayOfWeek, } from './helpers';

const UseCalendarHook = ({ setIncludedDates, originalStartDate, originalEndDate, setValue }) => {
    const newDate = originalStartDate;
    const INITIAL_DATE_RANGE = [
        originalStartDate, dayjs(newDate).add(1, 'd').toDate()
    ];
    const INITIAL_DATES_TO_INCLUDE = [{
        start: originalStartDate, end: dayjs(newDate).add(1, 'd').toDate()
    }];

    const calendarRef = useRef(null);

    const [dateRange, setDateRange] = useState(INITIAL_DATE_RANGE);
    const [startDate, endDate] = dateRange;
    const [dateToExclude, setDateToExclude] = useState([]);
    const [isRepeat, setIsRepeat] = useState(false);
    const [isDisabledRepeat, setIsDisabledRepeat] = useState(false);
    const [repeat, setRepeat] = useState("DAILY");
    const [repeatEvery, setRepeatEvery] = useState(1);
    const [minValueWithBuffer, setMinValueWithBuffer] = useState(1);
    const [eventInterval, setEventInterval] = useState(1);
    const [endRepeatOn, setEndRepeatOn] = useState(2);
    const [endRepeatAfter, setEndRepeatAfter] = useState(dayjs(originalEndDate).format('MM/DD/YYYY'));
    const [disableRepeatEndValues, setDisableEndRepeat] = useState({
        Never: false,
        Occurence: true,
        After: true,
    });
    const [activeRadio, setActiveRadio] = useState("Never");
    const [selectedDayOfWeek, setSelectedDayOfWeek] = useState();
    const [minDate, setMinDate] = useState(dayjs(originalStartDate).toDate());
    const [maxDate, setMaxDate] = useState(dayjs(originalStartDate).add(5, 'd').toDate());
    //States to handle new Calendar//
    const [reservationDate, setReservationDate] = useState(new Date());
    const [selectedReservationDateRange, setSelectedReservationDateRange] = useState({
        start: '',
        end: ''
    });
    //* States for monthly repeat //
    const [disableMonthlyRepeatRadioFields, setDisableMonthlyRepeatRadioFields] = useState({
        onSpecificDateOfMonth: false,
        onSpecificDayOfWeekInMonth: true
    });
    const [repeatOnDate, setRepeatOnDate] = useState(1);
    const [monthlyRepeatActiveRadio, setMonthlyRepeatActiveRadio] = useState("onSpecificDateOfMonth");
    const [repeatMonthly, setRepeatMonthly] = useState("MonthlyOnDate");



    useEffect(() => {
        const state = JSON.parse(localStorage.getItem('CalendarData'));
        if (state) {
            const { dateRange,
                dateToExclude,
                isRepeat,
                isDisabledRepeat,
                repeat,
                repeatEvery,
                minValueWithBuffer,
                eventInterval,
                endRepeatOn,
                repeatOnDate,
                endRepeatAfter,
                disableRepeatEndValues,
                activeRadio,
                selectedDayOfWeek,
                minDate, maxDate,
                reservationDate,
                selectedReservationDateRange,
                disableMonthlyRepeatRadioFields,
                monthlyRepeatActiveRadio, repeatMonthly } = state;
            const [startDate, endDate] = dateRange;
            setDateRange([dayjs(startDate).toDate(), dayjs(endDate).toDate()]);
            setDateToExclude(dateToExclude);
            setRepeat(repeat);
            setIsDisabledRepeat(isDisabledRepeat);
            setIsRepeat(isRepeat);
            setActiveRadio(activeRadio);
            setRepeatEvery(repeatEvery);
            setMinValueWithBuffer(minValueWithBuffer);
            setEventInterval(eventInterval);
            setEndRepeatOn(endRepeatOn);
            setEndRepeatAfter(endRepeatAfter);
            setDisableEndRepeat(disableRepeatEndValues)
            setReservationDate(dayjs(reservationDate).toDate())
            setSelectedReservationDateRange(selectedReservationDateRange);
            setMonthlyRepeatActiveRadio(monthlyRepeatActiveRadio);
            setSelectedDayOfWeek(selectedDayOfWeek);
            setMinDate(minDate);
            setMaxDate(maxDate);
            setDisableMonthlyRepeatRadioFields(disableMonthlyRepeatRadioFields);
            setRepeatMonthly(repeatMonthly);
            setRepeatOnDate(repeatOnDate);
        }
        else {
            const includedDateRange = [];
            includedDateRange.push({ start: startDate, end: endDate })
            setDateToExclude(includedDateRange);
            setIncludedDates(includedDateRange);
            setSelectedReservationDateRange({ start: dayjs(startDate).toDate(), end: dayjs(endDate).toDate() });
        }
    }, []);


    useEffect(() => {
        return () => {
            _HandleUnmountStates();
        };

    }, [dateRange,
        dateToExclude,
        isRepeat,
        isDisabledRepeat,
        repeat,
        repeatEvery,
        minValueWithBuffer,
        eventInterval,
        endRepeatOn,
        repeatOnDate,
        endRepeatAfter,
        disableRepeatEndValues,
        activeRadio,
        selectedDayOfWeek,
        minDate, maxDate,
        reservationDate,
        selectedReservationDateRange,
        disableMonthlyRepeatRadioFields,
        monthlyRepeatActiveRadio, repeatMonthly])


    const _HandleUnmountStates = () => {
        const data = {
            dateRange,
            dateToExclude,
            isRepeat,
            isDisabledRepeat,
            repeat,
            repeatEvery,
            minValueWithBuffer,
            eventInterval,
            endRepeatOn,
            repeatOnDate,
            endRepeatAfter,
            disableRepeatEndValues,
            activeRadio,
            selectedDayOfWeek,
            minDate, maxDate,
            reservationDate,
            selectedReservationDateRange,
            disableMonthlyRepeatRadioFields,
            monthlyRepeatActiveRadio, repeatMonthly
        }
        localStorage.setItem('CalendarData', JSON.stringify(data));
    }

    const getInitialStates = () => {
        const BUFFER = 1;
        let newMinRepeatValue = getDifferenceInDays(startDate, endDate);
        setValue('duration', newMinRepeatValue + 1, { shouldValidate: true });
        if (isRepeat) {
            if (repeat === "DAILY") {
                setMinValueWithBuffer(newMinRepeatValue + BUFFER + 1); //* +1 to include the current day//
                setRepeatEvery(newMinRepeatValue + BUFFER + 1); //* +1 to include the current day//
                setEventInterval(newMinRepeatValue + 1); //* +1 to include the current day//
            }
            else if (repeat === "WEEKLY") {
                setMinValueWithBuffer(1);
                setRepeatEvery(1);
                setDateToExclude([]);
                setIncludedDates([])
                setSelectedDayOfWeek(dayjs(startDate).day())
                setEventInterval(newMinRepeatValue + 1); //* +1 to include the current day//
            }
            else if (repeat === "MONTHLY") {
                setMinValueWithBuffer(1);
                setRepeatEvery(1);
                setDateToExclude([]);
                setEventInterval(newMinRepeatValue + 1); //* +1 to include the current day//
            }
        }
        else {
            setIncludedDates(INITIAL_DATE_RANGE)
            setDateToExclude(INITIAL_DATES_TO_INCLUDE);
        }
    }

    useEffect(() => {
        getInitialStates();
        if (dayjs(endDate).isSame(dayjs(originalEndDate).toDate())) {
            setIsDisabledRepeat(true);
            isRepeat && setIsRepeat(isRepeat => !isRepeat);
        }
        else setIsDisabledRepeat(false);
    }, [endDate, startDate, activeRadio, repeat]);


    useEffect(() => {
        if (isRepeat && disableRepeatEndValues['After'] === false && dayjs(endRepeatAfter).isBefore(dayjs(endDate))) {
            setDateRange([dayjs(startDate).toDate(), dayjs(endRepeatAfter).toDate()]);

        }
    }, [repeat, activeRadio, endRepeatAfter])


    function dailyOccurence({ newStartDate, newEndDate, bookedEvents, repeatEvery, interval }) {
        if (Number(endRepeatOn) > 1) {
            newStartDate = dayjs(newEndDate).add(1, interval).toDate();
            newEndDate = dayjs(newStartDate).add((eventInterval - 1), interval).toDate();
            for (let i = 1; i < Number(endRepeatOn); i++) {
                if (moment(newStartDate).isAfter(moment(originalEndDate))) {
                    break;
                }
                if (moment(newEndDate).isAfter(moment(originalEndDate))) {
                    break;
                }
                bookedEvents.push({
                    start: newStartDate,
                    end: newEndDate
                });
                newStartDate = dayjs(newEndDate).add(1, interval).toDate();
                newEndDate = dayjs(newStartDate).add((eventInterval - 1), interval).toDate();

            }
        }
        return bookedEvents;
    }

    function dailyNever({ newStartDate, newEndDate, bookedEvents, repeatEvery, interval }) {
        newStartDate = dayjs(newEndDate).add(1, interval).toDate();
        newEndDate = dayjs(newStartDate).add((eventInterval - 1), interval).toDate();
        while (moment(newStartDate).isSameOrBefore(moment(originalEndDate))) {
            if (moment(newStartDate).isAfter(moment(originalEndDate))) {
                break;
            }
            if (moment(newEndDate).isAfter(moment(originalEndDate))) {
                break;
            }
            bookedEvents.push({
                start: newStartDate,
                end: newEndDate
            });
            newStartDate = dayjs(newEndDate).add(1, interval).toDate();
            newEndDate = dayjs(newStartDate).add((eventInterval - 1), interval).toDate();
        }
        return bookedEvents;
    }

    function dailyAfter({ newStartDate, newEndDate, repeatEvery, bookedEvents, interval }) {
        newStartDate = dayjs(newEndDate).add(1, interval).toDate();
        newEndDate = dayjs(newStartDate).add((eventInterval - 1), interval).toDate();
        while (moment(newStartDate).isSameOrBefore(moment(endRepeatAfter))) {
            if (moment(newStartDate).isAfter(moment(endRepeatAfter))) {
                break;
            }
            if (moment(newEndDate).isAfter(moment(endRepeatAfter))) {
                break;
            }
            bookedEvents.push({
                start: newStartDate,
                end: newEndDate
            });
            newStartDate = dayjs(newEndDate).add(1, interval).toDate();
            newEndDate = dayjs(newStartDate).add((eventInterval - 1), interval).toDate();

        }
        return bookedEvents
    }

    function monthlyOccurence({ newStartDate, newEndDate, bookedEvents }) {
        if (Number(endRepeatOn) > 1) {
            for (let i = 1; i < Number(endRepeatOn); i++) {
                newStartDate = addMonthUntilNewStartDateNotGreaterThanPrevEndDate(newStartDate, newEndDate);
                if (getDifferenceInDays(newEndDate, newStartDate) >= 1) {
                    newEndDate = dayjs(newStartDate).add((eventInterval - 1), 'd').toDate();
                    if (moment(newStartDate).isAfter(moment(originalEndDate))) {
                        break;
                    }
                    if (moment(newEndDate).isAfter(moment(originalEndDate))) {
                        break;
                    }
                    bookedEvents.push({
                        start: newStartDate,
                        end: newEndDate
                    })
                }
            }
            return bookedEvents;
        }
    }

    function monthlyAfter({ newStartDate, newEndDate, bookedEvents }) {
        while (moment(newStartDate).isSameOrBefore(moment(endRepeatAfter))) {
            newStartDate = addMonthUntilNewStartDateNotGreaterThanPrevEndDate(newStartDate, newEndDate);
            if (getDifferenceInDays(newEndDate, newStartDate) >= 1) {
                newEndDate = dayjs(newStartDate).add((eventInterval - 1), 'd').toDate();
                if (moment(newStartDate).isAfter(moment(endRepeatAfter))) {
                    break;
                }
                if (moment(newEndDate).isAfter(moment(endRepeatAfter))) {
                    break;
                }
                bookedEvents.push({
                    start: newStartDate,
                    end: newEndDate
                })
            }
        }
        return bookedEvents;
    }

    const addMonthUntilNewStartDateNotGreaterThanPrevEndDate = (newStartDate, prevEndDate) => {
        let tempNewStartDate = newStartDate;
        while (moment(tempNewStartDate).isSameOrBefore(moment(prevEndDate))) {
            tempNewStartDate = dayjs(tempNewStartDate).add(1, 'M').toDate();
        }
        return tempNewStartDate;
    }

    function monthlyNever({ newStartDate, newEndDate, bookedEvents }) {
        while (moment(newStartDate).isSameOrBefore(moment(originalEndDate))) {
            newStartDate = addMonthUntilNewStartDateNotGreaterThanPrevEndDate(newStartDate, newEndDate);
            if (getDifferenceInDays(newEndDate, newStartDate) >= 1) {
                newEndDate = dayjs(newStartDate).add((eventInterval - 1), 'd').toDate();
                if (moment(newStartDate).isAfter(moment(originalEndDate))) {
                    break;
                }
                if (moment(newEndDate).isAfter(moment(originalEndDate))) {
                    break;
                }
                bookedEvents.push({
                    start: newStartDate,
                    end: newEndDate
                })
            }
        }
        return bookedEvents;
    }

    function weeklyNever({ newStartDate, newEndDate, bookedEvents, selectedStartDay, selectedEndDay }) {
        let prevStartDate = startDate, prevEndDate = endDate;
        while (moment(newStartDate).isSameOrBefore(moment(originalEndDate))) {
            if (dayjs(prevEndDate).add(1, 'd').day() <= selectedStartDay) {
                const diff = selectedStartDay - getDayCount(prevEndDate);
                newStartDate = dayjs(prevEndDate).add(diff, 'd').toDate();
                newEndDate = dayjs(newStartDate).add((eventInterval - 1), 'd').toDate();
            }
            else if ((selectedStartDay === 0 && getDayCount(prevEndDate) === 6)) {
                newStartDate = dayjs(prevEndDate).add(2, 'w').startOf('week').toDate();
                newEndDate = dayjs(newStartDate).add((eventInterval - 1), 'd').toDate();
            }
            else {
                const { nextStartDate } = getNewStartDateAndDay({ prevEndDate: prevEndDate, eventStartDay: selectedStartDay })
                newStartDate = nextStartDate;
                newEndDate = dayjs(newStartDate).add((eventInterval - 1), 'd').toDate();
            }
            if (dayjs(prevEndDate).add(1, 'd') <= newStartDate) {
                if (moment(newStartDate).isAfter(moment(originalEndDate))) {
                    break;
                }
                if (moment(newEndDate).isAfter(moment(originalEndDate))) {
                    // newEndDate = dayjs(originalEndDate).toDate();
                    // bookedEvents.push({
                    //     start: newStartDate,
                    //     end: newEndDate
                    // });
                    break;
                }
                bookedEvents.push({
                    start: newStartDate,
                    end: newEndDate
                })
            }
            else if (dayjs(prevEndDate).add(1, 'd') > newStartDate) {
                const { nextStartDate } = getNewStartDateAndDay({
                    prevEndDate: prevEndDate, eventStartDay: selectedStartDay
                });
                newStartDate = nextStartDate;
                newEndDate = dayjs(newStartDate).add((eventInterval - 1), 'd').toDate();
                if (moment(newStartDate).isAfter(moment(originalEndDate))) {
                    break;
                }
                if (moment(newEndDate).isAfter(moment(originalEndDate))) {
                    // newEndDate = dayjs(originalEndDate).toDate();
                    // bookedEvents.push({
                    //     start: newStartDate,
                    //     end: newEndDate
                    // });
                    break;
                }
                bookedEvents.push({
                    start: newStartDate,
                    end: newEndDate
                })
            }
            prevStartDate = newStartDate;
            prevEndDate = newEndDate;

        }
        return bookedEvents;
    }

    function weeklyOccurence({ newStartDate, newEndDate, bookedEvents, selectedStartDay }) {
        let prevStartDate = startDate, prevEndDate = endDate;
        for (let i = 1; i < Number(endRepeatOn); i++) {
            if (dayjs(prevEndDate).add(1, 'd').day() <= selectedStartDay) {
                const diff = selectedStartDay - getDayCount(prevEndDate);
                newStartDate = dayjs(prevEndDate).add(diff, 'd').toDate();
                newEndDate = dayjs(newStartDate).add((eventInterval - 1), 'd').toDate();
            }
            else if ((selectedStartDay === 0 && getDayCount(prevEndDate) === 6)) {
                newStartDate = dayjs(prevEndDate).add(2, 'w').startOf('week').toDate();
                newEndDate = dayjs(newStartDate).add((eventInterval - 1), 'd').toDate();
            }
            else {
                const { nextStartDate } = getNewStartDateAndDay({ prevEndDate: prevEndDate, eventStartDay: selectedStartDay })
                newStartDate = nextStartDate;
                newEndDate = dayjs(newStartDate).add((eventInterval - 1), 'd').toDate();
            }
            if (dayjs(prevEndDate).add(1, 'd') <= newStartDate) {
                if (moment(newStartDate).isAfter(moment(originalEndDate))) {
                    break;
                }
                if (moment(newEndDate).isAfter(moment(originalEndDate))) {
                    // newEndDate = dayjs(originalEndDate).toDate();
                    // bookedEvents.push({
                    //     start: newStartDate,
                    //     end: newEndDate
                    // });
                    break;
                }
                bookedEvents.push({
                    start: newStartDate,
                    end: newEndDate
                })
            }
            else if (dayjs(prevEndDate).add(1, 'd') > newStartDate) {
                const { nextStartDate } = getNewStartDateAndDay({
                    prevEndDate: prevEndDate, eventStartDay: selectedStartDay
                });
                newStartDate = nextStartDate;
                newEndDate = dayjs(newStartDate).add((eventInterval - 1), 'd').toDate();
                if (moment(newStartDate).isAfter(moment(originalEndDate))) {
                    break;
                }
                if (moment(newEndDate).isAfter(moment(originalEndDate))) {
                    // newEndDate = dayjs(originalEndDate).toDate();
                    // bookedEvents.push({
                    //     start: newStartDate,
                    //     end: newEndDate
                    // });
                    break;
                }
                bookedEvents.push({
                    start: newStartDate,
                    end: newEndDate
                })
            }
            prevStartDate = newStartDate;
            prevEndDate = newEndDate;
        }
        return bookedEvents;
    }

    function weeklyAfter({ newStartDate, newEndDate, bookedEvents, selectedStartDay }) {
        let prevStartDate = startDate, prevEndDate = endDate;
        while (moment(newStartDate).isSameOrBefore(moment(endRepeatAfter))) {
            if (dayjs(prevEndDate).add(1, 'd').day() <= selectedStartDay) {
                const diff = selectedStartDay - getDayCount(prevEndDate);
                newStartDate = dayjs(prevEndDate).add(diff, 'd').toDate();
                newEndDate = dayjs(newStartDate).add((eventInterval - 1), 'd').toDate();
            }
            else if ((selectedStartDay === 0 && getDayCount(prevEndDate) === 6)) {
                newStartDate = dayjs(prevEndDate).add(2, 'w').startOf('week').toDate();
                newEndDate = dayjs(newStartDate).add((eventInterval - 1), 'd').toDate();
            }
            else {
                const { nextStartDate } = getNewStartDateAndDay({ prevEndDate: prevEndDate, eventStartDay: selectedStartDay })
                newStartDate = nextStartDate;
                newEndDate = dayjs(newStartDate).add((eventInterval - 1), 'd').toDate();
            }
            if (dayjs(prevEndDate).add(1, 'd') <= newStartDate) {
                if (moment(newStartDate).isAfter(moment(endRepeatAfter))) {
                    break;
                }
                if (moment(newEndDate).isAfter(moment(endRepeatAfter))) {
                    // newEndDate = dayjs(endRepeatAfter).toDate();
                    // bookedEvents.push({
                    //     start: newStartDate,
                    //     end: newEndDate
                    // });
                    break;
                }
                bookedEvents.push({
                    start: newStartDate,
                    end: newEndDate
                })
            }
            else if (dayjs(prevEndDate).add(1, 'd') > newStartDate) {
                const { nextStartDate } = getNewStartDateAndDay({
                    prevEndDate: prevEndDate, eventStartDay: selectedStartDay
                });
                newStartDate = nextStartDate;
                newEndDate = dayjs(newStartDate).add((eventInterval - 1), 'd').toDate();
                if (moment(newStartDate).isAfter(moment(endRepeatAfter))) {
                    break;
                }
                if (moment(newEndDate).isAfter(moment(endRepeatAfter))) {
                    // newEndDate = dayjs(endRepeatAfter).toDate();
                    // bookedEvents.push({
                    //     start: newStartDate,
                    //     end: newEndDate
                    // });
                    break;
                }
                bookedEvents.push({
                    start: newStartDate,
                    end: newEndDate
                })
            }
            prevStartDate = newStartDate;
            prevEndDate = newEndDate;
        }
        return bookedEvents;
    }

    // const addMonthAndGetStartUntilNewStartDateNotGreaterThanPrevEndDate = (newStartDate, prevEndDate) => {
    //     let tempNewStartDate = newStartDate;
    //     while (moment(tempNewStartDate).isSameOrBefore(moment(prevEndDate))) {
    //         tempNewStartDate = dayjs(tempNewStartDate).add(1, "M").startOf('M').toDate();
    //     }
    //     return tempNewStartDate;
    // }

    const createNewStartDate = (newStartDate, newEndDate, startDay, weekOfMonth) => {
        let tempNewStartDate = newStartDate;

        while (moment(tempNewStartDate).isSameOrBefore(moment(newEndDate))) {
            let startOfNewMonth = dayjs(tempNewStartDate).add(1, 'M').startOf('M').toDate();
            const dayCountNewMonth = getDayCount(startOfNewMonth);

            let difference = getDifferenceInDaysUsingCount(dayCountNewMonth, startDay);
            startOfNewMonth = dayjs(startOfNewMonth).add(difference, 'd').toDate();

            tempNewStartDate = getSpecificDayOfWeek(startOfNewMonth, weekOfMonth);
        }
        return tempNewStartDate;
    }

    function NeverOfMonthlyEventsOnSpecificDayOfMonth({ newStartDate, newEndDate, startDay, weekOfMonth, bookedEvents }) {
        while (moment(newStartDate).isSameOrBefore(moment(originalEndDate))) {
            newStartDate = createNewStartDate(newStartDate, newEndDate, startDay, weekOfMonth);
            if (getDifferenceInDays(newEndDate, newStartDate) >= 1) {
                newEndDate = dayjs(newStartDate).add((eventInterval - 1), 'd').toDate();
                if (moment(newStartDate).isAfter(moment(originalEndDate))) {
                    break;
                }
                if (moment(newEndDate).isAfter(moment(originalEndDate))) {
                    break;
                }
                bookedEvents.push({
                    start: newStartDate,
                    end: newEndDate
                })
            }
        }
        return bookedEvents;
    }

    function AfterOfMonthlyEventsOnSpecificDayOfMonth({ newStartDate, newEndDate, weekOfMonth, startDay, bookedEvents }) {
        while (moment(newStartDate).isSameOrBefore(moment(endRepeatAfter))) {
            newStartDate = createNewStartDate(newStartDate, newEndDate, startDay, weekOfMonth);
            if (getDifferenceInDays(newEndDate, newStartDate) >= 1) {
                newEndDate = dayjs(newStartDate).add((eventInterval - 1), 'd').toDate();
                if (moment(newStartDate).isAfter(moment(endRepeatAfter))) {
                    break;
                }
                if (moment(newEndDate).isAfter(moment(endRepeatAfter))) {
                    break;
                }
                bookedEvents.push({
                    start: newStartDate,
                    end: newEndDate
                })
            }
        }
        return bookedEvents;
    }

    function OccurenceOfMonthlyEventsOnSpecificDayOfMonth({ newStartDate, newEndDate, weekOfMonth, startDay, bookedEvents }) {
        for (let i = 1; i < Number(endRepeatOn); i++) {
            newStartDate = createNewStartDate(newStartDate, newEndDate, startDay, weekOfMonth);
            if (getDifferenceInDays(newEndDate, newStartDate) >= 1) {
                newEndDate = dayjs(newStartDate).add((eventInterval - 1), 'd').toDate();
                if (moment(newStartDate).isAfter(moment(originalEndDate))) {
                    break;
                }
                if (moment(newEndDate).isAfter(moment(originalEndDate))) {
                    break;
                }
                bookedEvents.push({
                    start: newStartDate,
                    end: newEndDate
                })
            }
        }
        return bookedEvents;
    }




    const _HandleDailyEvents = ({ bookedEvents }) => {
        setMinDate(originalStartDate)
        let newStartDate = startDate;
        let newEndDate = endDate;
        if (disableRepeatEndValues['Occurence'] === false) {
            bookedEvents = dailyOccurence({ newStartDate, newEndDate, bookedEvents, repeatEvery: repeatEvery - 1, interval: 'd' });
        }
        else if (disableRepeatEndValues['After'] === false) {

            bookedEvents = dailyAfter({ bookedEvents, interval: 'd', newEndDate, newStartDate, repeatEvery: repeatEvery - 1 });
        }
        else if (disableRepeatEndValues['Never'] === false) {
            bookedEvents = dailyNever({ newStartDate, newEndDate, bookedEvents, interval: 'd', repeatEvery: repeatEvery - 1 });
        }
        return bookedEvents;
    }

    const _RecurrenceOnSameDay = ({ bookedEvents, selectedStartDay, selectedEndDay }) => {
        let newStartDate = startDate, newEndDate = endDate;
        if (disableRepeatEndValues['Occurence'] === false) {
            if (Number(endRepeatOn) > 1) {
                bookedEvents = weeklyOccurence({ bookedEvents, newEndDate, newStartDate, selectedStartDay });
            }
        }
        else if (disableRepeatEndValues['After'] === false) {
            bookedEvents = weeklyAfter({ bookedEvents, newEndDate, newStartDate, selectedStartDay });
        }
        else if (disableRepeatEndValues['Never'] === false) {
            bookedEvents = weeklyNever({
                newStartDate, newEndDate, bookedEvents, repeatEvery,
                selectedStartDay, selectedEndDay
            })
        }
        return bookedEvents;
    }

    const _HandleWeeklyEvents = ({ bookedEvents }) => {
        let selectedStartDay = getDayCount(startDate);
        let selectedEndDay = getDayCount(endDate);
        // let dateOnSelectedDateOfWeek = dayjs().day(selectedDayOfWeek).toDate()
        //*standing on selected day of week//
        if (selectedStartDay === selectedDayOfWeek) {
            bookedEvents.push({
                start: startDate,
                end: endDate
            });
            bookedEvents = _RecurrenceOnSameDay({ bookedEvents, selectedStartDay, selectedEndDay });
        }
        return bookedEvents;
    }

    const _HandleMonthlyEvents = ({ bookedEvents }) => {
        let newStartDate = startDate, newEndDate = endDate;
        if (disableRepeatEndValues['Occurence'] === false) {
            bookedEvents = monthlyOccurence({ newStartDate, newEndDate, interval: 'm', bookedEvents, repeatEvery });
        }
        else if (disableRepeatEndValues["After"] === false) {
            bookedEvents = monthlyAfter({ newStartDate, newEndDate, bookedEvents, repeatEvery })
        }
        else if (disableRepeatEndValues["Never"] === false) {
            bookedEvents = monthlyNever({ newStartDate, newEndDate, bookedEvents, repeatEvery });
        }

        return bookedEvents;
    }

    const _HandleMonthlyEventsOnDate = ({ bookedEvents }) => {
        const presentDayCount = getDayCount(startDate);
        // const repeatEvery = 1 //TODO: Remove and replace with repeatEvery count from state//
        const weekOfMonth = getIndexOfDayInWeek(startDate);
        let newStartDate = startDate, newEndDate = endDate;
        if (disableRepeatEndValues["Occurence"] === false) {
            if (Number(endRepeatOn) > 1) {
                bookedEvents = OccurenceOfMonthlyEventsOnSpecificDayOfMonth({ newStartDate, newEndDate, startDay: presentDayCount, weekOfMonth, bookedEvents })

            }
        }
        else if (disableRepeatEndValues["After"] === false) {
            bookedEvents = AfterOfMonthlyEventsOnSpecificDayOfMonth({ newStartDate, newEndDate, startDay: presentDayCount, weekOfMonth, bookedEvents })
        }
        else if (disableRepeatEndValues["Never"] === false) {
            bookedEvents = NeverOfMonthlyEventsOnSpecificDayOfMonth({ newStartDate, newEndDate, startDay: presentDayCount, weekOfMonth, bookedEvents })
        }

        return bookedEvents;
    }



    useEffect(() => {
        if (isRepeat) {
            let bookedEvents = [];
            if (repeat === "DAILY") {
                bookedEvents.push({ start: startDate, end: endDate });
                bookedEvents = _HandleDailyEvents({ bookedEvents })
            }
            else if (repeat === "WEEKLY") {
                bookedEvents = _HandleWeeklyEvents({ bookedEvents })
            }
            else if (repeat === "MONTHLY") {
                bookedEvents.push({ start: startDate, end: endDate });
                if (repeatMonthly === "MonthlyOnDate") {
                    bookedEvents = _HandleMonthlyEvents({ bookedEvents })
                }
                else {
                    bookedEvents = _HandleMonthlyEventsOnDate({ bookedEvents })
                }
            }
            setDateToExclude(bookedEvents);
            setIncludedDates(bookedEvents)
        }

    }, [startDate, endDate, repeatEvery, endRepeatOn, endRepeatAfter, isRepeat, activeRadio, selectedDayOfWeek, repeatMonthly, eventInterval]);




    const DateInput = forwardRef(({ onClick }, ref) => (
        <input
            ref={ref}
            value={`${dayjs(startDate).format("DD/MM/YYYY")} - ${dayjs(
                endDate
            ).format("DD/MM/YYYY")}`}
            onClick={onClick}
            className="form-control input-group-border-let-none"
            id="Date"
            aria-describedby="wishes"
        />
    ));

    const _ToggleRepeatBtn = ({ target }) => {
        const { checked } = target;
        setDateRange(INITIAL_DATE_RANGE);
        setMinDate(dayjs(originalStartDate).toDate());
        setSelectedDayOfWeek(dayjs(originalStartDate).day())
        setIsRepeat(checked);
        setRepeat("DAILY")
    };

    const _CheckRepeatValue = ({ target }) => {
        const { value } = target;
        setRepeat(value);
    };

    const _HandleChangeRepeeatEvery = ({ target }) => {
        const { value } = target;
        setRepeatEvery(value);
    };

    const _HandleOccurenceAndAfterEndValue = ({ target }) => {
        const { value, name } = target;
        if (name === "EndRepeatOn") {
            setEndRepeatOn(value);
        }
        else if (name === "EndRepeatAfter")
            setEndRepeatAfter(value);
    }

    const _handleEndRepeat = ({ target }) => {
        const { value } = target;
        setActiveRadio(value);
        setDisableEndRepeat(value === "Never" ?
            { Occurence: true, After: true, Never: false }
            : value === "Occurence" ?
                { Occurence: false, After: true, Never: true }
                : value === "After" ?
                    { Occurence: true, After: false, Never: true } : '');
    }

    const handleShowCalendar = () => {
        calendarRef.current.openCalendar();
    }

    const _HandleSelectedWeek = (name) => {
        setSelectedDayOfWeek(name);
    }

    const _HandleDateRange = (date) => {
        setDateRange(date);
    }


    const _CalculateDateRangeToInclude = () => {
        const includedDates = [];
        if (isEmpty(dateToExclude)) return [];
        for (let item of dateToExclude) {
            const { start } = item;
            includedDates.push(start);
        }
        return includedDates;
    }

    const _HighlightColor = (date) => {
        if (isEmpty(dateToExclude)) return undefined;
        for (let item of dateToExclude) {
            const { start, end } = item;
            let tempStartDate = moment(start).add(1, 'days').startOf('day');
            while (moment(tempStartDate).isSameOrBefore(moment(end))) {
                if (moment(tempStartDate).isSame(moment(date))) {
                    return 'highlight'
                }
                tempStartDate = dayjs(tempStartDate).add(1, 'd').toDate()
            }
        }
    }


    const _HandleNewCalendarDate = (date) => {
        for (let item of dateToExclude) {
            const { start, end } = item;
            if (getFormatedDate(start) === getFormatedDate(date)) {
                setSelectedReservationDateRange({ start, end });
                break;
            }
        }
        setReservationDate(date);
    }

    //* Monthly Component Handlers //



    return {
        repeat, isRepeat, selectedDayOfWeek, selectedReservationDateRange, startDate, endDate, dateRange,
        dateToExclude, _HandleNewCalendarDate, _HighlightColor, _CalculateDateRangeToInclude,
        _HandleDateRange, _HandleSelectedWeek, handleShowCalendar, _handleEndRepeat,
        _HandleOccurenceAndAfterEndValue, _HandleChangeRepeeatEvery, _CheckRepeatValue, _ToggleRepeatBtn,
        DateInput, repeatEvery, minValueWithBuffer, eventInterval, endRepeatOn,
        endRepeatAfter, disableRepeatEndValues, activeRadio, minDate, maxDate, reservationDate,
        disableMonthlyRepeatRadioFields, monthlyRepeatActiveRadio, setMonthlyRepeatActiveRadio,
        setDisableMonthlyRepeatRadioFields, setRepeatOnDate, repeatOnDate, repeatMonthly, setRepeatMonthly,
        isDisabledRepeat
    }
}

export default UseCalendarHook
