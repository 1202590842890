import React, { FunctionComponent, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

import * as yup from "yup";
import { fetchLoggedinUserDetails, sendOTP } from "../../store/Auth";
import axiosInstance from "../../utilities/axiosInstance";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
interface OtpSideBarProps {
  show: boolean;
  onClose: () => void;
  otp?: string;
}
interface ISignUpInputs {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  termsAgreed: boolean;
  privacyPolicy: boolean;
}
export const OtpSideBar: FunctionComponent<OtpSideBarProps> = ({
  show,
  onClose,
  otp,
}) => {
  const isMobileView = useMediaQuery({ maxWidth: 700 });
  const style = { width: show ? isMobileView ? "100%" : "300px" : "0px" };
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (rootState: RootState) => rootState.authReducer.UserDetails
  );

  const [Otp, setOtp] = useState<string>("");

  const { register, handleSubmit, errors, getValues } = useForm<ISignUpInputs>(
    {}
  );
  const onVerify = async () => {
    try {
      const res = await axiosInstance.post(
        `/api/user/email-change/${userDetails?.email}`,
        { otp: Otp }
      );
      toast.success(res.data.message);
      dispatch(fetchLoggedinUserDetails());
      onClose();
    } catch (e: any) {
      console.log("Otp verify api failed: ", e);
      toast.error(e.response.data.message);
    }
  };
  return (
    <div className="sidenav com-font" style={style}>
      <a
        href="javascript:void(0)"
        className="closebtn closex"
        onClick={onClose}
      >
        ×
      </a>
      <div className="col-lg-12">
        <form onSubmit={handleSubmit(onVerify)}>
          <div className="small signintxt">Email verification</div>
          <div className="small signintxt">
            Time to check your email. To continue, please enter the one-time
            password that we sent to your old email address. Please note that
            this code is only valid for 10 minutes. When the time expires you
            will need to sign in again with your old email address.
          </div>
          <div className="paddsearchfilup">
            <div className="input-group">
              <input
                type="text"
                placeholder="OTP"
                className="form-control"
                name="OTP"
                value={otp}
                onInput={(e) => setOtp((e.target as HTMLInputElement).value)}
              />
            </div>
          </div>
          <div className="paddsearchfilup text-center">
            <button
              className="btn btn-sm btn-default combtnclick btn-block"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
       
      </div>
    </div>
  );
};
