import { yupResolver } from "@hookform/resolvers/yup";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { RootState } from "../../store";
import {
  loginLocal,
  sendOTP,
  setAuthErrorMSg,
  setUserDetails,
} from "../../store/Auth";
import { setCurrentSideBar, SideBarEnum } from "../../store/SideBar";
import FacebookLogin from "react-facebook-login";
import axiosInstance from "../../utilities/axiosInstance";
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
interface LoginSideBarProps {
  show: boolean;
  onClose: () => void;
  emailChanged?: any;
}

interface ILoginInputs {
  email: string;
  password: string;
}

const loginValidationSchema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
});

export const LoginSideBar: FunctionComponent<LoginSideBarProps> = ({
  show,
  onClose,
  emailChanged,
}) => {
  const dispatch = useDispatch();

  const errorMsg = useSelector(
    (rootState: RootState) => rootState.authReducer.errorMsg
  );
  const authLan = useSelector(
    (rootState: RootState) => rootState.language.language?.content?.signin!
  );
  const onRegisterClick = () => dispatch(setCurrentSideBar(SideBarEnum.Signup));
  const onForgotClick = () => dispatch(setCurrentSideBar(SideBarEnum.Forgot));
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [user, setUser] = useState<any>({});
  const isMobileView = useMediaQuery({ maxWidth: 700 });
  const style = { width: show ? isMobileView ? "100%" : "300px" : "0px" };
  const { register, handleSubmit, errors } = useForm<ILoginInputs>({
    resolver: yupResolver(loginValidationSchema),
  });

  useEffect(() => {
    return () => {
      dispatch(setAuthErrorMSg(""));
    };
  }, []);
  useEffect(() => {
    if (emailChanged) {
      setOtp(emailChanged?.otp);
      setOtpSent(true);
    }
  }, [emailChanged]);
  
  const onSubmit = async ({ email, password }: ILoginInputs) => {
    try {
      const res: any = await dispatch(loginLocal(email, password));
      if (res === undefined) {
      } else if (res.success === true && res.resend === false) {
        onClose();
      } else if (res.success === true && res.resend === true) {
        setOtpSent(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const OnVerify = async ({ email }: ILoginInputs) => {
    try {
      await dispatch(sendOTP(email, otp));
      onClose();
      setOtpSent(false);
    } catch (e) {
      console.log("err in Login verify: ", e);
    }
  };

  const responseFacebook = async (response: any) => {
    try {
      const { data } = await axiosInstance.post("/api/auth/google", {
        ...response,
      });
      const { token, user } = data;
      localStorage.setItem("swt-token", token);
      dispatch(setUserDetails(user));
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

const login = useGoogleLogin({
  onSuccess: (codeResponse) => setUser(codeResponse),
  onError: (error) => console.log('Login Failed:', error)
});

useEffect(
  () => {
      if (Object.keys(user).length) {
          axios
            .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user?.access_token}`, {
                headers: {
                    Authorization: `Bearer ${user?.access_token}`,
                    Accept: 'application/json'
                }
            })
            .then(async (res) => {
              try {
                const { data } = await axiosInstance.post("/api/auth/google", {...res.data });
                const { token, user } = data;
                localStorage.setItem("swt-token", token);
                dispatch(setUserDetails(user));
                onClose();
                toast.success('Logged in successfully');
                setUser({});
              } catch (error) {
                console.log(error);
              }
            })
            .catch((err) => console.log(err));
      }
  },
  [ user ]
);

  return (
    <div className="sidenav com-font" style={style}>
      <a
        href="javascript:void(0)"
        className="closebtn closex"
        onClick={onClose}
      >
        ×
      </a>
      <div className="col-lg-12">
        <div className="searchfiltertxt txtup">{authLan?.sign_in}</div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: otpSent ? "none" : "block" }}
        >
          <div className="paddsearchfilup">
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                placeholder={authLan?.email}
                name="email"
                ref={register}
              />
            </div>
            {errors.email && (
              <div className="invalid-feedback d-block">
                {errors.email.message}
              </div>
            )}
          </div>
          <div className="paddsearchfilup">
            <div className="input-group">
              <input
                className="form-control"
                type="password"
                placeholder={authLan?.password}
                name="password"
                ref={register}
              />
            </div>
            {errors.password && (
              <div className="invalid-feedback d-block">
                Password is required
              </div>
            )}
          </div>
          <div className="paddsearchfilup text-center">
            {errorMsg && (
              <div className="invalid-feedback d-block">{errorMsg}</div>
            )}
            <button
              className="btn btn-sm btn-default combtnclick btn-block"
              type="submit"
            >
              {authLan?.sign_in}
            </button>
            <span
              onClick={onRegisterClick}
              className="text-white small text-center accopentxt"
            >
              {authLan?.no_account}
            </span>
            <br />
            <span
              className="text-white small text-center accopentxt"
              style={{ fontSize: "14px" }}
              onClick={onForgotClick}
            >
              {authLan?.forgot_password}
            </span>
          </div>
        </form>
        <form
          onSubmit={handleSubmit(OnVerify)}
          style={{ display: otpSent ? "block" : "none" }}
        >
          <div className="small signintxt">{authLan?.check_email}.</div>
          <div className="small signintxt">{authLan?.enter_otp_desc}</div>
          <div className="paddsearchfilup">
            <div className="input-group">
              <input
                type="text"
                placeholder={authLan?.enter_otp_place}
                className="form-control"
                name="OTP"
                value={otp}
                onInput={(e) => setOtp((e.target as HTMLInputElement).value)}
              />
            </div>
          </div>
          <div className="paddsearchfilup text-center">
            <button
              className="btn btn-sm btn-default combtnclick btn-block"
              type="submit"
            >
              {authLan?.submit}
            </button>
          </div>
        </form>
        {errorMsg && <div className="invalid-feedback d-block">{errorMsg}</div>}
        {!emailChanged?.isChanged && (
          <div className="paddsearchfilup">
            {/* <div className="orsignuptxt txtup">{authLan?.or}</div> */}
            {/* <div className="paddsearchfilup">
              <button className="my-google-button-class btn btn-sm btn-default btn-block bg-white" onClick={() => login()}><img src="images/google-ic.svg" className="img-fluid mr-2" alt="" /> Sign in with Google</button>
            </div> */}
            {/* <div className="paddsearchfilup">
              <FacebookLogin
                // appId="2164125103769799"
                appId={process.env.REACT_APP_FACEBOOK_APP_ID || ""}
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                cssClass="my-facebook-button-class btn btn-sm btn-default btn-block"
                icon="fa-facebook"
                textButton="Sign in with Facebook"
              />
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};
