import { LanguageActionTypes, LanguageState, SET_LANGUAGE } from "./type"

const initialState: LanguageState = {
    language: {
        content: undefined,
        language: localStorage.getItem('language') || "en"
    }
}

export function languageReducer(state = initialState, action: LanguageActionTypes): LanguageState {
    switch (action.type) {
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }
        default:
            return state
    }
}