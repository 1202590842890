import { CartActionTypes, CartState, SET_CART_ITEMS, SET_RESERVATIONS } from "./type"

const initialState: CartState = {
    Cart: undefined,
    Reservations: []
}

export function cartReducer(state = initialState, action: CartActionTypes): CartState {
    switch (action.type) {
        case SET_CART_ITEMS:
            return {
                ...state,
                Cart: action.payload
            }
        case SET_RESERVATIONS:
            return {
                ...state,
                Reservations: action.payload
            }
        default:
            return state
    }
}