import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import range from "lodash/range";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import {
  CloudinaryImg,
  ExtraAccommodation,
  ExtraChoiceOfFood,
  Project,
} from "../../store/Projects/type";
import FileUpload from "../FileUpload";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import Countries from "../../utilities/countries";
import { emptyStringToNull } from "../../util";
import { Modal } from "react-bootstrap";
import { AutoComplete } from "../autoComplete";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { saveEventAsDraft } from "../../store/Drafts";

const projectSchema = yup.object().shape({
  available: yup.boolean(),
  customId: yup.string(),
  projectName: yup.string().required(),
  // contactEmail: yup.string().required(),
  projectOwnerName: yup.string().required(),
  ownerDescription: yup.string().required(),
  projectDescription: yup.string().required(),
  localSupervisorName: yup.string().required(),
  localSupervisorPhone: yup.number().required(),
  location: yup.string().required(),
  startDate: yup.date().when([], {
    is: (val: any) => {
      return val === "" || val === undefined || val === null
    },
    then: yup.date().required(),
    otherwise: yup.date().notRequired()
  }),
  endDate: yup.date().when([], {
    is: (val: any) => val === "" || val === undefined || val === null,
    then: yup.date().required(),
    otherwise: yup.date().notRequired()
  }),
  capacity: yup.number().required(),
  category: yup.string().required(),
  accommodationDescription: yup.string().required(),
  // price: yup.number().required(),
  pricePerDay: yup.number().transform(emptyStringToNull).nullable(),
  // pricePerMonth: yup.number().transform(emptyStringToNull).nullable(),
  additionalPrice: yup.string(),
  foodDescription: yup.string().required(),
  transportation: yup.string().required(),
  levelOfDifficulty: yup.string().required(),
  nearAirport: yup.string().required(),
  spokenLanguages: yup.string().required(),
  additionalInfo: yup.string(),
  typicalDay: yup.string().required(),
  eventCity: yup.string().required(),
  noticePeriod: yup.number().required(),
  extraAccommodation: yup.array().of(
    yup.object().shape({
      category: yup.string().required(),
      extraPrice: yup.number().required(),
      description: yup.string().required(),
    })
  ),
  // qualifications: yup.array().of(yup.string()),
  feedback: yup.array().of(
    yup.object().shape({
      value: yup.string(),
    })
  ),
  choiceOfFood: yup.string().required(),
  foodPrice: yup.number().default(0).required(),
  extraChoiceOfFood: yup.array().of(
    yup.object().shape({
      category: yup.string().required(),
      description: yup.string().required(),
    })
  ),
  natureOfEvent: yup.string().required(),
  natureOfEventdescription: yup.string().required(),
  airportCost: yup.number().required(),
  sdg: yup.string().required(),

});

interface ProjectFormProps {
  initialData?: Project;
  onSubmit: (project: Project, isDraft: boolean) => void;
  isProviderForm?: boolean;
  status?: boolean;
  onSave?: (data: any, shoudSaveDraft: boolean) => void;
  onPrev?: () => void;
  onStartOver?: () => void;
  hidePrev?: boolean;
  isDraft?: boolean;
}

interface PicError {
  field: string;
  error: boolean;
}

const ProjectForm: FunctionComponent<ProjectFormProps> = ({
  initialData,
  onSubmit,
  isProviderForm,
  status,
  onSave,
  onPrev,
  onStartOver,
  hidePrev,
  isDraft
}) => {
  const userDetails = useSelector(
    (rootState: RootState) => rootState.authReducer.UserDetails!
  );
  const projectLan = useSelector(
    (rootState: RootState) => rootState.language.language?.content?.project!
  );
  console.log(projectLan);
  const Language = useSelector(
    (rootState: RootState) => rootState.language.language?.content!
  );
  const [projectPictures, setProjectPictures] = useState<CloudinaryImg[]>(
    initialData?.projectPictures || []
  );
  const [projectOwnerPicture, setProjectOwnerPicture] = useState<
    CloudinaryImg[]
  >(initialData?.projectOwnerPicture || []);
  const [accommodationPictures, setAccommodationPictures] = useState<
    CloudinaryImg[]
  >(initialData?.accommodationPicture || []);
  const [transportationPictures, setTransportationPictures] = useState<
    CloudinaryImg[]
  >(initialData?.transportationPicture || []);
  const [showStandards, setShowStandards] = useState<string | undefined>();
  const [includedDates, setIncludedDates] = useState<any[] | undefined>();
  const [pictureErrors, setPictureErrors] = useState<PicError[]>([
    { field: "projectPictures", error: false },
    { field: "accommodationPictures", error: false },
    { field: "projectOwnerPicture", error: false },
    { field: "transportationPictures", error: false },
  ]);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation()
  console.log("include dates", initialData, isDraft, location)
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    setValue,
    watch,
  } = useForm<Project>({
    resolver: yupResolver(projectSchema),
    defaultValues: initialData && {
      ...initialData,
      startDate: dayjs(initialData?.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(initialData?.endDate).format("YYYY-MM-DD"),
      feedback: initialData?.feedback?.map((v) => ({ value: v.value })) || [],
    },
  });
  const defaultAccomadation = watch("category");
  const startDate = watch("startDate");
  const endDate = watch("endDate");
  const capacity = watch("capacity");
  const extraAccommodation = watch("extraAccommodation");
  const choiceOfFood = watch('choiceOfFood')
  const extraChoiceOfFood = watch('extraChoiceOfFood')
  const {
    fields: extraAccommodationFields,
    append: appendExtraAccomodation,
    remove: removeExtraAccomodation,
  } = useFieldArray<ExtraAccommodation>({
    control,
    name: "extraAccommodation",
  });
  const {
    fields: extraChoiceOfFoodFields,
    append: appendExtraChoiceOfFood,
    remove: removeextraChoiceOfFood,
  } = useFieldArray<ExtraChoiceOfFood>({
    control,
    name: "extraChoiceOfFood",
  });
  const {
    fields: feedbackFields,
    append: appendFeedback,
    remove: removeFeedback,
  } = useFieldArray<String>({
    control,
    name: "feedback",
  });

  const settingError = (fieldName?: string[]) => {
    const newErrors = pictureErrors.map((item) => {
      if (fieldName?.includes(item.field)) {
        return { field: item.field, error: true };
      } else {
        return { field: item.field, error: false };
      }
    });

    setPictureErrors(newErrors);
  };

  const _onSubmit = async (data: Project) => {
    const picturesArr = [];
    if (!projectPictures.length) picturesArr.push("projectPictures");
    if (!accommodationPictures.length)
      picturesArr.push("accommodationPictures");
    if (!projectOwnerPicture.length) picturesArr.push("projectOwnerPicture");
    if (!transportationPictures.length)
      picturesArr.push("transportationPictures");

    if (
      !projectPictures.length ||
      !accommodationPictures.length ||
      !projectOwnerPicture.length ||
      !transportationPictures.length
    ) {
      await settingError(picturesArr);
    } if (!includedDates?.length) {
      await alert('select date')
    }
    else {
      onSubmit({
        ...data,
        projectPictures,
        includedEventsDates: includedDates,
        accommodationPicture: accommodationPictures,
        projectOwnerPicture,
        transportationPicture: transportationPictures,
        createdBy: userDetails?.id,
        localSupervisorPhone: String(data.localSupervisorPhone),
        startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(data.endDate).format('YYYY-MM-DD'),
      }, isDraft ? isDraft : false);
    }
  };

  const onSaveDraft = () => {
    const data = getValues();
    console.log("draft", data)
    onSave!(
      {
        ...data,
        projectPictures,
        includedEventsDates: includedDates || [],
        accommodationPicture: accommodationPictures,
        projectOwnerPicture,
        transportationPicture: transportationPictures,
        createdBy: userDetails?.id,
        localSupervisorPhone: String(data.localSupervisorPhone),
        startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(data.endDate).format('YYYY-MM-DD'),
        // feedback: data?.feedback?.map((d: any) => d.value) || [],
      },
      true
    );
  };

  const saveProjDraft = async () => {
    const data = getValues();
    const requestData = {
      ...data,
      projectPictures,
      includedEventsDates: includedDates || [],
      accommodationPicture: accommodationPictures,
      projectOwnerPicture,
      transportationPicture: transportationPictures,
      createdBy: userDetails?.id,
      localSupervisorPhone: String(data.localSupervisorPhone),
      startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(data.endDate).format('YYYY-MM-DD'),
    }
    try {
      await dispatch(saveEventAsDraft(requestData, 'project'));
      toast.success("Saved Draft");
      // history.push('/profile/projects')
    } catch (error: any) {
      toast.error(error.response.data.err.map((e: any) => e.message).join('\n'));
    }
  }

  const selectedAccomdations = useMemo(() => {
    return [
      ...(extraAccommodation?.map((acc) => acc.category) || []),
      defaultAccomadation,
    ];
  }, [extraAccommodation, defaultAccomadation]);

  const selectedChoiceOfFood = useMemo(() => {
    return [
      ...(extraChoiceOfFood?.map((acc) => acc.category) || []),
      choiceOfFood,
    ];
  }, [choiceOfFood, extraChoiceOfFood]);

  const checkError = (fieldName: string) => {
    const error = pictureErrors.find((item) => item.field === fieldName);
    return error?.error;
  };

  const removeError = (fieldName: string) => {
    const updatedErrors = pictureErrors.map((item) => {
      if (item.field === fieldName) return { field: fieldName, error: false }
      return item
    });
    setPictureErrors(updatedErrors);
  };
  function getDatesInRange(start: Date, end: Date) {
    const date = new Date(start.getTime());

    const dates = [];

    while (date <= end) {
      dates.push({
        date: dayjs(new Date(date)).format('YYYY-MM-DD'),
        capacity
      });
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }
  useEffect(() => {
    if (startDate && endDate && startDate < endDate) {
      const tempDates = getDatesInRange(dayjs(startDate).toDate(), dayjs(endDate).toDate())
      setIncludedDates(tempDates)
    }
  }, [startDate, endDate, capacity])
  useEffect(() => {
    if (initialData?.feedback?.length)
      return
    for (let i = 0; i < 1; i++) {
      appendFeedback('', false)
    }
  }, [])
  console.log("event: ", getValues('eventCity'))

  return (
    <>
      <Modal
        show={showStandards !== undefined}
        onHide={() => setShowStandards(undefined)}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">{projectLan?.project_standard}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Language?.general?.projectStandards?.map((item: any) =>
            <li>
              <img alt="" src={item?.included ? '/images/checkmark-red.svg' : "/images/crossx.svg"} style={{ height: '13px', width: '15px' }} className="img-fluid" />{" "}
              {item.text}
            </li>
          )}
        </Modal.Body>
      </Modal>
      <form
        className="respadding com-font sa-custom-form"
        onSubmit={handleSubmit(_onSubmit)}
      >
        <div className="form-group">
          <div className="row">
            <div className="col-lg-12">
              <label
                htmlFor="projectid"
                className="mb-0 sa-label small text-capitalize"
              >
                {projectLan?.custom_id}
              </label>
              <input
                type="text"
                className="form-control resforminp mt-0"
                id="projectid"
                name="customId"
                placeholder={projectLan?.custom_id_place}
                ref={register}
              ></input>
              {errors.customId && (
                <div className="invalid-feedback d-block">
                  {projectLan?.custom_id_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <label
                htmlFor="projectname"
                className="mb-0 sa-label small text-capitalize"
              >
                {projectLan?.event_name}
              </label>
              <input
                type="text"
                className="form-control resforminp mt-0"
                id="projectname"
                name="projectName"
                placeholder={projectLan?.event_name_place}
                ref={register}
              ></input>
              {errors.projectName && (
                <div className="invalid-feedback d-block">
                  {projectLan?.event_name_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <label
                htmlFor="projectdescription"
                className="mb-0 sa-label small text-capitalize"
              >
                {projectLan?.event_description}
              </label>
              <textarea
                name="projectDescription"
                id=""
                cols={30}
                rows={10}
                className="form-control"
                placeholder={projectLan?.event_desc_place}
                ref={register}
              ></textarea>
              {errors.projectDescription && (
                <div className="invalid-feedback d-block">
                  {projectLan?.event_description_err}

                </div>
              )}
            </div>
            <div className="col-lg-12">
              <label
                htmlFor="spokenLanguages"
                className="mb-0 sa-label small text-capitalize"
              >
                {projectLan?.spoken_languages}
              </label>
              <input
                type="text"
                className="form-control resforminp mt-0"
                id="spokenLanguages"
                name="spokenLanguages"
                placeholder={projectLan?.spoken_languages_place}
                ref={register}
              ></input>
              {errors?.spokenLanguages && (
                <div className="invalid-feedback d-block">
                  {projectLan?.spoken_languages_err}

                </div>
              )}
            </div>
            <div className="col-lg-12">
              <label htmlFor="natureOfEvent" className="mb-0 small text-capitalize">
                {projectLan?.type_of_event}
              </label>
              <select
                title={projectLan?.type_of_event_place} className="form-control resforminp mt-0"
                id="natureOfEvent"
                name="natureOfEvent"
                ref={register}
              >
                <option value=''>{Language?.general?.select}</option>
                {projectLan?.typeOfEventOptions?.length &&
                  <>
                    <option value={'living in a local community'}>{projectLan?.typeOfEventOptions[0]?.option}</option>
                    <option value={'just you & nature'}>{projectLan?.typeOfEventOptions[1]?.option}</option>
                  </>
                }
              </select>
              {errors?.natureOfEvent && (
                <div className="invalid-feedback d-block">
                  {projectLan?.type_of_event_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <label
                htmlFor="natureOfEventdescription"
                className="mb-0 sa-label small text-capitalize"
              >
                {projectLan?.type_of_event_desc}
              </label>
              <textarea
                name="natureOfEventdescription"
                id="natureOfEventdescription"
                cols={30}
                rows={10}
                className="form-control"
                ref={register}

                placeholder={projectLan?.type_of_event_desc_place}
              ></textarea>
              {errors?.natureOfEventdescription && (
                <div className="invalid-feedback d-block">
                  {projectLan?.type_of_event_desc_err}

                </div>
              )}
            </div>
            <div className="col-lg-12">
              <FileUpload
                label={projectLan?.project_pictures}
                formatLabel={projectLan?.project_pictures_place}
                multiple={true}

                value={projectPictures}
                onChange={(val) => {
                  setProjectPictures(val as CloudinaryImg[])
                  removeError('projectPictures')
                }}
                destination={'project'}
                field={'projectPictures'}
                isEvent={location?.pathname.split("/").pop() || false}
                removeFromDb={location.pathname !== "/profile/projects/add" ? true : false}
              />
              {checkError("projectPictures") && (
                <div className="invalid-feedback d-block">
                  {projectLan?.project_pictures_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <label htmlFor="levelOfDifficulty" className="mb-0 small text-capitalize">
                {projectLan?.event_difficulty}
              </label>
              <select
                className="form-control resforminp mt-0"
                id="levelOfDifficulty"
                name="levelOfDifficulty"
                ref={register}
                title={projectLan?.event_difficulty_place}
              >
                <option value=''>{Language?.general?.select}</option>
                {projectLan?.levelOfDifficultyOptions?.length &&
                  ['easy', 'moderate', 'difficult/ risky', 'extreme'].map((item: any, i) =>
                    <option value={item}>{projectLan.levelOfDifficultyOptions[i]?.option}</option>
                  )}
              </select>
              {errors.levelOfDifficulty && (
                <div className="invalid-feedback d-block">
                  {projectLan?.event_difficulty_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <label
                htmlFor="ownername"
                className="mb-0 mt-3 sa-label small text-capitalize"
              >
                {projectLan?.event_owner_name}

              </label>
              <input
                type="text"
                className="form-control resforminp mt-0"
                id="ownername"
                placeholder={projectLan?.event_owner_name_place}
                name="projectOwnerName"
                ref={register}
              ></input>
              {errors.projectOwnerName && (
                <div className="invalid-feedback d-block">
                  {projectLan?.event_owner_name_err}
                </div>
              )}
            </div>
            {/* <div className="col-lg-12">
              <label
                htmlFor="owneremail"
                className="mb-0 sa-label small text-capitalize"
              >
                contact email*
              </label>
              <input
                type="text"
                className="form-control resforminp mt-0"
                id="owneremail"
                placeholder="Email address you constantly check for new bookings"
                name="contactEmail"
                ref={register}
              ></input>
              {errors.contactEmail && (
                <div className="invalid-feedback d-block">
                  Valid email is required
                </div>
              )}
            </div> */}
            <div className="col-lg-12">
              <label
                htmlFor="descriptionabout"
                className="mb-0 mt-3 sa-label small text-capitalize"
              >
                {projectLan?.event_owner_description}
              </label>
              <textarea
                name="ownerDescription"
                id="descriptionabout"
                cols={30}
                rows={5}
                className="form-control mb-3"
                placeholder={projectLan?.event_owner_description_place}
                ref={register}
              ></textarea>
              {errors.ownerDescription && (
                <div className="invalid-feedback d-block">
                  {projectLan?.event_owner_description_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <FileUpload
                label={projectLan?.event_owner_pictures}
                multiple={true}
                formatLabel={projectLan?.owner_pictures_place}
                value={projectOwnerPicture}
                onChange={(val) => {
                  setProjectOwnerPicture(val as CloudinaryImg[])
                  removeError('projectOwnerPicture')
                }}
                destination={'project'}
                field={'projectOwnerPicture'}
                isEvent={location?.pathname.split("/").pop() || false}
                removeFromDb={location.pathname !== "/profile/projects/add" ? true : false}
              />
              {checkError("projectOwnerPicture") && (
                <div className="invalid-feedback d-block">
                  {projectLan?.event_owner_pictures_err}
                </div>
              )}
            </div>
            {/* <div className="col-lg-12">
              <p className="mt-3 fw700 text-capitalize">
                participant qualifications*
              </p>
              <div className="form-check mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="volunteers"
                  name="qualifications"
                  value="volunteers"
                  ref={register}
                ></input>
                <label className="form-check-label" htmlFor="volunteers">
                  volunteers
                </label>
              </div>
              <div className="form-check mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="interns"
                  name="qualifications"
                  value="interns"
                  ref={register}
                ></input>
                <label className="form-check-label" htmlFor="interns">
                  interns
                </label>
              </div>
              <div className="form-check mt-3 mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="experts"
                  name="qualifications"
                  value="experts"
                  ref={register}
                ></input>
                <label className="form-check-label" htmlFor="experts">
                  experts
                </label>
                {errors.qualifications && (
                  <div className="invalid-feedback d-block">
                    Please choose qualifications
                  </div>
                )}
              </div>
            </div> */}
            <div className="col-lg-6">
              <label
                htmlFor="supervisorname"
                className="mb-0 sa-label small text-capitalize"
              >
                {projectLan?.local_supervisor_name}
              </label>
              <input
                type="text"
                className="form-control resforminp mt-0"
                id="supervisorname"
                placeholder={projectLan?.local_supervisor_name_place}
                name="localSupervisorName"
                ref={register}
              ></input>
              {errors.localSupervisorName && (
                <div className="invalid-feedback d-block">
                  {projectLan?.local_supervisor_name_err}
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <label
                htmlFor="supervisorphone"
                className="mb-0 sa-label small text-capitalize"
              >
                {projectLan?.local_supervisor_phone}
              </label>
              <input
                type="text"
                className="form-control resforminp mt-0"
                id="supervisorphone"
                placeholder={projectLan?.local_supervisor_phone_place}
                name="localSupervisorPhone"
                ref={register}
              ></input>
              {errors.localSupervisorPhone && (
                <div className="invalid-feedback d-block">{projectLan?.local_supervisor_phone_err}</div>
              )}
            </div>

            <div className="col-lg-6">
              <label htmlFor="location" className="mb-0 small text-capitalize">
                {projectLan?.location}
              </label>
              <select
                className="form-control resforminp mt-0"
                id="location"
                name="location"
                ref={register}
              >
                {Countries.map((c) => (
                  <option value={c.name}>{c.name}</option>
                ))}
              </select>
              {errors.location && (
                <div className="invalid-feedback d-block">
                  {projectLan?.location_err}
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <label htmlFor="eventCity" className="mb-0 small text-capitalize">
                {projectLan?.city}
              </label>
              <AutoComplete
                name={`eventCity`}
                type="text"
                defaultValue={getValues('eventCity')}
                className="form-control resforminp mt-0"
                id={`eventCity`}
                placeholder={projectLan?.city_place}
                ref={register}
              />
              {errors.eventCity && (
                <div className="invalid-feedback d-block">
                  {projectLan?.city_err}
                </div>
              )}
            </div>
            {/* <div className={`col-lg-6 ${initialData?.available && initialData?.status === 'approved' && userDetails.swt_role === 'provider' && location.pathname !== "/profile/projects/add" && 'd-none'}`}> */}
            <div className={`col-lg-6`}>
              <label
                htmlFor="availablefrom"
                className="mb-0 sa-label small text-capitalize"
              >
                {projectLan?.available_from}
              </label>

              <input
                // readOnly={initialData?.available && initialData?.status === 'approved' && userDetails.swt_role === 'provider' && location.pathname !== "/profile/projects/add" ? true : false}
                readOnly
                type="date"
                className="form-control resforminp mt-0"
                id="availablefrom"
                placeholder={projectLan?.available_from_place}
                name="startDate"
                min={initialData?.status === 'approved' && userDetails.swt_role === 'provider' && location.pathname === "/profile/projects/add" ? dayjs().add(1, "day").format("YYYY-MM-DD") : ""}
                ref={register}
              ></input>
              {errors.startDate && (
                <div className="invalid-feedback d-block">
                  {projectLan?.available_from_err}
                </div>
              )}
            </div>
            {/* <div className={`col-lg-6 ${initialData?.available && initialData?.status === 'approved' && userDetails.swt_role === 'provider' && location.pathname !== "/profile/projects/add" && 'd-none'}`}> */}
            <div className={`col-lg-6`}>
              <label
                htmlFor="availableuntil"
                className="mb-0 sa-label small text-capitalize"
              >
                {projectLan?.available_until}
              </label>

              <input
                readOnly={initialData?.available && initialData?.status === 'approved' && userDetails.swt_role === 'provider' && location.pathname !== "/profile/projects/add" ? true : false}
                type="date"
                className="form-control resforminp mt-0"
                id="availableuntil"
                min={dayjs(getValues("startDate"))
                  .add(1, "day")
                  .format("YYYY-MM-DD")}
                max={initialData?.status !== 'approved' && userDetails.swt_role === 'provider' && location.pathname !== "/profile/projects/add" ? dayjs().add(1, 'year').format("YYYY-MM-DD") : undefined}
                placeholder={projectLan?.available_until_place}
                name="endDate"
                ref={register}
              ></input>
              {errors.endDate && (
                <div className="invalid-feedback d-block">
                  {projectLan?.available_until_err}
                </div>
              )}
            </div>
            {/* <div className={`col-lg-6 ${initialData?.status !== 'approved' && userDetails.swt_role === 'provider' && 'd-none'}`}>
              <label
                // htmlFor="availablefrom"
                className="mb-0 sa-label small text-capitalize"
              >
                {projectLan?.available_from}
              </label>

              <input
                type="date"
                className="form-control resforminp mt-0"
                // id="availablefrom"
                placeholder={projectLan?.available_from_place}
                value={startDate}
                disabled
              ></input>
            </div>
            <div className={`col-lg-6 ${initialData?.status !== 'approved' && userDetails.swt_role === 'provider' && 'd-none'}`}>
              <label
                // htmlFor="availableuntil"
                className="mb-0 sa-label small text-capitalize"
              >
                {projectLan?.available_until}
              </label>

              <input
                type="date"
                className="form-control resforminp mt-0"
                // id="availableuntil"
                placeholder={projectLan?.available_until_place}
                value={endDate}
                disabled
              ></input>
            </div> */}
            <div className="col-lg-6">
              <label htmlFor="capacity" className="mb-0 small text-capitalize">
                {projectLan?.capacity}
              </label>
              <select
                className="form-control resforminp mt-0"
                id="capacity"
                name="capacity"
                ref={register}
              >
                <option selected disabled value="">
                  {projectLan?.capacity_place}
                </option>
                {range(1, 101).map((i) => (
                  <option value={i}>{i}</option>
                ))}
              </select>
              {errors.capacity && (
                <div className="invalid-feedback d-block">
                  {projectLan?.capacity_err}
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <label
                htmlFor="noticeperiod"
                className="mb-0 small text-capitalize"
              >
                {projectLan?.notice_period}
              </label>
              <select
                className="form-control resforminp mt-0"
                id="noticeperiod"
                // data-toggle="tooltip"
                // data-placement="top"
                name="noticePeriod"
                ref={register}
                // title={projectLan?.notice_period_info}
              >
                <option selected disabled value="">
                  {projectLan?.notice_period_place}
                </option>
                {range(31, 101).map((i) => (
                  <option value={i}>{i}</option>
                ))}
              </select>
              {errors.noticePeriod && (
                <div className="invalid-feedback d-block">
                  {projectLan?.notice_period_err}
                </div>
              )}
            </div>
            <Tooltip
              anchorSelect="#noticeperiod"
              place="top"
              content={projectLan?.notice_period_place}
            />
            <div className="col-lg-6">
              <label
                htmlFor="costday"
                className="mb-0 small sa-label small text-capitalize"
              >
                {projectLan?.cost_per_day}
              </label>
              <input
                type="text"
                className="form-control resforminp mt-0"
                id="costday"
                placeholder={projectLan?.cost_per_day_place}
                name="pricePerDay"
                ref={register}
              ></input>
              {errors.pricePerDay && (
                <div className="invalid-feedback d-block">
                  {projectLan?.cost_per_day_err}
                </div>
              )}
            </div>
            {/* <div className="col-lg-6">
              <label
                htmlFor="costmonth"
                className="mb-0 small sa-label small text-capitalize"
              >
                cost per month
              </label>
              <input
                type="text"
                className="form-control resforminp mt-0"
                id="costmonth"
                placeholder="cost per month"
                name="pricePerMonth"
                ref={register}
              ></input>
              {errors.pricePerMonth && (
                <div className="invalid-feedback d-block">
                  Price Per Month is required
                </div>
              )}
            </div> */}
            <div className="col-lg-12">
              <label
                htmlFor="additionalPrice"
                className="mb-0 mt-3 sa-label small text-capitalize"
              >
                {projectLan?.additional_cost}
              </label>
              <textarea
                name="additionalPrice"
                id="additionalPrice"
                cols={30}
                rows={3}
                className="form-control"
                placeholder={projectLan?.additional_cost_place}
                ref={register}
              ></textarea>
            </div>
            <div className="col-lg-11">
              <label
                htmlFor="sdg"
                className="mb-0 small text-capitalize"
              >
                {projectLan?.sdg}
              </label>
              <select
                name="sdg"
                className="form-control resforminp mt-0"
                id="sdg"
                ref={register}
                title={projectLan?.sdg_place}
              >
                <option value="">Select</option>
                {projectLan?.sdgOptions.map(
                  (opt:any, i:any) => (
                    projectLan?.sdgOptions?.length &&

                    <option
                      key={opt}
                      // disabled={i < ["Low budget", "2 or 3 stars accommodation", "4 or 5 stars accommodation"].indexOf(defaultAccomadation) || selectedAccomdations.includes(opt)}
                      value={opt?.option}
                    >
                      SDG {projectLan?.sdgOptions[i]?.option}
                    </option>
                  )

                )}
              </select>
              {errors.sdg && (
                <div className="invalid-feedback d-block">
                  {projectLan?.sdg_err}
                </div>
              )}
            </div>
            <div className="col-lg-9">
              <label
                htmlFor="accomodation"
                className="mb-0 small text-capitalize"
              >
                {projectLan?.standard_accomodation}
              </label>
              {projectLan?.accomodationOptions?.length &&
                <select
                  className="form-control resforminp mt-0"
                  id="accomodation"
                  name="category"
                  ref={register}
                >
                  <option value="">{Language?.general?.select}</option>
                  <option disabled={defaultAccomadation === "Low budget" || selectedAccomdations.includes("Low budget")}
                    value="Low budget">{projectLan?.accomodationOptions[0]?.option}</option>
                  <option disabled={defaultAccomadation === "2 or 3 stars accommodation" || selectedAccomdations.includes("2 or 3 stars accommodation")}
                    value="2 or 3 stars accommodation">{projectLan?.accomodationOptions[1]?.option}</option>
                  <option disabled={defaultAccomadation === "4 or 5 stars accommodation" || selectedAccomdations.includes("4 or 5 stars accommodation")}
                    value="4 or 5 stars accommodation">{projectLan?.accomodationOptions[2]?.option}</option>

                  {/* {["Low budget", "2 or 3 stars accommodation", "4 or 5 stars accommodation"].map(
                  (opt, i) => (
                    <option
                      key={opt}
                      disabled={i < ["Low budget", "2 or 3 stars accommodation", "4 or 5 stars accommodation"]
                        .indexOf(defaultAccomadation)
                        || selectedAccomdations.includes(opt)}
                      value={opt}
                    >
                      {opt}
                    </option>
                  )
                )} */}
                </select>
              }
              {errors.extraAccommodation && (
                <div className="invalid-feedback d-block">
                  {projectLan?.standard_accomodation_err}
                </div>
              )}
            </div>
            <div className="col-lg-3 d-flex align-items-center">
              <a
                href="javascript:void(0)"
                className="mb-0"
                onClick={() => setShowStandards(defaultAccomadation)}
              >
                {projectLan?.project_standard}
              </a>
            </div>
            {defaultAccomadation && (
              <div className="col-lg-12">
                <label
                  htmlFor="descriptionaccomodation"
                  className="mb-0 mt-3 sa-label small text-capitalize"
                >
                  {projectLan?.included_standards}
                </label>
                <ul style={{ paddingLeft: "0px" }} className="list-none ">
                  {projectLan?.standards[defaultAccomadation]?.map((item: any) =>
                    <li>
                      <img alt="" src={item?.included ? '/images/checkmark-red.svg' : "/images/crossx.svg"} style={{ height: '13px', width: '15px' }} className="img-fluid" />{" "}
                      {item.value}
                    </li>
                  )}
                </ul>
              </div>
            )}
            <div className="col-lg-12">
              <label
                htmlFor="descriptionaccomodation"
                className="mb-0 mt-3 sa-label small"
              >
                {projectLan?.category}

              </label>
              <textarea
                name="accommodationDescription"
                id="descriptionaccomodation"
                cols={30}
                rows={5}
                className="form-control"
                placeholder={projectLan?.description_accomodation_place}
                ref={register}
              ></textarea>
              {errors.accommodationDescription && (
                <div className="invalid-feedback d-block">
                  {projectLan?.description_accomodation_err}
                </div>
              )}
            </div>
            {extraAccommodationFields.map((extraAccommodationField, index) => {
              return (
                <>
                  <div className="col-lg-8">
                    <label
                      htmlFor="accomodation"
                      className="mb-0 small text-capitalize"
                    >
                      {projectLan?.extra_accomodation_category}
                    </label>
                    <select
                      className="form-control resforminp mt-0"
                      id="accomodation"
                      name={`extraAccommodation[${index}].category`}
                      defaultValue={extraAccommodationField.category}
                      data-value={extraAccommodation[index]?.category}
                      ref={register}
                    >
                      <option value="">{Language?.general.select}</option>
                      {[
                        "Low budget",
                        "2 or 3 stars accommodation",
                        "4 or 5 stars accommodation",
                      ].map((opt, i) => (
                        <option
                          key={opt}
                          disabled={i < ["Low budget", "2 or 3 stars accommodation", "4 or 5 stars accommodation"].indexOf(defaultAccomadation) || selectedAccomdations.includes(opt)}
                          value={opt}
                        >
                          {projectLan?.accomodationOptions[i]?.option}
                        </option>
                      ))}
                    </select>
                    {errors.extraAccommodation?.[index]?.category && (
                      <div className="invalid-feedback d-block">
                        {projectLan?.extra_accomodation_err}

                      </div>
                    )}
                  </div>
                  <div className="col-lg-3">
                    <label
                      htmlFor={`extraAccommodation[${index}].extraPrice`}
                      className="mb-0 small text-capitalize"
                    >
                      {projectLan?.extra_accomodation_per_night}
                    </label>
                    <input
                      type="text"
                      className="form-control resforminp mt-0"
                      id={`extraAccommodation[${index}].extraPrice`}
                      name={`extraAccommodation[${index}].extraPrice`}
                      defaultValue={extraAccommodationField.extraPrice}
                      placeholder={projectLan?.extra_accomodation_per_night_place}
                      ref={register}
                    ></input>
                    {errors.extraAccommodation?.[index]?.extraPrice && (
                      <div className="invalid-feedback d-block">
                        {projectLan?.extra_accomodation_per_night_err}

                      </div>
                    )}
                  </div>
                  <div className="col-lg-1" style={{ alignSelf: "center" }}>
                    <button
                      className="btn btn-sm btn-link btnscart"
                      onClick={() => removeExtraAccomodation(index)}
                    >
                      <img
                        src="/images/delete_icon.svg"
                        className="img-fluid carticon"
                        alt=""
                      />
                    </button>
                  </div>
                  {extraAccommodation[index]?.category && (
                    <div className="col-lg-12">
                      <label
                        htmlFor="descriptionaccomodation"
                        className="mb-0 mt-3 sa-label small text-capitalize"
                      >
                        {projectLan?.accomodation_standard}
                      </label>
                      {
                        <ul style={{ paddingLeft: "0px" }} className="list-none ">
                          {projectLan?.standards[extraAccommodation[index].category]?.map((item: any) =>
                            <li>
                              <img alt="" src={item?.included ? '/images/checkmark-red.svg' : "/images/crossx.svg"}
                                style={{ height: '13px', width: '15px' }} className="img-fluid" />{" "}
                              {item.value}
                            </li>
                          )}
                        </ul>
                      }
                    </div>
                  )}
                  <div className="col-lg-12">
                    <label
                      htmlFor="descriptionaccomodation"
                      className="mb-0 mt-3 sa-label small text-capitalize"
                    >
                      {projectLan?.description_accomodation}
                    </label>
                    <textarea
                      name={`extraAccommodation[${index}].description`}
                      id={`extraAccommodation[${index}].description`}
                      defaultValue={extraAccommodationField.description}
                      cols={30}
                      rows={5}
                      className="form-control"
                      placeholder={projectLan?.description_accomodation_place}
                      ref={register}
                    ></textarea>
                    {errors.extraAccommodation?.[index]?.description && (
                      <div className="invalid-feedback d-block">
                        {projectLan?.description_accomodation_err}
                      </div>
                    )}
                  </div>
                </>
              );
            })}
            <div className="col-lg-12">
              <button
                type="button"
                data-toggle="modal"
                data-target="#paymentInfoModal"
                className="btn btn-default btnviewproj text-capitalize mt-3"
                disabled={extraAccommodationFields.length >= 2}
                onClick={() =>
                  appendExtraAccomodation({
                    category: "",
                    extraPrice: "",
                    description: "",
                  })
                }
              >
                {projectLan?.extra_accomodation}
              </button>
            </div>
            <div className="col-lg-12">
              <FileUpload
                label={projectLan?.accomodation_pictures}
                formatLabel={projectLan?.accomodation_pictures_place}
                multiple={true}
                value={accommodationPictures}
                onChange={(val) => {
                  setAccommodationPictures(val as CloudinaryImg[])
                  removeError('accommodationPictures')
                }}
                destination={'project'}
                field={'accommodationPicture'}
                isEvent={location?.pathname.split("/").pop() || false}
                removeFromDb={location.pathname !== "/profile/projects/add" ? true : false}
              />
              {checkError("accommodationPictures") && (
                <div className="invalid-feedback d-block">
                  {projectLan?.accomodation_pictures_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <label
                htmlFor="transportation"
                className="mb-0 mt-3 sa-label small text-capitalize"
              >
                {projectLan?.transportation}

              </label>
              <input
                type="text"
                className="form-control resforminp mt-0"
                id="transportation"
                placeholder={projectLan?.transportation_place}
                name="transportation"
                ref={register}
              ></input>
              {errors.transportation && (
                <div className="invalid-feedback d-block">
                  {projectLan?.transportation_err}
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <label
                htmlFor="airportCost"
                className="mb-0 mt-3 sa-label small text-capitalize"
              >
                {projectLan?.airport_cost}
              </label>
              <input
                name="airportCost"
                type="text"
                className="form-control resforminp mt-0"
                id="airportCost"
                ref={register}
                placeholder={projectLan?.airport_cost_place}
              ></input>
              {errors.airportCost && (
                <div className="invalid-feedback d-block">
                  {projectLan?.airport_cost_err}
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <label
                htmlFor="nearAirport"
                className="mb-0 mt-3 sa-label small text-capitalize"
              >
                {projectLan?.near_airport}
              </label>
              <input
                type="text"
                className="form-control resforminp mt-0"
                id="nearAirport"
                placeholder={projectLan?.near_airport_place}
                name="nearAirport"
                ref={register}
              ></input>
              {errors.nearAirport && (
                <div className="invalid-feedback d-block">
                  {projectLan?.near_airport_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <FileUpload
                label={projectLan?.transportation_pictures}
                formatLabel={projectLan?.transportation_pictures_place}
                multiple={true}
                value={transportationPictures}
                onChange={(val) => {
                  setTransportationPictures(val as CloudinaryImg[])
                  removeError('transportationPictures')
                }}
                destination={'project'}
                field={'transportationPicture'}
                isEvent={location?.pathname.split("/").pop() || false}
                removeFromDb={location.pathname !== "/profile/projects/add" ? true : false}
              />
              {checkError("transportationPictures") && (
                <div className="invalid-feedback d-block">
                  {projectLan?.transportation_pictures_err}
                </div>
              )}
            </div>
            <div className="col-lg-6 col-md-12">
              <label
                htmlFor="choiceOfFood"
                className="mb-0 small text-capitalize"
              >
                {projectLan?.choice_of_food}
              </label>
              <select
                title={projectLan?.choice_of_food_sub_snacks}
                name="choiceOfFood"
                className="form-control resforminp mt-0"
                id="choiceOfFood"
                ref={register}
              >
                <option selected disabled value="">{Language?.general?.select}</option>
                {projectLan?.choiceOfFoodOptions.map((item:any)=>(
                <option
                  key={item.option}
                  value={item.option}
                  disabled={selectedChoiceOfFood.includes(item.option)}>
                  {item.option}
                </option>
                ))}
              </select>
              {errors.choiceOfFood && (
                <div className="invalid-feedback d-block">
                  {projectLan?.choice_of_food_err}
                </div>
              )}
            </div>
            {(choiceOfFood === "three-times-a-day meals provided" || choiceOfFood === '3 times a day meals') &&
              <div className="col-lg-6 col-md-12">
                <label
                  htmlFor="choiceOfFood"
                  className="mb-0 small text-capitalize"
                >
                  {projectLan?.food_extra_price}
                </label>
                <input
                  name="foodPrice"
                  type="text"
                  className="form-control resforminp mt-0"
                  id="foodPrice"
                  ref={register}
                  placeholder={projectLan?.food_extra_price_place}
                ></input>
                {errors.foodPrice && (
                  <div className="invalid-feedback d-block">
                    {projectLan?.food_extra_price_err}
                  </div>
                )}
              </div>
            }
            <div className="col-md-12">
              <label
                htmlFor="offeredmeals"
                className="mb-0 mt-3 sa-label small text-capitalize"
              >
                {projectLan?.offered_meals} {choiceOfFood === "snacks & fresh water" ? projectLan?.snacks : projectLan?.meals}
              </label>
              <textarea
                name="foodDescription"
                id="offeredmeals"
                cols={30}
                rows={3}
                className="form-control"
                placeholder={choiceOfFood === "snacks & fresh water"
                  ? projectLan?.offered_meals_place1 :
                  projectLan?.offered_meals_place2}
                ref={register}
              ></textarea>
              {errors.foodDescription && (
                <div className="invalid-feedback d-block">
                  {projectLan?.offered_meals_err}
                </div>
              )}
            </div>
            {extraChoiceOfFoodFields?.map((extraChoiceOfFoodField, index) => {

              return (
                <>
                  <div className="col-lg-6 col-md-12">
                    <label
                      htmlFor="choiceOfFood"
                      className="mb-0 small text-capitalize"
                    >
                      {projectLan?.choice_of_food}
                    </label>
                    <select
                      defaultValue={extraChoiceOfFoodField.category}
                      name={`extraChoiceOfFood[${index}].category`}
                      className="form-control resforminp mt-0"
                      id={`extraChoiceOfFood[${index}].category`}
                      ref={register}
                    >
                      <option selected disabled value="">{Language?.general.select}</option>
                      <option
                        key={"three-times-a-day meals provided"}
                        value={"three-times-a-day meals provided"}
                        disabled={selectedChoiceOfFood.includes(projectLan?.choiceOfFoodOptions[0].option)}
                      >
                        {projectLan?.choiceOfFoodOptions?.length && projectLan?.choiceOfFoodOptions[0]?.option}
                      </option>
                      <option
                        title={projectLan?.choice_of_food_sub_snacks}
                        key={"snacks & fresh water"}
                        value={"snacks & fresh water"}
                        disabled={selectedChoiceOfFood.includes(projectLan?.choiceOfFoodOptions[1].option)}
                      >
                        {projectLan?.choiceOfFoodOptions?.length && projectLan?.choiceOfFoodOptions[1]?.option}
                      </option>
                    </select>
                    {errors?.extraChoiceOfFood?.[index]?.category && (
                      <div className="invalid-feedback d-block">
                        {projectLan?.choice_of_food_err}
                      </div>
                    )}
                  </div>
                  {extraChoiceOfFood[index].category === "three-times-a-day meals provided" &&
                    <div className="col-lg-6 col-md-12">
                      <label
                        htmlFor="foodPrice"
                        className="mb-0 small text-capitalize"
                      >
                        {projectLan?.food_extra_price}
                      </label>
                      <input
                        name="foodPrice"
                        type="text"
                        className="form-control resforminp mt-0"
                        id="foodPrice"
                        ref={register}
                        placeholder={projectLan?.food_extra_price_place}
                      ></input>
                      {errors.foodPrice && (
                        <div className="invalid-feedback d-block">
                          {projectLan?.food_extra_price_err}
                        </div>
                      )}
                    </div>
                  }
                  <div className="col-lg-11">
                    <label
                      htmlFor="offeredmeals"
                      className="mb-0 mt-3 sa-label small text-capitalize"
                    >
                      {projectLan?.offered_meals} {extraChoiceOfFood[index].category === "snacks & fresh water" ? 'snacks' : "meals"}
                    </label>
                    <textarea
                      name={`extraChoiceOfFood[${index}].description`}
                      id={`extraChoiceOfFood[${index}].description`}
                      cols={30}
                      rows={3}
                      defaultValue={extraChoiceOfFoodField.description}
                      className="form-control"
                      ref={register}
                      placeholder={choiceOfFood === "snacks & fresh water"
                        ? projectLan?.offered_meals_place1 :
                        projectLan?.offered_meals_place2}
                    ></textarea>
                    {errors?.extraChoiceOfFood?.[index]?.description && (
                      <div className="invalid-feedback d-block">
                        {projectLan?.offered_meals_err}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-1" style={{ alignSelf: "center" }}>
                    <button
                      className="btn btn-sm btn-link btnscart"
                      onClick={() => removeextraChoiceOfFood(index)}
                    >
                      <img
                        src="/images/delete_icon.svg"
                        className="img-fluid carticon"
                        alt=""
                      />
                    </button>
                  </div>
                </>
              );
            })}


            <div className="col-lg-12">
              <button
                type="button"
                data-toggle="modal"
                data-target="#paymentInfoModal"
                className="btn btn-default btnviewproj text-capitalize mt-3"
                disabled={extraChoiceOfFoodFields?.length >= 1}
                onClick={() =>
                  appendExtraChoiceOfFood({
                    category: "",
                    description: "",
                  })
                }
              >
                {projectLan?.extra_choice_of_food}
              </button>
            </div>
            <div className="col-lg-12">
              <label
                htmlFor="typicalday"
                className="mb-0 mt-3 sa-label small text-capitalize"
              >
                {projectLan?.typical_day}
              </label>
              <textarea
                name="typicalDay"
                id="typicalday"
                cols={30}
                rows={3}
                className="form-control"
                placeholder={projectLan?.typical_day_place}
                ref={register}
              ></textarea>
              {errors.typicalDay && (
                <div className="invalid-feedback d-block">
                  {projectLan?.typical_day_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <label
                htmlFor="additionalinformation"
                className="mb-0 mt-3 sa-label small text-capitalize"
              >
                {projectLan?.additional_info}
              </label>
              <textarea
                name="additionalInfo"
                id="additionalinformation"
                cols={30}
                rows={3}
                className="form-control"
                placeholder={projectLan?.additional_info_place}
                ref={register}
              ></textarea>
              {errors.additionalInfo && (
                <div className="invalid-feedback d-block">
                  {projectLan?.additional_info_err}
                </div>
              )}
            </div>
            <div className="col-lg-12 pt-3">
              <p className="com-font">
                {projectLan?.feedback}
              </p>
            </div>

            {feedbackFields.map((field, index) => (
              <>
                <div className="col-lg-11 pt-3">
                  <label  >
                    {projectLan?.add_feedback} {index + 1}
                  </label>
                  <textarea
                    name={`feedback[${index}].value`}
                    className="form-control"
                    id={`feedback${index}`}
                    placeholder={projectLan?.add_feedback_place}
                    // placeholder={`Small World Travel will regularly reach out to clients asking for feedback on your tour/project. If you want to ask your clients specific questions about your project/tour, please specify them here. We then add your questions to our feedback forms.`}
                    ref={register}
                    defaultValue={(feedbackFields[index] as any)?.value}
                  ></textarea>
                  {errors.feedback?.[index] && (
                    <div className="invalid-feedback d-block">required</div>
                  )}
                </div>
                <div className="col-lg-1" style={{ alignSelf: "center" }}>
                  <button
                    className="btn btn-sm btn-link btnscart"
                    onClick={() => removeFeedback(index)}
                  >
                    <img
                      src="/images/delete_icon.svg"
                      className="img-fluid carticon"
                      alt=""
                    />
                  </button>
                </div>
              </>
            ))}
            <div className="col-lg-12">
              <button
                type="button"
                className="btn btn-default btnviewproj text-capitalize mt-3"
                onClick={() => appendFeedback("")}
              >
                {projectLan?.add_feedback_btn}
              </button>
            </div>
            {/* {
              status && status === true && (
                  <div className="col-lg-12 pt-3">
                    <Form.Switch
                      id="available"
                      label="Published (i.e. available)"
                      name="available"
                      ref={register}
                    />
                  </div>
                )
            } */}
            <div className="col-lg-12">
              {isProviderForm ? (
                <>

                  <button
                    className="btn btn-default btnSaveandStart text-capitalize mt-3 "
                    onClick={onSaveDraft}
                  >
                    {Language?.general?.save_as_draft}

                  </button>
                  <button
                    type="button"
                    className="btn btn-default btnSaveandStart text-capitalize mt-3 ml-2"
                    onClick={onStartOver}
                  >
                    {Language?.general?.start_over}
                  </button>
                  {hidePrev ? null : (
                    <button
                      type="button"
                      className="btn btn-default btnviewprojback text-capitalize mt-3 ml-2"
                      onClick={onPrev}
                    >
                      {Language?.general?.back}
                    </button>
                  )}
                  <input
                    type="submit"
                    className="btn btn-default btnviewprojback text-capitalize mt-3 ml-2"
                    value={Language?.general?.next}
                  />
                </>
              ) : (
                <>
                <button
                  className="btn btn-default btnSaveandStart text-capitalize mt-3 "
                  onClick={saveProjDraft}
                >
                  {Language?.general?.save_as_draft}
                </button>
                <button
                  type="submit"
                  data-toggle="modal"
                  data-target="#paymentInfoModal"
                  className="btn btn-default btnviewproj text-capitalize mt-3"
                >
                  {location.pathname !== "/profile/projects/add" ? Language?.general?.update + ' ' + Language?.general?.project : projectLan?.request_approval}
                </button>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ProjectForm;
