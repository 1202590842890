import { ArticlesState, ArticlesActionTypes, SET_POPULAR_ARTICLES, SET_ALL_ARTICLES, SET_ALL_ARTICLES_IMAGES } from "./type"

const initialState: ArticlesState = {
    PopularArticles: [],
    allArticles: [],
    articlesImages: []
}

export function articleReducer(state = initialState, action: ArticlesActionTypes): ArticlesState {
    switch (action.type) {
        case SET_POPULAR_ARTICLES:
            return {
                ...state,
                PopularArticles: action.payload
            }
        case SET_ALL_ARTICLES:
            return {
                ...state,
                allArticles: action.payload
            }
        case SET_ALL_ARTICLES_IMAGES:
            return {
                ...state,
                articlesImages: action.payload
            }
        default:
            return state
    }
}