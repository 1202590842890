import React, { FunctionComponent, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from '../../store'
import { CartItem, deleteItemFromCart, deleteTourItemFromCart } from '../../store/Cart'
import { useMediaQuery } from 'react-responsive'

interface CartSideBarProps {
    show: boolean,
    onClose: () => void
}
export const CartSideBar: FunctionComponent<CartSideBarProps> = ({ show, onClose }) => {
    const isMobileView = useMediaQuery({ maxWidth: 700 });
    const style = { width: show ? isMobileView ? "100%" : "300px" : "0px" };
    const dispatch = useDispatch()
    const cartItems = useSelector((rootState: RootState) => rootState.cart.Cart?.items) || []
    const Language = useSelector((state: RootState) => state.language.language?.content)
    const selectedLan = useSelector((state: RootState) => state.language.language?.language)

    const total = cartItems.reduce((acc, item) => acc + item.price, 0)

    const removeItemFromCart = (itemId: number, eventType: string) => {
        if (eventType === "Project") {
            dispatch(deleteItemFromCart(itemId))
        } else if (eventType === "Tour") {
            dispatch(deleteTourItemFromCart(itemId))
        }
    }

    const getEventType = (cartItem: CartItem) => {
        return cartItem.projectId ? "Project" : "Tour"
    }

    const events = useMemo(() => {
        return cartItems.map(cartItem => {
            const eventType = getEventType(cartItem)
            if (eventType === "Project") {
                return {
                    id: cartItem.projectId!,
                    name: cartItem.projectName!,
                    imageUrl: cartItem.projectImage!?.imageURL,
                    eventType
                }
            } else {
                return {
                    id: cartItem.tourId!,
                    name: cartItem.tourName!,
                    imageUrl: cartItem.tourImage!?.imageURL,
                    eventType
                }
            }
        })
    }, [cartItems])
    return (
        <div className="sidenav com-font" style={style}>
            <a href="javascript:void(0)" className="closebtn closex" onClick={onClose}>×</a>
            <div className="col-lg-12">
                <div className="searchfiltertxt">{Language?.general?.shopping_cart}</div>
            </div>
            {/* <div className="col-lg-12 cartpaddtop">
                <div className="addsuccesstxt text-white small"><img src="/images/white_inc_tick.svg" className="img-fluid cartcheck" />Add Successfully</div>
            </div> */}
            {
                cartItems.length > 0
                    ? (<>
                        <div className="col-lg-12">
                            {
                                events.map(item => (
                                    <div className="row cartitempadd m-0">
                                        <div className="col-lg-4">
                                            <img src={item.imageUrl} className="img-fluid" alt="" />
                                        </div>
                                        <div className="col-lg-8 paddlr0">
                                            <div className="text-white cartitemtext">{item.name}</div>
                                            <span onClick={() => removeItemFromCart(item.id, item.eventType)} className="text-white small removeitem">{Language?.general?.remove}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="col-lg-12 cartitempadd">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="text-white font-weight-bold">{Language?.general?.total} {Language?.general?.items} <span className="font-weight-light">({cartItems.length} {Language?.general?.items})</span></div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="text-white font-weight-bold text-right">{total}€</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 cartitempadd">
                            <Link onClick={onClose} to="/cart" className="btn btnfinalbook btn-block">{Language?.general?.view} {Language?.general?.cart}</Link>
                        </div>
                    </>
                    )
                    : (
                        <div className="py-4">
                            <p className="text-white text-capitalize text-center">{Language?.general?.cart_is_empty}</p>
                        </div>
                    )
            }
            <div className="col-lg-12 cartitempadd text-center">
                <a href="javascript:void(0)" onClick={onClose} className="small text-white">{Language?.general?.continue_shopping}</a>
            </div>
        </div>
    )
}
