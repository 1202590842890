export interface TourPicture {
  imageURL: string;
  public_id: string;
  imageName: string;
}

export interface ExtraAccommodation {
  category: string;
  extraPrice: string;
  description: string;
  description1: string;
}
export interface IncludedDateRangeObject {
  start: Date,
  end: Date
  capacity: number
  confirm: boolean
}
export interface AccommodationPicture {
  imageURL: string;
  public_id: string;
  imageName: string;
}

export interface TransportationPicture {
  imageURL: string;
  public_id: string;
  imageName: string;
}

export interface TourOwnerPicture {
  imageURL: string;
  public_id: string;
  imageName: string;
}

export interface ProjectPicture {
  imageURL: string;
  public_id: string;
  imageName: string;
}

export interface ProjectOwnerPicture {
  imageURL: string;
  public_id: string;
  imageName: string;
}

export interface Event {
  includedEventsDates: IncludedDateRangeObject[];
  tourPictures: TourPicture[];
  extraAccommodation: ExtraAccommodation[];
  accommodationPicture: AccommodationPicture[];
  transportationPicture: TransportationPicture[];
  available: boolean;
  status: string;
  removed: string;
  dayToDay: string[];
  feedback: string[];
  _id: string;
  customId: string;
  tourName: string;
  contactEmail: string;
  tourOwnerPicture: TourOwnerPicture[];
  tourOwnerName: string;
  ownerDescription: string;
  tourDescription: string;
  program: any[];
  localSupervisorName: string;
  localSupervisorPhone: string;
  location: string;
  startDate: Date;
  endDate: Date;
  duration: number;
  capacity: number;
  minCapacity: number;
  noticePeriod: number;
  category: string;
  price: number;
  consumerPricePerDay: number;
  consumerPrice: number;
  additionalPrice: string;
  accommodationDescription: string;
  foodDescription: string;
  transportation: string;
  additionalInfo: string;
  createdBy: string;
  __v: number;
  projectPictures: ProjectPicture[];
  qualifications: string[];
  projectName: string;
  projectOwnerPicture: ProjectOwnerPicture[];
  projectOwnerName: string;
  projectDescription: string;
  pricePerDay?: number;
  pricePerMonth?: number;
  typicalDay: string;
  airportCost: number;
}
export interface Ifilter {
  eventType?: string;
  location?: string;
  startDate?: string;
  endDate?: string;
  qualifications?: string;
  category?: string;
}
export interface SearchState {
  filter: Ifilter;
  events: Event[];
}

export const SET_EVENTTYPE = "SET_EVENTTYPE";
export const SET_LOCATION = "SET_LOCATION";
export const SET_STARTDATE = "SET_STARTDATE";
export const SET_INCLUDEDATE = "SET_INCLUDEDATE";
export const SET_ENDDATE = "SET_ENDDATE";
export const SET_QUALIFICATIONS = "SET_QUALIFICATIONS";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_EVENTS = "SET_EVENTS";
export const SET_FILTER = "SET_FILTER";
export interface SetEventTypeAction {
  type: typeof SET_EVENTTYPE;
  payload: string | undefined;
}

export interface SetLocationAction {
  type: typeof SET_LOCATION;
  payload: string | undefined;
}
export interface SetStartDateAction {
  type: typeof SET_STARTDATE;
  payload: string | undefined;
}
export interface SetIncludeDateAction {
  type: typeof SET_INCLUDEDATE;
  payload: boolean | undefined;
}
export interface SetEndDateAction {
  type: typeof SET_ENDDATE;
  payload: string | undefined;
}
export interface SetQualificationsAction {
  type: typeof SET_QUALIFICATIONS;
  payload: string | undefined;
}
export interface SetCategoryAction {
  type: typeof SET_CATEGORY;
  payload: string | undefined;
}

export interface SetFilterAction {
  type: typeof SET_FILTER;
  payload: Ifilter;
}

export interface SetEventsAction {
  type: typeof SET_EVENTS;
  payload: Event[];
}

export type SearchActionTypes =
  | SetEventTypeAction
  | SetLocationAction
  | SetStartDateAction
  | SetEndDateAction
  | SetQualificationsAction
  | SetCategoryAction
  | SetEventsAction
  | SetFilterAction
  | SetIncludeDateAction;
