import dayjs from "dayjs";
import { useState } from "react";
import { Collapse } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { IReservation } from "../../../store/Cart";
import { isEmpty } from 'lodash';
import moment from "moment-timezone";

interface ReservationCardProps {
    reservation: IReservation
    setIdToCancel: (id: string) => void
    index?: number,
    handleGenrateInvoice: (id: string, status: string) => any
    handleCancelReservation?: () => any
}
const ReservationCard = ({ reservation, handleGenrateInvoice, setIdToCancel, index }: ReservationCardProps) => {
    const [showTravelers, setShowTravelers] = useState(false)

    const userDetails = useSelector((state: RootState) => state.authReducer.UserDetails);
    const disableReservation = reservation.startDate && dayjs().isAfter(reservation.startDate) || reservation.status == 'deleted'
    const genralLan = useSelector(
        (rootState: RootState) => rootState?.language?.language?.content!
    );
    return (
        <div className={`sa-card  mt-4 `}>
            <h5 className="fw700 text-capitalize sa-card-title pb-2">
                {reservation.eventName} - {reservation.eventType}
            </h5>
            <div className="sa-body">
                <div className="row">
                    <div className="col-md-6">
                        <div className="sa-cardcontent">
                            <p className="sa-startdate">
                                <span className="label">{genralLan?.general?.start + ' ' + genralLan?.general?.date}:</span>
                                <span>

                                    {moment(reservation.startDate).format(
                                        "ddd DD MMM YYYY"
                                    )}
                                </span>
                            </p>
                            <p className="sa-totalnights">
                                <span className="label">{genralLan?.general?.number_of_nights}:</span>
                                <span>{reservation.numberOfNights}</span>
                            </p>
                            <p className="sa-travelers">
                                <span className="label">{genralLan?.general?.travellers}:</span>
                                <span>
                                    {reservation.travelersInfo
                                        .map((t) => t.firstName + " " + t.lastName)
                                        .join(", ")}
                                </span>
                            </p>
                            <p className="sa-supervisor">
                                <span className="label">{genralLan?.general?.supervisor + ' ' + genralLan?.general?.name}:</span>
                                <span>{reservation.paid === true && reservation.eventSupervisorName}</span>
                            </p>
                            <p className="sa-contactname">
                                <span className="label">
                                    {genralLan?.general?.contact + ' ' + genralLan?.general?.emergency + ' ' + genralLan?.general?.phone}:
                                </span>
                                <span>{reservation.paid === true && reservation.eventEmergencyContact}</span>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <p className="sa-total">
                            <span className="label">{genralLan?.general?.total}:</span>
                            <span>{reservation.total}</span>
                        </p>
                        <p className="sa-enddate">
                            <span className="label">{genralLan?.general?.end + ' ' + genralLan?.general?.date}:</span>
                            <span>
                                {moment(reservation.endDate).format(
                                    "ddd DD MMM YYYY"
                                )}

                            </span>
                        </p>
                        <p className="sa-guests">
                            <span className="label">{genralLan?.general?.guests}:</span>
                            <span>{reservation.guests}</span>
                        </p>
                        <p className="sa-email">
                            <span className="label">{genralLan?.general?.contact + ' ' + genralLan?.general?.email}:</span>
                            <span>{reservation.paid === true && reservation.eventContactMail}</span>
                        </p>
                    </div>
                </div>
                <Collapse in={showTravelers} timeout={100}>
                    <div className="table-responsive  projectlisttabdiv  mb-2">
                        <table className="table projectlisttab  borderless text-center">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">{genralLan?.general?.name}</th>
                                    <th scope="col">{genralLan?.general?.phone}</th>
                                    <th scope="col">{genralLan?.general?.email}</th>
                                    {/* <th scope="col">Address</th>
                                <th scope="col">Country</th>
                              <th scope="col">Zip Code</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    reservation.travelersInfo.map((traveler, i) => (

                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                                {traveler.gender} {traveler.firstName} {traveler.lastName}
                                            </td>
                                            {
                                                ((userDetails?.swt_role === 'provider' && reservation.paid) ||
                                                    (userDetails?.swt_role === 'client')) && (
                                                    <td>
                                                        {traveler.phoneNumber}
                                                    </td>

                                                )}
                                            {
                                                ((userDetails?.swt_role === 'provider' && reservation.paid) ||
                                                    (userDetails?.swt_role === 'client')) && (
                                                    <td>{traveler.email}</td>
                                                )}
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </Collapse>
                {!isEmpty(reservation.invoices) &&
                    <button
                        type="button"
                        className="btn btnviewproj text-capitalize"
                        onClick={() => handleGenrateInvoice(reservation.id, reservation.status)}
                    >
                        {genralLan?.general?.generate + ' ' + genralLan?.general?.invoice}
                    </button>
                }
                <button
                    type="button"
                    className="btn btnviewproj text-capitalize"
                    onClick={() => setIdToCancel(reservation.id)}
                    disabled={disableReservation}
                >
                    {genralLan?.general?.cancel + ' ' + genralLan?.general?.reservation}
                </button>
                <button
                    type="button"
                    className="btn btnviewproj text-capitalize ml-1"
                    onClick={() => setShowTravelers(!showTravelers)}
                >
                    {showTravelers ? genralLan?.general?.hide : genralLan?.general?.show} {genralLan?.general?.travelers}
                </button>
            </div >
        </div >
    )
}

export default ReservationCard;