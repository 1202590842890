interface AboutType {
  _id: string;
  heading: string;
  content: string;
  image: string;
}

export interface About {
  _id: string;
  Bio_Section: any;
  About_Section: any;
  Projects_Section: any;
  Tours_Section: any;
  Vision_Section: any;
}

export interface AboutState {
  aboutContent: About;
}

export const SET_ABOUT_CONTENT = "SET_ABOUT_CONTENT";

export interface SetAboutAction {
  type: typeof SET_ABOUT_CONTENT;
  payload: About;
}

export type AboutActionTypes = SetAboutAction;
