import dayjs from 'dayjs'
import queryString from 'query-string'
import React, { FunctionComponent, useEffect, useState } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { RootState } from '../../store'
import { searchEvents, setCategory, setEndDate, setEventType, setFilter, setIncludeDate, setLocation, SetQualifications, setStartDate, } from '../../store/search'
import { useMediaQuery } from 'react-responsive'

interface SearchSideBarProps {
    show: boolean,
    onClose: () => void
}
export const SearchSideBar: FunctionComponent<SearchSideBarProps> = ({ show, onClose }) => {
    const isMobileView = useMediaQuery({ maxWidth: 700 });
    const style = { width: show ? isMobileView ? "100%" : "300px" : "0px" };
    const { pathname, search } = useLocation()
    const dispatch = useDispatch()
    const history = useHistory()
    const filter = useSelector((rootState: RootState) => rootState.searchReducer.filter)
    const Language = useSelector((state: RootState) => state.language.language?.content)
    const selectedLan = useSelector((state: RootState) => state.language.language?.language)

    const { location, eventType, category, startDate, endDate, qualifications } = filter
    const [formValues, setFormValues] = useState({
        location, eventType, category, startDate, endDate, qualifications
    })

    const applyFilter = () => {
        dispatch(setLocation(formValues.location))
        dispatch(setEventType(formValues.eventType))
        dispatch(setCategory(formValues.category))
        dispatch(SetQualifications(formValues.qualifications))


        dispatch(setStartDate(formValues.startDate))
        dispatch(setEndDate(formValues.endDate))

        history.push({
            pathname: '/events',
            search: Object.entries(formValues).filter(([_, value]) => value).map(([key, value]) => `${key}=${value}`).join('&')
        })

        onClose()
    }

    const setSelectedDateRange = (startDate: string, endDate: string) => {
        setFormValues({ ...formValues, startDate: dayjs(startDate).format('YYYY-MM-DD'), endDate: dayjs(endDate).format('YYYY-MM-DD') })
    }
    useEffect(() => {
        if (pathname === '/events') {
            let params = queryString.parse(search) as any
            setFormValues({ ...formValues, ...params })
        }

        return () => {
            dispatch(setLocation(undefined))
            dispatch(setEventType('all'))
            dispatch(setCategory(undefined))
            dispatch(SetQualifications(undefined))
            dispatch(setStartDate(undefined))
            dispatch(setEndDate(undefined))
        }
    }, [search])


    const handleTimeFilter = (val: any) => {

        const copyOfFilter = { ...filter };
        delete (copyOfFilter as any)['startDate'];
        delete (copyOfFilter as any)['endDate'];
        setFormValues({ ...formValues, startDate: undefined, endDate: undefined })
        dispatch(setFilter(copyOfFilter));
        dispatch(setStartDate(undefined))
        dispatch(setEndDate(undefined))



    }
    return (
        <div className="sidenav com-font" style={style}>
            <a href="javascript:void(0)" className="closebtn closex" onClick={onClose} >×</a>
            <div className="col-lg-12">
                <div className="searchfiltertxt">{Language?.general?.refine_search_title}</div>
                <div className="paddsearchfilup">
                    <label htmlFor="exampleFormControlSelect1" className="fillab">{Language?.general?.choose_location}</label>
                    <select className="form-control" id="exampleFormControlSelect1" value={formValues.location} onChange={e => setFormValues({ ...formValues, location: e.target.value })}>
                        <option value="">{Language?.general?.select}</option>
                        <option value="Europe">Europe</option>
                        <option value="America">South America</option>
                        <option value="Africa">Africa</option>
                        <option value="Asia">Asia</option>
                        <option value="Oceania">Australia</option>
                    </select>
                </div>
                <div className="paddsearchfilup">
                    <label htmlFor="exampleFormControlSelect1" className="fillab">{Language?.general?.choose_project}</label>
                    <select className="form-control" id="exampleFormControlSelect1" value={formValues.eventType} onChange={e => setFormValues({ ...formValues, eventType: e.target.value })}>
                        <option value="all">{Language?.general?.all}</option>
                        <option value="Project">{Language?.general?.project}</option>
                        <option value="Tour">{Language?.general?.tour}</option>
                    </select>
                </div>
                <div className="paddsearchfilup">
                    <label htmlFor="datePicker" className="fillab">{Language?.general?.choose_time_frame}</label>
                    <div className="input-group">
                        <div className="input-group-prepend bg-white">
                            <span className="bg-white input-group-text" id="calender">
                                <i className="fa fa-calendar-o" />
                            </span>
                        </div>
                        <DateRangePicker
                            initialSettings={{ locale: { format: 'DD/MM/YYYY' } }}
                            onCallback={setSelectedDateRange}>
                            <input type="text" value={``} placeholder={`${dayjs(formValues.startDate).format('MM/DD/YYYY')} - ${dayjs(formValues.endDate).format('MM/DD/YYYY')}`} name="daterange" className="form-control input-group-border-let-none" id="datePicker" aria-describedby="calender" />
                        </DateRangePicker>
                    </div>
                </div>
                <div className='pt-1' onClick={handleTimeFilter}>
                    <p className='mb-0' style={{ cursor: 'pointer' }}>
                        {Language?.general?.clear_selection}
                    </p>
                </div>
                <div className="paddsearchfilup d-none">
                    {/* <label for="exampleFormControlSelect1" class="fillab">Choose timeframe</label>
           <input type="text" name="daterange" value="01/01/2018 - 01/15/2018" class="form-control"/> */}
                    <label htmlFor="datePicker" className="fillab">What are you looking for?</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text input-group-icon">
                                <i className="fa fa-search" />
                            </span>
                        </div>
                        <input type="text" placeholder="Search" className="form-control input-group-border-let-none" />
                    </div>
                </div>
                <div className="paddsearchfilup pt-2">
                    {console.log(Language?.project?.accomodationOptions?.length)}
                    <label htmlFor="exampleFormControlSelect1" className="fillab">{Language?.general?.accomodation}</label>
                    <select className="form-control" id="exampleFormControlSelect1" value={formValues.category} onChange={e => setFormValues({ ...formValues, category: e.target.value })}>
                        <option value="">{Language?.general?.select}</option>
                        {Language?.project?.accomodationOptions?.length && [
                            "Low budget",
                            "2 or 3 stars accommodation",
                            "4 or 5 stars accommodation",
                        ].map((opt, i) => (
                            <option
                                key={opt}
                                value={opt}
                            >
                                {Language?.project?.accomodationOptions[i]?.option}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="paddsearchfilup">
                    <button onClick={applyFilter} className="btn btn-sm btn-default combtnclick btn-block" type="submit">{Language?.general?.apply_filters}</button>
                </div>
            </div>
        </div>

    )
}