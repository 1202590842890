import Axios from "axios";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axiosInstance from "../../utilities/axiosInstance";
import { FAQ, FAQsActionTypes, TitleActionTypes, SET_ALL_FAQS, SET_PAGE_TITLE, SET_PUBLISHED_FAQS } from "./type";

export const setPublishedFAQs = (faqs: FAQ[]): FAQsActionTypes => {
    return {
        type: SET_PUBLISHED_FAQS,
        payload: faqs
    }
}

export const setAllFAQs = (faqs: FAQ[]): FAQsActionTypes => {
    return {
        type: SET_ALL_FAQS,
        payload: faqs
    }
}
export const setPageTitle = (title: string): TitleActionTypes => {
    return {
        type: SET_PAGE_TITLE,
        payload: title
    }
}

// export const fetchPublishedFAQs = (): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
//     const res = await Axios.get('/api/faqs/published')
//     dispatch(setPublishedFAQs(res.data))
// }

// Fetching FAQs from Strapi
export const fetchPublishedFAQs = (appLanguage: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await axiosInstance.get(`/api/faq?populate=*&locale=${appLanguage}`);
    dispatch(setPublishedFAQs(res.data.data.attributes.Faq_items))
    dispatch(setPageTitle(res.data.data.attributes.page_heading))

}

export const fetchAllFAQs = (): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await Axios.get('/api/faqs')
    dispatch(setAllFAQs(res.data))
}

export const fetchFAQByID = (faqId: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await Axios.get(`/api/faqs/${faqId}`)
    return res.data
}

export const createFaq = (payload: { question: string, answer: string, published: boolean }): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await Axios.post(`/api/faqs`, payload)
    return res.data
}

export const updateFaq = (faqId: string, payload: { question: string, answer: string, published: boolean }): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await Axios.post(`/api/faqs/${faqId}`, payload)
    return res.data
}

export const deleteFAQByID = (faqId: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await Axios.delete(`/api/faqs/${faqId}`)
    return res.data
}