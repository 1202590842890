import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axiosInstance from "../../utilities/axiosInstance";
import { Footer, FooterActionTypes, SET_FOOTER_CONTENT } from "./type";

export const setFooter = (contents: Footer): FooterActionTypes => {
    return {
        type: SET_FOOTER_CONTENT,
        payload: contents
    }
}

export const fetchFooterContent = (): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await axiosInstance.get(`/api/footer?populate=*`);    
    dispatch(setFooter(res.data.data.attributes))
}
