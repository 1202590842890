import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Project } from "../../store/Projects";
import ProjectForm from "../projects/ProjectForm";

interface ProjectInfoFormProps {
  initialData: Project;
  onNext: () => void;
  onPrev: () => void;
  onStartOver: () => void;
  onSave: (data: any, shoudSaveDraft: boolean) => void;
  hidePrev: boolean;
}
const ProjectInfoForm: FunctionComponent<ProjectInfoFormProps> = ({
  initialData,
  onSave,
  onNext,
  onPrev,
  onStartOver,
  hidePrev,
}) => {
  const onSubmit = (data: Project) => {
    onSave(data, false);
    onNext();
  };
  const Language = useSelector(
    (rootState: RootState) => rootState.language.language?.content
  );
  return (
    <div className="row">
      <h4 className="com-font fw700 text-red text-capitalize">{Language?.general?.tour_info}</h4>
      <div className="col-lg-12 paddlr0 com-font">
        <p className="small fw700">{Language?.general?.project_info_sub}
        </p>
      </div>
      <ProjectForm
        isProviderForm={true}
        initialData={initialData}
        onSubmit={onSubmit}
        onSave={onSave}
        onPrev={onPrev}
        onStartOver={onStartOver}
        hidePrev={hidePrev}
      />
    </div>
  );
};

export default ProjectInfoForm;
