import dayjs from "dayjs";
import moment from "moment-timezone";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import { getDayCount } from "../../../Components/Calendar2/helpers";
import { RootState } from "../../../store";
import { cancelReservations, fetchInvoiceByReservation, fetchMyReservations, IReservation } from "../../../store/Cart";
import ReservationCard from "./reservationCard";
// import { Template, Viewer, } from '@pdfme/ui';
import { isEmpty } from 'lodash';



const MyReservation: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [idToCancel, setIdToCancel] = useState<undefined | string>(undefined);
  const [invoicePdf, setInvoicePdf] = useState<undefined | any>(undefined);
  const [showInvoice, setShowInvoice] = useState<boolean>(false);
  const [afterNotice, setAfterNotice] = useState<boolean>(false);
  const [showReservation, setShowReservation] = useState<boolean>(false);
  const [reservation, setReservation] = useState<IReservation | undefined>();
  const myReservations = useSelector(
    (rootState: RootState) => rootState.cart.Reservations
  );
  const role = useSelector((rootState: RootState) => rootState.authReducer.UserDetails?.swt_role)
  useEffect(() => {
    dispatch(fetchMyReservations());
  }, []);

  const confirmCancel = async () => {
    try {
      const a = idToCancel;
      setIdToCancel(undefined);
      await dispatch(cancelReservations(a!));
      await dispatch(fetchMyReservations())
      handleHideModal();
    }
    catch (e) {
      console.log('error in cancel reservation: ', e);
    }
  };
  const handleGenrateInvoice = async (id: string, status: string) => {
    try {
      let type = role && role === 'provider' ? 'p_payment' : status ? status : 'refund'
      console.log('type: ', type);
      const invoice = await dispatch(fetchInvoiceByReservation(id, type));
      setInvoicePdf(invoice)
      setShowInvoice(true)
    } catch {

    }
  }
  const handleHideModal = () => {
    setShowInvoice(false)
    setShowReservation(false)
    setInvoicePdf("")
  }
  const getDifferenceInDays = (date: Date) => {
    const date1 = dayjs(date)
    const daysToStart = date1.diff(dayjs(), 'days')
    return daysToStart;
  }
  const handleCancelReservation = () => {
    if (!reservation) return;
    if (role && role === 'provider' && getDifferenceInDays(reservation?.startDate) > 31) {
      setIdToCancel(reservation?.id)
    } else {
      setAfterNotice(true)
    }
  }
  const handleViewClick = (i: any, row: any) => {
    setReservation(row)
    setShowReservation(true)
  }
  const genralLan = useSelector(
    (rootState: RootState) => rootState?.language?.language?.content!
  );
  return (
    <>

      {idToCancel && (
        <SweetAlert
          title="Are you sure you want to cancel the event? Anything we can help you with, please contact us at support@socialtravel.world"
          danger
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnCssClass="btnfinalbook"
          cancelBtnCssClass="btnSaveandStart"
          cancelBtnBsStyle="primary"
          onConfirm={confirmCancel}
          onCancel={() => setIdToCancel(undefined)}
          style={{
            fontSize: '1em'
          }}
        />
      )}
      {afterNotice && (
        <SweetAlert
          title="Please get in touch with Small World Travel, if you need to cancel the project"
          danger

          confirmBtnBsStyle="danger"
          onConfirm={() => setAfterNotice(!afterNotice)}
        // onCancel={() => setIdToCancel(undefined)}
        />
      )}
      <Modal show={showInvoice || showReservation} onHide={handleHideModal} className="day-to-day-modal d-flex justify-content-center">
        <Modal.Header closeButton />
        <div className="container p-0 justify-content-center align-items-center">
          <Modal.Body>
            {invoicePdf &&
              <iframe
                loading='lazy'
                title="invoice"
                width="600px"
                height="550px"
                src={`data:application/pdf;base64,${invoicePdf}`}
              />
            }
            {reservation &&
              <ReservationCard reservation={reservation}
                handleGenrateInvoice={handleGenrateInvoice}
                setIdToCancel={setIdToCancel}
                handleCancelReservation={handleCancelReservation}
              />
            }
          </Modal.Body>
        </div>
      </Modal>
      <div className="col-lg-9 col-12">
        <div className="section-wrap paddresup sa-myreservations">
          <section className="sa-content com-font">
            <div className="sa-contentlist">
              <div className="sa-contentitem">
                <h4 className="fw700 text-red text-capitalize">
                  {role === 'client' ? genralLan?.general?.my_reservations : genralLan?.general?.my_bookings}
                </h4>


                <div className="respadding">
                  {/* {myReservations?.map((reservation, i) => (
                    <ReservationCard key={i} index={i} reservation={reservation}
                      setIdToCancel={setIdToCancel} />
                  ))} */}

                  <BootstrapTable
                    keyField="id"
                    wrapperClasses="table-responsive projectlisttabdiv"
                    bordered={false}
                    classes="projectlisttab borderless text-center border-collapse"
                    headerWrapperClasses="table-header"
                    data={myReservations.map((r, i) => ({
                      ...r,
                      index: i + 1,
                      sort: true,
                    }))}
                    columns={[
                      { text: "#", dataField: "index", sort: true },
                      {
                        text: genralLan?.general?.actions
                        , dataField: "detailsAction", isDummyField: true, sort: true,
                        formatter: (i, row) => (<button onClick={() => handleViewClick(i, row)} type="button" className="btn btnviewproj text-capitalize">
                          {genralLan?.general?.view}
                        </button>),
                      },
                      {
                        text: genralLan?.general?.name, dataField: "eventName", sort: true
                      },
                      {
                        text: genralLan?.general?.total, dataField: "amount", sort: true,
                        formatter: (i, row) => {
                          if(row.providerShare) return <span>{row.providerShare}</span>
                          return <span>{row.discountApplied ? row.discountedPrice : row.total}</span>
                        }
                      },
                      {
                        text: genralLan?.general?.status, dataField: "status", sort: true,
                        formatter: (data, row) => (<span className={`badge ${data === 'deleted' ? 'badge-danger' : moment(row.startDate).isBefore() ? 'badge-success' : 'badge-warning' }`}>
                          {data === 'deleted' ? 'Canceled' : moment(row.startDate).isBefore() ? 'Completed' : 'Active'}
                        </span>)
                      },
                      {
                        text: genralLan?.general?.start + ' ' + genralLan?.general?.date, dataField: "startDate",
                        formatter: (date) => (
                          moment(date).format(
                            "ddd DD MMM YYYY"
                          ))
                        , sort: true
                      },
                      {
                        text: genralLan?.general?.end + ' ' + genralLan?.general?.date, dataField: "endDate",
                        formatter: (date) => (
                          moment(date).format(
                            "ddd DD MMM YYYY"
                          ))
                        , sort: true
                      },
                      {
                        text: genralLan?.general?.nights, dataField: "numberOfNights", sort: true
                      },
                      {
                        text: genralLan?.general?.guests, dataField: "guests", sort: true
                      },
                      {
                        text: genralLan?.general?.supervisor + ' ' + genralLan?.general?.name, dataField: "eventSupervisorName", sort: true
                      },
                      {
                        text: genralLan?.general?.contact + ' ' + genralLan?.general?.email, dataField: "eventContactMail", sort: true,
                        formatter: (contact, row) => (<p style={{ overflowWrap: 'break-word' }} >
                          {row.paid && contact}
                        </p>)
                      },
                      {
                        text: genralLan?.general?.contact + ' ' + genralLan?.general?.emergency + ' ' + genralLan?.general?.phone, dataField: "eventEmergencyContact", sort: true,
                        formatter: (contact, row) => (<span className="p-1 d-flex">
                          {row.paid && contact}
                        </span>)
                      },
                    ]} />

                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};


export default MyReservation;