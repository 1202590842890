import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ToursForm from "../../../Components/tours/ToursForm";
import { RootState } from "../../../store";
import { fetchTourById, Tours, UpdateTour } from "../../../store/Tours";

const UpdateTourForm: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [initialData, setinitalData] = useState<any>(undefined);

  useEffect(() => {
    const getData = async () => {
      const initialData = await dispatch(fetchTourById(id));
      setinitalData(initialData);
    };
    getData();
  }, []);

  const onSubmit = async (tour: Tours) => {
    try {
      const availabilityUpdated = initialData.available !== tour.available
      await dispatch(UpdateTour(id || id as string, { availabilityUpdated, updatedTour: tour }));
      toast.success("Successfully requested for approval");
      history.push("/profile/tours");
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  const genralLan = useSelector(
    (rootState: RootState) => rootState?.language?.language?.content!
  );
  return initialData ? (
    <div className="col-lg-9 col-12">
      <div className="section-wrap paddresup">
        <section className="form-wrap">
          <h4 className="com-font fw700 text-red text-capitalize">
            {genralLan?.general?.edit} {genralLan?.general?.tour}
          </h4>
          <ToursForm initialData={initialData} onSubmit={onSubmit} status={initialData.status === "approved" ? true : false} />
        </section>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default UpdateTourForm;
