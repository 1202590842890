import Axios from "axios";
import { FunctionComponent, useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../store";
import axiosInstance from "../utilities/axiosInstance";
import ReactMarkdown from 'react-markdown'
import { countriesSafetyInfo } from "../utilities/countriesSafety";
export const Imprint: FunctionComponent = () => {

    const appLanguage = useSelector((rootState: RootState) => rootState.language.language?.language)

    const [markUp, setMarkUp] = useState<any>("");
    const fetchData = async () => {
        const { data } = await axiosInstance.get(`/api/imprint?populate=imprint.image&locale=${appLanguage}`);
        setMarkUp(data.data.attributes.imprint);
    };
    useEffect(() => {

        fetchData();
    }, [appLanguage]);
    if (!markUp)
        return <></>
    return (
        <>
            <div className="container-fluid paddlr0">
                <div className="col-lg-12 faqhead align-items-center justify-content-center d-flex com-font">
                    <div className="container">
                        <div className="faqheadtxt">Imprint</div>
                        {/* <div class="bgheadsmalltxt">Lorem ipsum dolor sit amet tida dino some text goes here</div> */}
                    </div>
                </div>
                <div className="container faqaccord faqconpaddup com-font">
                    {markUp?.map((item: any) =>
                        <div className="row justify-content-between align-items-start p-2">
                            {!!item.heading &&
                                <div className="h3">
                                    {item.heading}
                                </div>
                            }

                            {!!item?.image && <img className="img-fluid " src={item?.image?.data?.attributes?.url} />}
                            {!!item.content && <div dangerouslySetInnerHTML={{ __html: `${item?.content}` }}>
                            </div>}

                        </div>
                    )}


                </div>
            </div>
        </>
    );
};