interface FooterType {
  data: any
}

export interface Footer {
  terms_and_conditions: FooterType,
  privacy_policy: FooterType,
  imprint: FooterType
  provider_contract_en: FooterType
}

export interface FooterState {
  footerContent: Footer;
}

export const SET_FOOTER_CONTENT = "SET_FOOTER_CONTENT";

export interface SetFooterAction {
  type: typeof SET_FOOTER_CONTENT;
  payload: Footer;
}

export type FooterActionTypes = SetFooterAction;
