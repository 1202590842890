import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axiosInstance from "../../utilities/axiosInstance";
import { Lan, LanguageActionTypes, SET_LANGUAGE } from "./type";

export const setLang = (ln: Lan): LanguageActionTypes => {
    return {
        type: SET_LANGUAGE,
        payload: ln,
    }
}

export const setLanguage = (ln: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {

    const currentLanguage = localStorage.getItem('language') ?? 'en';

    // const res = await axiosInstance.get(`/api/content?populate=project,project.accomodationOptions,project.levelOfDifficultyOptions,project.sdgOptions,project.choiceOfFoodOptions,project.typeOfEventOptions,general.tourStandards,general.projectStandards,insurance.guides,tour,tour.accomodationOptions,tour.levelOfDifficultyOptions,tour.choiceOfFoodOptions,tour.typeOfEventOptions,tour.sdgOptions,company,payment,paymentClient,signin,general,travellerForm,insurance,workWithUs&locale=${ln}`)
    const res = await axiosInstance.get(`/api/projects/content?locale=${ln}`)
    localStorage.setItem('language', ln);

    dispatch(setLang({ content: res.data.data, language: ln }))

    if(currentLanguage !== ln) { window.location.reload(); }
}

