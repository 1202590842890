import { useState } from "react";
import { motion } from "framer-motion";

import axiosInstance from "../../utilities/axiosInstance";

export const useFetchHomeVideo = () => {
  const [coverVideo, setCoverVideo] = useState<string | null>(null);

  const fetchVideo = async () => {
    const response = await axiosInstance.get(
      `/api/homepage?populate=Hero.second_hero_img&locale=en`
    );

    setCoverVideo(
      response.data.data.attributes.Hero.second_hero_img.data.attributes.url
    );
  };

  return { coverVideo, fetchVideo };
};

type HomeVideoProps = {
  coverVideo: string | null;
};
const HomeVideo = ({ coverVideo }: HomeVideoProps) => {
  if (!coverVideo) {
    return null;
  }

  return (
    <motion.div
      className="img-16by9 container_video"
      animate={{ scale: 1 }}
      initial={{ scale: 2 }}
      transition={{ duration: 1.5, type: "spring" }}
    >
      <div className="div_video">
        <video
          muted
          autoPlay
          height={700}
          width="100%"
          preload="auto"
          className="tag_video"
        >
          <source src={coverVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </motion.div>
  );
};

export default HomeVideo;
