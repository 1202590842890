import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ProjectForm from "../../../Components/projects/ProjectForm";
import { RootState } from "../../../store";
import {
  fetchProjectById,
  Project,
  UpdateProject
} from "../../../store/Projects";

interface UpdateProjectFormProps {
  projectId?: string
}
const UpdateProjectForm: FunctionComponent<UpdateProjectFormProps> = ({ projectId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [initialData, setinitalData] = useState<any>(undefined);

  useEffect(() => {
    const getData = async () => {
      const initialData = await dispatch(fetchProjectById(id || projectId as string));
      setinitalData(initialData);
    };
    getData();
  }, []);

  const onSubmit = async (project: Project) => {
    try {
      const availabilityUpdated = initialData?.available !== project.available
      const res: any = await dispatch(UpdateProject(id || projectId as string, { availabilityUpdated, updatedProject: project }));
      if (res.success) {
        toast.success(res?.message)
        history.push("/profile/projects");
      }
      else {
        toast.error(res?.message)
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  const genralLan = useSelector(
    (rootState: RootState) => rootState?.language?.language?.content!
  );
  return initialData ? (
    <div className="col-lg-9 col-12">
      <div className="section-wrap paddresup">
        <section className="form-wrap">
          <h4 className="com-font fw700 text-red text-capitalize">
            {genralLan?.general?.edit} {genralLan?.general?.project}
          </h4>
          <ProjectForm initialData={initialData} onSubmit={onSubmit} status={initialData?.status === "approved" ? true : false} />

        </section>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default UpdateProjectForm;
