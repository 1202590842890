/* eslint-disable no-restricted-globals */
import dayjs from "dayjs";
import _ from "lodash";
import React, { FunctionComponent, useEffect, useMemo } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { RootState } from "../../store";
import { Project } from "../../store/Projects";
import { setCurrentDayToDayProgram } from "../../store/SideBar";
import { Tours } from "../../store/Tours";
import { getEventType, getLatestBookingDate, getReviewArray } from "../../util";
import calculateEventPrice from "../../utilities/calcProjectPrice";
import Standards from "../../utilities/Standards";
import ImageBox from "../ImageBox";
// import { Client } from "@googlemaps/google-maps-services-js";
import "./fullscreenModel.css";

const DayToDayProgramModal: FunctionComponent = () => {
  const dispatch = useDispatch();
  const Language = useSelector((state: RootState) => state.language.language?.content)

  const currentDayToDayProgram = useSelector(
    (rootState: RootState) => rootState.sideBar.currentDayToDayProgram
  );
  const onClose = () => dispatch(setCurrentDayToDayProgram(undefined));
  useEffect(() => {
    console.log("onClose")
  }, []);
  const eventDetails = useMemo(() => {
    if (!currentDayToDayProgram) return
    const { accommodationPicture, transportationPicture } = currentDayToDayProgram!
    const eventType = getEventType(currentDayToDayProgram)
    let allPictures = [...accommodationPicture, ...transportationPicture];
    const startDate = dayjs(getLatestBookingDate(currentDayToDayProgram.startDate)).toDate()
    const endDate = dayjs(getLatestBookingDate(currentDayToDayProgram.startDate)).add(1, 'd').toDate()
    const basePrice = calculateEventPrice(eventType, 1, currentDayToDayProgram.category, startDate, endDate, currentDayToDayProgram, 0)

    if (eventType === "project") {
      const project = (currentDayToDayProgram as Project)
      console.log(project)
      return {
        eventName: project.projectName,
        eventDesc: project.projectDescription,
        allPictures: [...project.projectPictures, ...allPictures],
        dayToDay: [],
        typicalDay: project.typicalDay,
        eventType,
        OwnerName: project.projectOwnerName,
        ownerDescription: project.ownerDescription,
        category: project.category,
        extraAccommodation: project.extraAccommodation,
        foodDescription: project.foodDescription,
        transportation: project.transportation,
        capacity: project.capacity,
        location: project.location,
        minCapacity: project.minCapacity,
        noticePeriod: project.noticePeriod,
        nearAirport: project.nearAirport,
        eventRating: project.rating,
        levelOfDifficulty: project.levelOfDifficulty,
        eventCity: project.eventCity,
        additionalCosts: project.additionalPrice,
        startDate: {
          start: getLatestBookingDate(project.startDate),
        },
        natureOfEvent: project.natureOfEvent,
        natureOfEventdescription: project.natureOfEventdescription,
        spokenLanguages: project.spokenLanguages,
        basePrice: basePrice.price
      }
    } else {
      const tour = (currentDayToDayProgram as Tours)
      return {
        eventName: tour.tourName,
        eventDesc: tour.tourDescription,
        allPictures: [...tour.tourPictures, ...allPictures],
        dayToDay: tour.dayToDay,
        typicalDay: [],
        additionalCosts: tour.additionalPrice,
        eventType,
        OwnerName: tour.tourOwnerName,
        ownerDescription: tour.ownerDescription,
        category: tour.category,
        extraAccommodation: tour.extraAccommodation,
        foodDescription: tour.foodDescription,
        transportation: tour.transportation,
        capacity: tour.capacity,
        duration: tour.duration,
        location: tour.location,
        eventCity: tour.eventCity,
        minCapacity: tour.minCapacity,
        noticePeriod: tour.noticePeriod,
        nearAirport: tour.nearAirport,
        eventRating: tour.rating,
        levelOfDifficulty: tour.levelOfDifficulty,
        startDate: _.sortBy(tour.includedEventsDates, d => d.start).find(item => {
          if (dayjs().isBefore(item.start) && item.capacity)
            return dayjs(item.start).toDate()
        }
        ),
        natureOfEvent: tour.natureOfEvent,
        natureOfEventdescription: tour.natureOfEventdescription,
        spokenLanguages: tour.spokenLanguages,
        basePrice: basePrice.price
      }
    }
  }, [currentDayToDayProgram])
  if (!currentDayToDayProgram) return <></>;
  const waypoints = () => {
    let str = ''
    if (eventDetails?.dayToDay && eventDetails?.dayToDay.length > 2) {
      let daytoDay = [...eventDetails?.dayToDay];
      daytoDay.shift()
      daytoDay.pop()
      str = `&waypoints=${daytoDay.map(d => d.city)}|`
      str = str.slice(0, -1);
    }
    console.log("hello there", str)
    return str
  }
  const starArray = getReviewArray(eventDetails?.eventRating);
  console.log(eventDetails?.startDate)
  return (
    <Modal show={currentDayToDayProgram ? true : false} onHide={onClose} className="day-to-day-modal m-5">
      <div id="animatedModalReadMore">
        <div className="col-lg-12">
          <div
            id="btn-close-modal"
            className="close-animatedModalReadMore closespan"
            onClick={onClose}
          >
            <div className="crossmarkx">x</div>
          </div>
          <div className="modal-content">
            <div className="container">
              <div className="row pb-5">
                <div className="col-12">
                  <ImageBox Images={eventDetails!.allPictures} />
                </div>
              </div>
              <div className="row com-font">
                <div className="col-md-12 col-lg-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="swt-headmain com-font text-uppercase">
                        {eventDetails!.eventName}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <HashLink scroll={(el: any) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })} onClick={onClose} to={`/${eventDetails!.eventType}s/${currentDayToDayProgram.id}#details`} className="btn btn-sm btnsmbook float-right">
                        Book Now
                      </HashLink>
                    </div>
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-6 ">
                          {/* <button class="circleplus circleplus-color btn btn-sm"><i class="fa fa-plus"
                       aria-hidden="true"></i></button> */}
                          {/* <img
                            src="/images/heart_icon.svg"
                            className="img-fluid modheartpadd"
                            alt=""
                          /> */}
                          {/* <button class="btn herrticon btn-sm"><i class="far fa-heart"></i></button> */}
                          <div className="row projectBooking">
                            <div className="col-lg-12 rate">
                              <div className="star-ratingproj text-left">
                                {starArray.map((checked) => (
                                  <span
                                    className={`fa fa-star ${checked ? "checked" : ""
                                      }`}
                                  />
                                ))}
                              </div>
                            </div>
                            <div className="col-lg-12 proj-detail-text com-font">
                              Customer reviews
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 float-right text-right">
                          <span className="datetxt">
                            {/* {dayjs(currentDayToDayProgram.startDate).format(
                              "D"
                            )} */}
                            {dayjs(eventDetails?.startDate?.start).format("D")}
                          </span>
                          <span className="monthtxt">
                            {dayjs(eventDetails?.startDate?.start).format(
                              "MMMM"
                            )}
                            .
                          </span>
                          <span className="posplaneic">
                            <img src="/images/suv-car.svg" className="img-fluid" alt="" />
                            <p className=" mb-0 loctxt">
                              <img src="/images/blackmap.svg" className="img-fluid" alt="" />{" "}
                              {currentDayToDayProgram.location}
                            </p>
                            {eventDetails?.eventType === 'tour' && <span className="small daymod">{eventDetails?.duration} days</span>}
                            <div className="mt-1">
                              <div className="font-weight-bold my-2 pricetxt">
                                {/* {eventDetails?.eventType === 'tour' ? `€${currentDayToDayProgram.price}` : `${currentDayToDayProgram.price}€/week`} */}
                                from {eventDetails?.eventType === 'tour' ? `€${eventDetails.basePrice}` : `${eventDetails?.basePrice}€/${Language?.general?.nights}`}
                                {/* €{currentDayToDayProgram.price} */}
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row paddformupdwn">
                <div className="col-md-6 col-lg-6 ">
                  <div className="swt-headmain com-font text-uppercase">
                    {eventDetails?.eventType} DETAILS
                  </div>
                  <div className="row textimgprojpaddud com-font">
                    <div
                      className="text-justify small col-lg-12"
                      dangerouslySetInnerHTML={{ __html: eventDetails!.eventDesc }}
                    ></div>
                  </div>
                  <div className="row textimgprojpaddud com-font">
                    <div className="form-group">
                      <label className="font-weight-bold d-block mb-0">Type of {eventDetails?.eventType}:</label>
                      <span className="d-block">{eventDetails?.natureOfEvent}</span>
                    </div>

                    <div
                      className="text-justify small col-lg-12"
                    >
                      {eventDetails?.natureOfEventdescription}
                    </div>
                  </div>
                  <div className="row textimgprojpaddud com-font">
                    <div className="form-group">
                      <label className="font-weight-bold d-block mb-0">Additional Price :</label>
                      <span className="d-block">{eventDetails?.additionalCosts}</span>
                    </div>
                  </div>
                  <h5 className="com-font fw700 text-capitalize mt-4 mb-4">about the owner</h5>
                  <div className="wrapper com-font">
                    <div className="form-group">
                      <label className="font-weight-bold d-block mb-0">name:</label>
                      <span className="d-block">{eventDetails?.OwnerName}</span>
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold d-block mb-0">tour owner description:</label>
                      <span className="small">{eventDetails?.ownerDescription}</span>
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold d-block mb-2">accomodation categories:</label>
                      <span className="font-weight-bold d-block">{eventDetails?.category}</span>
                      <ul className="list-none paddinclist proj-detail-text">
                        <li>{currentDayToDayProgram?.accommodationDescription}</li>
                      </ul>
                      {
                        eventDetails?.extraAccommodation.map(acc => (
                          <div>
                            <span className="font-weight-bold d-block">{acc.category}</span>
                            <span className="proj-detail-text">{acc.description}</span>
                          </div>
                        ))
                      }
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold d-block mb-0">Food:</label>
                      <span className="d-block small">{eventDetails?.foodDescription}</span>
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold d-block mb-0">transportation:</label>
                      <span className="d-block small">{eventDetails?.transportation}</span>
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold d-block mb-0">Nearest Airport:</label>
                      <span className="d-block small">{eventDetails?.nearAirport}</span>
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold d-block mb-0">Spoken Languages:</label>
                      <span className="d-block small">{eventDetails?.spokenLanguages}</span>
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold d-block mb-0">capacity:</label>
                      <span className="d-block">{eventDetails?.capacity}</span>
                    </div>
                    {
                      eventDetails?.minCapacity && (
                        <div className="form-group">
                          <label className="font-weight-bold d-block mb-0">Miniumum Capacity:</label>
                          <span className="d-block">{eventDetails?.minCapacity}</span>
                        </div>
                      )
                    }
                    {
                      eventDetails?.noticePeriod && (
                        <div className="form-group">
                          <label className="font-weight-bold d-block mb-0">Notice Period in Days:</label>
                          <span className="d-block">{eventDetails?.noticePeriod}</span>
                        </div>
                      )
                    }
                  </div>

                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="col">
                    <label>
                      Risk Level<span className="text-danger">*</span>
                    </label>
                    <ProgressBar>
                      {eventDetails?.levelOfDifficulty === 'easy' && <ProgressBar style={{ backgroundColor: '#A3A3A3' }} now={25} label={'Easy'} />}
                      {eventDetails?.levelOfDifficulty === 'moderate' && <ProgressBar style={{ backgroundColor: '#5AFF15' }} now={50} label={'Moderate'} />}
                      {eventDetails?.levelOfDifficulty === 'difficult/ risky' && <ProgressBar style={{ backgroundColor: '#6A66A3' }} now={75} label={"Difficult"} />}
                      {eventDetails?.levelOfDifficulty === 'extreme' && <ProgressBar style={{ backgroundColor: '#F52F57' }} now={100} label={'Extreme'} />}
                    </ProgressBar>
                    <p className="text-muted">
                      <span className="text-danger">*</span>
                      {Language?.general?.risk_level_helper}
                    </p>
                  </div>
                  <div className="form-group">
                    <label className="font-weight-bold d-block mb-0">Included Standards:</label>
                    {eventDetails?.eventType === 'project' ? Standards.Projects : Standards.Tours}
                  </div>

                  <div className="mapoverlay row">
                    {eventDetails?.dayToDay.length ?
                      <iframe
                        width={600}
                        height={300}
                        frameBorder={0}
                        style={{ border: 0 }}
                        aria-hidden="false"
                        tabIndex={0}
                        src={`https://www.google.com/maps/embed/v1/directions?key=${process.env.REACT_APP_MAPS_API_KEY}&origin=${eventDetails?.dayToDay[0].city}&destination=${eventDetails?.dayToDay[eventDetails.dayToDay.length - 1].city}${waypoints()}`}
                      /> :

                      <iframe
                        src={`https://www.google.com/maps/embed/v1/places?key=${process.env.REACT_APP_MAPS_API_KEY}&q=${eventDetails?.eventCity}`}
                        width="100%"
                        height={300}
                        frameBorder={0}
                        style={{ border: 0 }}
                        allowFullScreen
                        aria-hidden="false"
                        tabIndex={0}
                      />
                    }
                  </div>
                  <div className="row textimgprojpaddud com-font">
                    {/* <div className="col-lg-12 proj-detail-text text-justify">
                      <div className="includeprojtxt">Included</div>
                      <ul className="list-none paddinclist">
                        {currentDayToDayProgram.accommodationDescription
                          .split("\n")
                          .map((text) => (
                            <li>{text}</li>
                          ))}
                      </ul>
                       <div class="text-right chat-icon">
                       <i class="far fa-comment-alt"></i>
                     </div>
                    </div> */}
                    {
                      eventDetails?.eventType === 'tour' ?
                        (
                          <div className="col-md-12 mb-2">
                            <a href="#" className="btn-link">
                              <img src="/images/leaflet_icon.svg" alt="" />
                              <span className="daymod">Day-to-day program</span>
                            </a>
                            <div className="row">
                              {(eventDetails?.dayToDay as any[]).map((d, i) => (
                                <div className="col-lg-12" key={i}>
                                  <div className="daytextheading">Day {i + 1}</div>
                                  <div className="text-justify small">
                                    {d?.value}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) :
                        (
                          <div className="col-md-12 mb-2">
                            <a href="#" className="btn-link">
                              <img src="/images/leaflet_icon.svg" alt="" />
                              <span className="daymod">A Typical Day in a Project</span>
                            </a>
                            <div className="proj-detail-text" >{eventDetails?.typicalDay}</div>
                          </div>
                        )
                    }
                  </div>
                  <div className="form-group">
                    <label className="font-weight-bold d-block mb-0">Travel & Safety information of your destination:</label>
                    <a target={'_blank'} onClick={onClose} href={`/travel-and-safety/${eventDetails?.location!}`}>{`${location.host}/travel-and-safety/${eventDetails?.location!}`}</a>
                  </div>
                </div>
                <div className="col-lg-12 overbookbtnpadd">
                  <HashLink
                    onClick={onClose}
                    scroll={(el: any) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                    to={`/${eventDetails!.eventType}s/${currentDayToDayProgram.id}#details`}
                    className="btn btn-default btn-block btnfinalbook com-font"
                    type="submit"
                  >
                    Book Now
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DayToDayProgramModal;
