import React, { FunctionComponent, useState } from "react";
import { CloudinaryImg } from "../store/Projects";
import SwiperCore, { Navigation, Thumbs } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/thumbs/thumbs.min.css";
SwiperCore.use([Navigation, Thumbs]);

interface ImageBoxProps {
  Images: CloudinaryImg[];
}
const ImageBox: FunctionComponent<ImageBoxProps> = ({ Images }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

  return (
    <>
      <Swiper
        spaceBetween={1}
        thumbs={{ swiper: thumbsSwiper }}
        navigation
        autoplay
        observer={true}
        observeParents={true}
        className="mt-4"
        slideToClickedSlide={true}
      >
        {Images.map(({ imageURL }) => (
          <SwiperSlide key={imageURL}>
            <div className="img-box" style={{ backgroundColor: "#fffff", }}>
              <img className="img-fluid" src={imageURL} alt="" style={{ objectFit: 'cover', height: '500px', width: '100%' }} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        className="mt-1"
        spaceBetween={1}
        slidesPerView={5}
        observer={true}
        observeParents={true}
        freeMode={true}
        watchSlidesVisibility={true}
        watchSlidesProgress={true}
        slideToClickedSlide={true}
      >
        {Images.map(({ imageURL }) => (
          <SwiperSlide className="img-21by9" key={imageURL}>
            <div className="img-box" style={{ backgroundColor: "#fffff" }}>
              <img src={imageURL} alt="" style={{ objectFit: 'cover'}} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default ImageBox;
