import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import { filterEmptyStringsProps } from "../../util";
import axiosInstance from "../../utilities/axiosInstance";
import { DraftsActionTypes, SET_DRAFTS } from "./type";

export const setDrafts = (drafts: any[]): DraftsActionTypes => {
  return {
    type: SET_DRAFTS,
    payload: drafts
  }
}

export const fetchDrafts = (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
  const userId = getState().authReducer.UserDetails?.id
  const { data: { draft } } = await axiosInstance.get(`/api/drafts/getUserDraft/${userId}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem('swt-token')
    }
  })
  dispatch(setDrafts(draft))
  return draft
}

export const fetchEventDrafts = (type: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
  const userId = getState().authReducer.UserDetails?.id
  const { data: { draft } } = await axiosInstance.get(`/api/drafts/get-user-event/${userId}?type=${type}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem('swt-token')
    }
  })
  dispatch(setDrafts(draft))
  return draft
}

export const upsertDraft = (draft: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
  console.log('in upsert Draft');
  const copyOfDraft = filterEmptyStringsProps({ ...draft })
  // delete copyOfDraft.createdBy
  const id = draft.id
  console.log("draft copy", copyOfDraft)
  let res = null
  if (id) {
    res = await axiosInstance.post(`/api/drafts/${id}`, { draft: copyOfDraft }, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    })
  } else {
    res = await axiosInstance.post(`/api/drafts${draft.type === 'project' ? '/' : '/tour'}`, { draft: copyOfDraft }, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    })
  }
  return res.data.draft
}

export const saveEventAsDraft = (draft: any, type: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
  const copyOfDraft = filterEmptyStringsProps({ ...draft })
  console.log("in saveEventAsDraft draft copy", copyOfDraft)
  let res = await axiosInstance.post(`/api/drafts${type === 'project' ? '/project' : '/provider-tour'}`, { draft: copyOfDraft }, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem('swt-token')
    }
  });
  return res.data.draft
}

export const createCompleteDraft = (draft: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
  const copyOfDraft = { ...draft }
  delete copyOfDraft.id
  const res = await axiosInstance.post(`/api/drafts/create${draft.type === 'project' ? '/' : '/tour'}`, { draft: { ...copyOfDraft, requested: true } }, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem('swt-token')
    }
  })
  return res
}

export const deleteDraft = (draftId: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
  const res = await axiosInstance.delete(`/api/drafts/${draftId}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem('swt-token')
    }
  })
  return res.data.draft
}

export const fetchDraftById = (Id: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
  const res = await axiosInstance.get(`/api/drafts/${Id}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem('swt-token')
    }
  })
  return res.data.draft
}
