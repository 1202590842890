export const countriesSafetyInfo = [
    {
        "contendID": 213920,
        "contryName": "Australia",
        "isoCountryCode": "AU"
    },
    {
        "contendID": 212622,
        "contryName": "Egypt",
        "isoCountryCode": "EG"
    },
    {
        "contendID": 216248,
        "contryName": "Albania",
        "isoCountryCode": "AL"
    },
    {
        "contendID": 219044,
        "contryName": "Algeria",
        "isoCountryCode": "DZ"
    },
    {
        "contendID": 224898,
        "contryName": "Andorra",
        "isoCountryCode": "AD"
    },
    {
        "contendID": 208118,
        "contryName": "Angola",
        "isoCountryCode": "AO"
    },
    {
        "contendID": 226768,
        "contryName": "Equatorial Guinea",
        "isoCountryCode": "GQ"
    },
    {
        "contendID": 201326,
        "contryName": "Argentina",
        "isoCountryCode": "AR"
    },
    {
        "contendID": 201872,
        "contryName": "Armenia",
        "isoCountryCode": "AM"
    },
    {
        "contendID": 209504,
        "contryName": "Ethiopia",
        "isoCountryCode": "ET"
    },
    {
        "contendID": 200382,
        "contryName": "Belgium",
        "isoCountryCode": "BE"
    },
    {
        "contendID": 213428,
        "contryName": "Bolivia",
        "isoCountryCode": "BO"
    },
    {
        "contendID": 219522,
        "contryName": "Botsuana",
        "isoCountryCode": "BW"
    },
    {
        "contendID": 201092,
        "contryName": "Brazil",
        "isoCountryCode": "BR"
    },
    {
        "contendID": 224526,
        "contryName": "Brunei Darussalam",
        "isoCountryCode": "BR"
    },
    {
        "contendID": 211834,
        "contryName": "Bulgaria",
        "isoCountryCode": "BG"
    },
    {
        "contendID": 212336,
        "contryName": "Burkina Faso",
        "isoCountryCode": "BF"
    },
    {
        "contendID": 222614,
        "contryName": "Burundi",
        "isoCountryCode": "BI"
    },
    {
        "contendID": 208824,
        "contryName": "Cabo Verde",
        "isoCountryCode": "CV"
    },
    {
        "contendID": 201230,
        "contryName": "Chile",
        "isoCountryCode": "CL"
    },
    {
        "contendID": 200466,
        "contryName": "China",
        "isoCountryCode": "CN"
    },
    {
        "contendID": 224816,
        "contryName": "Costa Rica",
        "isoCountryCode": "CR"
    },
    {
        "contendID": 209460,
        "contryName": "Ivory Coast",
        "isoCountryCode": "CI"
    },
    {
        "contendID": 211724,
        "contryName": "Denmark",
        "isoCountryCode": "DK"
    },
    {
        "contendID": 220300,
        "contryName": "Dominica",
        "isoCountryCode": "DM"
    },
    {
        "contendID": 206146,
        "contryName": "Dominican Republic",
        "isoCountryCode": "DO"
    },
    {
        "contendID": 212168,
        "contryName": "Djibouti",
        "isoCountryCode": "DJ"
    },
    {
        "contendID": 223232,
        "contryName": "Ecuador",
        "isoCountryCode": "EC"
    },
    {
        "contendID": 221864,
        "contryName": "El Salvador",
        "isoCountryCode": "SV"
    },
    {
        "contendID": 226176,
        "contryName": "Eritrea",
        "isoCountryCode": "ER"
    },
    {
        "contendID": 200754,
        "contryName": "Estonia",
        "isoCountryCode": "EE"
    },
    {
        "contendID": 226240,
        "contryName": "Swaziland",
        "isoCountryCode": "SZ"
    },
    {
        "contendID": 226626,
        "contryName": "Fiji",
        "isoCountryCode": "FJ"
    },
    {
        "contendID": 211624,
        "contryName": "Finland",
        "isoCountryCode": "FI"
    },
    {
        "contendID": 209524,
        "contryName": "France",
        "isoCountryCode": "FR"
    },
    {
        "contendID": 226324,
        "contryName": "Gabon",
        "isoCountryCode": "GA"
    },
    {
        "contendID": 213624,
        "contryName": "Gambia",
        "isoCountryCode": "GM"
    },
    {
        "contendID": 201918,
        "contryName": "Georgia",
        "isoCountryCode": "GE"
    },
    {
        "contendID": 203372,
        "contryName": "Ghana",
        "isoCountryCode": "GH"
    },
    {
        "contendID": 220316,
        "contryName": "Grenada",
        "isoCountryCode": "GD"
    },
    {
        "contendID": 211534,
        "contryName": "Greece",
        "isoCountryCode": "GR"
    },
    {
        "contendID": 221882,
        "contryName": "Guatemala",
        "isoCountryCode": "GT"
    },
    {
        "contendID": 206098,
        "contryName": "Guinea",
        "isoCountryCode": "GN"
    },
    {
        "contendID": 220332,
        "contryName": "Guinea-Bissau",
        "isoCountryCode": "GW"
    },
    {
        "contendID": 220348,
        "contryName": "Guyana",
        "isoCountryCode": "GY"
    },
    {
        "contendID": 205048,
        "contryName": "Haiti",
        "isoCountryCode": "HT"
    },
    {
        "contendID": 221078,
        "contryName": "Honduras",
        "isoCountryCode": "HN"
    },
    {
        "contendID": 205998,
        "contryName": "India",
        "isoCountryCode": "IN"
    },
    {
        "contendID": 212396,
        "contryName": "Indonesia",
        "isoCountryCode": "ID"
    },
    {
        "contendID": 211460,
        "contryName": "Ireland",
        "isoCountryCode": "IE"
    },
    {
        "contendID": 223400,
        "contryName": "Iceland",
        "isoCountryCode": "IS"
    },
    {
        "contendID": 203814,
        "contryName": "Israel",
        "isoCountryCode": "IL"
    },
    {
        "contendID": 211322,
        "contryName": "Italy",
        "isoCountryCode": "IT"
    },
    {
        "contendID": 226490,
        "contryName": "Jamaica",
        "isoCountryCode": "JM"
    },
    {
        "contendID": 213032,
        "contryName": "Japan",
        "isoCountryCode": "JP"
    },
    {
        "contendID": 218008,
        "contryName": "Jordan",
        "isoCountryCode": "JO"
    },
    {
        "contendID": 220008,
        "contryName": "Cambodia",
        "isoCountryCode": "KH"
    },
    {
        "contendID": 208874,
        "contryName": "Cameroon",
        "isoCountryCode": "CM"
    },
    {
        "contendID": 204874,
        "contryName": "Canada",
        "isoCountryCode": "CA"
    },
    {
        "contendID": 208058,
        "contryName": "Kenia",
        "isoCountryCode": "KE"
    },
    {
        "contendID": 220364,
        "contryName": "Kiribati",
        "isoCountryCode": "KI"
    },
    {
        "contendID": 201516,
        "contryName": "Colombia",
        "isoCountryCode": "CO"
    },
    {
        "contendID": 203202,
        "contryName": "Congo",
        "isoCountryCode": "CD"
    },
    {
        "contendID": 210072,
        "contryName": "Croatia",
        "isoCountryCode": "HR"
    },
    {
        "contendID": 212208,
        "contryName": "Cuba",
        "isoCountryCode": "CU"
    },
    {
        "contendID": 204130,
        "contryName": "Kuwait",
        "isoCountryCode": "KW"
    },
    {
        "contendID": 201048,
        "contryName": "Laos",
        "isoCountryCode": "LA"
    },
    {
        "contendID": 226974,
        "contryName": "Lesotho",
        "isoCountryCode": "LS"
    },
    {
        "contendID": 200674,
        "contryName": "Latvia",
        "isoCountryCode": "LV"
    },
    {
        "contendID": 204048,
        "contryName": "Lebanon",
        "isoCountryCode": "LB"
    },
    {
        "contendID": 222378,
        "contryName": "Liberia",
        "isoCountryCode": "LR"
    },
    {
        "contendID": 219624,
        "contryName": "Libya",
        "isoCountryCode": "LY"
    },
    {
        "contendID": 200620,
        "contryName": "Lithuania",
        "isoCountryCode": "LT"
    },
    {
        "contendID": 211256,
        "contryName": "Luxembourg",
        "isoCountryCode": "LU"
    },
    {
        "contendID": 207960,
        "contryName": "Madagascar",
        "isoCountryCode": "MG"
    },
    {
        "contendID": 208508,
        "contryName": "Malawi",
        "isoCountryCode": "MW"
    },
    {
        "contendID": 223616,
        "contryName": "Malaysia",
        "isoCountryCode": "MY"
    },
    {
        "contendID": 220382,
        "contryName": "Maldives",
        "isoCountryCode": "MV"
    },
    {
        "contendID": 208258,
        "contryName": "Mali",
        "isoCountryCode": "ML"
    },
    {
        "contendID": 211188,
        "contryName": "Malta",
        "isoCountryCode": "MT"
    },
    {
        "contendID": 219190,
        "contryName": "Mauritania",
        "isoCountryCode": "MR"
    },
    {
        "contendID": 206994,
        "contryName": "Mauritius",
        "isoCountryCode": "MU"
    },
    {
        "contendID": 213648,
        "contryName": "Mexico",
        "isoCountryCode": "MX"
    },
    {
        "contendID": 201932,
        "contryName": "Moldova",
        "isoCountryCode": "MD"
    },
    {
        "contendID": 222842,
        "contryName": "Mongolia",
        "isoCountryCode": "MN"
    },
    {
        "contendID": 216330,
        "contryName": "Montenegro",
        "isoCountryCode": "ME"
    },
    {
        "contendID": 224080,
        "contryName": "Morocco",
        "isoCountryCode": "MA"
    },
    {
        "contendID": 221782,
        "contryName": "Mozambique",
        "isoCountryCode": "MZ"
    },
    {
        "contendID": 208314,
        "contryName": "Namibia",
        "isoCountryCode": "NA"
    },
    {
        "contendID": 220416,
        "contryName": "Nauru",
        "isoCountryCode": "NR"
    },
    {
        "contendID": 221216,
        "contryName": "Nepal",
        "isoCountryCode": "NP"
    },
    {
        "contendID": 220146,
        "contryName": "New Zealand",
        "isoCountryCode": "NZ"
    },
    {
        "contendID": 223316,
        "contryName": "Nicaragua",
        "isoCountryCode": "NI"
    },
    {
        "contendID": 211084,
        "contryName": "Netherlands",
        "isoCountryCode": "NL"
    },
    {
        "contendID": 226384,
        "contryName": "Niger",
        "isoCountryCode": "NE"
    },
    {
        "contendID": 205788,
        "contryName": "Nigeria",
        "isoCountryCode": "NG"
    },
    {
        "contendID": 205878,
        "contryName": "Norway",
        "isoCountryCode": "NO"
    },
    {
        "contendID": 204160,
        "contryName": "Oman",
        "isoCountryCode": "OM"
    },
    {
        "contendID": 210962,
        "contryName": "Austria",
        "isoCountryCode": "AT"
    },
    {
        "contendID": 204974,
        "contryName": "Pakistan",
        "isoCountryCode": "PK"
    },
    {
        "contendID": 220958,
        "contryName": "Palau",
        "isoCountryCode": "PW"
    },
    {
        "contendID": 206378,
        "contryName": "Panama",
        "isoCountryCode": "PA"
    },
    {
        "contendID": 220698,
        "contryName": "Papua New Guinea",
        "isoCountryCode": "PG"
    },
    {
        "contendID": 224964,
        "contryName": "Paraguay",
        "isoCountryCode": "PY"
    },
    {
        "contendID": 211938,
        "contryName": "Peru",
        "isoCountryCode": "PE"
    },
    {
        "contendID": 212492,
        "contryName": "Philippines",
        "isoCountryCode": "PH"
    },
    {
        "contendID": 199124,
        "contryName": "Poland",
        "isoCountryCode": "PL"
    },
    {
        "contendID": 210900,
        "contryName": "Portugal",
        "isoCountryCode": "PT"
    },
    {
        "contendID": 212026,
        "contryName": "Rwanda",
        "isoCountryCode": "RW"
    },
    {
        "contendID": 210822,
        "contryName": "Romania",
        "isoCountryCode": "RO"
    },
    {
        "contendID": 201536,
        "contryName": "Russia",
        "isoCountryCode": "RU"
    },
    {
        "contendID": 220650,
        "contryName": "Solomon Islands",
        "isoCountryCode": "SB"
    },
    {
        "contendID": 208606,
        "contryName": "Zambia",
        "isoCountryCode": "ZM"
    },
    {
        "contendID": 213758,
        "contryName": "Samoa",
        "isoCountryCode": "WS"
    },
    {
        "contendID": 210708,
        "contryName": "Sweden",
        "isoCountryCode": "SE"
    },
    {
        "contendID": 206208,
        "contryName": "Switzerland",
        "isoCountryCode": "CH"
    },
    {
        "contendID": 208190,
        "contryName": "Senegal",
        "isoCountryCode": "SN"
    },
    {
        "contendID": 207502,
        "contryName": "Serbia",
        "isoCountryCode": "RS"
    },
    {
        "contendID": 226912,
        "contryName": "Seychelles",
        "isoCountryCode": "SC"
    },
    {
        "contendID": 203500,
        "contryName": "Sierra Leone",
        "isoCountryCode": "SL"
    },
    {
        "contendID": 208948,
        "contryName": "Zimbabwe",
        "isoCountryCode": "ZW"
    },
    {
        "contendID": 225412,
        "contryName": "Singapore",
        "isoCountryCode": "SG"
    },
    {
        "contendID": 206360,
        "contryName": "Slovakia",
        "isoCountryCode": "SK"
    },
    {
        "contendID": 210644,
        "contryName": "Slovenia",
        "isoCountryCode": "SI"
    },
    {
        "contendID": 203132,
        "contryName": "Somalia",
        "isoCountryCode": "SO"
    },
    {
        "contendID": 210534,
        "contryName": "Spain",
        "isoCountryCode": "ES"
    },
    {
        "contendID": 212254,
        "contryName": "Sri Lanka",
        "isoCountryCode": "LK"
    },
    {
        "contendID": 208400,
        "contryName": "South Africa",
        "isoCountryCode": "ZA"
    },
    {
        "contendID": 203266,
        "contryName": "Sudan",
        "isoCountryCode": "SD"
    },
    {
        "contendID": 244250,
        "contryName": "South Sudan",
        "isoCountryCode": "SS"
    },
    {
        "contendID": 220450,
        "contryName": "Suriname",
        "isoCountryCode": "SR"
    },
    {
        "contendID": 206756,
        "contryName": "Tajikistan",
        "isoCountryCode": "TJ"
    },
    {
        "contendID": 208662,
        "contryName": "Tanzania",
        "isoCountryCode": "TZ"
    },
    {
        "contendID": 201558,
        "contryName": "Thailand",
        "isoCountryCode": "TH"
    },
    {
        "contendID": 213850,
        "contryName": "Togo",
        "isoCountryCode": "TG"
    },
    {
        "contendID": 213790,
        "contryName": "Tonga",
        "isoCountryCode": "TO"
    },
    {
        "contendID": 225774,
        "contryName": "Chad",
        "isoCountryCode": "TD"
    },
    {
        "contendID": 219024,
        "contryName": "Tunesien",
        "isoCountryCode": "TN"
    },
    {
        "contendID": 201962,
        "contryName": "Turkey",
        "isoCountryCode": "TR"
    },
    {
        "contendID": 206774,
        "contryName": "Turkmenistan",
        "isoCountryCode": "TM"
    },
    {
        "contendID": 208752,
        "contryName": "Uganda",
        "isoCountryCode": "UG"
    },
    {
        "contendID": 201946,
        "contryName": "Ukraine",
        "isoCountryCode": "UA"
    },
    {
        "contendID": 210332,
        "contryName": "Hungary",
        "isoCountryCode": "HU"
    },
    {
        "contendID": 201138,
        "contryName": "Uruguay",
        "isoCountryCode": "UY"
    },
    {
        "contendID": 220498,
        "contryName": "Vanuatu",
        "isoCountryCode": "VU"
    },
    {
        "contendID": 224982,
        "contryName": "Venezuela",
        "isoCountryCode": "VE"
    },
    {
        "contendID": 206408,
        "contryName": "United Kingdom",
        "isoCountryCode": "GB"
    },
    {
        "contendID": 217274,
        "contryName": "Vietnam",
        "isoCountryCode": "VN"
    },
    {
        "contendID": 226450,
        "contryName": "Central African Republic",
        "isoCountryCode": "CF"
    },
    {
        "contendID": 210258,
        "contryName": "Cyprus",
        "isoCountryCode": "CY"
    }
]

export function getCountryFromName(name: string) {
    return countriesSafetyInfo?.find(c => c.contryName.toLowerCase() === name.toLowerCase())
}