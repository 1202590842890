import { DocumentsActionTypes, DocumentState, SET_DOCUMENTS_CONTENT } from "./type";

const initialState: DocumentState = {
    documentContent: {
        terms_and_conditions: { data: {} },
        provider_contract: { data: {} },
        package_travel_policy: { data: {} },
        insurance_insolvancy_certificate: { data: {} },
        privacy_policy: { data: {} },
        hospitality_release: { data: {} },
        self_declaration: { data: {} },
        consent_promo_emails: { data: {} }
    }
};

export function documentReducer(
    state = initialState,
    action: DocumentsActionTypes
): DocumentState {
    switch (action.type) {
        case SET_DOCUMENTS_CONTENT:
            return {
                ...state,
                documentContent: action.payload
            };
        default:
            return state;
    }
}