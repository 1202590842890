import { combineReducers } from "redux";
import { articleReducer } from "./Articles";
import { sideBarReducer } from "./SideBar";
import { projectsReducer } from './Projects';
import { faqsReducer } from './FAQs';
import { authReducer } from './Auth';
import { cartReducer } from './Cart';
import { contentReducer } from './Content';
import { searchReducer } from './search';
import { tourReducer } from './Tours'
import { requestsReducer } from './requests'
import { draftsReducer } from './Drafts'
import { aboutReducer } from './About'
import { homeReducer } from './Home'
import { footerReducer } from './Footer'
import { documentReducer } from './Documents'
import { navReducer } from './NavBar'
import { languageReducer } from './Language'


export const rootReducer = combineReducers({
    sideBar: sideBarReducer,
    articles: articleReducer,
    projects: projectsReducer,
    faqs: faqsReducer,
    authReducer: authReducer,
    cart: cartReducer,
    content: contentReducer,
    searchReducer: searchReducer,
    tourReducer: tourReducer,
    requests: requestsReducer,
    drafts: draftsReducer,
    about: aboutReducer,
    home: homeReducer,
    footer: footerReducer,
    documents: documentReducer,
    navBar: navReducer,
    language: languageReducer,
})

export type RootState = ReturnType<typeof rootReducer>