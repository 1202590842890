export const REPEAT = [
    { id: "1", name: "DAILY" },
    { id: "2", name: "WEEKLY" },
    { id: "3", name: "MONTHLY" },
    // { id: "4", name: "YEARLY" },
];
export const DAYS_IN_WEEK = [
    { id: 0, name: "SUN" },
    { id: 1, name: "MON" },
    { id: 2, name: "TUE" },
    { id: 3, name: "WED" },
    { id: 4, name: "THU" },
    { id: 5, name: "FRI" },
    { id: 6, name: "SAT" },
];

export const COUNTS = [
    { id: 1, name: "First" },
    { id: 2, name: "Second" },
    { id: 3, name: "Third" },
    { id: 4, name: "Fourth" },
    { id: 5, name: "Fifth" },
];

export const MONTHS = [
    { id: 1, name: "January" },
    { id: 2, name: "Feburary" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
];
