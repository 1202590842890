import dayjs from "dayjs";
import { sortBy } from "lodash";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../Components/Spinner";
import useInterval from "../hooks/useInterval";
import { RootState } from "../store";
import { Article, fetchPopularArticles } from "../store/Articles";
import { fetchHomeContent } from "../store/Home";
import { fetchUpcomingProjects, Project } from "../store/Projects";
import { setCurrentArticle, setCurrentEvent } from "../store/SideBar";
import { fetchUpcomingTours, Tours } from "../store/Tours";
import { getEventType, getLatestBookingDate } from "../util";
import calculateEventPrice from "../utilities/calcProjectPrice";
import { getCloudinaryLink } from "../utilities/helpers";
import HomeVideo, { useFetchHomeVideo } from "./home/HomeVideo";
import UpcomingProjectsAndTours from "./home/UpcomingProjectsAndTours";
import ProjectsAndToursFilter from "./home/ProjectsAndToursFilter";
import { useMediaQuery } from "react-responsive";
import SWTButton from "../Components/SWTButton";

export const Index: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const homeContent = useSelector(
    (rootState: RootState) => rootState.home.homeContent
  );
  const isMobileView = useMediaQuery({ maxWidth: 991 });

  const appLanguage = useSelector(
    (rootState: RootState) => rootState.language?.language?.language
  );

  const [mapSrc, setMapSrc] = useState();

  const { coverVideo, fetchVideo } = useFetchHomeVideo();

  const [firstTime, setFirstTime] = useState<boolean>(false);

  const fetchContent = async () => {
    if (!firstTime) {
      setFirstTime(true);

      enableLoading();
      const language = appLanguage ? appLanguage : "en";

      await fetchVideo();

      dispatch(fetchHomeContent(language));
      dispatch(fetchPopularArticles(language));
      dispatch(fetchUpcomingProjects());
      dispatch(fetchUpcomingTours());
      setMapSrc(homeContent?.Hero?.first_hero_img?.data?.attributes.url);
    }
  };

  useEffect(() => {
    fetchContent();
  }, [appLanguage]);

  const popularArticles = useSelector(
    (state: RootState) => state.articles.PopularArticles
  );
  const newsImages = useSelector(
    (state: RootState) => state.articles.articlesImages
  );
  const upcomingProjects = useSelector(
    (state: RootState) => state.projects.UpcomingProjects
  );
  const upcomingTours = useSelector(
    (state: RootState) => state.tourReducer.upcomingTours
  );

  const genralLan = useSelector(
    (rootState: RootState) => rootState.language.language?.content?.general!
  );
  const openArticleSideNav = (article: Article) =>
    dispatch(setCurrentArticle(article));

  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };

  const titleAlign = isMobileView ? "text-left" : "text-right";
  const col1Align = isMobileView ? "order-1 mb-4" : "";
  const col2Align = isMobileView ? "order-2" : "";

  useInterval(() => {
    disableLoading();
  }, 2000);

  const events = useMemo(() => {
    return sortBy(
      [...upcomingProjects, ...upcomingTours],
      (e) => e.startDate
    ).map((event) => {
      const eventType = getEventType(event);
      const commonProps = {
        id: event.id,
        startDate: event.startDate,
        location: event.location,
        price: event.price,
        pricePerDay: event.pricePerDay,
        consumerPricePerDay: event.consumerPricePerDay,
        consumerPrice: event.consumerPrice,
        eventType,
        airportCost: event.airportCost,
        onsideNav: () => dispatch(setCurrentEvent(event)),
      };
      const startDate = dayjs(getLatestBookingDate(event.startDate)).toDate();
      const endDate = dayjs(getLatestBookingDate(event.startDate))
        .add(1, "d")
        .toDate();
      if (eventType === "project") {
        const basePrice = calculateEventPrice(
          "project",
          1,
          event.category,
          startDate,
          endDate,
          event,
          0
        );
        const { projectName } = event as Project;
        return {
          name: projectName,
          duration: 0,
          includedEventsDates: null,
          pictures: (event as Project).projectPictures,
          basePrice: basePrice.price,
          ...commonProps,
        };
      } else {
        const basePrice = calculateEventPrice(
          "tour",
          1,
          event.category,
          startDate,
          endDate,
          event,
          0
        );
        return {
          name: (event as Tours).tourName,
          duration: (event as Tours).duration,
          includedEventsDates: (event as Tours).includedEventsDates,
          pictures: (event as Tours).tourPictures,
          basePrice: basePrice.price,
          ...commonProps,
        };
      }
    });
  }, [upcomingTours, upcomingProjects]);

  return (
    <>
      <HomeVideo coverVideo={coverVideo} />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "20vh" }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          <div id="main_container">
            <ProjectsAndToursFilter homeContent={homeContent} />
            <UpcomingProjectsAndTours
              title={homeContent?.Hero?.upcoming_proj_and_tours?.main_heading}
              events={events}
              homepage
            />

            <section id="ourprojects-home" className="seccomvhmax">
              <div className="container-fluid">
                <div className="row no-gutter h100vh">
                  <div className="d-none d-md-flex col-md-4 col-lg-6 paddlr0 contcomh">
                    <div className="img-16/9 container_img">
                      <img
                        src={getCloudinaryLink(
                          homeContent?.Hero?.project_section?.image?.data
                            ?.attributes?.url
                        )}
                        className="img-fluid imgcom leftimgbor"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-6">
                    <div className="login d-flex align-items-center ">
                      <div className="container">
                        <div className="row ">
                          <div className="col-lg-12">
                            <div className="swt-headmain com-font">
                              {homeContent?.Hero?.project_section?.main_heading}
                            </div>
                            <div className="ourhead com-font text-red fw700">
                              {homeContent?.Hero?.project_section?.sub_heading}
                            </div>
                            <div className="row textimgprojpaddud com-font">
                              <div
                                className={`proj-detail-text col-lg-6 text-justify ${col2Align}`}
                              >
                                <div
                                  className="text-muted"
                                  dangerouslySetInnerHTML={{
                                    __html: `${homeContent?.Hero?.project_section?.content?.substring(
                                      0,
                                      650
                                    )}...`,
                                  }}
                                ></div>

                                {/* <SWTButton
                                  center={isMobileView ? true : false}
                                  marginsVertical
                                  to="/about-us#projects"
                                >
                                  {genralLan?.read_more}
                                </SWTButton> */}
                              </div>
                              <div
                                className={`col-lg-6 imgprojtour ${col1Align}`}
                              >
                                {homeContent?.Hero?.project_section?.Images?.data?.map(
                                  (item: any) => (
                                    <div className="image-listitem">
                                      <img
                                        src={item.attributes?.url}
                                        className="img-fluid listimg"
                                        alt=""
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="tours-home" className="seccomvhmax">
              <div className="container-fluid">
                <div className="row no-gutter h100vh">
                  <div className="col-md-6 col-lg-6">
                    <div className="login d-flex align-items-center ">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12">
                            <div
                              className={`swt-headmain com-font ${titleAlign}`}
                            >
                              {homeContent?.Hero?.tour_section?.main_heading}
                            </div>
                            <div
                              className={`ourhead com-font text-red fw700 ${titleAlign}`}
                            >
                              {homeContent?.Hero?.tour_section?.sub_heading}
                            </div>
                            <div className="row textimgprojpaddud com-font">
                              <div className="col-lg-6 imgprojtour">
                                {homeContent?.Hero?.tour_section?.Images?.data?.map(
                                  (item: any) => (
                                    <div className="image-listitem">
                                      <img
                                        src={item.attributes?.url}
                                        className="img-fluid listimg"
                                        alt=""
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                              <div className="proj-detail-text col-lg-6 text-justify">
                                <div
                                  className="text-muted"
                                  dangerouslySetInnerHTML={{
                                    __html: `${homeContent?.Hero?.tour_section?.content?.substring(
                                      0,
                                      650
                                    )}...`,
                                  }}
                                ></div>
                                {/* <SWTButton
                                  center={isMobileView ? true : false}
                                  marginsVertical
                                  to="/about-us#tours"
                                >
                                  {genralLan?.read_more}
                                </SWTButton> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-none d-md-flex col-md-6 col-lg-6 paddlr0">
                    <div className="img-16/9 container_img">
                      <img
                        src={getCloudinaryLink(
                          homeContent?.Hero?.tour_section?.image?.data
                            ?.attributes?.url
                        )}
                        className="img-fluid imgcom rightimgbor"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="vision-home" className="seccomvhmax">
              <div className="container-fluid">
                <div className="row no-gutter h100vh">
                  <div className="d-none d-md-flex col-md-4 col-lg-6 paddlr0 contcomh">
                    <div className="img-16/9 container_img">
                      <img
                        src={getCloudinaryLink(
                          homeContent?.Hero?.vision_section?.image?.data
                            ?.attributes?.url
                        )}
                        className="img-fluid imgcom leftimgbor"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-6">
                    <div className="login d-flex align-items-center ">
                      <div className="container">
                        <div className="row ">
                          <div className="col-lg-12">
                            <div className="swt-headmain com-font">
                              {homeContent?.Hero?.vision_section?.main_heading}
                            </div>
                            <div className="ourhead com-font text-red fw700">
                              {homeContent?.Hero?.vision_section?.sub_heading}
                            </div>
                            <div className="row textimgprojpaddud com-font">
                              <div
                                className={`proj-detail-text col-lg-6 text-justify ${col2Align}`}
                              >
                                <div
                                  className="text-muted"
                                  dangerouslySetInnerHTML={{
                                    __html: `${homeContent?.Hero?.vision_section?.content?.substring(
                                      0,
                                      650
                                    )}...`,
                                  }}
                                ></div>

                                {/* <SWTButton
                                  center={isMobileView ? true : false}
                                  marginsVertical
                                  to="/about-us#vision"
                                >
                                  {genralLan?.read_more}
                                </SWTButton> */}
                              </div>
                              <div
                                className={`col-lg-6 imgprojtour ${col1Align}`}
                              >
                                {homeContent?.Hero?.vision_section?.Images?.data?.map(
                                  (item: any) => (
                                    <div className="image-listitem">
                                      <img
                                        src={item.attributes?.url}
                                        className="img-fluid listimg"
                                        alt=""
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="latestnews-home" className="seccomvhmax">
              <div className="container-fluid">
                <div className="row no-gutter h100vh">
                  <div className="col-md-8 col-lg-6">
                    <div className="login d-flex align-items-center">
                      <div className="container">
                        <div className="row ">
                          <div className="col-lg-12 com-font">
                            <div
                              className={`ourhead com-font text-red fw700 ${titleAlign}`}
                            >
                              {genralLan?.latest_news}
                            </div>
                          </div>
                          <PerfectScrollbar className="col-lg-12 com-font maxscroll">
                            <ul className="timeline">
                              {popularArticles.map(
                                (article: any) =>
                                  article.published === true && (
                                    <li>
                                      <a
                                        rel="noopener noreferrer"
                                        href="#"
                                        className="newstitle"
                                      >
                                        {article.title}
                                      </a>
                                      <a
                                        rel="noopener noreferrer"
                                        href="#"
                                        className="float-right datecol"
                                      >
                                        {dayjs(article.date).format(
                                          "D MMMM, YYYY"
                                        )}
                                      </a>
                                      <p>{article.text}</p>

                                      <SWTButton
                                        center={isMobileView ? true : false}
                                        onClick={() =>
                                          openArticleSideNav(article)
                                        }
                                      >
                                        {genralLan?.read_more}
                                      </SWTButton>
                                    </li>
                                  )
                              )}
                            </ul>
                          </PerfectScrollbar>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-none d-md-flex col-md-6 col-lg-6 paddlr0">
                    {newsImages?.map((image) => (
                      <img
                        src={image?.attributes?.url}
                        className="img-fluid imgcom rightimgbor"
                        alt=""
                      />
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};
