import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { getOnboardLink } from "../store/Auth";

const StripConnectNotification: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [onBoardLink, setOnBoardLink] = useState("");

  const getOnboardinAccountLink = async () => {
    try {
      const res: any = await dispatch(getOnboardLink());
      setOnBoardLink(res.data.onboardLink);
    } catch (error) {
      console.log("getOnboard Link api failed", error);
    }
  };
  const genralLan = useSelector(
    (rootState: RootState) => rootState.language.language?.content?.general!
  );
  useEffect(() => {
    getOnboardinAccountLink();
  }, []);

  return (
    <div className="stripe-alert">
      <span>
        {genralLan?.get_connected_swt}
      </span>
      <a target={'_blank'} rel="noreferrer" href={onBoardLink}>{genralLan?.click_here}</a>
    </div>
  );
};

export default StripConnectNotification;
