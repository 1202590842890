import React from 'react';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';


const OutputComponent = ({ dateRange, isRepeat, repeat, dateToExclude, initialData }) => {
    const showDateToExclude = isEmpty(dateToExclude) && initialData?.includedEventsDates?.length ? false : true;
    const Language = useSelector(
        (rootState) => rootState.language.language?.content
    );
    return (
        <>
            <Table bordered variant="dark" className="mt-5">
                <thead>
                    <tr>
                        <th>{Language?.general?.start}{Language?.general?.date} {Language?.general?.of} {Language?.general?.interval}</th>
                        <th>{Language?.general?.end}{Language?.general?.date} {Language?.general?.of} {Language?.general?.interval}</th>
                        <th>{Language?.general?.repeat}</th>
                        <th>{Language?.general?.repeat_on}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {
                            dateRange.map((date, index) => (
                                <td key={index} >
                                    <p>{(dayjs(date).format('dddd DD.MM.YYYY'))}</p>
                                </td>
                            ))
                        }
                        <td>{isRepeat.toString()}</td>
                        <td>{repeat}</td>
                    </tr>
                </tbody>
            </Table>
            <Table bordered variant="dark" className="mt-5">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{Language?.general?.start}{Language?.general?.date}</th>
                        <th>{Language?.general?.end}{Language?.general?.date}</th>
                    </tr>
                </thead>
                {showDateToExclude && <tbody>
                    {
                        !isEmpty(dateToExclude) && dateToExclude.map(({ start, end }, index) => (
                            <tr key={index}>
                                <td>
                                    <p>{index + 1}</p>
                                </td>
                                <td>
                                    <p>{(dayjs(start).format('dddd DD.MM.YYYY'))}</p>
                                </td>
                                <td>
                                    <p>{(dayjs(end).format('dddd DD.MM.YYYY'))}</p>
                                </td>
                            </tr>
                        ))
                    }

                </tbody>}
                {!showDateToExclude && <tbody>
                    {
                        initialData.includedEventsDates.map(({ start, end }, index) => (
                            <tr key={index}>
                                <td>
                                    <p>{index}</p>
                                </td>
                                <td>
                                    <p> {(dayjs(start).toDate()).toString()}</p>
                                </td>
                                <td>
                                    <p>    {(dayjs(end).toDate()).toString()}</p>
                                </td>
                            </tr>
                        ))
                    }

                </tbody>}
            </Table>
        </>
    )

}

export default OutputComponent