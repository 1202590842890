import dayjs from "dayjs"

export function getReviewArray(point: number | undefined | null) {
    if (!point) return []
    const checkedArray: boolean[] = []
    for (let i = 1; i <= 5; i++) {
        checkedArray.push(i <= point)
    }
    return checkedArray
}

export const getEventType = (cartItem: any) => {
    return cartItem.projectName ? "project" : "tour"
}

export const filterEmptyStringsProps = (obj: Object) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
}

export function emptyStringToNull(value: string, originalValue: string) {
    if (typeof originalValue === 'string' && originalValue === '') {
        return null;
    }
    return value;
}
export function getLatestBookingDate(value: Date | string) {
    return dayjs(dayjs(value).startOf('D')
        .add(0, "d")
        .toDate() > dayjs().add(0, 'D').toDate() ? dayjs(value).startOf('D')
            .add(0, "d")
            .toDate() : dayjs(value).startOf('D')
                .add(dayjs().diff(dayjs(value).startOf('D'), 'day') + 1, 'd')
                .toDate())
}

export const getLocaleforDatePicker = () => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];

    return {
        localize: {
            day: (n: any) => days[n],
            month: (n: any) => months[n]
        },
        formatLong: {},
        options: {
            weekStartsOn: 1
        }
    }
}