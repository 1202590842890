interface DocumentType {
    data: any
}

export interface Document {
    terms_and_conditions: DocumentType,
    provider_contract: DocumentType
    privacy_policy: DocumentType,
    package_travel_policy: DocumentType
    insurance_insolvancy_certificate: DocumentType
    hospitality_release: DocumentType
    self_declaration: DocumentType
    consent_promo_emails: DocumentType
}

export interface DocumentState {
    documentContent: Document;
}

export const SET_DOCUMENTS_CONTENT = "SET_DOCUMENTS_CONTENT";

export interface SetDocumentAction {
    type: typeof SET_DOCUMENTS_CONTENT;
    payload: Document;
}

export type DocumentsActionTypes = SetDocumentAction;
