import { AboutActionTypes, AboutState, SET_ABOUT_CONTENT } from "./type";

const initialState: AboutState = {
  aboutContent: {
    _id: "",
    Bio_Section: [],
    About_Section: [],
    Projects_Section: [],
    Tours_Section: [],
    Vision_Section: [],
  }
};

export function aboutReducer(
  state = initialState,
  action: AboutActionTypes
): AboutState {
  switch (action.type) {
    case SET_ABOUT_CONTENT:
      return {
        ...state,
        aboutContent: action.payload
      };
    default:
      return state;
  }
}
