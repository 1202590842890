import { SearchActionTypes, SearchState, SET_EVENTTYPE, SET_LOCATION, SET_STARTDATE, SET_ENDDATE, SET_QUALIFICATIONS, SET_CATEGORY, SET_EVENTS, SET_FILTER } from "./type"

const initialState: SearchState = {
    filter: {
        eventType: 'all'
    },
    events: []
}

export function searchReducer(state = initialState, action: SearchActionTypes): SearchState {
    switch (action.type) {
        case SET_EVENTTYPE:
            return {
                ...state,
                filter: { ...state.filter, eventType: action.payload }
            }
        case SET_LOCATION:
            return {
                ...state,
                filter: { ...state.filter, location: action.payload }
            }
        case SET_STARTDATE:
            return {
                ...state,
                filter: { ...state.filter, startDate: action.payload }
            }
        case SET_ENDDATE:
            return {
                ...state,
                filter: { ...state.filter, endDate: action.payload }
            }
        case SET_QUALIFICATIONS:
            return {
                ...state,
                filter: { ...state.filter, qualifications: action.payload }
            }
        case SET_CATEGORY:
            return {
                ...state,
                filter: { ...state.filter, category: action.payload }
            }
        case SET_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case SET_EVENTS:
            return {
                ...state,
                events: action.payload
            }
        default:
            return state
    }
}