import dayjs from "dayjs";
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { getReviewArray } from "../../util";
import { getCloudinaryLink } from "../../utilities/helpers";
import { useMediaQuery } from "react-responsive";

interface NewsSideBarProps {
  show: boolean;
  onClose: () => void;
}
export const NewsSideBar: FunctionComponent<NewsSideBarProps> = ({
  show,
  onClose,
}) => {
  const isMobileView = useMediaQuery({ maxWidth: 991 });

  const width = isMobileView ? '100%' : '50%';

  const style = { width: show ? width : "0px" };
  const article = useSelector(
    (state: RootState) => state.sideBar.currentArticle
  );
  const starArray = getReviewArray(2);
  if (!article) return <></>;
  const { title="", text="", image=[], date="", published="" } = article;
  // const images = image.data ? [...image.data].slice(0, 1) : [];
  const images = image.data ? [...image.data] : [];




  if(!show && isMobileView) return <></>;

  return (
    <div className="sidenav com-font" style={style}>
      <a
        href="javascript:void(0)"
        className="closebtn closex"
        onClick={onClose}
      >
        ×
      </a>
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-9">
            <div className="text-white newsovertxt">{article.title}</div>
            <div className="newsdatetxt">
              Aktualisiert: <span>{dayjs(article.date).format("D MMMM")}</span>
            </div>
          </div>
          <div className="col-lg-3 text-center">
            <img
              src="/images/heart-white-ic.svg"
              className="img-fluid heartpos-o"
              alt=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="newsdetailtxt">{article.text}</div>
            {/* <div className="newquestxtitem ">
              <div className="newsques">
                Some text goes here to ask a question related to Small World
                Social Travel
              </div>
              <div className="newsans">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </div>
            </div> */}
          </div>
          <div className="col-lg-6">
            <div className="newquestxtitem ">
              <div className="newsans">
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum. */}
                <div className="newsoverimg">
                  {images.map((img) => (
                    <div className="image-listitem">
                      <img
                        src={getCloudinaryLink(img.attributes.url)}
                        alt={img.attributes.name}
                        className="img-fluid listimg"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="custstar float-right d-none">
              <div className="star-ratingnews text-right">
                <span className="fa fa-star checked" />
                <span className="fa fa-star checked" />
                <span className="fa fa-star checked" />
                <span className="fa fa-star" />
                <span className="fa fa-star" />
              </div>
              <div className="revlink">
                <a href="#" className="btn-link customerrevlink">
                  View Customer Reviews
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
