import dayjs from "dayjs";
import { FunctionComponent, useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../store";
import { fetchAllTours, requestForPublishUnpublish, requestToConfirmTourInterval, Tours } from "../../../store/Tours";
import { requestForTourRemoval } from "../../../store/requests";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import './tourTable.css';
import { Collapse, Modal } from "react-bootstrap";
import Switch from "../../../Components/switch";
import axiosInstance from "../../../utilities/axiosInstance";
import Swal from "sweetalert2";

const ToursList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [idToDelete, setidToDelete] = useState<undefined | string>(undefined);
  const { allTours } = useSelector(
    (rootState: RootState) => rootState.tourReducer
  );
  const [showReservations, setShowReservations] = useState<boolean>(false);
  const [projReservations, setProjReservations] = useState<undefined | any>(undefined);
  const [showTravelers, setShowTravelers] = useState<boolean>(false);
  const [travelersInfo, setTravellersInfo] = useState<undefined | any>(undefined);
  const [discountModal, setDiscountModal] = useState<boolean>(false);
  const [discountValue, setDiscountValue] = useState<number>(0);
  const [voucher, setVoucher] = useState<string>('');
  const [tour, setTour] = useState<Tours | any>();
  useEffect(() => {
    dispatch(fetchAllTours());
  }, []);

  const confirmDelete = async () => {
    try {
      const tempId = idToDelete
      setidToDelete(undefined);
      await dispatch(requestForTourRemoval(tempId!));
      toast.success("The request to cancel your event was successful");
    } catch (error: any) {
      toast.error(error.response.data.message);
      setidToDelete(undefined);
    } finally {
      dispatch(fetchAllTours());
    }
  };

  const _HandlePublishUnPublish = async (available: boolean, tourId: string) => {
    try {
      const res: any = await dispatch(requestForPublishUnpublish(tourId, available));
      toast.success(res.message)
    }
    catch (e: any) {
      toast.error(e.response.data.message);
    }
    finally {
      dispatch(fetchAllTours());
    }
  }

  const _HandleConfirmEvent = async ({ tourId, startDate, endDate }: any) => {
    try {
      const res: any = await dispatch(requestToConfirmTourInterval(tourId, startDate, endDate));
      toast.success(res.message)
    }
    catch (e: any) {
      toast.error(e.response.data.message);
    }
    finally {
      dispatch(fetchAllTours());
    }
  }
  const fetchReservations = async (id: string) => {
    return await axiosInstance.get(`/api/tours/reservations/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    })
  }
  const handleShowReservations = async (id: string) => {
    const res = await fetchReservations(id);
    setProjReservations(res.data.reservations)
    setShowReservations(true)
  }
  const handleShowTravellers = (travelersInfo: any) => {
    setTravellersInfo(travelersInfo)
    setShowTravelers(!showTravelers)
  }
  const hideModal = () => {
    setShowReservations(false)
    setTravellersInfo(undefined)
    setShowTravelers(!showTravelers)

  }
  const handleDiscountModal = (tour: Tours) => {
    setTour(tour)
    setDiscountModal(true)
  }
  const handleDiscountChange = useCallback((val: string) => { setDiscountValue(Number(val)) }, [])
  const handleVoucherChange = useCallback((val: string) => { setVoucher(val.toLocaleUpperCase()) }, [])
  const handleDiscountSubmit = async () => {
    const proj = await axiosInstance.post('/api/voucher/add-voucher', {
      voucher: voucher,
      discount: discountValue,
      id: tour?.id
    }, {

      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    })
    toast.success("Discount Code Added Successfully!");
    hideModal()
  }
  const handleDeleteEvent = (event: any) => {
    setidToDelete(event.id)
    setTour(event)
  }
  useMemo(async () => {
    if (idToDelete && tour) {
      if (tour && tour.bookings > 0) {
        return Swal.fire({
          title: "The project/tour cannot be deleted because there are still outstanding reservations. But you can unpublish your project/tour so that it is no longer visible to customers on the platform and submit your deletion request again after the last booking has been completed.",
          showConfirmButton: true,
        }).then(() => {
          setidToDelete(undefined)
        })
      }
      return Swal.fire({
        title: 'Are you sure you want to cancel this event? Thank you very much for the trustful cooperation. We are looking forward to organize more events together in future. Anything we can help you with, please contact us at support@socialtravel.world.',
        showConfirmButton: true,
        showCancelButton: true,

      }).then((result) => {
        if (result.isConfirmed) {
          confirmDelete()
        } else {
          setidToDelete(undefined)
        }
      })
    }
  }, [idToDelete, tour])
  const genralLan = useSelector(
    (rootState: RootState) => rootState?.language?.language?.content!
  );
  return (
    <>
      <div className="col-lg-9 col-12">
        <div className="section-wrap paddresup sa-myreservations">
          <section className="sa-content com-font">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <h4 className="fw700 text-red text-capitalize">

                      {genralLan?.general?.my_tours}
                    </h4>
                  </div>
                  <div className="col-lg-6">
                    <Link
                      to="/profile/tours/add"
                      type="button"
                      className="btn btnviewproj text-capitalize float-right"
                    >
                      {genralLan?.general?.add} {' '}
                      {genralLan?.general?.tour}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-wrapper">
                <BootstrapTable
                  keyField="id"
                  wrapperClasses="table-responsive projectlisttabdiv"
                  bordered={false}
                  classes="projectlisttab borderless text-center border-collapse"
                  headerWrapperClasses="table-header"
                  data={allTours.map((p, i) => ({
                    ...p,
                    index: i + 1,
                    sort: true,
                  }))}
                  columns={[
                    { text: "#", dataField: "index", sort: true },
                    {
                      text: genralLan?.general?.tour + ' ' + genralLan?.general?.name,
                      dataField: "tourName",
                      sort: true,
                      formatter: (Name, tour) => (
                        <a
                          href={`/events/tours/${tour.id}`}
                        >
                          {Name}
                        </a>
                      ),
                    },
                    { 
                      text: genralLan?.general?.cost, 
                      dataField: "price", 
                      formatter: (_, row) => {
                        let price = 0;
                        const airportCost = row.airportCost ? row.airportCost : 0;
                        price = row.price + airportCost;
                        return (
                          <>
                            <p>{price}</p>
                          </>
                        );
                      },
                    },
                    {
                      text: genralLan?.general?.start + ' ' + genralLan?.general?.date,
                      dataField: "intervalStart",
                      formatter: (intervalStart) =>
                        dayjs(intervalStart).format("DD/MM/YYYY"),
                    },
                    { text: genralLan?.general?.status, dataField: "status" },
                    {
                      text: genralLan?.general?.minimum + ' ' + genralLan?.general?.capacity,
                      dataField: "minCapacity",
                    },
                    {
                      text: genralLan?.general?.bookings,
                      dataField: "bookings",
                      formatter: (_, row) => {
                        return (
                          <>
                            <p>{row.bookings}/{row.capacity}</p>
                          </>
                        );
                      },
                    },
                    {
                      text: genralLan?.general?.get + ' ' + genralLan?.general?.reservations, dataField: "available",
                      formatter: (_cellContent, row) =>
                        <button
                          type="button"
                          className="btn btnviewproj text-capitalize ml-1"
                          onClick={() => handleShowReservations(row.id)}>
                          {genralLan?.general?.get + ' ' + genralLan?.general?.reservations}
                        </button>
                    },
                    {
                      text: genralLan?.general?.confirm,
                      dataField: "confirm",
                      formatter: (_, row) => {
                        return (
                          <Switch
                            checked={row.confirm}
                            _OnClick={() => _HandleConfirmEvent({ tourId: row.id, startDate: row.intervalStart, endDate: row.intervalEnd })}
                          />
                        )
                      }
                    },
                    {
                      text: genralLan?.general?.publish,
                      dataField: "available",
                      isDummyField: true,
                      formatter: (_cellContent, row) => {
                        return (
                          <>
                            {row.status === "approved" && (
                              <input type="checkbox" onClick={() => _HandlePublishUnPublish(row.available, row.id)} checked={row.available} />
                            )} </>
                        );
                      },
                    },
                    {
                      text: genralLan?.general?.feedback,
                      dataField: "feedback",
                      isDummyField: true,
                      formatter: (_cellContent, row) => (
                        <Link to={`/profile/feedback/tour/${row.id}`}>
                          {genralLan?.general?.view}{' '}{genralLan?.general?.feedback}
                        </Link>
                      )
                    },
                    // {
                    //   dataField: "df2",
                    //   isDummyField: true,
                    //   text: "Actions",
                    //   formatter: (_cellContent, row) => {
                    //     return (
                    //       <button type="button"
                    //         className="btn btnviewproj text-capitalize float-right"
                    //         onClick={() => handleDiscountModal(row)}>
                    //         {genralLan?.general?.discount} %
                    //       </button>
                    //     );
                    //   },
                    // },

                    {
                      dataField: "df1",
                      isDummyField: true,
                      text: "Actions",
                      formatter: (_cellContent, row) => {
                        return (
                          <>
                            <Link
                              to={`/profile/tours/${row.id}`}
                              className="btn btn-sm btn-link btnscart"
                            >
                              <img
                                src="/images/edit.svg"
                                className="img-fluid carticon"
                                alt=""
                              />
                            </Link>
                            <span
                              onClick={() => handleDeleteEvent(row)}
                              className="btn btn-sm btn-link btnscart "
                            >
                              <img
                                src="/images/delete_icon.svg"
                                className="img-fluid carticon"
                                alt=""
                              />
                            </span>
                          </>
                        );
                      },
                    },


                  ]}
                // pagination={paginationFactory({
                //   showTotal: true,
                //   sizePerPage: 5,
                //   // page: 2,
                //   // pageStartIndex: 1,
                //   hidePageListOnlyOnePage: true,
                //   sizePerPageRenderer: () => <></>,
                // })}
                />

              </div>
            </div>
          </section>
          <Modal show={discountModal} onHide={() => setDiscountModal(false)}>
            <Modal.Header closeButton  >
              {tour?.tourName}
            </Modal.Header>
            <Modal.Body>
              <div >
                <label
                  className="mb-0 mt-3 sa-label small text-capitalize">
                  {genralLan?.general?.discount} {genralLan?.general?.code}
                </label>
                <input
                  className="form-control resforminp mt-0"
                  name="voucher"
                  id="voucher"
                  value={voucher}
                  onChange={(e) => handleVoucherChange(e.target.value)}
                  placeholder={genralLan?.general?.discount_place}
                />
                <input type="number" min='0' max="100" step="1"
                  className="form-control resforminp mt-0"
                  name="discountPercent"
                  id="discountPercent"
                  value={discountValue}
                  onChange={(e) => handleDiscountChange(e.target.value)}
                  placeholder="Discount in percentage"
                />
                <button type="button"
                  className="btn btnviewproj text-capitalize float-right"
                  onClick={() => handleDiscountSubmit()}>
                  {genralLan?.general?.add_discount}
                </button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={showReservations} onHide={() => setShowReservations(false)}>
            <Modal.Header closeButton />
            <Modal.Body >
              <BootstrapTable
                keyField="id"
                wrapperClasses="table-responsive projectlisttabdiv"
                bordered={false}
                classes="projectlisttab  borderless text-center"
                headerWrapperClasses="table-header"
                data={projReservations?.map((p: any, i: number) => ({
                  ...p,
                  index: i + 1,
                }))}
                columns={[
                  { text: "#", dataField: "index", sort: true },
                  {
                    text: genralLan?.general?.booking + ' ' + genralLan?.general?.date

                    , dataField: "createdAt", sort: true,
                    formatter: (date) => dayjs(date).format("DD/MM/YYYY"),
                  },
                  {
                    text: genralLan?.general?.total, dataField: "total", sort: true,
                    // formatter: (date) => dayjs(date).format("DD/MM/YYYY"),
                  },
                  {
                    text: genralLan?.general?.get + ' ' + genralLan?.general?.invoice, dataField: "total", sort: true,
                    formatter: (date) => 'Get Invoice',
                  },
                  {
                    text: genralLan?.general?.paid, dataField: "paid", sort: true,
                    formatter: (paid) => paid ? 'Yes' : 'No',
                  },
                  {
                    text: genralLan?.general?.travellers, dataField: "travelersInfo", sort: true,
                    formatter: (travelersInfo) => <button
                      type="button"
                      className="btn btnviewproj text-capitalize ml-1"
                      onClick={() => handleShowTravellers(travelersInfo)}
                    >
                      {showTravelers ? genralLan?.general?.hide : genralLan?.general?.show} {genralLan?.general?.travellers}
                    </button>,
                  },
                ]}
              />
              <Collapse in={showTravelers} timeout={100}>
                <div className="table-responsive  projectlisttabdiv  mb-2">
                  <table className="table projectlisttab  borderless text-center">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{genralLan?.general?.name}</th>
                        <th scope="col">{genralLan?.general?.phone}</th>
                        <th scope="col">{genralLan?.general?.email}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        travelersInfo?.map((traveler: any, i: number) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              {traveler.gender} {traveler.firstName} {traveler.lastName}
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </Collapse>
            </Modal.Body>
          </Modal>

        </div>
      </div>
    </>
  );
};

export default ToursList;
