import { yupResolver } from '@hookform/resolvers/yup';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from "yup";
import { RootState } from '../../../store';

const CompanyInfoSchema = yup.object().shape({
    companyName: yup.string().required(),
    companyNumber: yup.string().required(),
    companyInfo: yup.string().required(),
    webAddress: yup.string().url().required(),
});

export interface ICompanyInfo {
    companyName: string,
    companyNumber: string,
    webAddress: string,
    companyInfo: string
}

interface CompanyInfoSectionProps {
    initialData?: ICompanyInfo,
    userDetails?: any,
    onSubmit: (data: ICompanyInfo) => void
}
const CompanyInfoSection: FunctionComponent<CompanyInfoSectionProps> = ({ initialData, onSubmit, userDetails }) => {
    const { register, handleSubmit, errors } = useForm<ICompanyInfo>({
        resolver: yupResolver(CompanyInfoSchema),
        defaultValues: initialData
    })

    const _onSubmit = (data: ICompanyInfo) => {
        onSubmit(data)
    }
    const genralLan = useSelector(
        (rootState: RootState) => rootState.language.language?.content!
    );
    return (
        <>
            <section className="form-wrap">
                <h4 className="com-font fw700 text-red text-capitalize">{genralLan?.general?.company_info}</h4>
                <form className="respadding com-font" onSubmit={handleSubmit(_onSubmit)}>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-lg-6">
                                <input type="text" className="form-control resforminp" name="companyName" ref={register} placeholder={genralLan?.company?.company_name} />
                                {errors.companyName && (<div className="invalid-feedback d-block">{genralLan?.company?.company_name_err}</div>)}
                            </div>
                            <div className="col-lg-6">
                                <input type="text" className="form-control resforminp" name="companyNumber" ref={register} placeholder={genralLan?.company?.company_num_place} />
                                {errors.companyNumber && (<div className="invalid-feedback d-block">{genralLan?.company?.company_num_err}</div>)}
                            </div>
                            {/* <div className="col-lg-12">
                                <input type="text" className="form-control resforminp" name="companyAddress" ref={register} placeholder="Company Address" />
                                {errors.companyAddress && (<div className="invalid-feedback d-block">Company Address is Required</div>)}
                            </div> */}
                            <div className="col-lg-6">
                                <input type="text" className="form-control resforminp" name="webAddress" ref={register} placeholder={genralLan?.company?.website_place} />
                                {errors.webAddress && (<div className="invalid-feedback d-block">{genralLan?.company?.website_err}</div>)}
                            </div>
                            <div className="col-lg-6">
                                <input type="text" className="form-control resforminp" name="companyInfo" ref={register} placeholder={genralLan?.company?.purpose} />
                                {errors.companyInfo && (<div className="invalid-feedback d-block">{genralLan?.company?.purpose_err}</div>)}
                            </div>
                            <div className="col-lg-12">
                                <button type="submit" data-toggle="modal" data-target="#companyInfoModal" className="btn btn-default
                btnviewprojnext float-right text-capitalize"
                                    disabled={!userDetails?.companyName ? true : false}
                                >
                                    {genralLan?.general?.update_info}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </section>

        </>

    )
}

export default CompanyInfoSection