import React, { FunctionComponent } from 'react';

export const Spinner: FunctionComponent = () => {
    return (
      <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
    )
}
