import { Project } from "../Projects";
import { Tours } from "../Tours";

export interface RequestsState {
  forApprovalProjects: Project[];
  forApprovalTours: Tours[];
  forApprovalProviders: any[];

  forRemovalProjects: Project[];
  forRemovalTours: Tours[];
}
export const SET_FOR_APPROVAL_PROJECTS = "SET_FOR_APPROVAL_PROJECTS";
export const SET_FOR_APPROVAL_TOURS = "SET_FOR_APPROVAL_TOURS";
export const SET_FOR_APPROVAL_PROVIDERS = "SET_FOR_APPROVAL_PROVIDERS";
export const SET_FOR_REMOVAL_PROJECTS = "SET_FOR_REMOVAL_PROJECTS";
export const SET_FOR_REMOVAL_TOURS = "SET_FOR_REMOVAL_TOURS";

export interface SetForApprovalProjects {
  type: typeof SET_FOR_APPROVAL_PROJECTS;
  payload: Project[];
}

export interface SetForApprovalTours {
  type: typeof SET_FOR_APPROVAL_TOURS;
  payload: Tours[];
}

export interface SetForApprovalProviders {
  type: typeof SET_FOR_APPROVAL_PROVIDERS;
  payload: any[];
}

export interface SetForRemovalProjects {
  type: typeof SET_FOR_REMOVAL_PROJECTS;
  payload: Project[];
}

export interface SetForRemovalTours {
  type: typeof SET_FOR_REMOVAL_TOURS;
  payload: Tours[];
}

export type RequestActionTypes =
  | SetForApprovalProjects
  | SetForApprovalTours
  | SetForApprovalProviders
  | SetForRemovalProjects
  | SetForRemovalTours;
