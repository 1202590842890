import axiosInstance from "../../utilities/axiosInstance";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import { Content, ContentActionTypes, SET_CONTENT } from "./type";

export const setContent = (contents: Content[]): ContentActionTypes => {
    return {
        type: SET_CONTENT,
        payload: contents
    }
}

export const fetchContents = (ln: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {

    const res = await axiosInstance.get(`/api/content?populate=*&locale=${ln}`)
    console.log("content data", res.data)
    dispatch(setContent(res.data.content))
}


interface Icontent {
    pageTitle: string;
    content: string;
    bio: string;
    mission: string;
    about: string;
    projects: string;
    tours: string;
    vision: string;
}
export const updateContents = (contents: Icontent[]): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    const payload = {
        content: contents
    }
    const res = await axiosInstance.post('/api/content', payload)
    dispatch(fetchContents('en'))
    return res
}