import dayjs from "dayjs";

export const getDifferenceInDays = (date1, date2) => {
    const diffInMs = Math.abs(date2 - date1);
    return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
}

export const getFormatedDate = (date) => {
    return new Date(date).getDate();
}

export const getDayCount = (date) => {
    const dayCount = dayjs(date).day()
    return dayCount;

}

export const getOccurcnceOfDayInWeekOfMonth = (i, date) => {
    var d = new Date(date),
        month = d.getMonth(),
        days = [];

    d.setDate(1);

    while (d.getDay() !== i) {
        d.setDate(d.getDate() + 1);
    }
    while (d.getMonth() === month) {
        days.push(new Date(d.getTime()));
        d.setDate(d.getDate() + 7);
    }

    return days;
}

export const getIndexOfDayInWeek = (startDate) => {
    const days = getOccurcnceOfDayInWeekOfMonth(getDayCount(startDate), startDate);
    let weekOfMonth = 0;
    for (let [index, item] of days.entries()) {
        if (item.getDate() === startDate.getDate()) {
            weekOfMonth = index + 1;
            break;
        }
    }
    return weekOfMonth;
}

export const getSpecificDayOfWeek = (startDate, index) => {
    const days = getOccurcnceOfDayInWeekOfMonth(getDayCount(startDate), startDate);
    return days[index - 1];
}

export const getNewStartDateAndDay = ({ prevEndDate, eventStartDay }) => {
    let tempNextWeekStart = dayjs(prevEndDate).add(1, 'w');
    let startOfNextWeek = tempNextWeekStart.startOf('week').toDate()
    let nextStartDate = dayjs(startOfNextWeek).add(eventStartDay, 'd').toDate()
    const nextStartDay = dayjs(nextStartDate).day();
    return { nextStartDate, nextStartDay };

}

const a = [
    { 'sun': 0 },
    { 'mon': 1 },
    { 'tue': 2 },
    { 'wed': 3 },
    { 'thu': 4 },
    { 'fri': 5 },
    { 'sat': 6 },
]

export const getDifferenceInDaysUsingCount = (today, nextDay) => {
    let days = 0;
    for (let i = today; ; i++) {
        if (i === a.length) {
            i = 0;
        }
        if (i === nextDay) {
            days = days + 1;
            break;
        }
        days = days + 1;
    }
    return days - 1;
}