import { NavActionTypes, NavState, SET_NAV_CONTENT, } from "./type";

const initialState: NavState = {
    navContent: {
        route1: '',
        route2: '',
        route3: '',
        route4: '',
        route5: '',
        route6: '',

    }
};

export function navReducer(
    state = initialState,
    action: NavActionTypes
): NavState {
    switch (action.type) {
        case SET_NAV_CONTENT:
            return {
                ...state,
                navContent: action.payload
            };
        default:
            return state;
    }
}