import React, { FunctionComponent, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

import * as yup from "yup";
import { fetchLoggedinUserDetails, sendOTP } from "../../store/Auth";
import axiosInstance from "../../utilities/axiosInstance";
import { toast } from "react-toastify";
import { setCurrentSideBar, SideBarEnum } from "../../store/SideBar";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMediaQuery } from "react-responsive";
interface OtpSideBarProps {
    show: boolean;
    onClose: () => void;

}
interface ForgotPassword {
    otp: string;
    newPassword: string;
    confirmPassword: string;
}
const ForgotPasswordSchema = yup.object().shape({
    newPassword: yup.string().required(),
    confirmPassword: yup.string().required(),
    otp: yup.string().required(),

})
export const ForgotPasswordSideBar: FunctionComponent<OtpSideBarProps> = ({
    show,
    onClose,

}) => {
    const isMobileView = useMediaQuery({ maxWidth: 700 });
    const style = { width: show ? isMobileView ? "100%" : "300px" : "0px" };
    const dispatch = useDispatch();
    const [email, setEmail] = useState<string>("");
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [otpSent, setOtpSent] = useState<boolean>(false);
    const { register, handleSubmit, errors, getValues } = useForm<ForgotPassword>(
        {
            resolver: yupResolver(ForgotPasswordSchema),
        }
    );
    const onVerify = async (data: ForgotPassword) => {
        if (data.newPassword !== data.confirmPassword) {
            return setPasswordError(true)
        }
        try {
            const res = await axiosInstance.post(
                `/api/user/forgot`,
                { email: email, ...data }
            );
            toast.success(res.data.message);
            setOtpSent(false);
            setEmail('');
            dispatch(setCurrentSideBar(SideBarEnum.Login));
            onClose();
        } catch (e: any) {
            console.log("Otp verify api failed: ", e);
            toast.error(e.response.data.message);
        }
    };
    const sendOtp = async () => {
        if (!email)
            return;
        try {
            const res = await axiosInstance.post(
                `/api/user/forgot`,
                { email: email }
            );
            if (res.data?.otpSent) {
                toast.success(res.data.message);
                setOtpSent(true)
            }
        } catch (e: any) {
            toast.error(e.response.data.message);
            console.log(e.response.data.message);
        }
    }
    return (
        <div className="sidenav com-font" style={style}>
            <a
                href="javascript:void(0)"
                className="closebtn closex"
                onClick={onClose}
            >
                ×
            </a>
            <div className="col-lg-12">

                {!otpSent ?

                    <div className="form-group">
                        <label className="col">
                            Forgot Password?
                        </label>
                        <input type="email" className="form-control" onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email address" />
                        <div className="paddsearchfilup text-center">
                            <button onClick={() => sendOtp()}
                                className="btn btn-sm btn-default combtnclick btn-block"
                                type="submit"
                            >
                                Submit
                            </button></div>
                    </div>

                    :
                    <form onSubmit={handleSubmit(onVerify)}>
                        <div className="small signintxt">Reset Password</div>
                        <div className="small signintxt">
                            Time to check your email. To continue, please enter the one-time
                            password that we sent to  email address.
                        </div>
                        <div className="paddsearchfilup">
                            <div className="input-group paddsearchfilup">
                                <input
                                    type="text"
                                    placeholder="OTP"
                                    className="form-control"
                                    name="otp"
                                    ref={register}
                                />

                                {errors.otp &&
                                    <div className="invalid-feedback d-block">
                                        Please enter Otp
                                    </div>
                                }
                            </div>
                            <div className="input-group paddsearchfilup">
                                <input
                                    type="password"
                                    placeholder="New Password"
                                    className="form-control"
                                    name="newPassword"
                                    ref={register}
                                // onInput={(e) => setPassword((e.target as HTMLInputElement).value)}
                                />
                                {errors.newPassword &&
                                    <div className="invalid-feedback d-block">
                                        Please Enter a new password
                                    </div>
                                }
                            </div>
                            <div className="input-group paddsearchfilup">
                                <input
                                    type="password"
                                    placeholder="Confirm Password"
                                    className="form-control"
                                    name="confirmPassword"
                                    ref={register}
                                // onInput={(e) => setConfirmPassword((e.target as HTMLInputElement).value)}
                                />
                                {errors.confirmPassword &&
                                    <div className="invalid-feedback d-block">
                                        Please confirm password
                                    </div>
                                }
                                {!errors.confirmPassword && passwordError &&
                                    <div className="invalid-feedback d-block">
                                        Passwords Does not match
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="paddsearchfilup text-center">
                            <button
                                className="btn btn-sm btn-default combtnclick btn-block"
                                type="submit"
                            >
                                Reset
                            </button>
                        </div>
                    </form>
                }

            </div>
        </div>
    );
};
