import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { COUNTS, DAYS_IN_WEEK, MONTHS } from './consts'

const YearlyStack = () => {
    return (
        <Form.Group as={Col} className="mb-3">
            <Row>
                <Col className=" d-flex flex-row justify-content-between align-items-center mt-3 ">
                    <Form.Check
                        type="radio"
                        label="Every"
                        value="Occurence"
                        name="formHorizontalRadios"
                        id="endRepeatOn"
                    />
                    <select>
                        {
                            MONTHS.map(({ id, name }) => (
                                <option key={id} value={name}>{name}</option>
                            ))
                        }
                    </select>
                    <input
                        id="endRepeatOnOccurenceCount"
                        type="number"
                        min={3}
                        name="EndRepeatOn"
                    />
                </Col>
            </Row>
            <Row>
                <Col className=" d-flex flex-row justify-content-between align-items-center mt-3 ">
                    <Form.Check
                        type="radio"
                        label="The"
                        value="Occurence"
                        name="formHorizontalRadios"
                        id="endRepeatOn"
                    />
                    <select>
                        {
                            COUNTS.map(({ id, name }) => (
                                <option key={id} value={name}>{name}</option>
                            ))
                        }
                    </select>
                    <select>
                        {
                            DAYS_IN_WEEK.map(({ id, name }) => (
                                <option key={id} value={name}>{name}</option>
                            ))
                        }
                    </select>

                </Col>
            </Row>
        </Form.Group>
    )
}

export default YearlyStack