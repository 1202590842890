import { HashLink } from "react-router-hash-link";

type SWTButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  children?: any;
  icon?: any;
  center?: boolean;
  marginsVertical?: boolean;
  to?: string;
};
const SWTButton = ({
  onClick,
  disabled = false,
  icon,
  children,
  center = false,
  marginsVertical,
  to,
}: SWTButtonProps) => {
  const LeftIcon = icon;

  const btnSwtMy = marginsVertical ? "my-4 mx-0" : "";
  const iconButton = icon ? "btn-swt-icon" : "";

  return (
    <CenterSWTElement center={center}>
      <button
        className={`btn-swt ${btnSwtMy} ${iconButton}`}
        disabled={disabled}
        onClick={onClick}
      >
        <HashLinkWrapper to={to}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {icon && <LeftIcon />}
            {children && children}
          </div>
        </HashLinkWrapper>
      </button>
    </CenterSWTElement>
  );
};

const HashLinkWrapper = ({ to, children }: any) => {
  if (!to) return children;

  return (
    <>
      <HashLink
        scroll={(el: any) =>
          el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          })
        }
        to={to}
        style={{
          textDecoration: "none",
          color: "#fff",
        }}
      >
        {children}
      </HashLink>
    </>
  );
};

const CenterSWTElement = ({ children, center }: any) => {
  if (!center) return children;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {children}
    </div>
  );
};

export default SWTButton;
