import React, { useEffect, FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from '../Components/Spinner'
import { RootState } from '../store'
import { fetchAboutUsContent } from '../store/About'
import { isEmpty } from 'lodash';
import { getCloudinaryLink } from '../utilities/helpers'
import { useMediaQuery } from "react-responsive";

export const About: FunctionComponent = ({ }) => {
    const dispatch = useDispatch()
    // const contents = useSelector((rootState: RootState) => rootState.content.content?.[0].content)
    const aboutContent = useSelector((rootState: RootState) => rootState.about.aboutContent)
    const appLanguage = useSelector((rootState: RootState) => rootState.language.language?.language)

    const [loading, setLoading] = useState(true);
    const isMobileView = useMediaQuery({ maxWidth: 991 });
    const order1Mobile = isMobileView ? "order-1" : "";
    const order2Mobile = isMobileView ? "order-2" : "";

    useEffect(() => {
        enableLoading();
        if (!appLanguage)
            return;
        dispatch(fetchAboutUsContent(appLanguage))
    }, [appLanguage])

    useEffect(() => {
        if (!isEmpty(aboutContent?.Bio_Section))
            disableLoading()
    }, [aboutContent])

    const enableLoading = () => {
        setLoading(true);
    };
    const disableLoading = () => {
        setLoading(false);
    };

    if (!aboutContent) return null
    return (
        <>
            {loading ?
                <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                    <Spinner />
                </div>
                :
                <div className="container-fluid">
                    <div className="row no-gutter aligncomrowpadd martopabout">
                        <div className="d-md-flex col-md-4 col-lg-6 paddlr0 about-us-photo-mobile">
                            <img src={(getCloudinaryLink(aboutContent?.Bio_Section[0]?.image?.data?.attributes?.url))}
                                className="img-fluid imgcom leftimgbor bgaboutagn border-radius-12" alt="bg-pic" />
                        </div>
                        <div className="col-md-8 col-lg-6">
                            <div className="login d-flex align-items-center ">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="swt-headmain com-font">{aboutContent?.Bio_Section[0]?.heading}</div>
                                            <div className="ourhead com-font text-red fw700">{aboutContent?.Bio_Section[0]?.subheading}</div>
                                            <div className="row textimgprojpaddud com-font">
                                                <div className="proj-detail-text col-lg-12 text-justify">
                                                    <div className="text-mutted" dangerouslySetInnerHTML={{ __html: aboutContent?.Bio_Section[0]?.content }}>
                                                        {/* <div className="text-mutted" dangerouslySetInnerHTML={{ __html: contents?.[2].bio }}> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutter aligncomrowpadd">
                        <div className={`col-md-8 col-lg-6 ${order2Mobile}`}>
                            <div className="login d-flex align-items-center ">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="swt-headmain com-font">{aboutContent?.About_Section[0]?.heading}</div>
                                            <div className="ourhead com-font text-red fw700">{aboutContent?.About_Section[0]?.subheading}</div>
                                            <div className="row textimgprojpaddud com-font">
                                                <div className="proj-detail-text col-lg-12 text-justify">
                                                    <div className="text-mutted" dangerouslySetInnerHTML={{ __html: aboutContent?.About_Section[0]?.content }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`d-md-flex col-md-4 col-lg-6 paddlr0 contcom about-us-photo-mobile ${order1Mobile}`}>
                            <img src={getCloudinaryLink(aboutContent?.About_Section[0]?.image?.data?.attributes?.url)}
                                className="img-fluid imgcom rightimgbor border-radius-12" alt="bg" />
                        </div>
                    </div>
                    <div className="row no-gutter aligncomrowpadd" id="projects">
                        <div className="d-md-flex col-md-4 col-lg-6 paddlr0 about-us-photo-mobile">
                            <img src={`${getCloudinaryLink(aboutContent?.Projects_Section[0]?.image?.data?.attributes?.url)}`}
                                className="img-fluid imgcom leftimgbor bgaboutagn border-radius-12" alt="bg" />
                        </div>
                        <div className="col-md-8 col-lg-6">
                            <div className="login d-flex align-items-center ">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="swt-headmain com-font">{aboutContent?.Projects_Section[0]?.heading}</div>
                                            <div className="ourhead com-font text-red fw700">{aboutContent?.Projects_Section[0]?.subheading}</div>
                                            <div className="row textimgprojpaddud com-font">
                                                <div className="proj-detail-text col-lg-12 text-justify">
                                                    <div className="text-mutted" dangerouslySetInnerHTML={{ __html: aboutContent?.Projects_Section[0]?.content }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutter aligncomrowpadd" id="tours">
                        <div className={`col-md-8 col-lg-6 ${order2Mobile}`}>
                            <div className="login d-flex align-items-center ">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="swt-headmain com-font">{aboutContent?.Tours_Section[0]?.heading}</div>
                                            <div className="ourhead com-font text-red fw700">{aboutContent?.Tours_Section[0]?.subheading}</div>
                                            <div className="row textimgprojpaddud com-font">
                                                <div className="proj-detail-text col-lg-12 text-justify">
                                                    <div className="text-mutted" dangerouslySetInnerHTML={{ __html: aboutContent?.Tours_Section[0]?.content }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`d-md-flex col-md-4 col-lg-6 paddlr0 contcom about-us-photo-mobile ${order1Mobile}`}>
                        <img src={`${getCloudinaryLink(aboutContent?.Tours_Section[0]?.image?.data?.attributes?.url)}`} style={{ width: '100%' }}
                                className="img-fluid imgcom leftimgbor bgaboutagn border-radius-12" alt="bg" />
                        </div>
                    </div>
                    <div className="row no-gutter aligncomrowpadd" id="vision">
                        <div className="d-md-flex col-md-4 col-lg-6 paddlr0 about-us-photo-mobile">
                            <img src={getCloudinaryLink(aboutContent?.Vision_Section[0]?.image?.data?.attributes?.url)}
                                className="img-fluid imgcom leftimgbor bgaboutagn border-radius-12" alt="bg" />
                        </div>
                        <div className="col-md-8 col-lg-6">
                            <div className="login d-flex align-items-center ">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="swt-headmain com-font">{aboutContent?.Vision_Section[0]?.heading}</div>
                                            <div className="ourhead com-font text-red fw700">{aboutContent?.Vision_Section[0]?.subheading}</div>
                                            <div className="row textimgprojpaddud com-font">
                                                <div className="proj-detail-text col-lg-12 text-justify">
                                                    <div className="text-mutted" dangerouslySetInnerHTML={{ __html: aboutContent?.Vision_Section[0]?.content }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}