import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import { SweetAlertRenderProps } from "react-bootstrap-sweetalert/dist/types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { RootState } from "../../../store";
import {
  approveProject,
  approveProvider,
  approveTour,
  fetchAllRequests,
  rejectProject,
  rejectTour,
} from "../../../store/requests";
import { deleteProjectById } from "../../../store/Projects";
import { deleteTourById } from "../../../store/Tours";
import { deleteDraft } from "../../../store/Drafts";
import { setCurrentDayToDayProgram } from "../../../store/SideBar";
import { Link, useHistory } from "react-router-dom";
import ProviderRequestInfo from "./provider-requests/ProviderRequestInfo";
interface IReject {
  type: "Project" | "Tours" | "Provider";
  RejectionType: "Approval" | "Removal";
  Id: string;
}
const Requests: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [rejectData, setRejectData] = useState<IReject | undefined>();
  const [selectedProviderToView, setSelectedProviderToView] = useState<any>();
  const [reason, setReason] = useState("");
  const projectsForApproval = useSelector(
    (rootState: RootState) => rootState.requests.forApprovalProjects
  );
  
  const toursForApproval = useSelector(
    (rootState: RootState) => rootState.requests.forApprovalTours
    );
      
  const providersForApproval = useSelector(
    (rootState: RootState) => rootState.requests.forApprovalProviders
  );

  const projectsForRemoval = useSelector(
    (rootState: RootState) => rootState.requests.forRemovalProjects
  );
  const toursForRemoval = useSelector(
    (rootState: RootState) => rootState.requests.forRemovalTours
  );
  useEffect(() => {
    dispatch(fetchAllRequests());
  }, []);

  const acceptProjectApproval = async (projectId: string) => {
    try {
      await dispatch(approveProject(projectId));
      toast.success("Project is Approved");
    } catch (error:any) {
      toast.error(error.response.data.err.map((e: any) => e.message).join('\n'));
    } finally {
      dispatch(fetchAllRequests());
    }
  };

  const acceptTourApproval = async (tourId: string) => {
    try {
      await dispatch(approveTour(tourId));
      toast.success("Project is Approved");
    } catch (error:any) {
      toast.error(error.response.data.err.map((e: any) => e.message).join('\n'));
    } finally {
      dispatch(fetchAllRequests());
    }
  };

  const deleteProject = async (projectId: string) => {
    try {
      await dispatch(deleteProjectById(projectId));
      toast.success("The request to cancel your event was successful");
    } catch (error:any) {
      toast.error(error.response.data.message);
    } finally {
      dispatch(fetchAllRequests());
    }
  };

  const deleteTour = async (tourID: string) => {
    try {
      await dispatch(deleteTourById(tourID));
      toast.success("The request to cancel your event was successful");
    } catch (error:any) {
      toast.error(error.response.data.msg);
    } finally {
      dispatch(fetchAllRequests());
    }
  };

  const confirmReject = async () => {
    try {
      if (rejectData?.type === "Project") {
        await dispatch(rejectProject(rejectData.Id, reason));
      } else if (rejectData?.type === "Tours") {
        await dispatch(rejectTour(rejectData.Id, reason));
      } else if (rejectData?.type === "Provider") {
        await dispatch(deleteDraft(rejectData.Id));
      }
      toast.success(
        `${rejectData?.type} is ${rejectData?.RejectionType === "Approval" ? "Deleted" : "Rejected"
        } successfully`
      );
    } catch (error:any) {
      toast.error(error.response.data.err.map((e: any) => e.message).join('\n'));
    } finally {
      setRejectData(undefined);
      setReason("");
      dispatch(fetchAllRequests());
    }
  };

  const acceptProviderApproval = async (draft: any) => {
    try {
      await dispatch(approveProvider(draft));
      toast.success("Provider Approved successfully");
    } catch (error:any) {
      toast.error(error.response.data.message);
    } finally {
      dispatch(fetchAllRequests());
    }
  };
  return (
    <>
      {rejectData && (
        <SweetAlert
          title={"Enter Reason for Rejection"}
          onConfirm={confirmReject}
          onCancel={() => setRejectData(undefined)}
          showCancel
          type={"controlled"}
          dependencies={[reason]}
        >
          {(renderProps: SweetAlertRenderProps) => (
            <textarea
              rows={6}
              cols={50}
              value={reason}
              autoFocus={true}
              onInput={(e) => setReason((e.target as HTMLInputElement).value)}
            ></textarea>
          )}
        </SweetAlert>
      )}
      {
        selectedProviderToView && (
          <Modal
            dialogClassName="modal-90w"
            show={selectedProviderToView}
            onHide={() => setSelectedProviderToView(undefined)}
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                Provider Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ProviderRequestInfo draft={selectedProviderToView} />
            </Modal.Body>
          </Modal>
        )

      }
      <div className="col-lg-9 col-12">
        <div className="section-wrap paddresup sa-myreservations">
          <section className="sa-content com-font">
            <Tabs defaultActiveKey="approval" id="uncontrolled-tab-example">
              <Tab eventKey="approval" title="Pending Approval">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mt-3 align-items-center">
                      <div className="col-lg-6">
                        <h5 className="fw700 text-red text-capitalize mb-0">
                          Projects
                        </h5>
                      </div>
                    </div>
                    <BootstrapTable
                      keyField="_id"
                      wrapperClasses="table-responsive projectlisttabdiv"
                      bordered={false}
                      classes="projectlisttab  borderless text-center"
                      data={projectsForApproval.map((p, i) => ({
                        ...p,
                        index: i + 1,
                      }))}
                      columns={[
                        { text: "#", dataField: "index", sort: true },
                        {
                          text: "Name",
                          dataField: "projectName",
                          sort: true,
                          formatter: (Name, project) => (
                            <Button variant="link" onClick={() =>
                              dispatch(setCurrentDayToDayProgram(project))
                            }>
                              {Name}
                            </Button>
                          ),
                        },
                        { text: "Cost", dataField: "price", sort: true },
                        { text: "Capacity", dataField: "capacity", sort: true },
                        {
                          dataField: "df1",
                          isDummyField: true,
                          text: "Actions",
                          formatter: (_cellContent, row) => {
                            return (
                              <div
                                className="btn-group btn-group-sm"
                                role="group"
                                aria-label="Actions"
                              >
                                <button
                                  type="button"
                                  className="btn btn-outline-primary"
                                  onClick={() =>
                                    history.push(`/profile/projects/${row.id}`)
                                  }
                                >
                                  View
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-outline-success"
                                  onClick={() => acceptProjectApproval(row.id)}
                                >
                                  Approve
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-outline-danger"
                                  onClick={() =>
                                    setRejectData({
                                      type: "Project",
                                      RejectionType: "Approval",
                                      Id: row.id,
                                    })
                                  }
                                >
                                  Reject
                                </button>
                              </div>
                            );
                          },
                        },
                      ]}
                      pagination={paginationFactory({
                        showTotal: true,
                        sizePerPage: 5,
                        hidePageListOnlyOnePage: true,
                        sizePerPageRenderer: () => <></>,
                      })}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mt-3 align-items-center">
                      <div className="col-lg-6">
                        <h5 className="fw700 text-red text-capitalize mb-0">
                          Tours
                        </h5>
                      </div>
                    </div>
                    <BootstrapTable
                      keyField="_id"
                      wrapperClasses="table-responsive projectlisttabdiv"
                      bordered={false}
                      classes="projectlisttab  borderless text-center"
                      data={toursForApproval.map((p, i) => ({
                        ...p,
                        index: i + 1,
                      }))}
                      columns={[
                        { text: "#", dataField: "index", sort: true },
                        {
                          text: "Name",
                          dataField: "tourName",
                          sort: true,
                          formatter: (Name, tour) => (
                            <Button variant="link" onClick={() =>
                              dispatch(setCurrentDayToDayProgram(tour))
                            }>
                              {Name}
                            </Button>
                          ),
                        },
                        { text: "Cost", dataField: "price", sort: true },
                        { text: "Capacity", dataField: "capacity", sort: true },
                        {
                          dataField: "df1",
                          isDummyField: true,
                          text: "Actions",
                          formatter: (_cellContent, row) => {
                            return (
                              <div
                                className="btn-group btn-group-sm"
                                role="group"
                                aria-label="Actions"
                              >
                                <button
                                  type="button"
                                  className="btn btn-outline-primary"
                                  onClick={() =>
                                    history.push(`/profile/tours/${row.id}`)
                                  }
                                >
                                  View
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-outline-success"
                                  onClick={() => acceptTourApproval(row.id)}
                                >
                                  Approve
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-outline-danger"
                                  onClick={() =>
                                    setRejectData({
                                      type: "Tours",
                                      RejectionType: "Approval",
                                      Id: row.id,
                                    })
                                  }
                                >
                                  Reject
                                </button>
                              </div>
                            );
                          },
                        },
                      ]}
                      pagination={paginationFactory({
                        showTotal: true,
                        sizePerPage: 5,
                        hidePageListOnlyOnePage: true,
                        sizePerPageRenderer: () => <></>,
                      })}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mt-3 align-items-center">
                      <div className="col-lg-6">
                        <h5 className="fw700 text-red text-capitalize mb-0">
                          Provider
                        </h5>
                      </div>
                    </div>
                    <div>
                      <BootstrapTable
                        keyField="_id"
                        wrapperClasses="table-responsive projectlisttabdiv"
                        bordered={false}
                        classes="projectlisttab  borderless text-center"
                        data={providersForApproval.map((p, i) => ({
                          ...p,
                          index: i + 1,
                        }))}
                        columns={[
                          { text: "#", dataField: "index", sort: true },
                          {
                            text: "Project/Tour Name",
                            dataField: "tourName",
                            formatter: (_Name, row) => (
                              <a href="javascript:void(0)" onClick={() => setSelectedProviderToView(row)}>
                                {row.tourName || "" + row.projectName || ""}
                              </a>
                            ),
                          },
                          {
                            text: "Name",
                            dataField: "projectOwnerName",
                            sort: true,
                          },
                          {
                            text: "Country",
                            dataField: "location",
                            sort: true,
                          },
                          {
                            dataField: "df1",
                            isDummyField: true,
                            text: "Actions",
                            formatter: (_cellContent, provider) => {
                              return (
                                <div
                                  className="btn-group btn-group-sm"
                                  role="group"
                                  aria-label="Actions"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-outline-success"
                                    onClick={() =>
                                      acceptProviderApproval(provider)
                                    }
                                  >
                                    Approve
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger"
                                    onClick={() =>
                                      setRejectData({
                                        type: "Provider",
                                        RejectionType: "Approval",
                                        Id: provider.id,
                                      })
                                    }
                                  >
                                    Reject
                                  </button>
                                </div>
                              );
                            },
                          },
                        ]}
                        pagination={paginationFactory({
                          showTotal: true,
                          sizePerPage: 5,
                          hidePageListOnlyOnePage: true,
                          sizePerPageRenderer: () => <></>,
                        })}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="removal" title="Pending Removal">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mt-3 align-items-center">
                      <div className="col-lg-6">
                        <h5 className="fw700 text-red text-capitalize mb-0">
                          Projects
                        </h5>
                      </div>
                    </div>
                    <BootstrapTable
                      keyField="_id"
                      wrapperClasses="table-responsive projectlisttabdiv"
                      bordered={false}
                      classes="projectlisttab  borderless text-center"
                      data={projectsForRemoval.map((p, i) => ({
                        ...p,
                        index: i + 1,
                        sort: true,
                      }))}
                      columns={[
                        { text: "#", dataField: "index" },
                        {
                          text: "Project Name",
                          dataField: "projectName",
                          formatter: (Name, project) => (
                            <Link to={`/profile/projects/${project.id}`}>
                              {Name}
                            </Link>
                          ),
                        },
                        { text: "Cost", dataField: "price" },
                        { text: "Capacity", dataField: "capacity" },
                        {
                          dataField: "df1",
                          isDummyField: true,
                          text: "Actions",
                          formatter: (_cellContent, row) => {
                            return (
                              <div
                                className="btn-group btn-group-sm"
                                role="group"
                                aria-label="Actions"
                              >
                                <button
                                  type="button"
                                  className="btn btn-outline-success"
                                  onClick={() => deleteProject(row.id)}
                                >
                                  Approve
                                </button>
                                {/* <button
                                  type="button"
                                  className="btn btn-outline-danger"
                                  onClick={() =>
                                    setRejectData({
                                      type: "Project",
                                      RejectionType: "Removal",
                                      Id: row._id,
                                    })
                                  }
                                >
                                  Reject
                                </button> */}
                              </div>
                            );
                          },
                        },
                      ]}
                      pagination={paginationFactory({
                        showTotal: true,
                        sizePerPage: 5,
                        hidePageListOnlyOnePage: true,
                        sizePerPageRenderer: () => <></>,
                      })}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mt-3 align-items-center">
                      <div className="col-lg-6">
                        <h5 className="fw700 text-red text-capitalize mb-0">
                          Tours
                        </h5>
                      </div>
                    </div>
                    <BootstrapTable
                      keyField="_id"
                      wrapperClasses="table-responsive projectlisttabdiv"
                      bordered={false}
                      classes="projectlisttab  borderless text-center"
                      data={toursForRemoval.map((p, i) => ({
                        ...p,
                        index: i + 1,
                      }))}
                      columns={[
                        { text: "#", dataField: "index", sort: true },
                        {
                          text: "Tour Name",
                          dataField: "tourName",
                          sort: true,
                          formatter: (Name, tour) => (
                            <Link to={`/profile/tours/${tour.id}`}>
                              {Name}
                            </Link>
                          ),
                        },
                        { text: "Cost", dataField: "price", sort: true },
                        { text: "Capacity", dataField: "capacity", sort: true },
                        {
                          dataField: "df1",
                          isDummyField: true,
                          text: "Actions",
                          formatter: (_cellContent, row) => {
                            return (
                              <div
                                className="btn-group btn-group-sm"
                                role="group"
                                aria-label="Actions"
                              >
                                <button
                                  type="button"
                                  className="btn btn-outline-success"
                                  onClick={() => deleteTour(row.id)}
                                >
                                  Approve
                                </button>
                                {/* <button
                                  type="button"
                                  className="btn btn-outline-danger"
                                  onClick={() =>
                                    setRejectData({
                                      type: "Tours",
                                      RejectionType: "Removal",
                                      Id: row._id,
                                    })
                                  }
                                >
                                  Reject
                                </button> */}
                              </div>
                            );
                          },
                        },
                      ]}
                      pagination={paginationFactory({
                        showTotal: true,
                        sizePerPage: 5,
                        hidePageListOnlyOnePage: true,
                        sizePerPageRenderer: () => <></>,
                      })}
                    />
                  </div>
                </div>
              </Tab>
            </Tabs>
          </section>
        </div>
      </div>
    </>
  );
};

export default Requests;
