import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axiosInstance from "../../utilities/axiosInstance";
import { Document, DocumentsActionTypes, SET_DOCUMENTS_CONTENT } from "./type";

export const setDocuments = (contents: Document): DocumentsActionTypes => {
    return {
        type: SET_DOCUMENTS_CONTENT,
        payload: contents
    }
}

export const fetchDocumentContent = (appLanguage: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await axiosInstance.get(`/api/document?populate=*&locale=${appLanguage}`);
    dispatch(setDocuments(res.data.data.attributes))
}
