import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axiosInstance from "../../utilities/axiosInstance";
import {
  Event,
  Ifilter,
  SearchActionTypes,
  SET_CATEGORY,
  SET_ENDDATE,
  SET_EVENTS,
  SET_EVENTTYPE,
  SET_FILTER,
  SET_INCLUDEDATE,
  SET_LOCATION,
  SET_QUALIFICATIONS,
  SET_STARTDATE
} from "./type";

export interface SearchEventsParams {
  eventType: string;
  location: string;
  startDate: string;
  endDate: string;
  qualifications: string;
  category: string;
}

export const setEventType = (
  eventType: string | undefined
): SearchActionTypes => {
  return {
    type: SET_EVENTTYPE,
    payload: eventType,
  };
};

export const setLocation = (
  continent: string | undefined
): SearchActionTypes => {
  return {
    type: SET_LOCATION,
    payload: continent,
  };
};

export const setIncludeDate = (
  includeDate: boolean | undefined
): SearchActionTypes => {
  return {
    type: SET_INCLUDEDATE,
    payload: includeDate,
  };
};
export const setStartDate = (
  startDate: string | undefined
): SearchActionTypes => {
  return {
    type: SET_STARTDATE,
    payload: startDate,
  };
};

export const setEndDate = (endDate: string | undefined): SearchActionTypes => {
  return {
    type: SET_ENDDATE,
    payload: endDate,
  };
};

export const SetQualifications = (
  qualifications: string | undefined
): SearchActionTypes => {
  return {
    type: SET_QUALIFICATIONS,
    payload: qualifications,
  };
};

export const setCategory = (
  category: string | undefined
): SearchActionTypes => {
  return {
    type: SET_CATEGORY,
    payload: category,
  };
};

export const setFilter = (filter: Ifilter): SearchActionTypes => {
  return {
    type: SET_FILTER,
    payload: filter,
  };
};

export const setEvents = (events: Event[]): SearchActionTypes => {
  return {
    type: SET_EVENTS,
    payload: events,
  };
};

export const searchEvents =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
    async (dispatch, getState) => {
      const filters = getState().searchReducer.filter;
      const params = {
        ...filters,
        continent: filters.location,
        eventType: filters.eventType?.toLowerCase()
      };
      delete params.location;
      const eventType = filters.eventType?.toLowerCase();
      try {

        if (eventType === 'all') {
          const res1 = await axiosInstance.get(`/api/tours/active`);
          const res2 = await axiosInstance.get(`/api/projects/active`);
          dispatch(setEvents([...res1.data.tours, ...res2.data.projects]));
        } else {
          const res = await axiosInstance.get(`/api/${eventType === 'project' ? 'project' : 'tour'}/search`, {
            params,
          });
          dispatch(setEvents(res.data.events));
        }
      }
      catch (e) {
        console.log('Api failed: ', e);
      }
    }
