import React from 'react';
import './index.css';


const Switch = ({ checked, _OnClick, _OnChange = () => {} }) => {
    return (
        <label 
            className="switch"
            onClick={() => {
                if (checked) return;
                _OnClick();
            }}
        >
            <input type="checkbox" checked={checked} onChange={_OnChange} />
            <span className="slider round"></span>
        </label>
    )
}

export default Switch;