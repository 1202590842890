import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Modal, Tab } from "react-bootstrap";
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { postFeedBack } from "../../store/feedback";
import { fetchProjectById } from "../../store/Projects";
import { fetchTourById } from "../../store/Tours";

export interface Ifeedback {
  travelArr?: string;
  recommendTrip?: string;
  recommendServ?: string;
  callback?: string;
  particularQuick?: string;
  personalQuick?: string;
  easyBook?: string;
  infoQual?: string;
  consultQual?: string;
  reqSpeed?: string;
  questions?: string;
  writtenQual?: string;
  wholeExp?: string;
  particularDetailed?: string;
  personalDetailed?: string;
  organization?: string;
  service?: string;
  atmosphere?: string;
  meals?: string;
  transportation?: string;
  accommodation?: string;
  standard?: string;
  satisfiedAcc?: string;
  closeToNatureQual?: string;
  closeToNatureQuan?: string;
  culturalQual?: string;
  culturalQuan?: string;
  activitiesQual?: string;
  activitiesQuan?: string;
  humanQual?: string;
  humanQuan?: string;
  recoveryQual?: string;
  recoveryQuan?: string;
  particularTravel?: string;
  personalTravel?: string;
  names?: string;
  organizationGuides?: string;
  competence?: string;
  friendliness?: string;
  engagement?: string;
  environment?: string;
  localIssues?: string;
  particularGuides?: string;
  personalGuides?: string;
  tanzania?: boolean;
  ghana?: boolean;
  missingCountry?: string;
  recommendEvent?: string;
  whyRecommend?: string;
  customFeedback?: any[];
}

interface YesOrNoProps {
  question: string
  value?: string
  yesLabel?: string,
  noLabel?: string
  onChange: (val: string) => void
}
const YesOrNo = ({ question, value, onChange, yesLabel, noLabel }: YesOrNoProps) => {
  return (
    <div className="form-group">
      <p className="mb-0">
        {question}
      </p>
      <div className="form-group-wrap sa-radio-type">
        <input type="radio" name={question} id={`${question}-yes`} checked={value === 'yes'} onClick={() => onChange('yes')} />
        <label htmlFor={`${question}-yes`}>{yesLabel || 'yes'}</label>
        <input type="radio" name={question} id={`${question}-no`} checked={value === 'no'} onClick={() => onChange('no')} />
        <label htmlFor={`${question}-no`}>{noLabel || 'no'}</label>
      </div>
    </div>
  )
}

const GoodOrSufficient = ({ question, value, onChange, id }: { id: string, question?: string, value?: string, onChange: (val: string) => void }) => {
  return (
    <div className="form-group">
      {
        question && (<p className="mb-0">{question}</p>)
      }
      <div className="form-group-wrap sa-radio-type">
        <input type="radio" name={question} id={`${id}-good`} checked={value === 'good'} onClick={() => onChange('good')} />
        <label htmlFor={`${id}-good`}>Good</label>
        <input type="radio" name={question} id={`${id}-sufficient`} checked={value === 'sufficient'} onClick={() => onChange('sufficient')} />
        <label htmlFor={`${id}-sufficient`}>Sufficient</label>
      </div>
    </div>
  )
}
const FeedBack: FunctionComponent<{ eventType: 'project' | 'tour' }> = ({ eventType }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [showSuccess, setShowSuccess] = useState(false)
  const [event, setEvent] = useState<any>(null)
  const [selectedTab, setSelectedTab] = useState('quick feedback')

  const [formData, setFormData] = useState<Ifeedback>({})



  const tabs = ['quick feedback', 'platform experience', 'Travel program', 'Local guides', 'Specific Questions']
  const { id } = useParams()

  const fetchEvent = async () => {
    try {
      const event: any = await dispatch(eventType === 'project' ? fetchProjectById(id) : fetchTourById(id))
      setEvent(event)
      formData.customFeedback = event.feedback.map((f: string) => ({ question: f, answer: '' }))
    } catch (error) {
      alert("Invalid Event")
    }
  }

  const onCloseModal = () => {
    history.push("/")
  }

  const postFeedBackClick = async (e: Event) => {
    e.preventDefault()
    try {
      const payload = {
        eventId: event.id,
        eventType: eventType,
        eventName: event?.projectName || event?.tourName,
        ...formData
      }

      const feedback = await dispatch(postFeedBack(payload))
      setShowSuccess(true)
    } catch (error) {
      alert("Somethig went wrong")
    }
  }

  useEffect(() => {
    fetchEvent()
  }, [])

  const setCustomFeedBack = (i: number, val: string) => {
    const copyOfFeedback = { ...formData.customFeedback }
    copyOfFeedback[i].answer = val
  }

  return (
    <>
      {
        showSuccess && (
          <Modal show={showSuccess} onHide={onCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title as="h5">Project Standards</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Thanks very much for your feedback!
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onCloseModal}>OK</Button>
            </Modal.Footer>
          </Modal>
        )

      }
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-wrap paddresup sa-quickfeedback">
              <section className="sa-content com-font">
                <section className="form-wrap">
                  <h2 className="fw700 text-red text-capitalize">We appreciate your feedback for "{event?.projectName || event?.tourName}"</h2>
                  <p>Select from 1 to 10, choose 10 when all your wishes have been fulfilled.</p>

                  <div className="row">
                    <Tab.Container
                      activeKey={selectedTab}
                      onSelect={(key) => setSelectedTab(key!)}
                    >
                      <div className="col-lg-12 col-md-12">
                        <ul className="nav nav-tabs process-model sa-process-model more-icon-process" role="tablist">
                          {
                            tabs.map((tab, i) => (<li role="presentation" className="active" onClick={() => setSelectedTab(tab)}>
                              <a href="javascript:void(0)" role="tab" data-toggle="tab">
                                <span className="sa-tabno">{i + 1}</span>
                                <span className="sa-tabname">{tab}</span>
                              </a>
                            </li>))
                          }
                        </ul>
                      </div>
                      <Tab.Content className="tab-content tabconpadd col-lg-12">
                        {selectedTab === 'quick feedback' &&
                          <Tab.Pane eventKey="quick feedback">
                            <h4 className="com-font fw700 text-red text-capitalize">QUICK FEEDBACK How satisfied are you?</h4>
                            <form className="com-font">
                              <YesOrNo
                                question="Did the travel arrangement match your ideas and wishes?"
                                value={formData.travelArr}
                                onChange={(val) => setFormData({ ...formData, travelArr: val })}
                              />
                              <YesOrNo
                                question="Will you recommend this trip to others?"
                                value={formData.recommendEvent}
                                onChange={(val) => setFormData({ ...formData, recommendEvent: val })}
                              />
                              <YesOrNo
                                question="Will you recommend our service to others?"
                                value={formData.recommendServ}
                                onChange={(val) => setFormData({ ...formData, recommendServ: val })}
                              />
                              <YesOrNo
                                question="Would you like us to call you back?"
                                value={formData.callback}
                                onChange={(val) => setFormData({ ...formData, callback: val })}
                              />
                              <div className="form-group">
                                <label htmlFor="particular" className="mb-0 mt-3 sa-label text-capitalize">what did you like in particular?</label>
                                <textarea name="" id="particular" value={formData.particularQuick} onChange={(e) => setFormData({ ...formData, particularQuick: e.target.value })} cols={30} rows={3} className="form-control" placeholder="what did you do in particular"></textarea>
                              </div>
                              <div className="form-group">
                                <label htmlFor="additions" className="mb-0 mt-3 sa-label text-capitalize">personal additions</label>
                                <textarea name="" id="additions" value={formData.personalQuick} onChange={(e) => setFormData({ ...formData, personalQuick: e.target.value })} cols={30} rows={3} className="form-control" placeholder="personal additions"></textarea>
                              </div>
                            </form>
                            <div className="col-lg-12">
                              <button onClick={() => setSelectedTab('platform experience')} className="btn btn-default btnviewprojback text-capitalize mt-3">
                                next
                              </button>
                            </div>
                          </Tab.Pane>}
                        {selectedTab === 'platform experience' && <Tab.Pane eventKey="platform experience">
                          <div>
                            <h4 className="com-font fw700 text-red text-capitalize">CARE BEFORE TRAVEL How satisfied are you?</h4>
                            <form className="com-font">
                              <div className="form-group">
                                <p className="mb-0">
                                  How easy was it to book?
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.easyBook}
                                    onChange={(val: string) => setFormData({ ...formData, easyBook: val })}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <p className="mb-0">
                                  Information quality
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.infoQual}
                                    onChange={(val: string) => setFormData({ ...formData, infoQual: val })}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <p className="mb-0">
                                  Consulting quality
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.consultQual}
                                    onChange={(val: string) => setFormData({ ...formData, consultQual: val })}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <p className="mb-0">
                                  Speed of processing your request
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.reqSpeed}
                                    onChange={(val: string) => setFormData({ ...formData, reqSpeed: val })}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <p className="mb-0">
                                  Availability for questions
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.questions}
                                    onChange={(val: string) => setFormData({ ...formData, questions: val })}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <p className="mb-0">
                                  Quality of the written documents
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.writtenQual}
                                    onChange={(val: string) => setFormData({ ...formData, writtenQual: val })}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <p className="mb-0">
                                  Whole experience with Small World Travel
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.wholeExp}
                                    onChange={(val: string) => setFormData({ ...formData, wholeExp: val })}
                                  />
                                </div>
                              </div>
                              <YesOrNo
                                question="Are you satisfied with the choice of accommodation?"
                                value={formData.satisfiedAcc}
                                onChange={(val) => setFormData({ ...formData, satisfiedAcc: val })}
                              />

                              <div className="form-group">
                                <label htmlFor="particularTravel" className="mb-0 mt-3 sa-label text-capitalize">what did you like in particular?</label>
                                <textarea name="" id="particularTravel" value={formData.particularTravel} onChange={(e) => setFormData({ ...formData, particularQuick: e.target.value })} cols={30} rows={3} className="form-control" placeholder="what did you do in particular"></textarea>
                              </div>
                              <div className="form-group">
                                <label htmlFor="additionsTravel" className="mb-0 mt-3 sa-label text-capitalize">personal additions</label>
                                <textarea name="" id="additionsTravel" value={formData.personalTravel} onChange={(e) => setFormData({ ...formData, personalQuick: e.target.value })} cols={30} rows={3} className="form-control" placeholder="personal additions"></textarea>
                              </div>
                            </form>
                            <div className="col-lg-12">
                              <button onClick={() => setSelectedTab('quick feedback')} className="btn btn-default btnviewprojback text-capitalize mt-3">
                                back
                              </button>
                              <button onClick={() => setSelectedTab('Travel program')} className="btn btn-default btnviewprojback text-capitalize mt-3">
                                next
                              </button>
                            </div>
                          </div>
                        </Tab.Pane>}

                        {selectedTab === 'Travel program' && <Tab.Pane eventKey="Travel program">
                          <div>
                            <h4 className="com-font fw700 text-red text-capitalize">TRAVEL PROGRAM & COURSE</h4>
                            <form className="com-font">
                              <div className="form-group">
                                <p className="mb-0">
                                  Organization on site
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.organization}
                                    onChange={(val: string) => setFormData({ ...formData, organization: val })}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <p className="mb-0">
                                  Service/friendliness
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.service}
                                    onChange={(val: string) => setFormData({ ...formData, service: val })}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <p className="mb-0">
                                  Atmosphere
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.atmosphere}
                                    onChange={(val: string) => setFormData({ ...formData, atmosphere: val })}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <p className="mb-0">
                                  Meals/catering
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.meals}
                                    onChange={(val: string) => setFormData({ ...formData, meals: val })}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <p className="mb-0">
                                  Transportation
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.transportation}
                                    onChange={(val: string) => setFormData({ ...formData, transportation: val })}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <p className="mb-0">
                                  Accommodation
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.accommodation}
                                    onChange={(val: string) => setFormData({ ...formData, accommodation: val })}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <p className="mb-0">
                                  Standard of chosen category
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.standard}
                                    onChange={(val: string) => setFormData({ ...formData, standard: val })}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <p>Close to nature</p>
                                </div>
                                <div className="col-md-4">
                                  <GoodOrSufficient
                                    id="natureQl"
                                    question="Quality"
                                    value={formData.closeToNatureQual}
                                    onChange={(val: string) => setFormData({ ...formData, closeToNatureQual: val })} />
                                </div>
                                <div className="col-md-4">
                                  <GoodOrSufficient
                                    id="natureQN"
                                    question="Quantity"
                                    value={formData.closeToNatureQuan}
                                    onChange={(val: string) => setFormData({ ...formData, closeToNatureQuan: val })} />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <p>Cultural encounters</p>
                                </div>
                                <div className="col-md-4">
                                  <GoodOrSufficient
                                    id="CulturalQl"
                                    value={formData.culturalQual}
                                    onChange={(val: string) => setFormData({ ...formData, culturalQual: val })} />
                                </div>
                                <div className="col-md-4">
                                  <GoodOrSufficient
                                    id="CulturalQN"
                                    value={formData.culturalQuan}
                                    onChange={(val: string) => setFormData({ ...formData, culturalQuan: val })} />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <p>Activities (cycling, hiking...)</p>
                                </div>
                                <div className="col-md-4">
                                  <GoodOrSufficient
                                    id="ActivitiesQl"
                                    value={formData.activitiesQual}
                                    onChange={(val: string) => setFormData({ ...formData, activitiesQual: val })} />
                                </div>
                                <div className="col-md-4">
                                  <GoodOrSufficient
                                    id="ActivitiesQN"
                                    value={formData.activitiesQual}
                                    onChange={(val: string) => setFormData({ ...formData, activitiesQual: val })} />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <p>Human encounters</p>
                                </div>
                                <div className="col-md-4">
                                  <GoodOrSufficient
                                    id="humanQualQl"
                                    value={formData.humanQual}
                                    onChange={(val: string) => setFormData({ ...formData, humanQual: val })} />
                                </div>
                                <div className="col-md-4">
                                  <GoodOrSufficient
                                    id="humanQualQN"
                                    value={formData.humanQuan}
                                    onChange={(val: string) => setFormData({ ...formData, humanQuan: val })} />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <p>Recovery times</p>
                                </div>
                                <div className="col-md-4">
                                  <GoodOrSufficient
                                    id="recoveryQl"
                                    value={formData.recoveryQual}
                                    onChange={(val: string) => setFormData({ ...formData, recoveryQual: val })} />
                                </div>
                                <div className="col-md-4">
                                  <GoodOrSufficient
                                    id="recoveryQn"
                                    value={formData.recoveryQuan}
                                    onChange={(val: string) => setFormData({ ...formData, recoveryQuan: val })} />
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="particular" className="mb-0 mt-3 sa-label text-capitalize">what did you like in particular?</label>
                                <textarea id="particular" value={formData.particularDetailed} onChange={e => setFormData({ ...formData, particularDetailed: e.target.value })} cols={30} rows={3} className="form-control" placeholder="what did you do in particular" />
                              </div>
                              <div className="form-group">
                                <label htmlFor="additions" className="mb-0 mt-3 sa-label text-capitalize">personal additions</label>
                                <textarea id="additions" value={formData.personalDetailed} onChange={e => setFormData({ ...formData, personalDetailed: e.target.value })} cols={30} rows={3} className="form-control" placeholder="personal additions" />
                              </div>
                            </form>
                            <div className="col-lg-12">
                              <button onClick={() => setSelectedTab('platform experience')} className="btn btn-default btnviewprojback text-capitalize mt-3">
                                back
                              </button>
                              <button onClick={() => setSelectedTab('Local guides')} className="btn btn-default btnviewprojback text-capitalize mt-3">
                                next
                              </button>
                            </div>
                          </div>
                        </Tab.Pane>}

                        {selectedTab === 'Local guides' && <Tab.Pane eventKey="Local guides">
                          <div>
                            <h4 className="com-font fw700 text-red text-capitalize">YOUR LOCAL GUIDES</h4>
                            <form className="com-font">
                              <div className="form-group">
                                <label htmlFor="additionsTravel" className="mb-0 mt-3 sa-label text-capitalize">Names</label>
                                <textarea name="" id="additionsTravel" value={formData.names} onChange={(e) => setFormData({ ...formData, names: e.target.value })} cols={30} rows={3} className="form-control" placeholder="personal additions"></textarea>
                              </div>
                              <div className="form-group">
                                <p className="mb-0">
                                  Organization
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.organizationGuides}
                                    onChange={(val: string) => setFormData({ ...formData, organizationGuides: val })}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <p className="mb-0">
                                  Competence
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.competence}
                                    onChange={(val: string) => setFormData({ ...formData, competence: val })}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <p className="mb-0">
                                  Friendliness
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.friendliness}
                                    onChange={(val: string) => setFormData({ ...formData, friendliness: val })}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <p className="mb-0">
                                  Engagement
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.engagement}
                                    onChange={(val: string) => setFormData({ ...formData, engagement: val })}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <p className="mb-0">
                                  Sensitivity to the environment

                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.environment}
                                    onChange={(val: string) => setFormData({ ...formData, environment: val })}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <p className="mb-0">
                                  Sensitivity to local issues
                                </p>
                                <div className="form-group-wrap sa-radio-type" style={{ width: '50%' }}>
                                  <Slider
                                    min={1}
                                    max={10}
                                    value={formData.localIssues}
                                    onChange={(val: string) => setFormData({ ...formData, localIssues: val })}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="particular" className="mb-0 mt-3 sa-label text-capitalize">what did you like in particular?</label>
                                <textarea id="particular" value={formData.particularGuides} onChange={e => setFormData({ ...formData, particularGuides: e.target.value })} cols={30} rows={3} className="form-control" placeholder="what did you do in particular" />
                              </div>
                              <div className="form-group">
                                <label htmlFor="additions" className="mb-0 mt-3 sa-label text-capitalize">personal additions</label>
                                <textarea id="additions" value={formData.personalGuides} onChange={e => setFormData({ ...formData, personalGuides: e.target.value })} cols={30} rows={3} className="form-control" placeholder="personal additions" />
                              </div>
                            </form>
                            <div className="col-lg-12">
                              <button onClick={() => setSelectedTab('Travel program')} className="btn btn-default btnviewprojback text-capitalize mt-3">
                                back
                              </button>
                              <button onClick={() => setSelectedTab('Specific Questions')} className="btn btn-default btnviewprojback text-capitalize mt-3">
                                next
                              </button>
                            </div>
                          </div>
                        </Tab.Pane>}

                        {selectedTab === 'Specific Questions' && <Tab.Pane eventKey="Specific Questions">
                          <div>
                            <h4 className="com-font fw700 text-red text-capitalize">WHICH DESTINATIONS ARE YOU INTERESTED IN?</h4>
                            <form>
                              <div className="form-group">
                                <div className="form-group-wrap">
                                  <input className="styled-checkbox" id="tanzania" type="checkbox" checked={formData.tanzania} onClick={() => setFormData({ ...formData, tanzania: !formData.tanzania })} />
                                  <label htmlFor="tanzania">tanzania</label>
                                </div>
                                <div className="form-group-wrap">
                                  <input className="styled-checkbox" id="ghana" type="checkbox" defaultValue="ghana" checked={formData.ghana} onClick={() => setFormData({ ...formData, ghana: !formData.ghana })} />
                                  <label htmlFor="ghana">ghana</label>
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="countriesmissing" className="mb-0 mt-3 sa-label text-capitalize">
                                  which countries are you missing in our offer?
                                </label>
                                <textarea value={formData.missingCountry} onChange={(e) => setFormData({ ...formData, missingCountry: e.target.value })} id="countriesmissing" cols={30} rows={3} className="form-control" placeholder="personal additions" defaultValue={""} />
                              </div>
                            </form>
                            <h4 className="com-font fw700 text-red text-capitalize">SPECIFIC QUESTIONS ABOUT YOUR TOUR</h4>
                            <form>
                              <YesOrNo
                                question="Would you recommend your tour to a friend of yours?"
                                value={formData.recommendEvent}
                                onChange={(val) => setFormData({ ...formData, recommendEvent: val })}
                                yesLabel="efinitely yes"
                                noLabel="unfortunately not"
                              />
                              <div className="form-group">
                                <label htmlFor="why" className="mb-0 mt-3 sa-label text-capitalize">What Did You Like In Particular?</label>
                                <textarea value={formData.whyRecommend} onChange={(e) => setFormData({ ...formData, whyRecommend: e.target.value })} id="why" cols={30} rows={3} className="form-control" placeholder="what did you do in particular" defaultValue={""} />
                              </div>
                              {
                                formData?.customFeedback?.map((f, i) => (
                                  <div className="form-group">
                                    <label htmlFor="why" className="mb-0 mt-3 sa-label text-capitalize">{f?.question?.value} ?</label>
                                    <textarea onChange={(e) => setCustomFeedBack(i, e.target.value)} id="why" cols={30}

                                      rows={3} className="form-control" />
                                  </div>
                                ))
                              }
                              <div className="col-lg-12">
                                <button onClick={() => setSelectedTab('Local guides')} className="btn btn-default btnviewprojback text-capitalize mt-3">
                                  back
                                </button>
                                <button onClick={(e: any) => postFeedBackClick(e)} className="btn btn-default btnviewproj text-capitalize mt-3">
                                  send
                                </button>
                              </div>
                            </form>
                          </div>
                        </Tab.Pane>}

                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </section>
              </section>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default FeedBack
