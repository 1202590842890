/* eslint-disable no-restricted-globals */
import dayjs from "dayjs";
import _ from "lodash";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Modal, ProgressBar, Collapse } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";
import { RootState } from "../store";
import { fetchProjectById, Project } from "../store/Projects";
import {
    setCurrentSideBar,
    SideBarEnum,
} from "../store/SideBar";
import { fetchTourById, Tours } from "../store/Tours";
import { getEventType, getLatestBookingDate, getReviewArray } from "../util";
import Standards from "../utilities/Standards";
import { Tooltip } from 'react-tooltip';
import ImageBox from "../Components/ImageBox";
import { useParams, useLocation } from "react-router-dom";
import calculateEventPrice from "../utilities/calcProjectPrice";
// import { Client } from "@googlemaps/google-maps-services-js";
// import "./fullscreenModel.css";
export const EventDetails: FunctionComponent = () => {
    interface collapse {
        accomodation: boolean;
    }
    const dispatch = useDispatch();
    const { id } = useParams();
    const ID = parseInt(id);
    const { pathname } = useLocation();
    const [currentDayToDayProgram, setCurrentDayToDayProgram] = useState<
        Tours | Project | undefined
    >();
    const [collapse, setCollapse] = useState<collapse>({
        accomodation: true,
    });
    const [daytoDayModal, setDaytoDayModal] = useState<boolean>(false);
    const fetchDetails = async () => {
        if (pathname.includes("tours")) {
            const tourDetails = await dispatch(fetchTourById(ID));

            setCurrentDayToDayProgram(tourDetails as any);
        } else {
            const projectDetails = await dispatch(fetchProjectById(ID));

            setCurrentDayToDayProgram(projectDetails as any);
        }
    };
    useEffect(() => {
        fetchDetails()
    }, [])
    const Language = useSelector((state: RootState) => state.language.language?.content)
    const selectedLan = useSelector((state: RootState) => state.language.language?.language)

    const eventDetails = useMemo(() => {
        if (!currentDayToDayProgram) return;
        const { accommodationPicture, transportationPicture, startDate, category } =
            currentDayToDayProgram!;
        const eventType = getEventType(currentDayToDayProgram);
        let allPictures = [...accommodationPicture, ...transportationPicture];
        const eventStartDate = dayjs(getLatestBookingDate(startDate)).toDate();
        const eventEndDate = dayjs(getLatestBookingDate(startDate)).add(1, 'd').toDate();

        if (eventType === "project") {
            const basePrice = calculateEventPrice('project', 1, category, eventStartDate, eventEndDate, currentDayToDayProgram, 0 )
            const project = (currentDayToDayProgram as Project)
            let projectLan = {
                eventDesc: project.projectDescription,
                typicalDay: project.typicalDay,
                ownerDescription: project.ownerDescription,
                foodDescription: project.foodDescription,
                natureOfEventdescription: project.natureOfEventdescription,
                transportation: project.transportation,
                ownerPicture: project.projectOwnerPicture,
                choiceOfFood: [
                    {
                        category: project.choiceOfFood,
                        description: project.foodDescription,
                    },
                    ...project.extraChoiceOfFood,
                ],
            }
            if (selectedLan == 'es') {
                projectLan = {
                    eventDesc: project.projectDescription_es,
                    typicalDay: project.typicalDay_es,
                    ownerDescription: project.ownerDescription_es,
                    foodDescription: project.foodDescription_es,
                    natureOfEventdescription: project.natureOfEventdescription_es,
                    transportation: project.transportation_es,
                    ownerPicture: project.projectOwnerPicture,
                    choiceOfFood: [
                        {
                            category: project.choiceOfFood,
                            description: project.foodDescription_es,
                        },
                        ...project.extraChoiceOfFood,
                    ],
                }
            } else if (selectedLan == 'de') {
                projectLan = {
                    eventDesc: project.projectDescription_de,
                    typicalDay: project.typicalDay_de,
                    ownerDescription: project.ownerDescription_de,
                    foodDescription: project.foodDescription_de,
                    natureOfEventdescription: project.natureOfEventdescription_de,
                    transportation: project.transportation_de,
                    ownerPicture: project.projectOwnerPicture,
                    choiceOfFood: [
                        {
                            category: project.choiceOfFood,
                            description: project.foodDescription_de,
                        },
                        ...project.extraChoiceOfFood,
                    ],
                }
            }
            return {
                eventName: project.projectName,
                allPictures: [...project.projectPictures, ...allPictures],
                dayToDay: [],
                duration: '',
                eventType,
                OwnerName: project.projectOwnerName,
                category: project.category,
                extraAccommodation: project.extraAccommodation,
                capacity: project.capacity,
                location: project.location,
                minCapacity: project.minCapacity,
                noticePeriod: project.noticePeriod,
                nearAirport: project.nearAirport,
                eventRating: project.rating,
                levelOfDifficulty: project.levelOfDifficulty,
                eventCity: project.eventCity,
                additionalCosts: project.additionalPrice,
                startDate: {
                    start: getLatestBookingDate(project.startDate),
                },
                natureOfEvent: project.natureOfEvent,
                spokenLanguages: project.spokenLanguages,
                sdg: project.sdg,
                // choiceOfFood: [
                //     {
                //         category: project.choiceOfFood,
                //         description: selectedLan === 'en' ? project.foodDescription : selectedLan === 'de' ? project.foodDescription_de : project.foodDescription_es,
                //     },
                //     ...project.extraChoiceOfFood,
                // ],
                basePrice: basePrice.price,
                ...projectLan
            };
        } else {
            const basePrice = calculateEventPrice('tour', 1, category, eventStartDate, eventEndDate, currentDayToDayProgram, 0 )
            const tour = (currentDayToDayProgram as Tours)
            let tourLan = {
                eventDesc: tour.tourDescription,
                ownerDescription: tour.ownerDescription,
                foodDescription: tour.foodDescription,
                natureOfEventdescription: tour.natureOfEventdescription,
                transportation: tour.transportation,
                ownerPicture: tour.tourOwnerPicture,
                choiceOfFood: [
                    {
                        category: tour.choiceOfFood,
                        description: tour.foodDescription,
                    },
                    ...tour.extraChoiceOfFood,
                ],
            }
            if (selectedLan == 'es') {
                tourLan = {
                    eventDesc: tour.tourDescription_es,
                    ownerDescription: tour.ownerDescription_es,
                    foodDescription: tour.foodDescription,
                    natureOfEventdescription: tour.natureOfEventdescription_es,
                    transportation: tour.transportation_es,
                    ownerPicture: tour.tourOwnerPicture,
                    choiceOfFood: [
                        {
                            category: tour.choiceOfFood,
                            description: tour.foodDescription,
                        },
                        ...tour.extraChoiceOfFood,
                    ],
                }
            } else if (selectedLan == 'de') {
                tourLan = {
                    eventDesc: tour.tourDescription_de,
                    ownerDescription: tour.ownerDescription_de,
                    foodDescription: tour.foodDescription_de,
                    natureOfEventdescription: tour.natureOfEventdescription_de,
                    transportation: tour.transportation_de,
                    ownerPicture: tour.tourOwnerPicture,
                    choiceOfFood: [
                        {
                            category: tour.choiceOfFood,
                            description: tour.foodDescription,
                        },
                        ...tour.extraChoiceOfFood,
                    ],
                }
            }
            return {
                eventName: tour.tourName,
                allPictures: [...tour.tourPictures, ...allPictures],
                dayToDay: tour.dayToDay,
                typicalDay: [],
                additionalCosts: tour.additionalPrice,
                eventType,
                OwnerName: tour.tourOwnerName,
                category: tour.category,
                extraAccommodation: tour.extraAccommodation,
                capacity: tour.capacity,
                duration: tour.duration,
                location: tour.location,
                eventCity: tour.eventCity,
                minCapacity: tour.minCapacity,
                noticePeriod: tour.noticePeriod,
                nearAirport: tour.nearAirport,
                eventRating: tour.rating,
                levelOfDifficulty: tour.levelOfDifficulty,
                startDate: _.sortBy(tour.includedEventsDates, (d) => d.start).find(
                    (item) => {
                        if (dayjs().isBefore(item.start) && item.capacity)
                            return dayjs(item.start).toDate();
                    }
                ),
                natureOfEvent: tour.natureOfEvent,
                spokenLanguages: tour.spokenLanguages,
                ...tourLan,
                sdg: tour.sdg,
                basePrice: basePrice.price
                // choiceOfFood: [
                //     { category: tour.choiceOfFood, description: selectedLan === 'en' ? tour.foodDescription : selectedLan === 'de' ? tour.foodDescription_de : tour.foodDescription_es },
                //     ...tour.extraChoiceOfFood,
                // ],
            };
        }
    }, [currentDayToDayProgram, selectedLan]);

    if (!currentDayToDayProgram) return <></>;
    const waypoints = () => {
        let str = "";
        if (eventDetails?.dayToDay && eventDetails?.dayToDay.length > 2) {
            let daytoDay = [...eventDetails?.dayToDay];
            daytoDay.shift();
            daytoDay.pop();
            str = `&waypoints=${daytoDay.map((d) => d.city)}|`;
            str = str.slice(0, -1);
        }
        return str;
    };
    const handleViewDayToDay = () => {
        setDaytoDayModal(true);
    };
    const starArray = getReviewArray(eventDetails?.eventRating);
    const handldeCollapseChange = (type: string) => {
        const tmp_collapse = { ...collapse, [type]: !collapse.accomodation };
        setCollapse(tmp_collapse);
    };
    const onSideNavClick = (sidebar: SideBarEnum) =>
        dispatch(setCurrentSideBar(sidebar));
    return (
        // <Modal show={true} className="day-to-day-modal">
        <div id="animatedModalReadMore">
            <div className="col-lg-12">
                <Modal show={daytoDayModal} onHide={() => setDaytoDayModal(false)}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        {eventDetails?.eventType === "tour" && (
                            <div className="col-md-12 mb-2">
                                <a href="#" className="btn-link">
                                    <img src="/images/leaflet_icon.svg" alt="" />
                                    <span className="daymod">Day-to-day program</span>
                                </a>
                                <div className="row">
                                    {(eventDetails?.dayToDay as any[]).map((d, i) => (
                                        <div className="col-lg-12" key={i}>
                                            <div className="daytextheading">Day {i + 1}</div>
                                            <div className="text-justify small">{d?.value}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                </Modal>
                {/* <div
                        id="btn-close-modal"
                        className="close-animatedModalReadMore closespan"
                        onClick={onClose}
                    >
                        <div className="crossmarkx">x</div>
                    </div> */}
                <div className="modal-content">
                    <div className="container">
                        <div className="row">

                            <div className="col-12">
                                <div className="row projsecpaddup event_details_s1">
                                    <div className="col-12">
                                        <ImageBox Images={eventDetails!.allPictures} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row com-font event_details_s2">
                                    <div className="col-md-12 col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="swt-headmain com-font text-uppercase">
                                                    {eventDetails!.eventName}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <HashLink
                                                    scroll={(el: any) =>
                                                        el.scrollIntoView({
                                                            behavior: "smooth",
                                                            block: "center",
                                                        })
                                                    }
                                                    to={`/${eventDetails!.eventType}s/${currentDayToDayProgram.id
                                                        }#details`}
                                                    className="btn btn-sm btnsmbook float-right"
                                                >
                                                    {Language?.general?.book_now}
                                                </HashLink>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-lg-6 ">
                                                        {/* <button class="circleplus circleplus-color btn btn-sm"><i class="fa fa-plus"
                       aria-hidden="true"></i></button> */}
                                                        {/* <img
                                                    src="/images/heart_icon.svg"
                                                    className="img-fluid modheartpadd"
                                                    alt=""
                                                /> */}
                                                        {/* <button class="btn herrticon btn-sm"><i class="far fa-heart"></i></button> */}
                                                        <div className="row projectBooking">
                                                            <div className="col-lg-12 rate">
                                                                <div className="star-ratingproj text-left">
                                                                    {starArray.map((checked) => (
                                                                        <span
                                                                            className={`fa fa-star ${checked ? "checked" : ""
                                                                                }`}
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-lg-12 proj-detail-text com-font">
                                                        Customer reviews
                                                    </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 float-right text-right">
                                                        <img src="/images/suv-car.svg" className="img-fluid" alt="" />
                                                        <span className="datetxt" style={{ fontSize: "1.3em" }}>
                                                            {dayjs(eventDetails?.startDate?.start).format("D")}
                                                        </span>
                                                        <span className="monthtxt">
                                                            {dayjs(eventDetails?.startDate?.start).format("MMMM")}
                                                        </span>
                                                        <span className="posplaneic">
                                                            <p className="mt-2 mb-0 loctxt">
                                                                <img src="/images/blackmap.svg" className="img-fluid" alt="" />{" "}
                                                                {currentDayToDayProgram.location}{" "}
                                                            </p>
                                                            {/* <span className="small daytxt">7 days</span> */}
                                                            <div className="mt-0">
                                                                <div className="font-weight-bold my-2 pricetxt">
                                                                    {Language?.general?.from}{" "}
                                                                    {eventDetails?.eventType === "tour"
                                                                        ? `${eventDetails?.basePrice}€`
                                                                        : `${eventDetails?.basePrice}€/${Language?.general?.nights}`}
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>

                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 justify-content-center">
                                                        <div className="row text-center content_details_card">

                                                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-1">
                                                                <div className="p-1 card_details pricetxt h-100 d-flex flex-column align-items-center justify-content-center">
                                                                    <span className="font-weight-bold d-block proj-detail-text h6 redtext">
                                                                        {Language?.general?.typeof}{" "}{eventDetails?.eventType === 'project' ? Language?.general?.project : Language?.general?.tour}
                                                                    </span>
                                                                    {console.log("natureevents", eventDetails)}
                                                                    <span className="font-weight-bold d-block proj-detail-text">
                                                                        {eventDetails?.natureOfEvent}
                                                                        {/* {eventDetails?.spokenLanguages} */}
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-1">
                                                                <div className="p-1 card_details pricetxt h-100 d-flex flex-column align-items-center justify-content-center">
                                                                    <span className="font-weight-bold d-block proj-detail-text h6 redtext">
                                                                        {Language?.general?.nearest_airport}
                                                                    </span>
                                                                    <span className="font-weight-bold d-block proj-detail-text">
                                                                        <a href="#maps_src_frame">
                                                                            <img src="/images/blackmap.svg" className="img-fluid" alt="" />{" "}
                                                                        </a>
                                                                        {currentDayToDayProgram.nearAirport}{" "}
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-1">
                                                                <div className="p-1 card_details pricetxt h-100 d-flex flex-column align-items-center justify-content-center">
                                                                    <span className="font-weight-bold d-block proj-detail-text h6 redtext">
                                                                        {Language?.general?.spoken_languages}
                                                                    </span>
                                                                    <span className="font-weight-bold d-block proj-detail-text">
                                                                        {eventDetails?.spokenLanguages}
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-1">
                                                                <div className="p-1 card_details pricetxt h-100 d-flex flex-column align-items-center justify-content-center">
                                                                    <span className="font-weight-bold d-block proj-detail-text h6 redtext">
                                                                        {Language?.general?.priority_sdg}
                                                                    </span>
                                                                    <span className="font-weight-bold d-block proj-detail-text">
                                                                        {eventDetails?.sdg?.split('.')[1]}
                                                                    </span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row event_details_s3">
                                    <div className="col-md-6 col-lg-6 form-group">

                                        <label className="h5">
                                            <b>{Language?.general?.about_owner}</b>
                                        </label>
                                        <div className="d-flex mb-3">
                                            <div className="mr-2">
                                                <img alt={eventDetails?.OwnerName} src={eventDetails?.ownerPicture[0]?.imageURL} width={80} height={80} style={{borderRadius: 40}} />
                                                {/* <img alt={eventDetails?.OwnerName} width="150" src={'https://res.cloudinary.com/small-world-travel-gmbh/image/upload/v1677920606/Prod_Pictures/oemnhxzkdf7sss3pdnal.jpg'} /> */}
                                            </div>
                                            <div>
                                            <div className="form-group">
                                                <label className="d-block mb-0 h6">{Language?.general?.name}</label>
                                                <span className="d-block proj-detail-text">{eventDetails?.OwnerName}</span>
                                        </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="d-block mb-0 h6">{eventDetails?.eventType === 'tour' ? Language?.general?.tour : Language?.general?.project} {Language?.general?.owner_desc}
                                            </label>
                                            <span className="d-block proj-detail-text">
                                                {eventDetails?.ownerDescription}
                                            </span>
                                        </div>

                                        <label className="h5">
                                            <b>{eventDetails?.eventType === 'project' ?  Language?.general?.project : Language?.general?.tour} {" "}{Language?.general?.details}</b>
                                        </label>
                                        <div className="row textimgprojpaddud mb-3">
                                            <div
                                                className="text-justify col-lg-12 proj-detail-text"
                                                dangerouslySetInnerHTML={{
                                                    __html: eventDetails!.eventDesc,
                                                }}
                                            ></div>
                                        </div>
                                        {/* <div className="row textimgprojpaddud com-font">


                                </div> */}
                                        {/* <div className="wrapper com-font"> */}
                                        {/* <div className="row textimgprojpaddud com-font"> */}
                                        {/* </div> */}
                                        <div className="form-group">
                                            <label className="d-block mb-0 h6">
                                                {Language?.general?.tour_description}
                                            </label>
                                            <span className="d-block proj-detail-text">
                                                {eventDetails?.natureOfEventdescription}
                                            </span>
                                        </div>
                                        <div className="form-group">
                                            <label className="d-block h6">
                                                {Language?.tour?.additional_cost}
                                            </label>
                                            <span className="d-block proj-detail-text">
                                                {eventDetails?.additionalCosts}
                                            </span>
                                        </div>
                                        <div className="form-group">
                                            <label className="d-block mb-2 h6 pointer" onClick={() => handldeCollapseChange('accomodation')}> {Language?.general?.accomodation_categories}</label>
                                            <Collapse in={collapse.accomodation} timeout={100}>
                                                <div className="mb-1">
                                                    <span className="d-block h6 mb-0">
                                                        {eventDetails?.category?.includes('accommodation') ? eventDetails?.category : eventDetails?.category?.concat(' accommodation')}*
                                                    </span>
                                                    <ul className="list-none paddinclist proj-detail-text mb-2">
                                                        <li>
                                                            {selectedLan === 'en' ? currentDayToDayProgram?.accommodationDescription : selectedLan === 'de' ? currentDayToDayProgram?.accommodationDescription_de : currentDayToDayProgram?.accommodationDescription_es}
                                                        </li>
                                                    </ul>
                                                    {eventDetails?.extraAccommodation.map((acc) => (
                                                        <div className="mb-1">
                                                            <span className=" d-block h6 mb-0">
                                                                {acc?.category?.includes('accommodation') ? acc?.category : acc?.category?.concat(' accommodation')}
                                                            </span>
                                                            <span className="proj-detail-text mb-0">
                                                                {acc.description}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Collapse>
                                        </div>

                                        <div className="form-group">
                                            <label className="d-block mb-0 h6">{Language?.tour?.choice_of_food}</label>
                                            {eventDetails?.choiceOfFood?.map((food) => (
                                                <>
                                                    <span className="d-block proj-detail-text h6 pt-2 mb-0">
                                                        {food.category}
                                                    </span>
                                                    <span className="d-block proj-detail-text">
                                                        {food.description}
                                                    </span>
                                                </>
                                            ))}
                                        </div>
                                        <div className="form-group">
                                            <label className="d-block mb-0 h6">{Language?.tour?.transportation}</label>
                                            <span className="d-block proj-detail-text">
                                                {eventDetails?.transportation}
                                            </span>
                                        </div>
                                        <div className="form-group">
                                            <label className="d-block mb-0 h6">{Language?.general?.closest_airport}</label>
                                            <span className="d-block proj-detail-text">
                                                {eventDetails?.nearAirport}
                                            </span>
                                        </div>
                                        <div className="form-group">
                                            <label className="d-block mb-0 h6">{Language?.general?.spoken_languages}</label>
                                            <span className="d-block proj-detail-text">
                                                {eventDetails?.spokenLanguages}
                                            </span>
                                        </div>
                                        <div className="form-group">
                                            <label className=" d-block mb-0 h6">{Language?.project?.capacity}</label>
                                            <span className="d-block proj-detail-text">
                                                {eventDetails?.capacity}
                                            </span>
                                        </div>
                                        {eventDetails?.minCapacity && (
                                            <div className="form-group">
                                                <label className=" d-block mb-0 h6">
                                                    {Language?.tour?.min_participants}
                                                </label>
                                                <span className="d-block proj-detail-text">
                                                    {eventDetails?.minCapacity}
                                                </span>
                                            </div>
                                        )}
                                        {eventDetails?.noticePeriod && (
                                            <div className="form-group">
                                                <label className=" d-block mb-0 h6">
                                                    {Language?.project?.notice_period}
                                                </label>
                                                <span className="d-block proj-detail-text">
                                                    {eventDetails?.noticePeriod}
                                                </span>
                                            </div>
                                        )}
                                        {/* </div> */}
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                        <label className="d-block h6">
                                            {Language?.general?.risk_level}<span className="text-danger">*</span>
                                        </label>
                                        <ProgressBar>
                                            {eventDetails?.levelOfDifficulty === "easy" && (
                                                <ProgressBar
                                                    style={{ backgroundColor: "#A3A3A3" }}
                                                    now={25}
                                                    label={"Easy"}
                                                />
                                            )}
                                            {eventDetails?.levelOfDifficulty === "moderate" && (
                                                <ProgressBar
                                                    style={{ backgroundColor: "#5AFF15" }}
                                                    now={50}
                                                    label={"Moderate"}
                                                />
                                            )}
                                            {eventDetails?.levelOfDifficulty === "difficult/ risky" && (
                                                <ProgressBar
                                                    style={{ backgroundColor: "#6A66A3" }}
                                                    now={75}
                                                    label={"Difficult"}
                                                />
                                            )}
                                            {eventDetails?.levelOfDifficulty === "extreme" && (
                                                <ProgressBar
                                                    style={{ backgroundColor: "#F52F57" }}
                                                    now={100}
                                                    label={"Extreme"}
                                                />
                                            )}
                                        </ProgressBar>
                                        <p className="text-muted small">
                                            <span className="text-danger ">*</span>{Language?.general?.risk_level_helper}
                                        </p>

                                        <div className="form-group">
                                            <label className=" d-block mb-0 h6">
                                                {Language?.general?.included_standards}
                                            </label>
                                            <ul style={{ paddingLeft: "0px" }} className="list-none proj-detail-text">
                                                {eventDetails?.eventType === "project"
                                                    ? Language?.general?.projectStandards?.map((item: any) =>
                                                        <li>
                                                            <img alt="" src={item?.included ? '/images/checkmark-red.svg' : "/images/crossx.svg"} style={{ height: '13px', width: '15px' }} className="img-fluid" />{" "}
                                                            {item.text}
                                                        </li>
                                                    )
                                                    : Language?.general?.tourStandards?.map((item: any) =>
                                                        <li>
                                                            <img alt="" src={item?.included ? '/images/checkmark-red.svg' : "/images/crossx.svg"} style={{ height: '13px', width: '15px' }} className="img-fluid" />{" "}
                                                            {item.text}
                                                        </li>
                                                    )}
                                            </ul>
                                        </div>

                                        <div className="mapoverlay row" id="maps_src_frame">
                                            {eventDetails?.dayToDay.length ? (
                                                <iframe
                                                    title="map"
                                                    width={600}
                                                    height={300}
                                                    frameBorder={0}
                                                    style={{ border: 0 }}
                                                    aria-hidden="false"
                                                    tabIndex={0}
                                                    src={`https://www.google.com/maps/embed/v1/directions?key=${process.env.REACT_APP_MAPS_API_KEY
                                                        }&origin=${eventDetails?.dayToDay[0].city}&destination=${eventDetails?.dayToDay[eventDetails.dayToDay.length - 1]
                                                            .city
                                                        }${waypoints()}`}
                                                />
                                            ) : (
                                                <iframe
                                                    title="maps"
                                                    src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_MAPS_API_KEY}&q=${eventDetails?.eventCity}`}
                                                    width="100%"
                                                    height={300}
                                                    frameBorder={0}
                                                    style={{ border: 0 }}
                                                    allowFullScreen
                                                    aria-hidden="false"
                                                    tabIndex={0}
                                                />
                                            )}
                                        </div>
                                        <div className="row textimgprojpaddud com-font">
                                            {/* <div className="col-lg-12 proj-detail-text text-justify">
                      <div className="includeprojtxt">Included</div>
                      <ul className="list-none paddinclist">
                        {currentDayToDayProgram.accommodationDescription
                          .split("\n")
                          .map((text) => (
                            <li>{text}</li>
                          ))}
                      </ul>
                       <div class="text-right chat-icon">
                       <i class="far fa-comment-alt"></i>
                     </div>
                    </div> */}
                                            {eventDetails?.eventType === "tour" ? (
                                                <div className="col-lg-12 proj-detail-text text-justify">
                                                    <button
                                                        className="btn btnviewproj"
                                                        type="button"
                                                        onClick={() => onSideNavClick(SideBarEnum.DayToDay)}
                                                    >
                                                        {Language?.general?.view_day_to_day}
                                                    </button>
                                                </div>
                                            ) : (
                                                // (
                                                //     <div className="col-md-12 mb-2">
                                                //         <a href="#" className="btn-link">
                                                //             <img src="/images/leaflet_icon.svg" alt="" />
                                                //             <span className="daymod">Day-to-day program</span>
                                                //         </a>
                                                //         <div className="row">
                                                //             {(eventDetails?.dayToDay as any[]).map((d, i) => (
                                                //                 <div className="col-lg-12" key={i}>
                                                //                     <div className="daytextheading">Day {i + 1}</div>
                                                //                     <div className="text-justify small">
                                                //                         {d?.value}
                                                //                     </div>
                                                //                 </div>
                                                //             ))}
                                                //         </div>
                                                //     </div>
                                                // )
                                                <div className="col-md-12 mb-2">
                                                    <button
                                                        className="btn btnviewproj"
                                                        type="button"
                                                        onClick={() => onSideNavClick(SideBarEnum.DayToDay)}
                                                    >
                                                        {Language?.project?.typical_day}
                                                    </button>
                                                    {/* <a href="#" className="btn-link">
                                                        <img src="/images/leaflet_icon.svg" alt="" />
                                                        <span className="daymod">A Typical Day in a Project</span>
                                                    </a> */}
                                                    {/* <div className="proj-detail-text pt-3 pb-2" >{eventDetails?.typicalDay}</div> */}
                                                </div>
                                            )}
                                        </div>
                                        <div id="travelandsafety" className="form-group mt-4 p-2" style={{ backgroundColor: "#e5eaee", borderRadius: "5px" }}>
                                            <label className="d-block mb-2 h6">
                                                {Language?.general?.travel_and_safety}:
                                            </label>
                                            <a target={"_blank"} style={{color: '#6a66a3'}} rel="noreferrer" href={`/travel-and-safety/${eventDetails?.location!}`}>
                                                {`${location.host}/travel-and-safety/${eventDetails?.location!}`}
                                            </a>
                                        </div>
                                        <Tooltip
                                            anchorSelect="#travelandsafety"
                                            place="top"
                                            content="Please read up here the latest travel and safety information
                                            for your country of travel. Inform yourself about your
                                            destination's passport and visa requirements, including the
                                            deadlines for obtaining the visa, as well as health
                                            formalities. The document contains the latest information from
                                            the German Foreign Office"
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row justify-content-center mb-5 event_details_s4">
                                    <div className="col-lg-4">
                                        <HashLink
                                            // onClick={onClose}
                                            scroll={(el: any) =>
                                                el.scrollIntoView({ behavior: "smooth", block: "center" })
                                            }
                                            to={`/${eventDetails!.eventType}s/${currentDayToDayProgram.id
                                                }#details`}
                                            className="btn btn-default btn-block btnfinalbook com-font"
                                            type="submit"
                                        >
                                            {Language?.general?.book_now}
                                        </HashLink>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
        // </Modal>
    );
};
