import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { getCloudinaryLink } from "../../utilities/helpers";
import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.3,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const loadingDivVariants = {
  start: {
    y: 200,
    opacity: 0,
  },
  end: {
    y: 0,
    opacity: 1,
  },
};

const loadingDivTransition = {
  duration: 1,
  ease: "easeInOut",
  type: "spring",
};

const FILTER_ITEMS = [
  { name: "footer_item_1", link: "Project" },
  { name: "footer_item_2", link: "Tour" },
  { name: "footer_item_3", link: null },
];

type FilterItem = {
  image: string;
  title: string;
  description: string;
  link: string;
};

const ProjectsAndToursFilter = ({ homeContent }: any) => {
  const [filters, setFilters] = useState<FilterItem[] | null>(null);

  useEffect(() => {
    const result = FILTER_ITEMS.map((item) => {
      const filter = homeContent?.Hero[item.name];
      const link = item.link ? `/events?eventType=${item.link}` : "/events";

      return {
        image: filter?.image?.data?.attributes?.url,
        title: filter?.heading,
        description: filter?.content,
        link,
      };
    });

    setFilters(result.filter((item) => item.title?.length > 0));
  }, [homeContent]);

  if (!filters) {
    return null;
  }

  return (
    <div className="container-fluid">
      <div className="rowicons_1 mt-2">
        <motion.div
          className="row rowicons mb-1 mt-1"
          variants={loadingContainerVariants}
          initial="start"
          animate="end"
        >
          {filters?.map((filter) => (
            <FilterBox
              key={`filter-box-${filter.description}`}
              filter={filter}
            />
          ))}
        </motion.div>
      </div>
    </div>
  );
};

type FilterBoxProps = {
  filter: FilterItem;
};
const FilterBox = ({ filter }: FilterBoxProps) => {
  const { image, title, description, link } = filter;
  const isMobileView = useMediaQuery({ maxWidth: 375 });
  const scale = isMobileView ? 0.75 : 1;

  return (
    <motion.div
      variants={loadingDivVariants}
      transition={loadingDivTransition}
      className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12"
    >
      <div className="com-font">
        <NavLink to={link}>
          <div
            style={{
              boxShadow: "1px 1px 1px 1px rgb(32 40 45 / 10%)",
              marginBottom: 20,
              padding: 10 * scale,
              borderRadius: "1rem",
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "flex",
                height: "3rem",
                width: "3rem",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "0.5rem",
              }}
            >
              <Image
                src={getCloudinaryLink(image)}
                rounded
                width={50 * scale}
                height={50 * scale}
                style={{
                  maxHeight: "3rem",
                  maxWidth: "3rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            </div>
            <div>
              <h4
                style={{
                  fontSize: 14 * scale,
                  margin: 5,
                  textTransform: "uppercase",
                  color: "#6a66a3",
                  fontWeight: "bold",
                }}
              >
                {title}
              </h4>

              <hr
                style={{
                  margin: "auto",
                  padding: 0,
                  borderWidth: 2,
                  borderColor: "#6a66a3",
                  width: `${16 * scale}rem`,
                }}
              />
              <p style={{ fontSize: 12 * scale, margin: 5, color: "#A3A3A3" }}>
                {description}
              </p>
            </div>
          </div>
        </NavLink>
      </div>
    </motion.div>
  );
};

export default ProjectsAndToursFilter;
