export interface Certificate {
    _id: string;
    fileName: string;
    path: string;
    __v: number;
}
export interface userDetails {
    registerDate: Date;
    companyName?: string;
    companyNumber?: string;
    webAddress?: string;
    companyInfo?: string;
    // _id: string;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    // password: string;
    swt_role: 'provider' | 'client' | 'swt_admin';
    // __v: number;
    addressLine1?: string;
    bankAccountId?: string;
    bankName?: string;
    branchId?: string;
    certificate?: Certificate;
    city?: string;
    companyContactEmail?: string;
    country?: string;
    dateOfBirth?: Date;
    paymentReceiverFirstName?: string;
    paymentReceiverLastName?: string;
    postalCode?: string;
    stateProvince?: string;
    recomendationId?: string;
    transferMethodCountry?: string;
}

export interface AuthState {
    UserDetails?: userDetails,
    errorMsg: string
}
export const SET_USER_DETAILS = "SET_USER_DETAILS"
export const SET_ERROR_MSG = "SET_ERROR_MSG"


export interface SetUserDetailsAction {
    type: typeof SET_USER_DETAILS,
    payload: userDetails | undefined
}

export interface SetErrorMsgAction {
    type: typeof SET_ERROR_MSG,
    payload: string
}

export type AuthActionTypes = SetUserDetailsAction | SetErrorMsgAction