export interface FAQ {
    published: boolean;
    _id: string;
    question: string;
    answer: string;
    __v: number;
}

export interface FAQsState {
    pageTitle: string;
    publishedFAQs: FAQ[],
    allFAQs: FAQ[],
}
export const SET_PUBLISHED_FAQS = "SET_PUBLISHED_FAQS"
export const SET_ALL_FAQS = "SET_ALL_FAQS"
export const SET_PAGE_TITLE = "SET_PAGE_TITLE"


export interface SetPublishedFaqsAction {
    type: typeof SET_PUBLISHED_FAQS,
    payload: FAQ[]
}

export interface SetAllFaqsAction {
    type: typeof SET_ALL_FAQS,
    payload: FAQ[]
}
export interface TitleActionTypes {
    type: typeof SET_PAGE_TITLE,
    payload: string
}


export type FAQsActionTypes = SetPublishedFaqsAction | SetAllFaqsAction | TitleActionTypes