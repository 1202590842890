import { useState, useEffect, useRef } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";

import { useMediaQuery } from "react-responsive";
import MenuItems from "./navbar/MenuItems";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../store/Language";
import { RootState } from "../store";

import { HashLink } from "react-router-hash-link";
import { SideBarEnum, setCurrentSideBar } from "../store/SideBar";
import Select from "react-select";

const Logo = () => (
  <img
    src="https://socialtravel.world/images/logo2.svg"
    height="50"
    className="d-inline-block align-top"
    alt="Social Travel World Logo"
  />
);

const SWTNavbar = () => {
  const dispatch = useDispatch();

  const selectedLan = useSelector(
    (rootState: RootState) => rootState?.language.language?.language
  );

  const userDetails = useSelector(
    (rootState: RootState) => rootState.authReducer.UserDetails
  );

  const [expanded, setExpanded] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    selectedLan || "en"
  );

  const isMobileView = useMediaQuery({ maxWidth: 991 });

  const ref = useRef<HTMLDivElement>(null);

  const onLanguageClick = (currentLanguage: string) => {
    dispatch(setLanguage(currentLanguage));
  };

  const onProfileClick = () => {
    setExpanded(false);
    if (userDetails) {
      dispatch(setCurrentSideBar(SideBarEnum.ProfileMenu));
    } else {
      dispatch(setCurrentSideBar(SideBarEnum.Login));
    }
  };

  const onSideNavClick = (sidebar: SideBarEnum) => {
    setExpanded(false);
    dispatch(setCurrentSideBar(sidebar));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetNode = event.target as Node | null;
      if (ref.current && targetNode && !ref.current.contains(targetNode)) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const languageOptions = [
    { value: "en", label: "En" },
    { value: "de", label: "De" },
    { value: "es", label: "Es" },
  ];

  const navigationLinks = [
    {
      path: "/images/navbar/search.svg",
      onClick: () => onSideNavClick(SideBarEnum.Search),
    },
    {
      path: "/images/navbar/contact.svg",
      onClick: () => onSideNavClick(SideBarEnum.Contact),
    },
    {
      path: "/images/navbar/cart.svg",
      onClick: () => onSideNavClick(SideBarEnum.Cart),
    },
    { path: "/images/navbar/login.svg", onClick: onProfileClick },
  ];

  useEffect(() => {
    const scrollThreshold = 100;

    function handleScroll() {
      if (window.scrollY > scrollThreshold) {
        setExpanded(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar
      expand={isMobileView ? "lg" : undefined}
      expanded={expanded}
      bg="light"
      variant="light"
      ref={ref}
      fixed="top"
      className={isMobileView ? "swt-navbar-mobile" : "swt-navbar"}
    >
      <div id="#" />
      <Container style={{ maxWidth: "1400px" }}>
        <Navbar.Brand style={{ paddingBottom: 0, paddingTop: 0 }}>
          <HashLink to="/#">
            <Logo />
          </HashLink>
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={() => setExpanded(expanded ? false : true)}
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-between"
        >
          <Nav className="me-auto">
            <MenuItems />
          </Nav>
          <Nav
            className="d-flex flex-row align-items-center"
            style={
              isMobileView
                ? { borderTop: "1px solid #58548C", paddingTop: "0.5rem" }
                : {}
            }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {navigationLinks.map((link, index) => (
                  <SideNavLink
                    key={`side-nav-link-${index}`}
                    path={link.path}
                    onClick={link.onClick}
                  />
                ))}
              </div>

              <div className="ml-4">
                <Select
                  options={languageOptions}
                  value={languageOptions.find(
                    (option) => option.value === selectedLanguage
                  )}
                  onChange={(e: any) => {
                    const currentSelectedLanguge = e.value;

                    setSelectedLanguage(currentSelectedLanguge);
                    onLanguageClick(currentSelectedLanguge);
                  }}
                  isSearchable={false}
                  components={{
                    Option: CustomOption,
                    SingleValue: CustomSingleValue,
                  }}
                />
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const CustomOption = ({ innerProps, label }: any) => (
  <div
    {...innerProps}
    style={{
      width: "1rem",
      cursor: "pointer",
      margin: "0.5rem 1.25rem",
    }}
  >
    {label}
  </div>
);

const CustomSingleValue = ({ innerProps, data }: any) => (
  <div
    {...innerProps}
    style={{
      width: "1.25rem",
      padding: 0,
      marginTop: "-1rem",
    }}
  >
    {data.label}
  </div>
);

const SideNavLink = ({ path, onClick }: any) => {
  return (
    <Nav.Link className="px-2" onClick={onClick}>
      <img
        src={path}
        height="22"
        alt="test"
        style={{ marginTop: "-4px", fill: "#58548C" }}
      />
    </Nav.Link>
  );
};

export default SWTNavbar;
