import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axiosInstance from "../../utilities/axiosInstance";
import { Nav, NavActionTypes, SET_NAV_CONTENT } from "./type";

export const setNav = (contents: Nav): NavActionTypes => {
    return {
        type: SET_NAV_CONTENT,
        payload: contents
    }
}

export const fetchNavContent = (lan: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await axiosInstance.get(`/api/nav?&locale=${lan}`);
    dispatch(setNav(res.data.data.attributes))
}
