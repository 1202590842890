import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getFeedbackById } from '../../../store/feedback'

const RenderFeedBackQuestion = ({ question, res1, res2 }: any) => {
    return (
        <div className="row">
            <div className="col-md-6">
                <p>{question}?</p>
            </div>
            <div className="col-md-3">
                <p className="text-muted">{res1 || 'N/A'}</p>
            </div>
            {
                res2 != undefined && (
                    <div className="col-md-3">
                        <p className="text-muted">{res2 || 'N/A'}</p>
                    </div>
                )
            }
        </div>
    )
}

const RenderFeedBackQuestionText = ({ question, res1, res2 }: any) => {
    return (
        <div className="row">
            <div className="col-md-12">
                <p>{question}?</p>
            </div>
            <div className="col-md-12">
                <p className="text-muted">{res1 || 'N/A'}</p>
            </div>
        </div>
    )
}

const ViewFeedback: FunctionComponent = () => {
    const dispatch = useDispatch()
    const { feedbackId } = useParams()
    const [feedback, setFeedback] = useState<any>()


    const fetchFeedback = async () => {
        const feedback = await dispatch(getFeedbackById(feedbackId))
        setFeedback(feedback)
    }

    useEffect(() => {
        fetchFeedback()
    }, [])

    if (!feedback) {
        return null
    }

    return (
        <div className="col-lg-9 col-12">
            <div className="section-wrap paddresup sa-quickfeedback">
                <section className="sa-content com-font">
                    <h2 className="fw700 text-red text-capitalize">{feedback.eventName}</h2>
                    <h5 className="fw500 text-red border-bottom pb-2">Here you can find answers given to our feedback questions for your event, submitted on {dayjs(feedback.submitDate).format("DD-MMM-YYYY")}</h5>
                    <h5 className="fw500 text-red text-uppercase pt-2">quick feedback</h5>
                    <RenderFeedBackQuestion
                        question="Did the travel arrangement match your ideas and wishes"
                        res1={feedback.travelArr}
                    />
                    <RenderFeedBackQuestion
                        question="Will you recommend this trip to others"
                        res1={feedback.recommendTrip}
                    />
                    <RenderFeedBackQuestion
                        question="Will you recommend our service to others"
                        res1={feedback.recommendServ}
                    />
                    <RenderFeedBackQuestion
                        question="Would you like us to call you back"
                        res1={feedback.callback}
                    />
                    <RenderFeedBackQuestionText
                        question="What did you like in particular"
                        res1={feedback.particularQuick}
                    />
                    <RenderFeedBackQuestionText
                        question="Personal additions"
                        res1={feedback.personalQuick}
                    />

                    <h5 className="fw500 text-red text-uppercase pt-2">CARE BEFORE TRAVEL</h5>
                    <RenderFeedBackQuestion
                        question="How easy was it to book"
                        res1={feedback.easyBook}
                    />
                    <RenderFeedBackQuestion
                        question="Information quality"
                        res1={feedback.infoQual}
                    />
                    <RenderFeedBackQuestion
                        question="Consulting quality"
                        res1={feedback.consultQual}
                    />
                    <RenderFeedBackQuestion
                        question="Speed of processing your request"
                        res1={feedback.reqSpeed}
                    />
                    <RenderFeedBackQuestion
                        question="Availability for questions"
                        res1={feedback.questions}
                    />
                    <RenderFeedBackQuestion
                        question="Quality of the written documents"
                        res1={feedback.writtenQual}
                    />
                    <RenderFeedBackQuestion
                        question="Whole experience with Small World Travel"
                        res1={feedback.wholeExp}
                    />
                    <RenderFeedBackQuestionText
                        question="What did you like in particulars"
                        res1={feedback.particularDetailed}
                    />
                    <RenderFeedBackQuestionText
                        question="Personal additions"
                        res1={feedback.personalDetailed}
                    />


                    <h5 className="fw500 text-red text-uppercase pt-2">TRAVEL PROGRAM & COURSE</h5>
                    <RenderFeedBackQuestion
                        question="Organization on site"
                        res1={feedback.organization}
                    />
                    <RenderFeedBackQuestion
                        question="Service/friendliness"
                        res1={feedback.service}
                    />
                    <RenderFeedBackQuestion
                        question="Atmosphere"
                        res1={feedback.atmosphere}
                    />
                    <RenderFeedBackQuestion
                        question="Meals/catering"
                        res1={feedback.meals}
                    />
                    <RenderFeedBackQuestion
                        question="Transportation"
                        res1={feedback.transportation}
                    />
                    <RenderFeedBackQuestion
                        question="Accommodation"
                        res1={feedback.accommodation}
                    />
                    <RenderFeedBackQuestion
                        question="Standard of chosen category"
                        res1={feedback.standard}
                    />
                    <RenderFeedBackQuestion
                        question="Are you satisfied with the choice of accommodation?"
                        res1={feedback.satisfiedAcc}
                    />
                    <RenderFeedBackQuestion
                        question="Close to nature"
                        res1={`quality: ${feedback.closeToNatureQual || 'N/A'}`}
                        res2={`quantity: ${feedback.closeToNatureQuan || 'N/A'}`}
                    />
                    <RenderFeedBackQuestion
                        question="Cultural encounters"
                        res1={`quality: ${feedback.culturalQual || 'N/A'}`}
                        res2={`quantity: ${feedback.culturalQuan || 'N/A'}`}
                    />
                    <RenderFeedBackQuestion
                        question="Activities (cycling, hiking...)"
                        res1={`quality: ${feedback.activitiesQual || 'N/A'}`}
                        res2={`quantity: ${feedback.activitiesQuan || 'N/A'}`}
                    />
                    <RenderFeedBackQuestion
                        question="Human encounters"
                        res1={`quality: ${feedback.humanQual || 'N/A'}`}
                        res2={`quantity: ${feedback.humanQuan || 'N/A'}`}
                    />
                    <RenderFeedBackQuestion
                        question="Recovery times"
                        res1={`quality: ${feedback.recoveryQual || 'N/A'}`}
                        res2={`quantity: ${feedback.recoveryQuan || 'N/A'}`}
                    />
                    <RenderFeedBackQuestionText
                        question="What did you like in particulars"
                        res1={feedback.particularTravel}
                    />
                    <RenderFeedBackQuestionText
                        question="Personal additions"
                        res1={feedback.personalTravel}
                    />

                    <h5 className="fw500 text-red text-uppercase pt-2">YOUR LOCAL GUIDES</h5>
                    <RenderFeedBackQuestion
                        question="Names"
                        res1={feedback.names}
                    />
                    <RenderFeedBackQuestion
                        question="Organization"
                        res1={feedback.organizationGuides}
                    />
                    <RenderFeedBackQuestion
                        question="Competence"
                        res1={feedback.competence}
                    />
                    <RenderFeedBackQuestion
                        question="Friendliness"
                        res1={feedback.friendliness}
                    />
                    <RenderFeedBackQuestion
                        question="Engagement"
                        res1={feedback.engagement}
                    />
                    <RenderFeedBackQuestion
                        question="Sensitivity to the environment"
                        res1={feedback.environment}
                    />
                    <RenderFeedBackQuestion
                        question="Sensitivity to local issues"
                        res1={feedback.localIssues}
                    />
                    <RenderFeedBackQuestionText
                        question="What did you like in particulars"
                        res1={feedback.particularGuides}
                    />
                    <RenderFeedBackQuestionText
                        question="Personal additions"
                        res1={feedback.personalGuides}
                    />

                    <h5 className="fw500 text-red text-uppercase pt-2">SPECIFIC QUESTIONS</h5>
                    <RenderFeedBackQuestion
                        question="Missing destinations"
                        res1={`Tanazania: ${feedback.tanzania ? 'yes' : 'no'}`}
                        res2={`Ghana: ${feedback.ghana ? 'yes' : 'no'}`}
                    />
                    <RenderFeedBackQuestion
                        question="Would you recommend your event to a friend of yours?"
                        res1={feedback.recommendEvent}
                    />
                    <RenderFeedBackQuestionText
                        question="Why?"
                        res1={feedback.whyRecommend}
                    />
                </section>
            </div>
        </div>

    )
}

export default ViewFeedback