import Axios from 'axios'
import { FunctionComponent, useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { countriesSafetyInfo } from '../utilities/countriesSafety'

export const SafetyInformation: FunctionComponent = () => {
    const { location } = useParams()
    const [markUp, setMarkUp] = useState('')


    const fetchData = async () => {
        const countryId = countriesSafetyInfo.find(c => c.contryName.toLowerCase() === location.toLowerCase())?.contendID
        if (countryId) {
            const { data } = await Axios.get(`https://www.auswaertiges-amt.de/opendata/travelwarning/${countryId}`)
            setMarkUp(data.response[countryId].content)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            <div className="container-fluid paddlr0">
                <div className="col-lg-12 faqhead align-items-center d-flex com-font">
                    <div className="container">
                        <div className="faqheadtxt">Travel and Safety Information</div>
                        {/* <div class="bgheadsmalltxt">Lorem ipsum dolor sit amet tida dino some text goes here</div> */}
                    </div>
                </div>
                <div className="container faqaccord faqconpaddup com-font paddlr0" dangerouslySetInnerHTML={{ __html: markUp }}>
                </div>
            </div>

        </>
    )
}