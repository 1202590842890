export interface dayToDay {
  value: string;
  city: string;
}
export interface Tours {
  eventType: "tour",
  // _id: string;
  id: number;
  tourName: string;
  contactEmail: string;
  tourOwnerPicture: CloudinaryImg[];
  tourOwnerName: string;
  ownerDescription: string;
  tourPictures: CloudinaryImg[];
  tourDescription: string;
  program: string;
  qualifications: string;
  localSupervisorName: string;
  localSupervisorPhone: string;
  location: string;
  // startDate: Date | string;
  // endDate: Date | string;
  startDate: string;
  endDate: string;
  capacity: number;
  category: string;
  price: number;
  rating?: number;
  pricePerDay: number;
  pricePerMonth: number;
  additionalPrice: string;
  accommodationDescription: string;
  accommodationPicture: CloudinaryImg[];
  foodDescription: string;
  transportation: string;
  transportationPicture: CloudinaryImg[];
  additionalInfo: string;
  dayToDay: dayToDay[];
  status: string;
  dayOne: string;
  possibleAdditionalCost: number;
  standardAccomodation: string;
  extraAccommodation: ExtraAccommodation[];
  numberOfReservations: number;
  noticePeriod: number;
  minParticipants: number;
  duration: number;
  createdBy: string;
  customId: string;
  minCapacity?: number;
  feedback: any[];
  available: Boolean;
  bookings?: number;
  confirm?: true,
  includedEventsDates: IncludedDateRangeObject[];
  consumerPrice?: number;
  consumerPricePerDay: number;
  levelOfDifficulty: string;
  nearAirport: string;
  eventCity: string;
  natureOfEvent: string;
  spokenLanguages: string;
  natureOfEventdescription: string;
  choiceOfFood: string;
  extraChoiceOfFood: ExtraChoiceOfFood[];
  foodPrice: number;
  vouchers: Voucher[];
  companyInfo_de: string;
  ownerDescription_de: string;
  tourDescription_de: string;
  accommodationDescription_de: string;
  foodDescription_de: string;
  transportation_de: string;
  additionalInfo_de: string;
  typicalDay_de: string;
  natureOfEvent_de: string;
  natureOfEventdescription_de: string;
  companyInfo_es: string;
  ownerDescription_es: string;
  tourDescription_es: string;
  accommodationDescription_es: string;
  foodDescription_es: string;
  transportation_es: string;
  additionalInfo_es: string;
  natureOfEvent_es: string;
  natureOfEventdescription_es: string;
  airportCost: number;
  sdg: string;
  natureOfEventHeading?:string;
}
export interface Voucher {
  createdAt: Date;
  discountValue: number;
  id: number;
  updatedAt: Date;
  voucher: string;
}
export interface ExtraAccommodation {
  category: string;
  extraPrice: string;
  description: string;
}
export interface ExtraChoiceOfFood {
  category: string;
  description: string;
}

export interface CloudinaryImg {
  imageURL: string;
  public_id: string;
  imageName: string;
}

export const SET_ALL_TOURS = "SET_ALL_TOURS";
export const SET_UPCOMING_TOURS = "SET_UPCOMING_TOURS";
export const SET_CALENDAR_DATA = "SET_CALENDAR_DATA";

export interface tourState {
  upcomingTours: Tours[];
  allTours: Tours[];
  calendarData: any
}

export interface SetAllToursAction {
  type: typeof SET_ALL_TOURS;
  payload: Tours[];
}

export interface SetUpcomingToursAction {
  type: typeof SET_UPCOMING_TOURS;
  payload: Tours[];
}

export interface IncludedDateRangeObject {
  start: string | Date,
  end: string | Date
  capacity: number
  confirm: boolean
}

export type ToursActionTypes = SetAllToursAction | SetUpcomingToursAction;
