import { Article } from "../Articles";
import { Project } from "../Projects";
import { Tours } from "../Tours";
import { SET_CURRENT_ARTICLE, SET_CURRENT_DAYTODAYPROGRAM, SET_CURRENT_EVENT, SET_SIDEBAR, SideBarActionTypes, SideBarEnum } from "./type";

export function setCurrentSideBar(currentSideBar: SideBarEnum): SideBarActionTypes {
    return {
        type: SET_SIDEBAR,
        payload: currentSideBar
    }
}

export function setCurrentEvent(currentEvent: Project | Tours): SideBarActionTypes {
    return {
        type: SET_CURRENT_EVENT,
        payload: currentEvent
    }
}
export function setCurrentArticle(currentArticle: Article): SideBarActionTypes {
    return {
        type: SET_CURRENT_ARTICLE,
        payload: currentArticle
    }
}
export function setCurrentDayToDayProgram(event: Project | Tours | undefined): SideBarActionTypes {
    return {
        type: SET_CURRENT_DAYTODAYPROGRAM,
        payload: event
    }
}