import { FooterActionTypes, FooterState, SET_FOOTER_CONTENT } from "./type";

const initialState: FooterState = {
  footerContent: {
    terms_and_conditions: { data: {} },
    privacy_policy: { data: {} },
    imprint: { data: {} },
    provider_contract_en: { data: {} },
  }
};

export function footerReducer(
  state = initialState,
  action: FooterActionTypes
): FooterState {
  switch (action.type) {
    case SET_FOOTER_CONTENT:
      return {
        ...state,
        footerContent: action.payload
      };
    default:
      return state;
  }
}