import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { RootState } from '../../store'
import { Logout } from '../../store/Auth'
import { setCurrentSideBar, SideBarEnum } from '../../store/SideBar'
import { useMediaQuery } from 'react-responsive'

interface ProfileMenuSideBarProps {
    show: boolean,
    onClose: () => void
}
export const ProfileMenuSideBar: FunctionComponent<ProfileMenuSideBarProps> = ({ show, onClose }) => {
    const isMobileView = useMediaQuery({ maxWidth: 700 });
    const style = { width: show ? isMobileView ? "100%" : "300px" : "0px" };
    const dispatch = useDispatch()
    const history = useHistory()
    const userDetails = useSelector((rootState: RootState) => rootState.authReducer.UserDetails)
    const onLogOutclick = async () => {
        await dispatch(Logout())
        onClose()
        history.push('/')
    }

    const genralLan = useSelector(
        (rootState: RootState) => rootState.language.language?.content?.general!
    );
    return (
        <div className="sidenav com-font" style={style}>
            <a href="javascript:void(0)" className="closebtn closex" onClick={onClose}>×</a>
            <div className="col-lg-12">
                <div className="searchfiltertxt">{genralLan?.greeting}, {userDetails?.firstName} </div>
            </div>
            <div className="d-flex flex-column h-100">
                <div>
                    <ul className="nav-list custom-list">
                        <li className="nav-listitem">
                            <Link to="/profile/" onClick={onClose}>
                                <span className="morefiltertxt mr-2">
                                    <img alt="" style={{marginBottom: "6px"}} className="img-fluid posnaviconslogin" src="/images/navbar/login_white.svg" />
                                </span>
                                {genralLan?.my_account}
                            </Link>
                        </li>
                        <li className="nav-listitem">
                            <a href="javascript:void(0)" onClick={() => dispatch(setCurrentSideBar(SideBarEnum.Cart))}>
                                <span className="morefiltertxt mr-2">
                                    <img alt="" style={{marginBottom: "6px"}} className="img-fluid posnaviconslogin" src="/images/navbar/cart_white.svg" />
                                </span>
                                {genralLan?.my_shopping_cart}

                            </a>
                        </li>
                        {
                            userDetails?.swt_role !== 'swt_admin' && (
                                <li className="nav-listitem">
                                    <a href="/profile/my-reservations">
                                        <span className="morefiltertxt mr-2">
                                            <img alt="" style={{marginBottom: "6px"}} className="img-fluid posnaviconslogin" src="/images/my-reservations.svg" />
                                        </span>
                                        {userDetails?.swt_role === 'client' ? genralLan?.my_reservations : genralLan?.my_bookings}
                                    </a>
                                </li>
                            )}
                    </ul>
                    <div className="paddsearchfilup text-center m-4">
                        <button onClick={onLogOutclick} className="btn btn-sm btn-default combtnclick btn-block" type="submit">
                            Log Out   <img alt="" className="img-fluid logouticon" src="/images/log-out-white.png" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}