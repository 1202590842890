import { isEmpty } from "lodash";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../Components/Spinner";
import { RootState } from "../store";
import { fetchPublishedFAQs } from "../store/FAQs";
export const Faqs: FunctionComponent = ({ }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const appLanguage = useSelector((rootState: RootState) => rootState.language.language?.language)

  const publishedFaqs = useSelector(
    (state: RootState) => state.faqs.publishedFAQs
  );
  const Title = useSelector(
    (state: RootState) => state.faqs.pageTitle
  );

  console.log(Title);
  useEffect(() => {
    enableLoading();
    if (!appLanguage)
      return;
    dispatch(fetchPublishedFAQs(appLanguage));
  }, [appLanguage]);

  useEffect(() => {
    if (!isEmpty(publishedFaqs))
      disableLoading();
  }, [publishedFaqs])


  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };

  return (
    <div className="container-fluid paddlr0">
      <div className="col-lg-12 faqhead align-items-center d-flex com-font">
        <div className="container">
          <div className="faqheadtxt">{Title}</div>
          {/* <div class="bgheadsmalltxt">Lorem ipsum dolor sit amet tida dino some text goes here</div> */}
        </div>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '20vh' }}>
          <Spinner />
        </div>
      ) : (
        <div className="container faqaccord faqconpaddup com-font paddlr0">
          {/*Accordion wrapper*/}
          <Accordion defaultActiveKey="0">
            {publishedFaqs.map((faq, i) => (
              <div className="card">
                <Accordion.Toggle as={Card.Header} eventKey={String(i)}>
                  <h5 className="mb-0">{faq.question}</h5>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={String(i)}>
                  <div className="card-body text-muted">{faq.answer}</div>
                </Accordion.Collapse>
              </div>
            ))}
          </Accordion>
          {/* Accordion wrapper */}
        </div>
      )}
    </div>
  );
};
