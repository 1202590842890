import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from "redux-thunk"
import logger from 'redux-logger'
import axios from 'axios';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import TagManager from 'react-gtm-module';
import App from './App';
import './styles/index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { rootReducer } from './store/index';

import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-tooltip/dist/react-tooltip.css'
import Modal from 'react-modal';

const store = createStore(rootReducer, applyMiddleware(thunk, logger))
// const tagManagerArgs = {
//   gtmId: 'G-K4VRRFTGHR'
// }

// todo  implement token logic for authentication below
axios.interceptors.request.use(request => {
    return request;
}, error => {
    return Promise.reject(error);
});

Modal.setAppElement('#root')

// TagManager.initialize(tagManagerArgs);
ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}>
        <React.StrictMode>

    <Provider store={store}>
      <App />
    </Provider>
        </React.StrictMode>
        </GoogleOAuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for exsample: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
