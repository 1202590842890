import React, { FunctionComponent, useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { CloudinaryImg } from "../store/Projects";
import axiosInstance from "../utilities/axiosInstance";

interface FileUploadProps {
  label?: string;
  formatLabel?: string;
  value?: CloudinaryImg | CloudinaryImg[] | undefined;
  onChange: (data: CloudinaryImg | CloudinaryImg[] | undefined) => void;
  onUploading?: (val: boolean) => void;
  multiple: boolean;
  className?: string;
  destination?: string;
  field?: string;
  isEvent?: string | number | boolean
  removeFromDb?: boolean
}
const FileUpload: FunctionComponent<FileUploadProps> = ({
  label = "upload images",
  formatLabel = "Format: png/jpeg",
  value,
  multiple,
  onChange,
  onUploading,
  className,
  destination = "",
  field = "",
  isEvent = false,
  removeFromDb = false
}) => {
  const [percent, setPercent] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    for (const file of Array.from(e.target.files!)) {
      setIsUploading(true);
      var formdata = new FormData();
      formdata.append("image", file, file.name);
      try {

        const res = await axiosInstance.post("/api/images", formdata, {
          onUploadProgress: (p) => {
            setPercent((p.loaded / p.total) * 100);
          },
        });
        if (multiple) {
          onChange([...((value as CloudinaryImg[]) || []), res.data.image]);
        } else {
          onChange(res.data.image);
        }
      } catch (e) {
        console.error(e);
      }
    }
    setIsUploading(false);
  };

  const removeImage = async (img: any) => {
    try {
      await axiosInstance.delete(`/api/images?publicId=${encodeURIComponent(img.imageData.public_id)}&&destination=${destination}&&field=${field}&&isEvent=${isEvent}&&removeFromDb=${removeFromDb}`, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('swt-token')
        }
    })
      if (multiple) {
        onChange((value as CloudinaryImg[]).filter((i) => i !== img));
      } else {
        onChange(undefined);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    onUploading && onUploading(isUploading)
  }, [isUploading])

  return (
    <>
      <div className="sa-customupload">
        <button
          type="button"
          className={`btn btn-default btnviewproj text-capitalize mt-3 ${className}`}
        >
          {label}
        </button>
        <input
          className="form-control mt-3"
          type="file"
          accept="image/*,.pdf"
          onChange={onFileChange}
        />
      </div>
      <small>{formatLabel}</small>
      <div className="sa-display-filename mt-3">
        <ul>
          {isUploading && (
            <li>
              <ProgressBar now={percent} label={percent.toFixed()} style={{ width: '50%', backgroundColor: '#6a66a3' }} />
            </li>
          )}
          {value &&
            (multiple
              ? (value as CloudinaryImg[])
              : [value as CloudinaryImg]
            ).map((img) => {
              return (
                <li>
                  <span className="badge badge-pill sa-badge-border">
                    <a href={img?.imageURL} target="_blank">{img?.imageName}</a>
                    <button
                      type="button"
                      className="btn sa-btn-close"
                      onClick={() => removeImage(img)}
                    >
                      x
                    </button>
                  </span>
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
};

export default FileUpload;
