import React, { FunctionComponent, useEffect, useState } from 'react'
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import PasswordChangeSection from './PasswordChangeSection';
import Swal from 'sweetalert2';
import axiosInstance from '../../../utilities/axiosInstance';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentSideBar, SideBarEnum } from '../../../store/SideBar';
import { RootState } from '../../../store';

const PersonalInfoSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
});
export interface IPersonalInfo {
    firstName: string,
    lastName: string,
    email: string
}

interface PersonalInfoSectionProps {
    initialData: IPersonalInfo,
    onSubmit: (data: IPersonalInfo) => void
}

const PersonalInfoSection: FunctionComponent<PersonalInfoSectionProps> =
    ({ initialData, onSubmit }) => {
        const { register, handleSubmit, errors, setValue } = useForm<IPersonalInfo>({
            resolver: yupResolver(PersonalInfoSchema),
            defaultValues: initialData
        })
        const dispatch = useDispatch()
        const _onSubmit = async (data: IPersonalInfo) => {
            if (data.email !== initialData.email) {
                try {
                    const res = await axiosInstance.post(`/api/user/email-change/${initialData.email}`, { newEmail: data.email })
                    console.log(res);
                    Swal.fire({ title: 'Email adress not verified. Verification OTP sent to your email address.' }).then(() => {
                        if (res.data.otpSent) {
                            dispatch(setCurrentSideBar(SideBarEnum.Otp))
                        }
                    })
                } catch (err: any) {
                    console.error(err)
                    Swal.fire({ title: err.response.data.message })
                }
            } else {
                onSubmit(data)
            }
        }
        const genralLan = useSelector(
            (rootState: RootState) => rootState.language.language?.content
        );
        return (
            <>
                <section className="form-wrap">
                    <h4 className="com-font fw700 text-red text-capitalize">{genralLan?.general?.personal_information}</h4>
                    <form className="respadding com-font" onSubmit={handleSubmit(_onSubmit)}>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-lg-6">
                                    <input type="text" className="form-control resforminp" name="firstName" ref={register} placeholder={genralLan?.signin?.first_name_place} />
                                    {errors.firstName && (<div className="invalid-feedback d-block">{genralLan?.signin?.first_name_err}</div>)}
                                </div>
                                <div className="col-lg-6">
                                    <input type="text" className="form-control resforminp" name="lastName" ref={register} placeholder={genralLan?.signin?.last_name_place} />
                                    {errors.lastName && (<div className="invalid-feedback d-block">{genralLan?.signin?.last_name_err}</div>)}
                                </div>
                                <div className="col-lg-6">
                                    <input type="text" className="form-control resforminp" name="email" ref={register} placeholder={genralLan?.signin?.email_place} />
                                    {errors.email && (<div className="invalid-feedback d-block">{genralLan?.signin?.email_err}</div>)}
                                </div>
                                <div className="col-lg-12">
                                    <button type="submit" data-toggle="modal" data-target="#personalInfoModal" className="btn btn-default btnviewprojnext float-right text-capitalize">
                                        {genralLan?.general?.update_info}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="form-group">
                        <PasswordChangeSection />
                    </div>
                </section>
            </>

        )
    }

export default PersonalInfoSection