import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { fetchAllProviders, fetchProvidersEvents } from "../../../store/Users/actions";
import { Link } from "react-router-dom";
import { getEventType } from "../../../util";


function ExpandedRows({ row: { id } }: any) {
  const dispatch = useDispatch();
  const [events, setEvents] = useState<any[]>([])

  const fetchEvents = async () => {
    const providers = await dispatch(fetchProvidersEvents(id));
    setEvents(providers as any)
  }

  useEffect(() => {
    fetchEvents()
  }, []);

  return (
    <section className="sa-content com-font m-4">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <h5 className="fw700 text-red text-capitalize">
                Events
              </h5>
            </div>
          </div>
        </div>
        <div>
          <BootstrapTable
            keyField="_id"
            bordered={false}
            data={events}
            condensed
            columns={[
              {
                text: "Name",
                dataField: "projectName",
                isDummyField: true,
                sort: true,
                formatter: (_, row) => (
                  <Link
                    to={`/profile/${getEventType(row)}s/${row.id}`}
                  >
                    {row.projectName || row.tourName}
                  </Link>
                ),
              },
              {
                isDummyField: true,
                text: "Event Type",
                dataField: "projectName",
                sort: true,
                formatter: (_, row) => getEventType(row),
              },
              {
                text: "Published",
                dataField: "available",
                // isDummyField: true,
                formatter: (available, row) => available ? 'Yes' : 'No',
              }
            ]}
          />
        </div>
      </div>
    </section>
  )
}
const Users: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [providers, setProviders] = useState<any[]>([])

  const fetchProviders = async () => {
    const providers = await dispatch(fetchAllProviders());
    setProviders(providers as any)
  }

  useEffect(() => {
    fetchProviders()
  }, []);

  return (
    <>
      <div className="col-lg-9 col-12">
        <div className="section-wrap paddresup sa-myreservations">
          <section className="sa-content com-font">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <h4 className="fw700 text-red text-capitalize">
                      Providers
                    </h4>
                  </div>
                </div>
              </div>
              <div>
                <BootstrapTable
                  keyField="_id"
                  bordered={false}
                  data={providers}
                  columns={[
                    {
                      text: "First Name",
                      dataField: "firstName",
                      sort: true,
                    },
                    {
                      text: "Last Name",
                      dataField: "lastName",
                      sort: true,
                    },
                    {
                      text: "Email",
                      dataField: "email",
                      sort: true,
                    }
                  ]}
                  pagination={paginationFactory({
                    showTotal: true,
                    sizePerPage: 5,
                    hidePageListOnlyOnePage: true,
                    sizePerPageRenderer: () => <></>,
                  })}
                  expandRow={{
                    renderer: row => <ExpandedRows row={row} />,
                    showExpandColumn: true,
                    expandColumnRenderer: ({ expanded }) => {
                      if (expanded) {
                        return (
                          <i className="fa fa-minus" aria-hidden="true"></i>
                        );
                      }
                      return (
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      );
                    }
                  }}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Users;
