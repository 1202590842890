import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { RootState } from "../../store";
import { useMediaQuery } from "react-responsive";

const MenuItems = () => {
  const isMobileView = useMediaQuery({ maxWidth: 991 });

  const nav = useSelector(
    (rootState: RootState) => rootState?.navBar?.navContent
  );

  const MENU_ITEMS = [
    {
      name: nav?.route2,
      link: "/events",
    },
    {
      name: nav?.route3,
      link: "/about-us",
    },
    {
      name: nav?.route4,
      link: "/#latestnews-home",
      scroll: (el: any) =>
        setTimeout(
          () =>
            el.scrollIntoView({
              behavior: "smooth",
              block: "center",
            }),
          1000
        ),
    },
    {
      name: nav?.route5,
      link: "/faqs",
    },
  ];

  return (
    <ul
      className="nav navbar-nav"
      style={{
        maxHeight: isMobileView ? undefined : "2rem",
        // marginTop: "1rem",
      }}
    >
      {MENU_ITEMS.map((item, index) => {
        if (item.scroll)
          return (
            <li
              className="nav-item"
              key={index}
              style={isMobileView ? { marginBottom: "-0.75rem" } : {}}
            >
              <HashLink
                className="nav-link"
                to={item.link}
                scroll={item.scroll}
                style={{ cursor: "pointer", color: "#58548C" }}
              >
                <p className="swt-nav-link">{item.name}</p>
              </HashLink>
            </li>
          );

        return (
          <li
            className="nav-item"
            key={index}
            style={isMobileView ? { marginBottom: "-0.75rem" } : {}}
          >
            <Link
              className="nav-link"
              to={item.link}
              style={{
                cursor: "pointer",
                color: "#58548C",
              }}
            >
              <p className="swt-nav-link">{item.name}</p>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default MenuItems;
