import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axiosInstance from "../../utilities/axiosInstance";
import {
  Project,
  ProjectsActionTypes,
  SET_ALL_PROJECTS,
  SET_SELECTED_PROJECT,
  SET_UPCOMING_PROJECTS
} from "./type";

export const setUpcomingProjects = (
  projects: Project[]
): ProjectsActionTypes => {
  return {
    type: SET_UPCOMING_PROJECTS,
    payload: projects,
  };
};
export const setSelectedProject = (project: Project): ProjectsActionTypes => {
  return {
    type: SET_SELECTED_PROJECT,
    payload: project,
  };
};

export const setAllProjects = (projects: Project[]): ProjectsActionTypes => {
  return {
    type: SET_ALL_PROJECTS,
    payload: projects,
  };
};

export const fetchUpcomingProjects = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch) => {
  const res = await axiosInstance.get("/api/projects/active");
  dispatch(setUpcomingProjects(res.data.projects));
};

export const fetchProjectById = (
  projectID: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch
) => {
    const res = await axiosInstance.get(`/api/projects/${projectID}`);
    dispatch(setSelectedProject(res.data.data.project));
    return res.data.data.project;
  };

export const fetchAllProjects = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch, getState) => {
  const { swt_role, id } = getState().authReducer.UserDetails!
  const apiUrl = swt_role === 'swt_admin' ? "/api/projects" : `/api/projects/byUser/${id}`
  try {
    const res = await axiosInstance.get(apiUrl);
    dispatch(setAllProjects(res.data.projects));
  }
  catch (e) {
    console.log('api failed: ', e);
  }
};

export const createProject = (
  payload: Project, isDraft: boolean = false
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch, getState
) => {
    const { email } = getState().authReducer.UserDetails!
    const res = await axiosInstance.post(`/api/project`, { payload, notificationEmail: email, isDraft }, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    });
    return res.data;
  };

export const deleteProjectById = (
  projectID: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch
) => {
    const res = await axiosInstance.delete(`/api/projects/${projectID}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    });
    return res.data;
  };

export const UpdateProject = (
  id: string,
  payload: { updatedProject: Project, availabilityUpdated: boolean }
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
) => {
    const res = await axiosInstance.post(`/api/projects/${id}`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    });
    return res.data;
  };

export const publishUnpublishProject = (
  projectId: string,
  available: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
) => {
    const res = await axiosInstance.post(`/api/projects/publish-unpublish/${projectId}`, { available }, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    });
    return res.data;
  };
