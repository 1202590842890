import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useMediaQuery } from "react-responsive";

const URL = "https://api.whatsapp.com/send/?phone=4915566020276";

const WhatsAppButton = () => {
  const [showDiv, setShowDiv] = useState(true);
  const isMobileView = useMediaQuery({ maxWidth: 700 });

  const genralLan = useSelector(
    (rootState: RootState) => rootState.language.language?.content?.general!
  );

  const paddings = isMobileView ? "1rem" : "2rem";
  const topPadding = isMobileView ? "5rem" : "6rem";
  const size = isMobileView ? "3rem" : "4rem";

  const handleClick = () => {
    window.open(URL, "_blank");
    window.focus();
  };

  useEffect(() => {
    const showDivAfterDelay = () => {
      setTimeout(() => {
        setShowDiv(false);
      }, 3500);
    };

    showDivAfterDelay();
  }, []);

  return (
    <div
      style={{
        zIndex: 19999,
      }}
    >
      {showDiv && (
        <div
          style={{
            position: "fixed",
            bottom: topPadding,
            right: paddings,
            width: isMobileView ? "11rem" : "15rem",
            height: size,
            borderRadius: "2rem",
            backgroundColor: "#25D366",
            opacity: 1,
            transition: "opacity 1s ease-in-out",
            zIndex: 18999,
          }}
        >
          <div
            style={{
              width: "11rem",
              height: size,
              padding: "0rem 1.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: "#fff",
                margin: 0,
                padding: 0,
                fontSize: isMobileView ? "12px" : undefined,
              }}
            >
              {genralLan?.help.length > 40
                ? "Chat with us on WhatsApp"
                : genralLan?.help}
            </p>
          </div>
        </div>
      )}
      <div
        style={{
          position: "fixed",
          bottom: topPadding,
          right: paddings,
          width: size,
          height: size,
          backgroundColor: "#25D366",
          boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3",
          borderRadius: "50%",
          cursor: "pointer",
          zIndex: 19999,
        }}
        onClick={handleClick}
        onMouseEnter={() => {
          setShowDiv(true);
        }}
        onMouseLeave={() => {
          setShowDiv(false);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: size,
          }}
        >
          <img
            src="/images/whatsapp.svg"
            alt="contact us on whatsapp"
            width={isMobileView ? 25 : 35}
            height={isMobileView ? 25 : 35}
          />
        </div>
      </div>
    </div>
  );
};

export default WhatsAppButton;
