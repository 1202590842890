import Axios from 'axios'
import { FunctionComponent, useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from "react-router";
import { toast } from 'react-toastify'
import { Spinner } from '../Components/Spinner'
import { RootState } from '../store'
import axiosInstance from '../utilities/axiosInstance'
import { countriesSafetyInfo } from '../utilities/countriesSafety'

export const EmailConsent: FunctionComponent = () => {
    const history = useHistory()
    const [accepted, setAccepted] = useState(false)
    const userDetails = useSelector((rootState: RootState) => rootState.authReducer.UserDetails)

    const consent = async () => {
        const consent = await axiosInstance.get(`/api/emails/consent`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('swt-token')
            }
        })
    }

    useEffect(() => {
        consent().then(() => {
            toast.success(`Consent sent successfully`)
            setAccepted(true)
            history.push('/')
        }).catch((error) => {
            setAccepted(false)
            toast.error(error.response.data.message)
            history.push(`/`)
        });
    }, [])

    return (
        <>
            <div className="container-fluid paddlr0">
                <div className="col-lg-12 faqhead align-items-center d-flex com-font">
                    <div className="container">
                        <div className="faqheadtxt">Email Subscription</div>
                        {/* <div class="bgheadsmalltxt">Lorem ipsum dolor sit amet tida dino some text goes here</div> */}
                    </div>
                </div>
                <div className="container faqaccord faqconpaddup com-font paddlr0" >
                    {!userDetails ? <h3>You must be login to continue</h3> : null}

                    {accepted ? <h3>You have successfully subscribed the email subscription</h3> : <Spinner />}
                </div>
            </div>

        </>
    )
}