export interface Image {
    imageURL: string;
    public_id: string;
    imageName: string;
}

export interface Article {
    published: boolean;
    date: Date;
    image: any;
    id: string;
    title: string;
    text: string;
    // __v: number;
}

export interface ArticlesState {
    PopularArticles: Article[],
    allArticles: Article[],
    articlesImages: any[]
}
export const SET_POPULAR_ARTICLES = "SET_POPULAR_ARTICLES"
export const SET_ALL_ARTICLES = "SET_ALL_ARTICLES"
export const SET_ALL_ARTICLES_IMAGES = "SET_ALL_ARTICLES_IMAGES"


export interface SetPopularArticlesAction {
    type: typeof  SET_POPULAR_ARTICLES,
    payload: Article[]
}

export interface SetAllArticlesAction {
    type: typeof SET_ALL_ARTICLES,
    payload: Article[]
}
export interface SetAllArticlesImagesAction {
    type: typeof SET_ALL_ARTICLES_IMAGES,
    payload: any[]
}

export type ArticlesActionTypes = SetPopularArticlesAction | SetAllArticlesAction | SetAllArticlesImagesAction