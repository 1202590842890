import dayjs from "dayjs";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './calendar.css';
import { DAYS_IN_WEEK, REPEAT } from "./consts";
import MonthlyStack from "./monthlyStack";
import OutputComponent from "./outputComponent";
import UseCalendarHook from "./useCalendarHook";
import YearlyStack from "./yearlyStack";
import { useSelector } from "react-redux";


const Index = ({ setCalendarDateRange, setIncludedDates,
    startDate: originalStartDate, endDate: originalEndDate,
    setValue, trigger, initialData, toggleModal
}) => {
    const Language = useSelector(
        (rootState) => rootState.language.language?.content
    );
    const {
        repeat,
        isRepeat,
        selectedDayOfWeek,
        startDate,
        endDate,
        dateRange,
        ReservationDateInput,
        DateInput,
        endRepeatOn,
        activeRadio,
        // minDate,
        reservationDate,
        endRepeatAfter,
        disableRepeatEndValues,
        dateToExclude,
        disableMonthlyRepeatRadioFields,
        monthlyRepeatActiveRadio,
        repeatOnDate,
        repeatMonthly,
        isDisabledRepeat,
        _HandleNewCalendarDate,
        _HighlightColor,
        _CalculateDateRangeToInclude,
        _HandleDateRange,
        _HandleSelectedWeek,
        _handleEndRepeat,
        _HandleOccurenceAndAfterEndValue,
        _CheckRepeatValue,
        _ToggleRepeatBtn,
        setMonthlyRepeatActiveRadio,
        setDisableMonthlyRepeatRadioFields,
        setRepeatOnDate,
        setRepeatMonthly,
    } = UseCalendarHook({
        setCalendarDateRange,
        setIncludedDates,
        originalStartDate,
        originalEndDate,
        setValue,
        trigger,
    });
    return (
        <div style={{ height: "100%", width: "100%" }} className="">
            <Container className="shadow-sm rounded-sm bg-light">
                <Row>
                    <Col sm={12} lg={6}>
                        <div className="form-group mx-auto">
                            <label htmlFor="datePicker">{Language?.general?.select}{' '}{Language?.general?.date}</label>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text input-group-icon" id="calendar">
                                        <i className="fa fa-calendar-o" />
                                    </span>
                                </div>
                                <DatePicker
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    // minDate={minDate}
                                    minDate={startDate}
                                    maxDate={disableRepeatEndValues.After === false ? dayjs(endRepeatAfter).toDate()
                                        : originalEndDate} //* max date set in tour creation form // 
                                    selected={startDate}
                                    shouldCloseOnSelect={false}
                                    onChange={(date) => _HandleDateRange(date)}
                                    customInput={<DateInput />}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        onChange={(e) => _ToggleRepeatBtn(e)}
                                        type="checkbox"
                                        checked={isRepeat}
                                        disabled={isDisabledRepeat}
                                        id="disabledFieldsetCheck"
                                        label={Language?.general?.repeat}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} lg={6}>
                        <Row>
                            {isRepeat && (
                                <div className="container">
                                    <Form.Group className="mb-3">
                                        <Form.Label>{Language?.general?.repeat}</Form.Label>
                                        <select
                                            class="form-control resforminp mt-0"
                                            aria-label="Default select example"
                                            onChange={(e) => _CheckRepeatValue(e)}
                                        >
                                            {REPEAT.map(({ id, name }) => (
                                                <option
                                                    key={id}
                                                    value={name}
                                                    selected={name === repeat ? true : false}
                                                >
                                                    {name === "DAILY" ? Language?.general.daily : name === "WEEKLY" ?
                                                        Language?.general.weekly : Language?.general.monthly}
                                                </option>
                                            ))}
                                        </select>
                                    </Form.Group>
                                    {
                                        repeat === "MONTHLY" ? (<MonthlyStack
                                            Language={Language}
                                            disableMonthlyRepeatRadioFields={disableMonthlyRepeatRadioFields}
                                            monthlyRepeatActiveRadio={monthlyRepeatActiveRadio}
                                            setMonthlyRepeatActiveRadio={setMonthlyRepeatActiveRadio}
                                            setDisableMonthlyRepeatRadioFields={setDisableMonthlyRepeatRadioFields}
                                            setRepeatOnDate={setRepeatOnDate}
                                            repeatOnDate={repeatOnDate}
                                            startDate={startDate}
                                            repeatMonthly={repeatMonthly}
                                            setRepeatMonthly={setRepeatMonthly}
                                        />) :
                                            repeat === "YEARLY" ? (
                                                <YearlyStack />
                                            ) :
                                                ''}

                                    {
                                        repeat === "WEEKLY" && (
                                            <Form.Group className="mb-3">
                                                <div className="d-flex flex-row flex-wrap">
                                                    {
                                                        DAYS_IN_WEEK.map(({ id, name }) => (
                                                            <Button key={id} type="button"
                                                                className={id === selectedDayOfWeek ? 'active-btn' : 'not-active-btn'}
                                                                onClick={() => _HandleSelectedWeek(id)}>
                                                                {name}
                                                            </Button>
                                                        ))
                                                    }
                                                </div>
                                            </Form.Group>
                                        )}


                                    <Form.Group as={Col} className="mb-3">
                                        <Form.Label as="legend" column sm={12}>
                                            {Language?.general?.end} {Language?.general?.repeat}
                                        </Form.Label>
                                        <Row>
                                            <Col sm={10}>
                                                <Form.Check
                                                    onChange={_handleEndRepeat}
                                                    type="radio"
                                                    label="Never"
                                                    value="Never"
                                                    checked={activeRadio === "Never"}
                                                    name="formHorizontalRadios"
                                                    id="endRepeatNever"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className=" d-flex flex-row justify-content-between align-items-center mt-3 ">
                                                <Form.Check
                                                    onChange={_handleEndRepeat}
                                                    type="radio"
                                                    label="On"
                                                    value="Occurence"
                                                    checked={activeRadio === "Occurence"}
                                                    name="formHorizontalRadios"
                                                    id="endRepeatOn"
                                                />
                                                <input
                                                    id="endRepeatOnOccurenceCount"
                                                    type="number"
                                                    min={3}
                                                    name="EndRepeatOn"
                                                    disabled={disableRepeatEndValues.Occurence}
                                                    value={endRepeatOn}
                                                    onChange={(e) => _HandleOccurenceAndAfterEndValue(e)}
                                                />
                                                <span>{Language?.general?.occurence}(s)</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="d-flex flex-row mt-3 justify-content-between align-items-center">
                                                <Form.Check
                                                    onChange={_handleEndRepeat}
                                                    type="radio"
                                                    label={Language?.general?.after}
                                                    value="After"
                                                    checked={activeRadio === "After"}
                                                    name="formHorizontalRadios"
                                                    id="endRepeatAfterEnd"
                                                />
                                                <input
                                                    type="date"
                                                    value={endRepeatAfter}
                                                    min={dayjs(originalStartDate).format('YYYY-MM-DD')}
                                                    max={dayjs(originalEndDate).format('YYYY-MM-DD')}
                                                    name="EndRepeatAfter"
                                                    id="endRepeatAfterDate"
                                                    disabled={disableRepeatEndValues.After}
                                                    onChange={(e) => _HandleOccurenceAndAfterEndValue(e)}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </div>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col >
                        <OutputComponent
                            repeat={repeat}
                            isRepeat={isRepeat}
                            dateRange={dateRange}
                            dateToExclude={dateToExclude}
                            reservationDate={reservationDate}
                            ReservationDateInput={ReservationDateInput}
                            _HighlightColor={_HighlightColor}
                            _CalculateDateRangeToInclude={_CalculateDateRangeToInclude}
                            _HandleNewCalendarDate={_HandleNewCalendarDate}
                            initialData={initialData}
                        />
                    </Col>
                </Row>
                <div className="btn btn-default btnviewproj text-capitalize mt-3 mx-1" onClick={toggleModal}>{Language?.general?.confirm}</div>
            </Container>
        </div>
    );
};

export default Index;
