import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ProjectForm from "../../../Components/projects/ProjectForm";
import { createProject } from "../../../store/Projects";
import { Project } from "../../../store/Projects/type";
import { toast } from "react-toastify";
import { RootState } from "../../../store";
import { fetchEventDrafts } from "../../../store/Drafts";

const CreateProjectForm: FunctionComponent = () => {
  const [formData, setFormData] = useState<any>({});
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const data = await dispatch(fetchEventDrafts('project'));
      if (data?.length > 0) {
        const draft = (data as any)[0];
        console.log('------data fetched', draft);
        setFormData(draft);
      } else {
        setFormData({ id: 'non-draft' })
      }
    };
    if(pathname.includes('/projects/add')) {
      fetchData();
    } else {
      setFormData({ id: 'non-draft' })
    }
  }, [])

  const onSubmit = async (project: Project, isDraft: boolean = false) => {
    try {
      const res: any = await dispatch(createProject(project, isDraft));
      delete (project as any).available;
      if (res.success) {
        toast.success(res.message)
        history.push("/profile/projects");
      }
      else {
        toast.error(res.message);
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.err.map((e: any) => e.message).join("\n")
      );
    }
  };
  const genralLan = useSelector(
    (rootState: RootState) => rootState?.language?.language?.content!
  );
  return formData?.id ? (
    <div className="col-lg-9 col-12">
      <div className="section-wrap paddresup">
        <section className="form-wrap">
          <h4 className="com-font fw700 text-red text-capitalize">
            {genralLan?.general?.add + ' ' + genralLan?.general?.new + ' ' + genralLan?.general?.project}
          </h4>
          {/* <ProjectForm onSubmit={onSubmit} /> */}
          <ProjectForm onSubmit={onSubmit} initialData={formData} isDraft={formData?.id === 'non-draft' ? false : true} />
        </section>
      </div>
    </div>
  ) : <></>;
};

export default CreateProjectForm;
