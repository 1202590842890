import { FunctionComponent } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../store";
import { setCurrentSideBar, SideBarEnum } from "../../store/SideBar";
import { CartSideBar } from "./CartSideBar";
import { ContactSideBar } from "./ContactSideBar";
import { LoginSideBar } from "./LoginSideBar";
import { NewsSideBar } from "./NewsSideBar";
import { ProfileMenuSideBar } from "./ProfileMenu";
import { ProjectsSideBar } from "./ProjectsSideBar";
import { SearchSideBar } from "./SearchSideBar";
import { SignUpSideBar } from "./SignUpSideBar";
import { DayToDaySideBar } from "./DayToDaySideBar";
import { OtpSideBar } from "./OtpSideBar";
import { ForgotPasswordSideBar } from "./ForgotPasswordSideBar";

export const SideBarContainer: FunctionComponent = () => {
    const dispatch = useDispatch()
    const currentSideBar = useSelector((state: RootState) => state.sideBar.currentSideBar)
    const isCurrent = (sidebar: SideBarEnum) => currentSideBar === sidebar
    const onClose = () => dispatch(setCurrentSideBar(SideBarEnum.None))

    return (
        <>
            <LoginSideBar show={isCurrent(SideBarEnum.Login)} onClose={onClose} />
            <SignUpSideBar show={isCurrent(SideBarEnum.Signup)} onClose={onClose} />
            <NewsSideBar show={isCurrent(SideBarEnum.News)} onClose={onClose} />
            <SearchSideBar show={isCurrent(SideBarEnum.Search)} onClose={onClose} />
            <CartSideBar show={isCurrent(SideBarEnum.Cart)} onClose={onClose} />
            <ProjectsSideBar show={isCurrent(SideBarEnum.Projects)} onClose={onClose} />
            <ProfileMenuSideBar show={isCurrent(SideBarEnum.ProfileMenu)} onClose={onClose} />
            <ContactSideBar show={isCurrent(SideBarEnum.Contact)} onClose={onClose} />
            <DayToDaySideBar show={isCurrent(SideBarEnum.DayToDay)} onClose={onClose} />
            <OtpSideBar show={isCurrent(SideBarEnum.Otp)} onClose={onClose} />
            <ForgotPasswordSideBar show={isCurrent(SideBarEnum.Forgot)} onClose={onClose} />
        </>
    )
}