import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { FooterSocial } from '../Footer'
import { useMediaQuery } from 'react-responsive'

interface ContactSideBarProps {
    show: boolean,
    onClose: () => void
}

export const ContactSideBar: FunctionComponent<ContactSideBarProps> = ({ show, onClose }) => {
    const isMobileView = useMediaQuery({ maxWidth: 700 });
    const style = { width: show ? isMobileView ? "100%" : "300px" : "0px" };
    const Language = useSelector((state: RootState) => state.language.language?.content?.general)

    return (
        <div className="sidenav com-font" style={style}>
            <a href="javascript:void(0)" className="closebtn closex" onClick={onClose}>×</a>
            <div className="col-lg-12">
                <h5 className="text-center text-uppercase my-5 fw700" style={{ color: '#fff', letterSpacing: '2px' }}>{Language?.follow_us}</h5>
                <div className="">
                    <FooterSocial sidebar />
                </div>
                <div className="my-3 text-white">
                    <p className="mb-0 mb-2"><strong>{Language?.email}:</strong> <a className="text-white" href="mailto:info@socialtravel.world">info@socialtravel.world</a></p>
                    <p className="mb-0 mb-2"><strong>{Language?.phone}</strong> <a className="text-white" href="tel:+49 (0)176 6655 0020">+49 (0)176 6655 0020</a></p>
                </div>
            </div>
        </div>
    )
}