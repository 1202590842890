import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Drawer from "./Components/Drawer";
import FeedBack from "./Components/feedback/Feedback";
import QuickFeedBack from "./Components/feedback/QuickFeedBack";
import { PrivateRoute } from "./Components/PrivateRoute";
import ScrollToTop from "./Components/ScrollToTop";
import { DefaultLayout } from "./layout/DefaultLayout";
import { About } from "./pages/about";
import { Cart } from "./pages/cart";
import { Checkout } from "./pages/checkout";
import { EmailConsent } from "./pages/email-consent";
import { EventDetails } from "./pages/event-details";
import { Events } from "./pages/events";
import { Faqs } from "./pages/faqs";
import { Imprint } from "./pages/imprint";
import { Index } from "./pages/index";
import { LatestNews } from "./pages/latestNews";
import FeedBackList from "./pages/profile/feedbacks";
import ViewFeedback from "./pages/profile/feedbacks/view";
import MyReservation from "./pages/profile/my-reservations";
import PersonaInfo from "./pages/profile/PersonalInformation";
import ProjectListings from "./pages/profile/projects";
import CreateProjectForm from "./pages/profile/projects/createProject";
import UpdateProjectForm from "./pages/profile/projects/UpdateProject";
import Requests from "./pages/profile/requests";
import Tours from "./pages/profile/tours";
import CreateToursForm from "./pages/profile/tours/createTours";
import UpdateTourForm from "./pages/profile/tours/UpdateTours";
import Users from "./pages/profile/users";
import { ProjectDetails } from "./pages/projectsDetails";
import { ProviderApplicationForm } from "./pages/provider-application";
import { SafetyInformation } from "./pages/SafetyInformation";
import { TourDetails } from "./pages/tourDetails";
import { UpdatePayment } from "./pages/update-pament";
import { RootState } from "./store";
import { fetchLoggedinUserDetails } from "./store/Auth";
import { fetchCartDetails } from "./store/Cart";
import { fetchContents } from "./store/Content";
import { setLanguage } from "./store/Language";
import { fetchNavContent } from "./store/NavBar";




function App() {
  const dispatch = useDispatch();
  const appLanguage = useSelector((rootState: RootState) => rootState.language.language?.language)

  useEffect(() => {
    const language = appLanguage || "en";
    const fetchData = async () => {
      dispatch(fetchContents(language));
      dispatch(setLanguage(language));
      await dispatch(fetchLoggedinUserDetails());
      dispatch(fetchCartDetails());
      dispatch(fetchNavContent(language));
    };
    fetchData();
  }, [appLanguage]);

  return (
    <Router>
      <ScrollToTop />
      <DefaultLayout>
        <Switch>
          <PrivateRoute
            path="/profile/requests"
            component={() => (
              <Drawer>
                <Requests />
              </Drawer>
            )}
          />
          <PrivateRoute
            path="/profile/my-reservations"
            component={() => (
              <Drawer>
                <MyReservation />
              </Drawer>
            )}
          />
          {/* <PrivateRoute
            path="/profile/contents/:index"
            component={() => (
              <Drawer>
                <UpdateContentForm />
              </Drawer>
            )}
          />
          <PrivateRoute
            path="/profile/contents"
            component={() => (
              <Drawer>
                <ContentList />
              </Drawer>
            )}
          />
          <PrivateRoute
            path="/profile/latest-news/add"
            component={() => (
              <Drawer>
                <CreateArticleForm />
              </Drawer>
            )}
          />
          <PrivateRoute
            path="/profile/latest-news/:id"
            component={() => (
              <Drawer>
                <UpdateArticleForm />
              </Drawer>
            )}
          />
          <PrivateRoute
            path="/profile/latest-news"
            component={() => (
              <Drawer>
                <ArticleList />
              </Drawer>
            )}
          />
          <PrivateRoute
            path="/profile/faqs/add"
            component={() => (
              <Drawer>
                <CreateFaqForm />
              </Drawer>
            )}
          />
          <PrivateRoute
            path="/profile/faqs/:id"
            component={() => (
              <Drawer>
                <UpdateFaqForm />
              </Drawer>
            )}
          />
          <PrivateRoute
            path="/profile/faqs"
            component={() => (
              <Drawer>
                <FAQList />
              </Drawer>
            )}
          /> */}
          <PrivateRoute
            path="/profile/projects/add"
            component={() => (
              <Drawer>
                <CreateProjectForm />
              </Drawer>
            )}
          />
          <PrivateRoute
            path="/profile/projects/:id"
            component={() => (
              <Drawer>
                <UpdateProjectForm />
              </Drawer>
            )}
          />
          <PrivateRoute
            path="/profile/projects"
            component={() => (
              <Drawer>
                <ProjectListings />
              </Drawer>
            )}
          />
          <PrivateRoute
            path="/profile/tours/add"
            component={() => (
              <Drawer>
                <CreateToursForm />
              </Drawer>
            )}
          />
          <PrivateRoute
            path="/profile/tours/:id"
            component={() => (
              <Drawer>
                <UpdateTourForm />
              </Drawer>
            )}
          />
          <PrivateRoute
            path="/profile/feedback/view/:feedbackId"
            component={() => (
              <Drawer>
                <ViewFeedback />
              </Drawer>
            )}
          />
          <PrivateRoute
            path="/profile/feedback/:eventType/:eventId"
            component={() => (
              <Drawer>
                <FeedBackList />
              </Drawer>
            )}
          />
          <PrivateRoute
            path="/profile/tours"
            component={() => (
              <Drawer>
                <Tours />
              </Drawer>
            )}
          />
          <PrivateRoute
            path="/profile/users"
            component={() => (
              <Drawer>
                <Users />
              </Drawer>
            )}
          />
          <PrivateRoute
            path="/profile"
            component={() => (
              <Drawer>
                <PersonaInfo />
              </Drawer>
            )}
          />
          <PrivateRoute
            path="/update/payment"

            component={() => (
              <Drawer>
                <UpdatePayment />
              </Drawer>
            )}
          />
          {/* <PrivateRoute
            path="/email-consent"
            component={() => (
              <Drawer>
                <EmailConsent />
              </Drawer>
            )}
            /> */}
          <Route
            path="/email-consent"
          >
            <EmailConsent />
            {/* <Checkout /> */}
          </Route>
          <Route path="/checkout">
            <Checkout />
          </Route>
          <Route path="/cart">
            <Cart />
          </Route>
          <Route path="/faqs">
            <Faqs />
          </Route>
          <Route path="/latest-news">
            <LatestNews />
          </Route>
          <Route path="/about-us">
            <About />
          </Route>

          <Route path="/projects/:id">
            <ProjectDetails />
          </Route>
          <Route path="/tours/:id">
            <TourDetails />
          </Route>
          <Route exact={true} path="/events/projects/:id">
            <EventDetails />
          </Route>
          <Route exact={true} path="/events/tours/:id">
            <EventDetails />
          </Route>
          <Route path="/events">
            <Events />
          </Route>
          <PrivateRoute
            path="/provider-application"
            component={() => (
              <ProviderApplicationForm />
            )}
          />

          <Route path="/quick-feedback/project/:id">
            <QuickFeedBack eventType="project" />
          </Route>

          <Route path="/quick-feedback/tour/:id">
            <QuickFeedBack eventType="tour" />
          </Route>

          <Route path="/feedback/project/:id">
            <FeedBack eventType="project" />
          </Route>

          <Route path="/feedback/tour/:id">
            <FeedBack eventType="tour" />
          </Route>

          <Route path="/travel-and-safety/:location">
            <SafetyInformation />
          </Route>
          <Route path="/imprint">
            <Imprint />
          </Route>
          <Route path="/">
            <Index />
          </Route>
        </Switch>
        <ToastContainer />
      </DefaultLayout>
    </Router>
  );
}

export default App;
