import Axios from 'axios'
import { FunctionComponent, useEffect, useMemo } from 'react'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { RootState } from '../store'
import { paymentDue } from '../store/Cart/actions'
import { Col, Form, Row, Tab } from "react-bootstrap";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    ElementsConsumer,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from 'react-toastify'
import FileUpload from '../Components/FileUpload'
import { useHistory } from 'react-router-dom'

const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");
interface queryObject {
    [key: string]: any
}
export const UpdatePayment: FunctionComponent = () => {
    const { state, search } = useLocation()
    const dispatch = useDispatch()
    const history = useHistory();

    console.log("params: ", search, state)
    const [markUp, setMarkUp] = useState('')


    const useQuery = useMemo(() => {
        return new URLSearchParams(search);
    }, [search])
    const [processing, setProcessing] = useState(false);
    const [cardToken, setCardToken] = useState("");
    const handleSubmit = async (stripe: any, elements: any) => {
        if (!stripe || !elements) {
            return;
        }
        setProcessing(true);
        try {
            const cardNumberElement = await elements.getElement(CardNumberElement);
            const response = await stripe.createToken(cardNumberElement);
            if (response.token) {
                toast.success("Credit card validated successfully");
                setProcessing(false);
                setCardToken(response.token.id);
                return;
            } else if (response.error) {
                setProcessing(false);
                setCardToken("");
                toast.warn(response?.error?.message);
                return;
            }
            return;
        } catch (e) {
            console.log("stripe:error: ", e);
        }
    };
    useEffect(() => {
        // fetchData()
    }, [])

    const retryPayment = async () => {

        if (state) {
            const { reservationIDs, userEmail, userName, paymentDueId } = state
            await dispatch(paymentDue(paymentDueId, userEmail, userName, cardToken, paymentDueId, reservationIDs, history))
        } else {
            let query: queryObject = {}
            useQuery.forEach((value: any, key: any) => {
                query[key] = value;
            })
            const { paymentDueId, userEmail, userName, reservationIDs, } = query
            await dispatch(paymentDue(paymentDueId, userEmail, userName, cardToken, paymentDueId, reservationIDs, history))
        }
    }
    return (
        <>
            <div className="col paddlr0">
                <div className="col-lg-12 faqhead align-items-center d-flex com-font">
                    <div className="container">
                        <div className="faqheadtxt">Retry Payment</div>
                        {/* <div class="bgheadsmalltxt">Lorem ipsum dolor sit amet tida dino some text goes here</div> */}
                    </div>
                </div>
                < div className="container faqaccord faqconpaddup com-font paddlr0" >
                    <h4 className="com-font fw700 text-red">Payment details</h4>
                    <h6 className="com-font fw500 mb-3">
                        Small World Travel GmbH recommends you to enter your card
                        details to place order.
                    </h6>
                    <div>
                        <Elements stripe={stripe}>
                            <ElementsConsumer>
                                {({ stripe, elements }) => (
                                    <form>
                                        <Row>
                                            <Col lg={12}>
                                                <label>Enter Card Number</label>
                                                <CardNumberElement className="form-control resforminp" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} lg={6}>
                                                <label>Enter Card Expiry</label>
                                                <CardExpiryElement className="form-control resforminp" />
                                            </Col>
                                            <Col md={12} lg={6}>
                                                <label>Enter Card CVC</label>
                                                <CardCvcElement className="form-control resforminp" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <button
                                                    onClick={() => handleSubmit(stripe, elements)}
                                                    className="btn btn-default btnviewprojnext float-right"
                                                    type="button"
                                                >
                                                    Verify Card
                                                </button>
                                            </Col>
                                        </Row>
                                    </form>
                                )}
                            </ElementsConsumer>
                        </Elements>
                    </div>
                    {cardToken &&

                        <button
                            onClick={retryPayment}
                            className="btn btn-default btnviewprojnext float-right mt-2 mb-2"
                            type="button"
                        >
                            Retry Payment
                        </button>
                    }
                    {/* {cardToken && (
                        <div className="col-lg-12">
                            <FileUpload
                                label="Upload copy of passport*"
                                formatLabel="To secure payments to our local provider, legal restrictions require to safe a copy of the customer's ID card or passport."
                                multiple={true}
                                value={passportIdCard}
                                onChange={(val) =>
                                    setPassportIdCard(val as CloudinaryImg[])
                                }
                            />
                        </div>
                    )} */}


                </div >
            </div >

        </>
    )
}