interface HomeType {
  _id: string;
    hero_heading: string;
    hero_content: string;
  second_hero_img: any;
    first_hero_img: any;
    footer_item_3: any;
    footer_item_2: any;
    footer_item_1: any;
    project_section: any;
    vision_section: any;
  tour_section: any;
  upcoming_proj_and_tours: any;
}

export interface Home {
  _id: string;
    Hero: HomeType;
}

export interface HomeState {
  homeContent: Home;
}

export const SET_HOME_CONTENT = "SET_HOME_CONTENT";

export interface SetHomeAction {
  type: typeof SET_HOME_CONTENT;
  payload: Home;
}

export type HomeActionTypes = SetHomeAction;
