import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { forwardRef, useState } from "react";
import { getLocaleforDatePicker } from "../../util";

const ProjectDateRangePicker = ({ projectDetails, setSelectedDateRange, selectedDateRange }) => {
    console.assert("========================================================================ProjectDetails======================================================================== ", projectDetails);
    const onChange = (dates, j) => {
        const [start, end] = dates;
        console.log("second argument", j)
        setSelectedDateRange({ startDate: start, endDate: end })
    };
    let includeDates = []
    projectDetails.includedEventsDates?.filter(item => dayjs().isBefore(dayjs(item.date)) && includeDates.push(item))
    const renderDayContents = (i, j) => {
        const tmpArr = includeDates.find(item => dayjs(item.date).isSame(dayjs(j), 'date'));
        return <><span className="font-weight-bold">{i}</span><br /><span className="font-weight-light">{tmpArr?.capacity ? `${tmpArr?.capacity}/${projectDetails.capacity}` : null}</span></>
    }
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <input
            ref={ref}
            value={`${dayjs(selectedDateRange?.startDate).format("DD/MM/YYYY")} - ${dayjs(
                selectedDateRange?.endDate
            ).format("DD/MM/YYYY")}`}
            onClick={onClick}
            className="col form-control input-group-border-let-none"
            id="Date"
            aria-describedby="wishes"
        />
    ));
    return (
        <DatePicker
            includeDates={includeDates.map(item => dayjs(item.date).toDate())}
            renderDayContents={(i, j) => renderDayContents(i, j)}
            onChange={onChange}
            startDate={selectedDateRange.startDate}
            endDate={selectedDateRange.endDate}
            selectsRange
            allowSameDay={false}
            autoComplete={true}
            autoFocus={true}
            closeOnScroll={true}
            shouldCloseOnSelect={false}
            customInput={<CustomInput />}
            locale={getLocaleforDatePicker()}
        />
    );
};
export default ProjectDateRangePicker