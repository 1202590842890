import dayjs from "dayjs";
import { FunctionComponent } from "react";

interface PaymentInfoProps {
    draft: any
}
const PaymentInfo: FunctionComponent<PaymentInfoProps> = ({ draft }) => {
    return (
        <section className="form-wrap">
            <h4 className="com-font fw700 text-red text-capitalize">payment information</h4>
            <h5 className="fw700 text-capitalize mb-4">Account holder's information</h5>
            <div className="wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Owner's first name:</label>
                            <span>{draft.firstName}</span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Owner's last name:</label>
                            <span>{draft.lastName} </span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">date of birth:</label>
                            <span>{dayjs(draft.dateOfBirth).format('DD/MM/YYYY')}</span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="" className="font-weight-bold">Account Owner Address:</label>
                        <span>{draft.addressLine1}</span>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">City/Location:</label>
                            <span>{draft.city}</span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Postal code:</label>
                            <span>{draft.postalCode}</span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">State/province or region:</label>
                            <span>{draft.state}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PaymentInfo;
