import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axiosInstance from "../../utilities/axiosInstance";
import { SET_ALL_TOURS, SET_CALENDAR_DATA, SET_UPCOMING_TOURS, Tours, ToursActionTypes } from "./type";

export const setAllTours = (tours: Tours[]): ToursActionTypes => {
  return {
    type: SET_ALL_TOURS,
    payload: tours,
  };
};

export const setUpcomingTours = (tours: Tours[]): ToursActionTypes => {
  return {
    type: SET_UPCOMING_TOURS,
    payload: tours,
  };
};

export const setCalendarData = (data:any) => {
  return {
    type: SET_CALENDAR_DATA,
    payload: data,
  };
};

export const fetchAllTours = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch, getState) => {
  const { swt_role, id  } = getState().authReducer.UserDetails!
  const apiUrl = swt_role === 'swt_admin' ? "/api/tours" : `/api/tours/byUser/${id}`
  const res = await axiosInstance.get(apiUrl);
  dispatch(setAllTours(res.data.tours));
};

export const fetchUpcomingTours = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch) => {
  const res = await axiosInstance.get("/api/tours/active");
  dispatch(setUpcomingTours(res.data.tours));
};

export const createTours = (
  payload: Tours, isDraft = false
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,getState
) => {
  const { email } = getState().authReducer.UserDetails!;
  const res = await axiosInstance.post(`/api/tours`, { payload, notificationEmail: email, isDraft }, {
       headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
  });
  return res.data;
  };

export const fetchTourById = (
  projectID: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch
) => {
  const res = await axiosInstance.get(`/api/tours/${projectID}`);
  return res.data.tour;
};

export const UpdateTour = (
  id: string,
  payload: { updatedTour: Tours, availabilityUpdated: boolean }
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch
) => {
  const res = await axiosInstance.post(`/api/tours/${id}`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
  });
  return res.data;
  };

export const requestForPublishUnpublish = (
  tourId:string,
  available:boolean,
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch
) => {
  const res = await axiosInstance.post(`/api/tours/publish-unpublish/${tourId}`, {available}, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
  });
  return res.data;
  };

export const requestToConfirmTourInterval = (
  tourId:string,
  startDate: Date,
  endDate:Date
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch
) => {
  const res = await axiosInstance.post(`/api/tours/confirm-interval`, {
    tourId,
    startDate,
    endDate
  }, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
  });
  return res.data;
  };



export const deleteTourById = (
  tourId: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch
) => {
  const res = await axiosInstance.delete(`/api/tours/${tourId}`, {
     headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
  });
  return res.data;
  };
