import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { postQuickFeedBack } from "../../store/feedback";
import { fetchProjectById } from "../../store/Projects";
import { fetchTourById } from "../../store/Tours";
const QuickFeedBack: FunctionComponent<{ eventType: 'project' | 'tour' }> = ({ eventType }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [showSuccess, setShowSuccess] = useState(false)
  const [impression, setImpression] = useState(0)
  const [bookingExp, setBookingExp] = useState(0)
  const [eventName, setEventName] = useState(null)
  const { id } = useParams()

  const fetchEvent = async () => {
    try {
      const event: any = await dispatch(eventType === 'project' ? fetchProjectById(id) : fetchTourById(id))
      setEventName(eventType === 'project' ? event.projectName : event.tourName)
    } catch (error) {
      alert("Invalid Event")
    }
  }

  const onCloseModal = () => {
    history.push("/")
  }

  const postFeedBack = async () => {
    try {
      await dispatch(postQuickFeedBack({ eventId: id, eventType, impression, bookingExp }))
      setShowSuccess(true)
    } catch (error) {
      alert("Somethig went wrong")
    }
  }

  useEffect(() => {
    fetchEvent()
  }, [])

  const redirectToFeedback = () => {
    history.push(`/feedback/${eventType}/${id}`)
  }
  return (
    <>
      {
        showSuccess && (
          <Modal show={showSuccess} onHide={onCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title as="h5">Project Standards</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Thank you very much for your feedback. We really apreciate it!
                If you have five more minutes to spare you can continue answering the following questions. You can always submit this form at any time!
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onCloseModal}>Cancel</Button>
              <Button onClick={redirectToFeedback}>Okay</Button>
            </Modal.Footer>
          </Modal>
        )

      }
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-wrap paddresup sa-quickfeedback">
              <section className="sa-content com-font">
                <section className="form-wrap">
                  <h2 className="fw700 text-red text-capitalize">We appreciate your feedback for "{eventName}"</h2>
                  <p>Choose 5 stars when all your wishes have been fulfilled.</p>
                  <div className="sa-ratings mt-3">
                    <p className="mb-0">Your impression of the entire trip</p>
                    <ReactStars
                      count={5}
                      size={50}
                      isHalf={true}
                      value={impression}
                      onChange={(val: number) => setImpression(val)}
                      activeColor="#ffa500"
                    />
                  </div>
                  <div className="sa-ratings mt-3">
                    <p className="mb-0">How was your booking experience?</p>
                    <ReactStars
                      count={5}
                      size={50}
                      isHalf={true}
                      value={bookingExp}
                      onChange={(val: number) => setBookingExp(val)}
                      activeColor="#ffa500"
                    />
                  </div>
                  <button disabled={impression === 0 || bookingExp === 0} type="button" className="btn btn-default btnviewproj text-capitalize mt-3" onClick={postFeedBack}>
                    send
                  </button>
                </section>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuickFeedBack
