import dayjs from 'dayjs';
import { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { getFeedBackByEvent } from '../../../store/feedback';

const FeedBackList: FunctionComponent = () => {
  const dispatch = useDispatch()
  const { eventId, eventType } = useParams()
  const [feedbacks, setFeedbacks] = useState<any>([])

  const fetchFeedbacks = async () => {
    const feedbacks = await dispatch(getFeedBackByEvent(eventId, eventType))
    setFeedbacks(feedbacks)
  }
  useEffect(() => {
    fetchFeedbacks()
  }, [])

  return (
    <div className="col-lg-9 col-12">
      <div className="section-wrap paddresup sa-myreservations">
        <section className="sa-content com-font">
          <div className="row">
            <div className="col-lg-12">
              <div className="row mt-3 align-items-center">
                <div className="col-lg-6">
                  <h5 className="fw700 text-red text-capitalize mb-0">Feedbacks</h5>
                </div>
              </div>
              <div className="table-responsive  projectlisttabdiv">
                <table className="table projectlisttab  borderless text-center">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Date</th>
                      <th scope="col">Event Name</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      feedbacks.map((f: any, i: number) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{dayjs(f?.createdAt).format("YYYY-MM-DD")}</td>
                          <td>{f.eventName}</td>
                          <td>
                            <Link to={`/profile/feedback/view/${f.id}`}>View</Link>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default FeedBackList