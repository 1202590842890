import axios from "axios";

// const Local = "http://localhost:1337";
// const Server = "https://swt-cms.dev.smallworldtravel.de";

const axiosInstance = axios.create({
  // baseURL: "https://swt-cms.dev.smallworldtravel.de",
  baseURL: process.env.REACT_APP_SERVER_BASE_URL,

});

export default axiosInstance;
