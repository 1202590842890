import dayjs from 'dayjs'
import _ from 'lodash'
import React, { FunctionComponent, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { RootState } from '../../store'
import { Project } from '../../store/Projects'
import { setCurrentDayToDayProgram } from '../../store/SideBar'
import { Tours } from '../../store/Tours'
import { getEventType, getLatestBookingDate, getReviewArray } from '../../util'
import axiosInstance from '../../utilities/axiosInstance'
import calculateEventPrice from '../../utilities/calcProjectPrice'
import { useMediaQuery } from "react-responsive";
import { HashLink } from 'react-router-hash-link'

interface ProjectsSideBarProps {
    show: boolean,
    onClose: () => void
}
export const ProjectsSideBar: FunctionComponent<ProjectsSideBarProps> = ({ show, onClose }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation()
    const currentEvent = useSelector((state: RootState) => state.sideBar.currentEvent!)
    const Language = useSelector((state: RootState) => state.language.language?.content)
    const selectedLan = useSelector((state: RootState) => state.language.language?.language)
    const [dayToDay, setDayToDay] = useState()
    const [showPdf, setShowPdf] = useState(false)
    const event = useMemo(() => {
        if (!currentEvent) return
        const eventType = getEventType(currentEvent)
        const { id, startDate, location, price, consumerPricePerDay, consumerPrice, pricePerDay, accommodationPicture, transportationPicture, includedEventsDates } = currentEvent
        const eventStartDate = dayjs(getLatestBookingDate(startDate)).toDate();
        const eventEndDate = dayjs(getLatestBookingDate(startDate)).add(1, 'd').toDate();
        const commonProps = {
            id,
            startDate,
            location,
            price,
            pricePerDay,
            consumerPricePerDay,
            consumerPrice,
            includedEventsDates,
            eventType,
            images: [...accommodationPicture, ...transportationPicture]
        }
        if (eventType === "project") {
            const { projectName, projectPictures, projectDescription, projectDescription_de, projectDescription_es, category } = (currentEvent as Project)
            const basePrice = calculateEventPrice('project', 1, category, eventStartDate, eventEndDate, currentEvent, 0 )
            // console.log('---------base price', basePrice, eventStartDate, endDate)
            return {
                name: projectName,
                description: selectedLan == 'en' ? projectDescription : selectedLan == 'de' ? projectDescription_de : projectDescription_es,
                duration: 0,
                ...commonProps,
                images: [...projectPictures, ...commonProps.images].slice(0, 3),
                link: `/projects/${id}`,
                basePrice: basePrice.price
            }
        } else {
            const { tourName, tourPictures, tourDescription, duration, tourDescription_de, tourDescription_es, category } = (currentEvent as Tours)
            const basePrice = calculateEventPrice('tour', 1, category, eventStartDate, eventEndDate, currentEvent, 0 )
            return {
                name: tourName,
                description: selectedLan == 'en' ? tourDescription : selectedLan == 'de' ? tourDescription_de : tourDescription_es,
                // description: tourDescription,
                description_es: tourDescription_es,
                description_de: tourDescription_de,
                duration: duration,
                ...commonProps,
                images: [...tourPictures, ...commonProps.images].slice(0, 3),
                link: `/tours/${id}`,
                basePrice: basePrice.price

            }
        }
    }, [currentEvent, selectedLan])

    const isMobileView = useMediaQuery({ maxWidth: 991 });


    const width = isMobileView ? '100%' : '50%';


    // const style = { width: show ? location.pathname === '/events' ? '44%' : width : '0px', paddingRight: "1rem" }
    const style = { width: show ? location.pathname === '/events' ? isMobileView ? '100%' : width : width : '0px', paddingRight: "1rem" }
    const starArray = getReviewArray(2)
    if (!event) return <></>

    const onDaytoDayProgram = () => {
        onClose()
        // dispatch(setCurrentDayToDayProgram(currentEvent))
        // console.log('onCloseModal', currentEvent)
        const eventType = getEventType(currentEvent)
        history.push(`/events/${eventType}s/${currentEvent.id}`)
    }
    const onDaytoDayProgramPdf = async () => {

        const res = await axiosInstance.get(`/api/${event?.eventType}s/day-to-day/${event.id}`);
        setDayToDay(res.data.dayToDayPdf)
        setShowPdf(true)
    }
    let latestDate = null;
    if (event.includedEventsDates) {
        latestDate = _.sortBy(event.includedEventsDates, d => d.start).find(item => dayjs().isBefore(item.start) && item.capacity)
    }

    if (!show) return <></>



    return (
        <div className="sidenav com-font" style={style}>
            <Modal show={showPdf} onHide={() => setShowPdf(false)} className="day-to-day-modal d-flex justify-content-center">
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>

                    {dayToDay &&
                        <iframe
                            loading='lazy'
                            title="invoice"
                            width="600px"
                            height="550px"
                            src={`data:application/pdf;base64,${dayToDay}`}
                        />
                    }
                </Modal.Body>
            </Modal>
            <a href="javascript:void(0)" className="closebtn closex" onClick={onClose}>×</a>

            <div className="col-lg-12">
                <div className="row paddbot30">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="projectnavhead">
                                    {event.name}
                                </div>
                            </div>
                            {/* <div className="col-lg-2">
                                <div className="navprojicpadd">
                                    <img src="/images/plus-white-ic.svg" className="img-fluid maxhprojnav{" />
                                    <img src="/images/heart-white-ic.svg" className="img-fluid heartspi maxhprojnav{" />
                                </div>
                            </div> */}
                            <div className="col-lg-12">
                                <div className="navprojicpadd">
                                    <img alt="" src="/images/suv-car-white.svg" className="img-fluid " /> <span className="datesprojenav text-white">
                                        <span>{event.eventType === 'tour' ? latestDate && dayjs(latestDate.start).format('D MMMM') : getLatestBookingDate(event.startDate).format('D MMMM')}</span></span>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="navprojicpadd">
                                    <span className=" text-white mb-0 loctxt seppadd"><img alt="" src="/images/white-marker-ic.svg" className="img-fluid" /> {event.location}</span>
                                    {event?.eventType === 'tour' && <span className="text-white seppadd">{event?.duration} {Language?.general?.days}</span>}
                                    <span className="projpricenav text-white">
                                        {Language?.general?.from}   {event?.eventType === 'tour' ? `${event.basePrice}€` : `${event.basePrice}€/${Language?.general?.nights}`}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 
                    <div className="col-lg-12">
                        <div className='d-flex'>
                            <div className="col-lg-6 text-container">
                                <div className="text-white text-justify small text-truncate">
                                    {event.description}
                                </div>
                            </div>
                            <div className="col-lg-6 imgprojtour">
                                {
                                    event.images.map(img => (
                                        <div className="image-listitem">
                                            <img src={img.imageURL} alt={img.imageName} className="img-fluid listimg" />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    */}
                    <div className="col-lg-6">
                        <div className="text-white text-justify small">
                            {event.description}
                        </div>
                    </div>
                    <div className="col-lg-6 imgprojtour">
                        {
                            event.images.map(img => (
                                <div className="image-listitem">
                                    <img src={img.imageURL} alt={img.imageName} className="img-fluid listimg" />
                                </div>
                            ))
                        }
                    </div>
                    <div className="col-lg-12">
                        <div className="d-flex align-items-center pt-5 paddbooknownav">
                            <div className="col-lg-6">
                                <button onClick={onDaytoDayProgram} className="btn btn-link what-to-expect">{`${Language?.general?.what_to_expect}`}</button>
                            </div>
                            <div className="col-lg-6">
                                <HashLink
                                    onClick={onClose}
                                    scroll={(el: any) =>
                                        el.scrollIntoView({ behavior: "smooth", block: "center" })
                                    }
                                    to={`${event!.link}#details`}
                                    className="btn btn-block btnfinalbook"
                                    type="submit"
                                >
                                    {Language?.general?.book_now}
                                </HashLink>
                                <div className="custstar paddprojstar float-right d-none">
                                    <div className="star-ratingnews text-right">
                                        {starArray.map(checked => <span className={`fa fa-star ${checked ? 'checked' : ''}`} />)}
                                    </div>
                                    <div className="revlink">
                                        <a href="#" className="btn-link customerrevlink">{Language?.general?.customer_reviews}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    )
}