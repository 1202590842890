import Axios from "axios";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axiosInstance from "../../utilities/axiosInstance";
import { AboutActionTypes, About, SET_ABOUT_CONTENT } from "./type";

export const setAboutUs = (contents: About): AboutActionTypes => {
    return {
        type: SET_ABOUT_CONTENT,
        payload: contents
    }
}

export const fetchAboutUsContent = (appLanguage: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await axiosInstance.get(`/api/about-us?populate=About_Section.image,Bio_Section.image,Projects_Section.image,Tours_Section.image,Vision_Section.image&locale=${appLanguage}`);
    dispatch(setAboutUs(res.data.data.attributes))
}
