import React, { FunctionComponent, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../store";
import { deleteAccount, updatePaymentInfoDetails, updateUserProfile } from "../../../store/Auth";
import CompanyInfoSection, { ICompanyInfo } from "./CompanyInfoSection";
import PaymentInfoSection, { IPaymentInfo } from "./PaymentInfoSection";
import PersonalInfoSection, { IPersonalInfo } from "./PersonalInfoSection";

const PersonaInfo: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [showDeleteAccount, setShowDeleteAccount] = useState(false)
  const userDetails = useSelector(
    (rootState: RootState) => rootState.authReducer.UserDetails
  );
  const personalInfo: IPersonalInfo = {
    email: userDetails?.email!,
    firstName: userDetails?.firstName!,
    lastName: userDetails?.lastName!,
  };

  const companyInfo: ICompanyInfo = {
    companyInfo: userDetails?.companyInfo!,
    companyName: userDetails?.companyName!,
    companyNumber: userDetails?.companyNumber!,
    webAddress: userDetails?.webAddress!,
  };

  const paymentInfo: IPaymentInfo = {
    paymentReceiverFirstName: userDetails?.paymentReceiverFirstName!,
    paymentReceiverLastName: userDetails?.paymentReceiverLastName!,
    addressLine1: userDetails?.addressLine1!,
    city: userDetails?.city!,
    stateProvince: userDetails?.stateProvince!,
    postalCode: userDetails?.postalCode!,
    bankAccountId: userDetails?.bankAccountId!,
    bankName: userDetails?.bankName!,
    branchId: userDetails?.branchId!,
    country: userDetails?.country!,
    transferMethodCountry: userDetails?.transferMethodCountry!,
  };

  const onSubmit = (data: IPersonalInfo | ICompanyInfo | IPaymentInfo) => {
    console.log(data);
    // if(data.email&&data.email!==personalInfo.email){

    // }
    dispatch(updateUserProfile(data))
    // push('/')
  };

  const onSubmitPaymentInfo = (data: IPersonalInfo | ICompanyInfo | IPaymentInfo) => {
    console.log(data);
    dispatch(updatePaymentInfoDetails(data))
  };

  const confirmDelete = async () => {
    try {
      await dispatch(deleteAccount())
      push('/')
    }
    catch (e) {
      setShowDeleteAccount(false)
    }
  }
  const genralLan = useSelector(
    (rootState: RootState) => rootState.language.language?.content
  );
  return (
    <>
      {showDeleteAccount && (
        <SweetAlert
          title="Are You sure you want to delete"
          danger
          showCancel
          confirmBtnBsStyle="danger"
          onConfirm={confirmDelete}
          onCancel={() => setShowDeleteAccount(false)}
        />
      )}
      <div className="col-lg-9 col-12">
        <div className="section-wrap paddresup sa-personalinfo">
          {userDetails && (
            <>
              <PersonalInfoSection
                initialData={personalInfo}
                onSubmit={onSubmit}
              />
              {userDetails.swt_role === "provider" && (
                <>
                  <CompanyInfoSection userDetails={userDetails.companyName}
                    initialData={companyInfo}
                    onSubmit={onSubmit}
                  />
                  <PaymentInfoSection
                    initialData={paymentInfo}
                    onSubmit={onSubmitPaymentInfo}
                  />
                </>
              )}
            </>
          )}
          {/* <section className="form-wrap pb-3">
            <h4 className="com-font fw700 text-red text-capitalize">
              {genralLan?.general?.recomendation_id}
            </h4>
            <div className="sa-content com-font ">
              <p className="medium">
                {genralLan?.general?.recomendation_id_sub}
              </p>
              <div className="row">
                <div className="col-12">

                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#deleteModal"
                    className="btn btn-default btnviewprojnext float-right text-capitalize "
                    onClick={() => userDetails?.recomendationId && navigator.clipboard.writeText(userDetails?.recomendationId)}
                  >
                    {genralLan?.general?.recomendation_id_copy}

                  </button>
                </div>
              </div>
            </div>
          </section> */}
          <section className="form-wrap pb-3">
            <h4 className="com-font fw700 text-red text-capitalize ">
              {genralLan?.general?.delete_account}
            </h4>
            <div className="sa-content com-font">
              <p className="medium">
                {genralLan?.general?.delete_account_sub}
              </p>
              <button
                type="button"
                data-toggle="modal"
                data-target="#deleteModal"
                className="btn btn-default btnviewprojnext float-right text-capitalize"
                onClick={() => setShowDeleteAccount(true)}
              >
                {genralLan?.general?.delete_account}

              </button>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default PersonaInfo;
