import { yupResolver } from '@hookform/resolvers/yup';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import FacebookLogin from "react-facebook-login";
import { RootState } from '../../store';
import { sendOTP, setAuthErrorMSg, signUpLocal, setUserDetails, } from '../../store/Auth';
import { setCurrentSideBar, SideBarEnum } from '../../store/SideBar';
import axiosInstance from "../../utilities/axiosInstance";
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { toast } from "react-toastify";
import { useMediaQuery } from 'react-responsive';

interface SignUpSideBarProps {
    show: boolean,
    onClose: () => void
}

interface ISignUpInputs {
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    termsAgreed: boolean,
    privacyPolicy: boolean
}

const SignUpValidationSchema = yup.object().shape({
    firstName: yup.string().required("first name is required."),
    lastName: yup.string().required("last name is required."),
    email: yup.string().required().email("Invalid Email"),
    password: yup.string().required("password is required."),
    termsAgreed: yup.bool().isTrue(),
    privacyPolicy: yup.bool().isTrue()
});


export const SignUpSideBar: FunctionComponent<SignUpSideBarProps> = ({ show, onClose }) => {
    const dispatch = useDispatch()
    const isMobileView = useMediaQuery({ maxWidth: 700 });
    const style = { width: show ? isMobileView ? "100%" : "300px" : "0px" };
    const [otpSent, setOtpSent] = useState(false)
    const [otp, setOtp] = useState("")
    const [user, setUser] = useState<any>({});
    const onLoginClick = () => dispatch(setCurrentSideBar(SideBarEnum.Login))
    const errorMsg = useSelector((rootState: RootState) => rootState.authReducer.errorMsg)
    const footerContent = useSelector((rootState: RootState) => rootState.footer)
    const documentContent = useSelector((rootState: RootState) => rootState.documents.documentContent)

    const { register, handleSubmit, errors, getValues } = useForm<ISignUpInputs>({
        resolver: yupResolver(SignUpValidationSchema)
    })

    const onSubmit = async (data: ISignUpInputs) => {
        try {
            await dispatch(signUpLocal(getValues()))
            setOtpSent(true)
        }
        catch (e) {
            console.log("err in signup api: ", e);
        }
    }

    const onVerify = async (data: ISignUpInputs) => {
        try {
            await dispatch(sendOTP(data.email, otp))
            onClose();
            setOtpSent(false);
        }
        catch (e) {
            console.log('Otp verify api failed: ', e);
        }
    }

    useEffect(() => {
        return () => {
            dispatch(setAuthErrorMSg(""))
        }
    }, [])
    const authLan = useSelector((rootState: RootState) => rootState.language.language?.content?.signin!)

      const responseFacebook = async (response: any) => {
        try {
          const { data } = await axiosInstance.post("/api/auth/google", {
            ...response,
          });
          const { token, user } = data;
          localStorage.setItem("swt-token", token);
          dispatch(setUserDetails(user));
          onClose();
        } catch (error) {
          console.log(error);
        }
      };

      const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
      });
      
      useEffect(
        () => {
            if (Object.keys(user).length) {
                axios
                  .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user?.access_token}`, {
                      headers: {
                          Authorization: `Bearer ${user?.access_token}`,
                          Accept: 'application/json'
                      }
                  })
                  .then(async (res) => {
                    try {
                      const { data } = await axiosInstance.post("/api/auth/google", {...res.data });
                      const { token, user } = data;
                      localStorage.setItem("swt-token", token);
                      dispatch(setUserDetails(user));
                      onClose();
                      toast.success('Logged in successfully');
                      setUser({});
                    } catch (error) {
                      console.log(error);
                    }
                  })
                  .catch((err) => console.log(err));
            }
        },
        [ user ]
      );

    return (
        <div className="sidenav com-font" style={style}>
            <a href="javascript:void(0)" className="closebtn closex" onClick={onClose}>×</a>
            <div className="col-lg-12">
                <div className="searchfiltertxt txtup">{authLan?.sign_up}</div>
                <form onSubmit={handleSubmit(onSubmit)} style={{ display: otpSent ? 'none' : 'block' }}>
                    <div className="paddsearchfilup">
                        <div className="input-group">
                            <input type="text" placeholder={authLan?.first_name} className="form-control" name="firstName" ref={register} />
                        </div>
                        {errors.firstName && (<div className="invalid-feedback d-block">{errors.firstName.message}</div>)}
                    </div>
                    <div className="paddsearchfilup">
                        <div className="input-group">
                            <input type="text" placeholder={authLan?.last_name} className="form-control" name="lastName" ref={register} />
                        </div>
                        {errors.lastName && (<div className="invalid-feedback d-block">{errors.lastName.message}</div>)}
                    </div>
                    <div className="paddsearchfilup">
                        <div className="input-group">
                            <input type="text" placeholder={authLan?.email} className="form-control" name="email" ref={register} />
                        </div>
                        {errors.email && (<div className="invalid-feedback d-block">{errors.email.message}</div>)}
                    </div>
                    <div className="paddsearchfilup">
                        <div className="input-group">
                            <input type="password" placeholder={authLan?.password} className="form-control" name="password" ref={register} />
                        </div>
                        {errors.password && (<div className="invalid-feedback d-block">{errors.password.message}</div>)}
                    </div>
                    <div className="paddsearchfilup">
                        <div className="form-check">
                            <input type="checkbox" name="termsAgreed" ref={register} className="form-check-input" id="exampleCheck1" />
                            <label className="form-check-label small termscontxt" htmlFor="exampleCheck1">{authLan?.agree_to} <a href={documentContent?.terms_and_conditions.data.attributes?.url} target="_blank" rel="noreferrer" className="text-white"><u>{authLan?.terms_and_conditions}</u></a></label>
                        </div>
                        <div className="form-check">
                            <input type="checkbox" name="privacyPolicy" ref={register} className="form-check-input" id="exampleCheck2" />
                            <label className="form-check-label small termscontxt" htmlFor="exampleCheck2">{authLan?.agree_to} <a href={documentContent?.privacy_policy.data.attributes?.url} target="_blank" rel="noreferrer" className="text-white"><u>{authLan?.privacy_policy}</u></a></label>
                        </div>
                        <div className="form-check">
                            <input type="checkbox" name="receiveEmails" ref={register} className="form-check-input" id="receiveEmails" />
                            <label className="form-check-label small termscontxt" htmlFor="receiveEmails">{authLan?.agree_to} <a href={documentContent?.consent_promo_emails.data?.attributes?.url} target="_blank" rel="noreferrer" className="text-white"><u>{authLan?.receive_permotions}</u></a></label>
                        </div>
                        {errors.termsAgreed && (<div className="invalid-feedback d-block">{authLan?.terms_and_conditions_err}</div>)}
                        {errors.privacyPolicy && (<div className="invalid-feedback d-block">{errors.privacyPolicy.message}</div>)}

                    </div>
                    <div className="paddsearchfilup text-center">
                        <button className="btn btn-sm btn-default combtnclick btn-block" type="submit">{authLan?.sign_up}</button>
                        <span className="text-white small text-center accopentxt" onClick={onLoginClick}>{authLan?.have_account}</span>
                    </div>
                </form>
                <form onSubmit={handleSubmit(onVerify)} style={{ display: otpSent ? 'block' : 'none' }}>
                    <div className="small signintxt">{authLan?.check_email}</div>
                    <div className="small signintxt">{authLan?.enter_otp_desc}</div>
                    <div className="paddsearchfilup">
                        <div className="input-group">
                            <input type="text" placeholder={authLan?.enter_otp_place} className="form-control" name="OTP" value={otp} onInput={e => setOtp((e.target as HTMLInputElement).value)} />
                        </div>
                    </div>
                    <div className="paddsearchfilup text-center">
                        <button className="btn btn-sm btn-default combtnclick btn-block" type="submit">{authLan?.submit}</button>
                    </div>
                </form>
                {errorMsg && (<div className="invalid-feedback d-block">{errorMsg}</div>)}
                <div className="paddsearchfilup">
                    <div className="orsignuptxt txtup">{authLan?.or}</div>
                    <div className="paddsearchfilup">
                        <button className="my-google-button-class btn btn-sm btn-default btn-block bg-white" onClick={() => login()}><img src="images/google-ic.svg" className="img-fluid mr-2" alt="" /> Sign in with Google</button>
                    </div>
                    {/* <div className="paddsearchfilup">
                        <FacebookLogin
                            appId={process.env.REACT_APP_FACEBOOK_APP_ID || ""}
                            autoLoad={false}
                            fields="name,email,picture"
                            callback={responseFacebook}
                            cssClass="my-facebook-button-class btn btn-sm btn-default btn-block"
                            icon="fa-facebook"
                            textButton="Sign in with Facebook"
                        />
                    </div> */}
                </div>
            </div>
        </div>
    )
}
