import { yupResolver } from '@hookform/resolvers/yup';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from "yup";
import { RootState } from '../../../store';
import { changeUserPassword } from '../../../store/Auth';

const PasswordInfoSchema = yup.object().shape({
    currentPassword: yup.string()
        .required("This is a required field "),
    newPassword: yup.string()
        .required("This is a required field "),
    confirm: yup.string()
        .oneOf([yup.ref("newPassword")], "Confirm password must match with new password")
        .required("This is a required field"),
});

interface PasswordChangeSectionProps {
}

export interface IPasswordInfo {
    currentPassword: string,
    newPassword: string,
    confirm: string
}

const initialData: IPasswordInfo = {
    currentPassword: '',
    newPassword: '',
    confirm: ''
};

const PasswordChangeSection: FunctionComponent<PasswordChangeSectionProps> = () => {

    const history = useHistory();

    const { register, handleSubmit, errors, setValue } = useForm<IPasswordInfo>({
        resolver: yupResolver(PasswordInfoSchema),
        defaultValues: initialData
    })
    const dispatch = useDispatch();

    const _ChangePassword = async (data: IPasswordInfo) => {
        try {
            await dispatch(changeUserPassword(data, history));

        }
        catch (e: any) {
            console.log('error in change password api: ', e.response.data.message);
        }
    }
    const genralLan = useSelector(
        (rootState: RootState) => rootState.language.language?.content
    );
    return (
        <form className="respadding com-font" onSubmit={handleSubmit(_ChangePassword)}>
            <div className="row">
                <div className="col-lg-6">
                    <input type="text" className="form-control resforminp" name="currentPassword" ref={register} id="actualpassword" placeholder={genralLan?.general?.actual + ' ' + genralLan?.general?.password} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <input type="text" className="form-control resforminp" name="newPassword" ref={register} id="newpassword" placeholder={genralLan?.general?.new + ' ' + genralLan?.general?.password} />
                </div>
                <div className="col-lg-6">
                    <input type="text" className="form-control resforminp" name="confirm" ref={register} id="confirmnewpassword" placeholder={genralLan?.general?.actual + ' ' + genralLan?.general?.actual + ' ' + genralLan?.general?.password} />
                </div>
                <div className="col-lg-12">
                    <button type="submit" data-toggle="modal" data-target="#changePasswordModal" className="btn btn-default btnviewprojnext float-right text-capitalize">
                        {genralLan?.general?.change + ' ' + genralLan?.general?.password}
                    </button>
                </div>
            </div>

        </form>
    )

}
export default PasswordChangeSection; 