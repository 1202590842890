import dayjs from "dayjs";
import { useState } from "react";
import { FunctionComponent } from "react";
import { Tab } from "react-bootstrap";
import PaymentInfo from "../../../../Components/provider-details/PaymentInfo";
import ProjectInfo from "../../../../Components/provider-details/ProjectInfo";
import TourInfo from "../../../../Components/provider-details/TourInfo";

interface ProviderRequestInfoProps {
    draft: any
}
const ProviderRequestInfo: FunctionComponent<ProviderRequestInfoProps> = ({ draft }) => {
    const [selectedTab, setSelectedTab] = useState("payment")
    return (
        <Tab.Container
            activeKey={selectedTab}
            onSelect={(key) => setSelectedTab(key!)}
        >
            <Tab.Content>
                <Tab.Pane eventKey="payment">
                    <PaymentInfo draft={draft} />
                </Tab.Pane>
                <Tab.Pane eventKey="event">
                    {
                        draft.type === "project" ?
                            <ProjectInfo draft={draft} />
                            : <TourInfo draft={draft} />
                    }
                </Tab.Pane>
            </Tab.Content>
            <div className="row">
                <button onClick={() => setSelectedTab('payment')} className="btn btn-default btnviewproj text-capitalize mt-3">
                    View Payment Info
                </button>
                <button onClick={() => setSelectedTab('event')} className="btn btn-default btnviewproj text-capitalize mt-3">
                    View {draft.type} Info
                </button>
            </div>
        </Tab.Container>
    );
};

export default ProviderRequestInfo;
