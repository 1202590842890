import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { getIndexOfDayInWeek } from './helpers';


function MonthlyStack({ startDate, setRepeatMonthly, Language }) {

    const [selectors, setSelectors] = useState([]);

    useEffect(() => {
        if (startDate) {
            const weekOfMonth = getIndexOfDayInWeek(startDate);
            const todayDate = dayjs(startDate).date();
            setSelectors([
                { id: 1, name: `${Language?.general?.monthly_on} ${Language?.general?.day} ${todayDate}`, value: `MonthlyOnDate` },
                {
                    id: 2, name: `${Language?.general?.monthly_on} ${weekOfMonth} ${dayjs(startDate).format('dddd')}`,
                    value: `MonthlyOnWeekCount`
                },
            ])
        }
    }, [startDate]);


    const _HandleChangeRepeatition = ({ target }) => {
        const { value } = target;
        setRepeatMonthly(value);
    }

    return (
        <Form.Group as={Col} className="mb-3">
            <Row>
                <Col className=" d-flex flex-row justify-content-between align-items-center mt-3 ">
                    <select onChange={(e) => _HandleChangeRepeatition(e)}>
                        {
                            selectors && selectors.map(({ id, name, value }) => (
                                <option key={id} value={value}>{name}</option>
                            ))
                        }
                    </select>
                </Col>
            </Row>
        </Form.Group>
    )
}

export default MonthlyStack