import { ProjectsActionTypes, ProjectsState, SET_SELECTED_PROJECT, SET_UPCOMING_PROJECTS, SET_ALL_PROJECTS } from "./type"

const initialState: ProjectsState = {
    UpcomingProjects: [],
    selectedProject: undefined,
    AllProjects: []
}

export function projectsReducer(state = initialState, action: ProjectsActionTypes): ProjectsState {
    switch (action.type) {
        case SET_UPCOMING_PROJECTS:
            return {
                ...state,
                UpcomingProjects: action.payload
            }
        case SET_SELECTED_PROJECT:
            return {
                ...state,
                selectedProject: action.payload
            }
        case SET_ALL_PROJECTS:
            return {
                ...state,
                AllProjects: action.payload
            }
        default:
            return state
    }
}