import { DraftState, SET_DRAFTS, DraftsActionTypes } from "./type"

const initialState: DraftState = {
    drafts: []
}

export function draftsReducer(state = initialState, action: DraftsActionTypes): DraftState {
    switch (action.type) {
        case SET_DRAFTS:
            return {
                ...state,
                drafts: action.payload
            }
        default:
            return state
    }
}