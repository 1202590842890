import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router";
import { RootState } from "../store";
import { fetchLoggedinUserDetails } from "../store/Auth";

export const PrivateRoute = (props: any) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const userDetails = useSelector((rootState: RootState) => rootState.authReducer.UserDetails)
  
  const { component: Component, ...rest } = props;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(!userDetails) await dispatch(fetchLoggedinUserDetails());
  
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false)
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <Route
      {...rest}
      render={() =>
        isAuthenticated ? (
          <Component {...props} />
        ) : loading ? (
          <div>LOADING...</div>
        ) : (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        )
      }
    />
  );
};
