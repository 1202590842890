import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import { CheckoutDetails } from "../../types/CheckoutDetails";
import axiosInstance from "../../utilities/axiosInstance";
import { fetchLoggedinUserDetails } from "../Auth";
import {
  CartActionTypes, CartItem, ICart, IReservation, SET_CART_ITEMS,
  SET_RESERVATIONS, ToursCartItem
} from "./type";

export const setCartItems = (cart: ICart): CartActionTypes => {
  console.log('from actions', cart)
  return {
    type: SET_CART_ITEMS,
    payload: cart,
  };
};

export const setReservations = (
  reservations: IReservation[]
): CartActionTypes => {
  return {
    type: SET_RESERVATIONS,
    payload: reservations,
  };
};

export const fetchCartDetails = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch, getState) => {
  const userId = getState().authReducer.UserDetails?.id;
  try {
    const res = await axiosInstance.get(`/api/cart/${userId}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    });
    res.data.cart[0].items = res.data.cart[0].items.filter(
      (i: Object) => !(Object.keys(i).length === 0 && i.constructor === Object)
    );
    dispatch(setCartItems(res.data.cart[0]));
  }
  catch (e) {
    console.log('API Failed', e);
  }
};

export const addItemToCart = (
  cartItem: CartItem
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
    const userId = getState().authReducer.UserDetails?.id;


    let tmpCart = {}
    tmpCart = cartItem.startDate ? { ...cartItem, startDate: cartItem.startDate.toDateString(), endDate: cartItem.endDate.toDateString() } : tmpCart = { ...cartItem }
    await axiosInstance.post(`/api/cart/${userId}`, tmpCart, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    });
    dispatch(fetchCartDetails());
  };
export const addTourItemToCart = (
  cartItem: ToursCartItem
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
    const userId = getState().authReducer.UserDetails?.id;
    let tmpCart = {}
    tmpCart = cartItem.startDate ? { ...cartItem, startDate: cartItem.startDate.toDateString(), endDate: cartItem.endDate.toDateString() } : tmpCart = { ...cartItem }

    await axiosInstance.post(`/api/cart/${userId}`, tmpCart, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    });
    dispatch(fetchCartDetails());
  };
export const deleteItemFromCart = (
  projectId: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
    const userId = getState().authReducer.UserDetails?.id;
    await axiosInstance.post(`/api/cart/project/${userId}`, {
      projectId,
    }, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    });
    dispatch(fetchCartDetails());
  };

export const deleteTourItemFromCart = (
  tourId: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
    const userId = getState().authReducer.UserDetails?.id;
    await axiosInstance.post(`/api/cart/tour/${userId}`, {
      tourId
    }, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    });
    dispatch(fetchCartDetails());
  };

export const updateItemToCart = (
  cartItem: CartItem
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
    const userId = getState().authReducer.UserDetails?.id;
    let tmpCart = {}
    tmpCart = cartItem.startDate ? { ...cartItem, startDate: cartItem.startDate.toDateString(), endDate: cartItem.endDate.toDateString() } : tmpCart = { ...cartItem }
    await axiosInstance.put(`/api/cart/${userId}`, {
      items: [tmpCart],
    }, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    });
    dispatch(fetchCartDetails());
  };
export const updateTourItemToCart = (
  cartItem: ToursCartItem
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
    const userId = getState().authReducer.UserDetails?.id;
    let tmpCart = {}
    tmpCart = cartItem.startDate ? { ...cartItem, startDate: cartItem.startDate.toDateString(), endDate: cartItem.endDate.toDateString() } : tmpCart = { ...cartItem }
    await axiosInstance.put(`/api/cart/${userId}`, {
      items: [tmpCart],
    }, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    });
    dispatch(fetchCartDetails());
  };
export const paymentDue = (
  paymentDueId: any,
  userEmail: string | undefined,
  userName: string,
  cardToken: string,
  orderNumber: string,
  reservationIDs: any[],
  history: any
)
  : ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    try {
      const { data: { message } } = await axiosInstance.post("/api/payment-due/payments", {
        description: "SWT test payment",
        paymentDueId: paymentDueId,
        userEmail: userEmail,
        userName: userName,
        cardToken: cardToken,
        orderNumber: paymentDueId,
        reservationIDs: reservationIDs
      }, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('swt-token')
        }
      });
      toast.success(message);
      dispatch(fetchCartDetails());
      history.push('/profile/my-reservations');
    }
    catch (e: any) {
      console.log('payment api failed:----- ', e.response.data.message);
      dispatch(fetchCartDetails());
      if (!e?.response?.data?.data?.chargeStatus) {
        history.push({
          pathname: '/update/payment',
          state: { ...e.response.data.data, paymentDueId }
        });
      }
      toast.error(e.response.data.message);
      return e
    }
  }
export const createReservation = (
  checkoutDetails: CheckoutDetails,
  cardToken: string,
  history: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
    try {
      const user = getState().authReducer.UserDetails;
      const reservationRes = await axiosInstance.post("/api/reservations", checkoutDetails, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('swt-token')
        }
      });
      if (reservationRes.status === 200) {
        await dispatch(paymentDue(reservationRes?.data?.paymentDueId,
          user?.email, user?.firstName + " " + user?.lastName, cardToken,
          reservationRes?.data?.paymentDueId, reservationRes?.data?.reservationIDs, history))
      }
    } catch (err) {
      console.log('failed to create reservation: ' + err);
    }
  }

export const fetchInvoiceByReservation = (id: string, status: string): ThunkAction<void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch, getState) => {
  try {

    let user = getState().authReducer.UserDetails;
    if (!user) {
      await dispatch(fetchLoggedinUserDetails());
    }
    user = getState().authReducer.UserDetails;
    const url = `/api/invoice/genrate-invoice`
    const invoiceRes = await axiosInstance.get(url, {
      params: {
        id,
        status
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }

    })
    return invoiceRes.data.invoicePdf
  } catch (err) {
    console.log('error', err)
  }
}
export const fetchMyReservations = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch, getState) => {
  let user = getState().authReducer.UserDetails;
  if (!user) {
    await dispatch(fetchLoggedinUserDetails());
  }
  user = getState().authReducer.UserDetails;
  const url =
    user?.swt_role === "swt_admin"
      ? "/api/reservations"
      : `/api/reservations/${user?.swt_role}/${user?.id}`;
  const reservationRes = await axiosInstance.get(url, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem('swt-token')
    }
  });
  dispatch(setReservations(reservationRes.data.reservations));
};

export const cancelReservations = (
  id: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
    try {
      const { data: { message } } = await axiosInstance.delete(`/api/reservations/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('swt-token')
        }
      });
      toast.success(message);
    }
    catch (e: any) {
      toast.error(e.response.data.message);
    }
  };

export const getAllReservationsOfSpecificProject = (
  id: number,
  selectedStartDate: Date,
  selectedEndDate: Date,
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
    const res = await axiosInstance.post(`/api/all-reservations/${id}`, {
      selectedStartDate, selectedEndDate
    },
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem('swt-token')
      //   }
      // }
    );
    return res
  };
export const getAllCapacitiesOfProj = (
  id: number,
  selectedStartDate: Date,
  selectedEndDate: Date,
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
    const res = await axiosInstance.post(`/api/project-reservations/${id}`, {
      selectedStartDate, selectedEndDate
    },
      // {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem('swt-token')
      //   }
      // }
    );
    console.log('res here', res)
    return res
  };
