import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import React, { FunctionComponent, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { CloudinaryImg } from "../../store/Tours";
import Countries from "../../utilities/countries";
import FileUpload from "../FileUpload";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
export interface PaymentInfoFormInput {
  paymentReceiverFirstName: string;
  paymentReceiverLastName: string;
  addressLine1: string;
  city: string;
  postalCode: string;
  stateProvince: string;
  country: string;
  passportIdCard: CloudinaryImg[];
  businessCategory: string;
  businessName: string;
  accountAddress: string;
  accountCity: string;
  accountPostalCode: string;
  accountCountry: string;
  accountStateProvince: string;
  companyCountry: string;
  bankAccountId: string;
  branchId: string;
  bankName: string;
  transferMethodCountry: string;
  swift: string;
  accountCode: string;
  dateOfBirth: string;
  providerContract: boolean;
  privacyPolicy: boolean;
  mobleMoneyType: boolean;
  mobleMoneyNo: boolean;

}

const PaymentInfoInputSchema = yup.object().shape({
  paymentReceiverLastName: yup.string().required(),
  paymentReceiverFirstName: yup.string().required(),
  addressLine1: yup.string().required(),
  dateOfBirth: yup
    .string()
    .test("DOB", "Date of Birth Should be over 18", (value) => {
      return dayjs().diff(dayjs(value), "year") >= 18;
    }),
  city: yup.string().required(),
  postalCode: yup.string().required(),
  stateProvince: yup.string().required(),
  country: yup.string().required(),
  passportIdCard: yup.object().nullable(),

  businessName: yup.string().required(),
  accountAddress: yup.string().required(),
  accountCity: yup.string().required(),
  accountPostalCode: yup.string().required(),
  accountStateProvince: yup.string().required(),
  accountCountry: yup.string().required(),

  bankAccountId: yup.string(),
  branchId: yup.string(),
  bankName: yup.string(),
  transferMethodCountry: yup.string().required(),
  swift: yup.string(),
  accountCode: yup.string(),
  privacyPolicy: yup.bool().isTrue().required(),
  providerContract: yup.bool().isTrue().required(),
  mobleMoneyType: yup.string(),
  mobleMoneyNo: yup.string(),
});

interface PaymentInfoFormProps {
  initialData: PaymentInfoFormInput;
  onPrev: () => void;
  onStartOver?: () => void;
  onSave: (data: any, shoudSaveDraft: boolean) => void;
  createAccount: (data: any) => void;
}
const PaymentInfoForm: FunctionComponent<PaymentInfoFormProps> = ({
  initialData,
  onPrev,
  onSave,
  onStartOver,
  createAccount,
}) => {
  const { register, handleSubmit, errors, getValues } =
    useForm<PaymentInfoFormInput>({
      resolver: yupResolver(PaymentInfoInputSchema),
      defaultValues: initialData,
    });

  const [providerContractCheck, setProviderContractCheck] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [selfDeclaration, setSelfDeclaration] = useState(false);
  const [passportIdCard, setPassportIdCard] = useState<CloudinaryImg[]>(
    initialData?.passportIdCard || []
  );
  const [picError, setPicError] = useState<boolean>(false);
  const [branchId, setBranchIdError] = useState<boolean>(false);
  const [account, setAccountError] = useState<boolean>(false);
  // const [diffCountry, setDiffCountryErr] = useState<boolean>(false);

  const onSaveDraft = () => {
    const data = getValues();
    console.log("data to send", data);
    onSave({ ...data, passportIdCard }, true);
  };
  console.log("onSave", getValues());
  const _createAccount = (data: any) => {
    // if (
    //   initialData.companyCountry !== data.transferMethodCountry || 
    //   initialData.companyCountry !== data.country || 
    //   data.transferMethodCountry !== data.country
    // ) {
    //   return setDiffCountryErr(true)
    // }
    // setDiffCountryErr(false);
    if (!passportIdCard.length) {
      setPicError(true);
      return;
    } else if (
      initialData.businessCategory == "company" &&
      !getValues("branchId")
    ) {
      setBranchIdError(true);
      return
    } else if (initialData.businessCategory == 'company' && !getValues('bankAccountId')) {
      setAccountError(true);
      return
    }
    createAccount({ ...data, passportIdCard });
  };
  const documentContent = useSelector((rootState: RootState) => rootState.documents.documentContent)
  const paymentLan = useSelector(
    (rootState: RootState) => rootState.language.language?.content?.payment!
  );
  const Language = useSelector(
    (rootState: RootState) => rootState.language.language?.content!
  );
  return (
    <>
      <form className="sa-custom-form" onSubmit={handleSubmit(_createAccount)}>
        <div className="row">
          <h4 className="com-font fw700 text-red text-capitalize">
            {paymentLan?.payout_info}
          </h4>
          <div className="col-lg-12 paddlr0 com-font">
            <p className="small fw700">
              {paymentLan?.payout_info_sub}

            </p>
            <p className="small fw700">
              {paymentLan?.payout_info_sub2}
            </p>
            <h5 className="fw700">
              {paymentLan?.account_holder_info}
            </h5>
            <p className="small">
              {paymentLan?.account_holder_info_sub}
            </p>
            <div className="form-group">
              <div className="row respadding">
                <div className="col-lg-6">
                  <label
                    htmlFor="firstName"
                    className="mb-0 small text-capitalize"
                  >
                    {paymentLan?.owner_firstname}
                  </label>
                  <input
                    type="text"
                    className="form-control resforminp mt-0"
                    id="firstName"
                    name="paymentReceiverFirstName"
                    placeholder={paymentLan?.owner_firstname_place}
                    ref={register}
                  />
                  {errors.paymentReceiverFirstName && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.owner_firstname_err}
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor="lastName"
                    className="mb-0 small text-capitalize"
                  >
                    {paymentLan?.owner_lastname}
                  </label>
                  <input
                    type="text"
                    className="form-control resforminp mt-0"
                    id="lastName"
                    name="paymentReceiverLastName"
                    placeholder={paymentLan?.owner_lastname_place}
                    ref={register}
                  />
                  {errors.paymentReceiverLastName && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.owner_lastname_err}
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  <label htmlFor="dob" className="mb-0 small text-capitalize">
                    {paymentLan?.date_of_birth}
                  </label>
                  <input
                    type="date"
                    className="form-control resforminp mt-0"
                    id="dateOfBirth"
                    name="dateOfBirth"
                    placeholder={paymentLan?.date_of_birth_place}
                    ref={register}
                  />
                  {errors.dateOfBirth && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.date_of_birth_err}
                    </div>
                  )}
                </div>
                <div className="col-lg-12">
                  <label
                    htmlFor="addressLine1"
                    className="mb-0 small text-capitalize"
                  >
                    {paymentLan?.account_owner_address}
                  </label>
                  <input
                    type="text"
                    className="form-control resforminp mt-0"
                    id="addressLine1"
                    name="addressLine1"
                    placeholder={paymentLan?.account_owner_address_place}
                    ref={register}
                  />
                  {errors.addressLine1 && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.account_owner_address_err}
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  <label htmlFor="city" className="mb-0 small text-capitalize">
                    {paymentLan?.city}
                  </label>
                  <input
                    type="text"
                    className="form-control resforminp mt-0"
                    id="city"
                    name="city"
                    placeholder={paymentLan?.city_place}
                    ref={register}
                  />
                  {errors.city && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.city_err}
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor="postalCode"
                    className="mb-0 small text-capitalize"
                  >
                    {paymentLan?.postal_code}
                  </label>
                  <input
                    type="text"
                    className="form-control resforminp mt-0"
                    id="postalCode"
                    name="postalCode"
                    placeholder={paymentLan?.postal_code_place}
                    ref={register}
                  />
                  {errors.postalCode && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.postal_code_err}
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor="stateProvince"
                    className="mb-0 small text-capitalize"
                  >
                    {paymentLan?.state}
                  </label>
                  <input
                    type="text"
                    className="form-control resforminp mt-0"
                    id="stateProvince"
                    name="stateProvince"
                    placeholder={paymentLan?.state_place}
                    ref={register}
                  />
                  {errors.stateProvince && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.state_err}
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor="country"
                    className="mb-0 small text-capitalize text-capitalize"
                  >
                    {paymentLan?.country}
                  </label>
                  <select
                    className="form-control resforminp mt-0"
                    id="country"
                    name="country"
                    ref={register}
                  >
                    <option selected disabled value="">
                      {paymentLan?.residence_country_place}
                    </option>
                    {Countries.map((c) => (
                      <option value={c.name}>{c.name}</option>
                    ))}
                  </select>
                  {errors.country && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.country_err}
                    </div>
                  )}
                </div>
                <div className="col-lg-12">
                  <FileUpload
                    label={paymentLan?.passport}
                    formatLabel={paymentLan?.passport_place}
                    multiple={true}
                    value={passportIdCard}
                    onChange={(val) => {
                      setPassportIdCard(val as CloudinaryImg[]);
                      setPicError(false);
                    }}
                  />

                  {picError && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.passport_err}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <h4 className="com-font fw700 text-red text-capitalize">
            {paymentLan?.bank_info}
          </h4>
          <div className="col-lg-12 paddlr0 com-font">
            <h5 className="fw700">{paymentLan?.bank_account_holder_info}</h5>
            <p className="small">{paymentLan?.bank_account_holder_info_sub}</p>
            <div className="form-group">
              <div className="row respadding">
                <div className="col-lg-6">
                  <label
                    htmlFor="businessName"
                    className="mb-0 small text-capitalize"
                  >
                    {paymentLan?.bank_holder_name}
                  </label>
                  <input
                    type="text"
                    className="form-control resforminp mt-0"
                    id="businessName"
                    name="businessName"
                    placeholder={paymentLan?.bank_holder_name_place}
                    ref={register}
                  />
                  {errors.businessName && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.bank_holder_name_err}
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor="accountAddress"
                    className="mb-0 small text-capitalize"
                  >
                    {paymentLan?.bank_holder_address}
                  </label>
                  <input
                    type="text"
                    className="form-control resforminp mt-0"
                    id="accountAddress"
                    name="accountAddress"
                    placeholder={paymentLan?.bank_holder_address_place}
                    ref={register}
                  />
                  {errors.accountAddress && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.bank_holder_address_err}
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor="accountCity"
                    className="mb-0 small text-capitalize"
                  >
                    {paymentLan?.city}
                  </label>
                  <input
                    type="text"
                    className="form-control resforminp mt-0"
                    id="accountCity"
                    name="accountCity"
                    placeholder={paymentLan?.city}
                    ref={register}
                  />
                  {errors.accountCity && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.city}
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor="accountPostalCode"
                    className="mb-0 small text-capitalize"
                  >
                    {paymentLan?.postal_code}
                  </label>
                  <input
                    type="text"
                    className="form-control resforminp mt-0"
                    id="accountPostalCode"
                    name="accountPostalCode"
                    placeholder={paymentLan?.postal_code_place}
                    ref={register}
                  />
                  {errors.accountPostalCode && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.postal_code_err}
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor="accountStateProvince"
                    className="mb-0 small text-capitalize"
                  >
                    {paymentLan?.bank_holder_state}
                  </label>
                  <input
                    type="text"
                    className="form-control resforminp mt-0"
                    id="accountStateProvince"
                    name="accountStateProvince"
                    placeholder={paymentLan?.bank_holder_state_place}
                    ref={register}
                  />
                  {errors.accountStateProvince && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.bank_holder_state_err}
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor="accountCountry"
                    className="mb-0 small text-capitalize text-capitalize"
                  >
                    {paymentLan?.bank_country}
                  </label>
                  <select
                    className="form-control resforminp mt-0"
                    id="accountCountry"
                    name="accountCountry"
                    ref={register}
                  >
                    <option selected disabled value="">
                      {paymentLan?.bank_country_place}
                    </option>
                    {Countries.map((c) => (
                      <option value={c.name}>{c.name}</option>
                    ))}
                  </select>
                  {errors.accountCountry && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.country}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 paddlr0 com-font">
            <h5 className="fw700">{paymentLan?.bank_account_info}</h5>
            <p className="small">{paymentLan?.bank_info_of_bank_account_holder}</p>
            <div className="form-group">
              <div className="row respadding">
                <div className="col-lg-6">
                  <label
                    htmlFor="bankAccountId"
                    className="mb-0 small text-capitalize"
                  >
                    {paymentLan?.account_number}
                  </label>
                  <input
                    type="text"
                    className="form-control resforminp mt-0"
                    id="bankAccountId"
                    placeholder={paymentLan?.account_number_place}
                    name="bankAccountId"
                    ref={register}
                  />
                  {account && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.account_number_err}
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor="branchId"
                    className="mb-0 small text-capitalize"
                  >
                    {paymentLan?.aba_rtn}
                  </label>
                  <input
                    type="text"
                    className="form-control resforminp mt-0"
                    id="branchId"
                    placeholder={paymentLan?.aba_rtn_place}
                    name="branchId"
                    ref={register}
                  />
                  {branchId && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.aba_rtn_err}
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor="bankName"
                    className="mb-0 small text-capitalize"
                  >
                    {paymentLan?.bank_name}
                  </label>
                  <input
                    type="text"
                    className="form-control resforminp mt-0"
                    id="bankName"
                    placeholder={paymentLan?.bank_name_place}
                    name="bankName"
                    ref={register}
                  />
                  {errors.bankName && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.bank_name_err}
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor="country"
                    className="mb-0 text-capitalize small text-capitalize"
                  >
                    {paymentLan?.country}
                  </label>
                  <select
                    className="form-control resforminp mt-0"
                    id="transferMethodCountry"
                    name="transferMethodCountry"
                    ref={register}
                  >
                    <option selected disabled value="">
                      {paymentLan?.country}
                    </option>
                    {Countries.map((c) => (
                      <option value={c.name}>{c.name}</option>
                    ))}
                  </select>
                  {errors.transferMethodCountry && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.bank_country_err}
                    </div>
                  )}
                  {/* {diffCountry && <div className="invalid-feedback d-block">
                    Due to KYC related regulations your home, company and bank location must be in the same country.
                  </div>} */}
                </div>
                <div className="col-lg-6">
                  <label htmlFor="swift" className="mb-0 small text-capitalize">
                    {paymentLan?.swift_code}
                  </label>
                  <input
                    type="text"
                    className="form-control resforminp mt-0"
                    id="swift"
                    placeholder={paymentLan?.swift_code_place}
                    name="swift"
                    ref={register}
                  />
                  {errors.swift && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.swift_code_err}
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor="accountCode"
                    className="mb-0 small text-capitalize"
                  >
                    {paymentLan?.bic}
                  </label>
                  <input
                    type="text"
                    className="form-control resforminp mt-0"
                    id="accountCode"
                    placeholder={paymentLan?.bic_place}
                    name="accountCode"
                    ref={register}
                  />
                  {errors.accountCode && (
                    <div className="invalid-feedback d-block">
                      {paymentLan?.bic_err}
                    </div>
                  )}
                </div>
                {/* <h5 className="fw700">Bank account information</h5> */}
                <div className="col-12 pt-2 pb-1">
                  <p className="small mb-0">{paymentLan?.mobile_money}</p>
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor="mobleMoneyType"
                    className="mb-0 small text-capitalize"
                  >
                    {paymentLan?.mobile_money_account}
                  </label>
                  <input
                    type="text"
                    className="form-control resforminp mt-0"
                    id="mobleMoneyType"
                    placeholder={paymentLan?.mobile_money_account_place}
                    name="mobleMoneyType"
                    ref={register}
                  />

                  {/* {errors.mobleMoneyType && (
                    <div className="invalid-feedback d-block">
                      Bank Account/BIC (SWIFT) code is required
                    </div>
                  )} */}
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor="mobleMoneyNo"
                    className="mb-0 small text-capitalize"
                  >
                    {paymentLan?.mobile_money_account_num}
                  </label>
                  <input
                    type="text"
                    className="form-control resforminp mt-0"
                    id="mobleMoneyNo"
                    placeholder={paymentLan?.mobile_money_account_num_place}
                    name="mobleMoneyNo"
                    ref={register}
                  />
                  {/* {errors.mobleMoneyNo && (
                    <div className="invalid-feedback d-block">
                      Bank Account/BIC (SWIFT) code is required
                    </div>
                  )} */}
                </div>
                <div className="col-lg-12">
                  <div className="form-check mt-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="providerContract"
                      name="providerContract"
                      ref={register}
                      checked={providerContractCheck}
                      onClick={() =>
                        setProviderContractCheck(!providerContractCheck)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="providerContract"
                    >
                      {console.log('paymentLan?.agree_to',paymentLan?.agree_to)}
                      {paymentLan?.agree_to}{" "}
                      <a href={documentContent?.provider_contract?.data?.attributes?.url} target="blank">
                        {paymentLan?.provider_contract}
                      </a>
                    </label>
                    {errors.providerContract && (
                      <div className="invalid-feedback d-block">
                        {paymentLan?.provider_contract_err}
                      </div>
                    )}
                  </div>
                  <div className="form-check mt-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="privacyPolicy"
                      name="privacyPolicy"
                      checked={privacyPolicy}
                      ref={register}
                      onClick={() => setPrivacyPolicy(!privacyPolicy)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="privacyPolicy"
                    >
                      {paymentLan?.agree_to}{" "}
                      <a href={documentContent?.privacy_policy?.data?.attributes?.url} target="blank">
                        {Language?.general?.privacy_policy}
                      </a>
                    </label>
                    {errors.privacyPolicy && (
                      <div className="invalid-feedback d-block">
                        {paymentLan?.privacy_policy_err}
                      </div>
                    )}
                  </div>
                  <div className="form-check mt-2">
                    <input
                      onClick={() => setSelfDeclaration(!selfDeclaration)}
                      checked={selfDeclaration}
                      type="checkbox"
                      className="form-check-input"
                      id="selfDeclaration"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="selfDeclaration"
                    >

                      {paymentLan?.i_hereby}
                      <a
                        target={"_blank"}
                        href={
                          documentContent?.self_declaration?.data?.attributes?.url
                        }
                      >{' '}
                        {paymentLan?.self_declaration}
                      </a>{" "}
                      {paymentLan?.safety_on_site}

                    </label>
                  </div>
                </div>
                <div className="col-lg-12">
                  <button
                    type="button"
                    className="btn btn-default btnSaveandStart text-capitalize mt-3 mx-1"
                    onClick={onSaveDraft}
                  >
                    {Language?.general?.save_as_draft}
                  </button>
                  <button
                    type="button"
                    className="btn btn-default btnSaveandStart text-capitalize mt-3 mx-1"
                    onClick={onStartOver}
                  >
                    {Language?.general?.start_over}

                  </button>
                  <button
                    type="button"
                    className="btn btn-default btnviewprojback text-capitalize mt-3 mx-1"
                    onClick={onPrev}
                  >
                    {Language?.general?.back}

                  </button>
                  <button
                    type="submit"
                    className="btn btn-default btnCreateAccount text-capitalize mt-3 danger mx-1"
                    disabled={
                      !privacyPolicy ||
                      !providerContractCheck ||
                      !selfDeclaration
                    }
                  >
                    {Language?.general?.create_account}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default PaymentInfoForm;
