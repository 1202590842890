export interface Content {
    content: {
        pageTitle: string;
        content: string;
        bio: string;
        mission: string;
        about: string;
        projects: string;
        tours: string;
        vision: string;
    }[];
    _id: string;
    __v: number;
}

export interface ContentState {
    content?: Content[]
}
export const SET_CONTENT = "SET_CONTENT"

export interface SetContentAction {
    type: typeof SET_CONTENT,
    payload: Content[]
}

export type ContentActionTypes = SetContentAction