import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import Swal from 'sweetalert2';
import { RootState } from "..";
import axiosInstance from "../../utilities/axiosInstance";
import {
  AuthActionTypes,
  SET_ERROR_MSG,
  SET_USER_DETAILS,
  userDetails
} from "./type";


export const setUserDetails = (
  userDetails: userDetails | undefined
): AuthActionTypes => {
  return {
    type: SET_USER_DETAILS,
    payload: userDetails,
  };
};

export const setAuthErrorMSg = (msg: string): AuthActionTypes => {
  return {
    type: SET_ERROR_MSG,
    payload: msg,
  };
};

async function getRefreshToken() {
  try {
    const res: any = await axiosInstance.get('/api/users/refresh-token', {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    })
    const token = res.data.token;
    console.log('token: ', token);
    localStorage.setItem('swt-token', token);
    return token;
  }
  catch (e) {
    console.log('Not client anymore');
    return e;
  }
}

export const fetchLoggedinUserDetails =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
    async (dispatch) => {
      getRefreshToken();
      try {
        const { data: { data: { data: { user } } } } =
          await axiosInstance.get('/api/user/details', {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('swt-token')
            }
          })
        dispatch(setUserDetails(user))
      }
      catch (e) {
        console.log('error in api: ', e);
      }
    };

export const loginLocal =
  (
    email: string,
    password: string
  ): ThunkAction<any, RootState, unknown, Action<string>> =>
    async (dispatch) => {
      try {
        const token = localStorage.getItem('swt-token');
        if (!token) {
          const { data: { data, success, message, resend } } =
            await axiosInstance.post("/api/auth/login", {
              email,
              password,
            });
          if (!resend) {
            const { token, user } = data;
            localStorage.setItem('swt-token', token);
            dispatch(setUserDetails(user))
            toast.success(message);
            return { success, resend: false };
          }
          else {
            toast.success(message)
            return { success, resend: true };
          }
        }
        else {
          dispatch(fetchLoggedinUserDetails())
        }
      } catch (error: any) {
        toast.warn(error.response.data.message)
        throw error;
      }
    };

interface ISignUpDetails {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export const sendOTP =
  (email: string, OTP: string): ThunkAction<void, RootState, unknown, Action<string>> =>
    async (dispatch) => {
      try {
        const { data: { message, user, token } } = await axiosInstance.post("/api/auth/verify ", { email, OTP });
        localStorage.setItem('swt-token', token);
        dispatch(setUserDetails(user))
        toast.success(message);
      } catch (error: any) {
        toast.error(error.response.data.message);
        throw error;
      }
    };

export const resendOTP =
  (email: string): ThunkAction<void, RootState, unknown, Action<string>> =>
    async (dispatch) => {
      try {
        const { data: { message, user } } = await axiosInstance.post("/api/auth/resendOTP", { email });
        toast.success(message);
        return dispatch(setUserDetails(user))
      } catch (error: any) {
        toast.error(error.response.data.message);
        // dispatch(setAuthErrorMSg(error.response.data.msg));
        throw error;
      }
    };

export const signUpLocal =
  (
    signUpDetails: ISignUpDetails,
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
    async (dispatch) => {
      try {
        await axiosInstance.post("/api/auth/signup", { ...signUpDetails, swt_role: "client" });
      } catch (error: any) {
        toast.warn(error.response.data.message);
        throw error;
      }
    };

export const Logout =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
    async (dispatch) => {
      try {
        localStorage.clear();
        dispatch(setUserDetails(undefined));
      } catch (error) {
        throw error;
      }
    };

export const updateUserProfile =
  (data: any): ThunkAction<void, RootState, unknown, Action<string>> =>
    async (dispatch, getState) => {
      try {
        const { data: { message, emailChanged } } = await axiosInstance.post(`/api/user/update`, { userInfo: data }, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('swt-token')
          }
        });
        toast.success(message);
        if (emailChanged) {
          await dispatch(Logout())

        }
        dispatch(fetchLoggedinUserDetails());
      } catch (error: any) {
        toast.error(error.response.data.message);
      }
    };

    export const updatePaymentInfoDetails =
    (data: any): ThunkAction<void, RootState, unknown, Action<string>> =>
      async (dispatch, getState) => {
        try {
          const { data: { message } } = await axiosInstance.post(`/api/user/payment-update`, { userInfo: data }, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('swt-token')
            }
          });
          toast.success(message);
          dispatch(fetchLoggedinUserDetails());
        } catch (error: any) {
          toast.error(error.response.data.message);
        }
      };

export const changeUserPassword =
  (data: any, history: any): ThunkAction<void, RootState, unknown, Action<string>> =>
    async (dispatch) => {
      try {
        const { data: { message } } = await axiosInstance.post(`/api/user/password/update`, { passwordInfo: data }, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('swt-token')
          }
        });
        toast.success(message);
        dispatch(Logout());
        history.push('/');
      } catch (error: any) {
        toast.error(error.response.data.message);
      }
    };

export const deleteAccount =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
    async (dispatch, getState) => {
      try {
        const userID = getState().authReducer.UserDetails?.id;
        const swt_role = getState().authReducer.UserDetails?.swt_role;
        const { data: { msg } } = await axiosInstance.post(`/api/users/deleteUser`, {
          id: userID,
          swt_role,
          date: new Date(),
        }, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem('swt-token')
          }
        });
        toast.success(msg);
        dispatch(Logout());
      } catch (error: any) {
        if (error.response.data.notify) {
          Swal.fire({
            icon: 'info',
            text: error.response.data.msg,
            showCancelButton: false,
            showDenyButton: false,
            showCloseButton: true
          })
        }
        else {
          toast.error(error.response.data.msg);
        }
        throw error;
      }
    };

export const getOnboardLink =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
    async (dispatch) => {
      const res = await axiosInstance.get(`/api/stripe/account-link`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('swt-token')
        }
      });
      return res;
    };

export const getStripeConnectedDetails =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
    async (dispatch) => {
      const res = await axiosInstance.get(`/api/stripe/account/me`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('swt-token')
        }
      });
      return res;
    };
