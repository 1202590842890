import dayjs from "dayjs";
import { FunctionComponent } from "react";

interface TourInfoProps {
    draft: any
}
const TourInfo: FunctionComponent<TourInfoProps> = ({ draft }) => {
    return (
        <section className="form-wrap">
            <h4 className="com-font fw700 text-red text-capitalize">Tour Information</h4>
            <div className="wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Name:</label>
                            <span> {draft.tourName}</span>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Pictures:</label>
                            {
                                draft.tourPictures.map((p: any) => (<img style={{
                                    width: '33%',
                                    height: '184px',
                                    padding: '2px'
                                }} alt="" src={p.imageURL} />))
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Start date:</label>
                            <span> {dayjs(draft.startDate).format('DD/MM/YYYY')}</span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">End date:</label>
                            <span> {dayjs(draft.endDate).format('DD/MM/YYYY')}</span>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <label htmlFor="" className="font-weight-bold">Description:</label>
                        <p>{draft.tourDescription}</p>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Cost:</label>
                            <span> {draft.price}</span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Capacity:</label>
                            <span> {draft.capacity}</span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Minimum Capacity:</label>
                            <span> {draft.capacity}</span>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Additional Costs:</label>
                            <p>{draft.additionalPrice}</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Accommodation:</label>
                            {
                                draft.accommodationPicture.map((p: any) => (<img style={{
                                    width: '33%',
                                    height: '184px',
                                    padding: '2px'
                                }} alt="" src={p.imageURL} />))
                            }
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">{draft.category}:</label>
                            <p>{draft.accommodationDescription}</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Extra Accomodation:</label>
                        </div>
                    </div>

                    {
                        draft?.extraAccommodation?.map((d: any) => (
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="" className="font-weight-bold">{d.category}:</label>
                                    <p>{d.description}</p>
                                </div>
                            </div>
                        ))
                    }
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Transportation: </label>
                            <p></p>
                            {
                                draft.transportationPicture.map((p: any) => (<img style={{
                                    width: '33%',
                                    height: '184px',
                                    padding: '2px'
                                }} alt="" src={p.imageURL} />))
                            }
                            <p>{draft.transportation}</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Offered meals:</label>
                            <p>{draft.foodDescription}</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Qualifications:</label>
                            <p>{draft.qualifications?.join()}</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Location:</label>
                            <span> {draft.location}</span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Local supervisor:</label>
                            <span> {draft.localSupervisorName}</span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Local supervisor number:</label>
                            <span> {draft.localSupervisorPhone}</span>
                        </div>
                    </div>
                    {/* <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">A typical day:</label>
                            <p>{draft.typicalDay}</p>
                        </div>
                    </div> */}
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">tour owner name:</label>
                            <span> {draft.tourOwnerName}</span>
                            <br></br>
                            {
                                draft.tourOwnerPicture.map((p: any) => (<img style={{
                                    width: '33%',
                                    height: '184px',
                                    padding: '2px'
                                }} alt="" src={p.imageURL} />))
                            }
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">tour owner description:</label>
                            <p>{draft.ownerDescription}</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Contact: </label>
                            <span> {draft.contactEmail}</span>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="" className="font-weight-bold">Additional information: </label>
                            <p>{draft.additionalInfo}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TourInfo;
