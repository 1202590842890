import { ContentActionTypes, ContentState, SET_CONTENT } from "./type"

const initialState: ContentState = {
    content: undefined
}

export function contentReducer(state = initialState, action: ContentActionTypes): ContentState {
    switch (action.type) {
        case SET_CONTENT:
            return {
                ...state,
                content: action.payload
            }
        default:
            return state
    }
}