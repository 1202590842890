/* eslint-disable import/no-anonymous-default-export */
import React from "react";
export default {
  Projects: (
    <ul style={{ paddingLeft: "0px" }} className="list-none proj-detail-text">
      <li>
        <img alt="" src="/images/checkmark-red.svg" className="img-fluid" />
        price with
        no additional costs

      </li>
      <li>
        <img alt="" src="/images/checkmark-red.svg" className="img-fluid" />{" "}
        airport-pick up & transportation
      </li>
      <li>
        <img alt="" src="/images/checkmark-red.svg" className="img-fluid" /> local
        guide & supervisor
      </li>
      <li>
        <img alt="" src="/images/checkmark-red.svg" className="img-fluid" /> 24/7 local
        emergency number
      </li>
      <li>
        <img alt="" src="/images/checkmark-red.svg" className="img-fluid" />{" "}
        once-in-your-life experience
      </li>
      <li>
        <img alt="" src="/images/checkmark-red.svg" className="img-fluid" />{" "}
        appropriate salary for (drivers, cooks, teachers) caretakers
      </li>
      <li>
        <img alt="" src="/images/checkmark-red.svg" className="img-fluid" /> you help
        the community to grow
      </li>
      <li>
        <img alt="" src="/images/checkmark-red.svg" className="img-fluid" /> official
        graduation certificate at the end of your work-experience
      </li>
    </ul>
  ),
  Tours: (
    <ul style={{ paddingLeft: "0px" }} className="list-none proj-detail-text">
      <li>
        <img alt="" src="/images/checkmark-red.svg" className="img-fluid" /> price with
        no additional costs
      </li>
      <li>
        <img alt="" src="/images/checkmark-red.svg" className="img-fluid" />{" "}
        airport-pick up & transportation
      </li>
      <li>
        <img alt="" src="/images/checkmark-red.svg" className="img-fluid" /> local
        guide & supervisor
      </li>
      <li>
        <img alt="" src="/images/checkmark-red.svg" className="img-fluid" /> 24/7 local
        emergency number
      </li>
      <li>
        <img alt="" src="/images/checkmark-red.svg" className="img-fluid" />{" "}
        once-in-your-life experience
      </li>
      <li>
        <img alt="" src="/images/checkmark-red.svg" className="img-fluid" />{" "}
        appropriate salary for (drivers, cooks, teachers) caretakers
      </li>
      <li>
        <img alt="" src="/images/checkmark-red.svg" className="img-fluid" /> you help
        the environment to stay healthy
      </li>
      <li>
        <img alt="" src="/images/checkmark-red.svg" className="img-fluid" /> you
        support a local social project with 5% of the tour price
      </li>
    </ul>
  ),
};
