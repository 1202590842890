import Axios from "axios";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axiosInstance from "../../utilities/axiosInstance";
import { CloudinaryImg } from "../Projects";
import { Article, ArticlesActionTypes, SET_ALL_ARTICLES, SET_ALL_ARTICLES_IMAGES, SET_POPULAR_ARTICLES } from "./type";

export const setPopularArticles = (articles: Article[]): ArticlesActionTypes => {
    return {
        type: SET_POPULAR_ARTICLES,
        payload: articles
    }
}

export const setAllArticles = (articles: Article[]): ArticlesActionTypes => {
    return {
        type: SET_ALL_ARTICLES,
        payload: articles
    }
}

export const setAllArticlesImages = (images: any[]): ArticlesActionTypes => {
    return {
        type: SET_ALL_ARTICLES_IMAGES,
        payload: images
    }
}

export const fetchPopularArticles = (lan: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await axiosInstance.get(`/api/article?populate=articles.image,newsImages&locale=${lan}`)
    dispatch(setPopularArticles(res.data.data.attributes.articles))
    dispatch(setAllArticlesImages(res.data.data.attributes.newsImages.data))
}

export const fetchAllArticles = (): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await Axios.get('/api/articles')
    dispatch(setAllArticles(res.data.articles))
}

export const fetchArticleByID = (faqId: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await Axios.get(`/api/articles/${faqId}`)
    return res.data.article
}

export const createArticle = (payload: { title: string, text: string, published: boolean, image: CloudinaryImg[] }): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await Axios.post(`/api/articles`, payload)
    return res.data
}

export const updateArticle = (articleId: string, payload: { title: string, text: string, published: boolean, image: CloudinaryImg[] }): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await Axios.post(`/api/articles/${articleId}`, payload)
    return res.data
}

export const deleteArticleByID = (articleId: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await Axios.delete(`/api/articles/${articleId}`)
    return res.data
}