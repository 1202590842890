interface NavType {
    data: any
}

export interface Nav {
    route1: string,
    route2: string,
    route3: string,
    route4: string,
    route5: string,
    route6: string,

}

export interface NavState {
    navContent: Nav;
}

export const SET_NAV_CONTENT = "SET_NAV_CONTENT";

export interface SetNavAction {
    type: typeof SET_NAV_CONTENT;
    payload: Nav;
}

export type NavActionTypes = SetNavAction;
