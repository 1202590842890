import { yupResolver } from '@hookform/resolvers/yup';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import * as yup from "yup";
import { RootState } from '../../../store';
import Countries from '../../../utilities/countries';

const PaymentInfoSchema = yup.object().shape({
    paymentReceiverFirstName: yup.string().required(),
    paymentReceiverLastName: yup.string().required(),
    addressLine1: yup.string().required(),
    city: yup.string().required(),
    stateProvince: yup.string().required(),
    postalCode: yup.string().required(),
    bankAccountId: yup.string().required(),
    bankName: yup.string().required(),
    branchId: yup.string(),
    country: yup.string().required(),
    transferMethodCountry: yup.string().required(),
});

export interface IPaymentInfo {
    paymentReceiverFirstName: string
    paymentReceiverLastName: string
    addressLine1: string
    city: string
    stateProvince: string
    postalCode: string
    bankAccountId: string
    bankName: string
    branchId: string
    country: string
    transferMethodCountry: string
}

interface PaymentInfoSectionProps {
    initialData?: IPaymentInfo,
    onSubmit: (data: IPaymentInfo) => void
}
const PaymentInfoSection: FunctionComponent<PaymentInfoSectionProps> = ({ initialData, onSubmit }) => {
    const { register, handleSubmit, errors } = useForm<IPaymentInfo>({
        resolver: yupResolver(PaymentInfoSchema),
        defaultValues: initialData
    })

    const _onSubmit = (data: IPaymentInfo) => {
        Swal.fire({
            title: "Are you sure you want to make changes to your payout information",
            showConfirmButton: true,
            showCancelButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
                onSubmit(data)
            } else {
              console.log('nothing')
            }
          })
        // onSubmit(data)
    }
    const genralLan = useSelector(
        (rootState: RootState) => rootState.language.language?.content!
    );
    return (
        <>
            <section className="form-wrap">
                <h4 className="com-font fw700 text-red text-capitalize">{genralLan?.general?.payment_info}</h4>
                <form className="respadding com-font" onSubmit={handleSubmit(_onSubmit)}>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-lg-6">
                                <input type="text" className="form-control resforminp" name="paymentReceiverFirstName" ref={register} placeholder={genralLan?.payment?.owner_firstname_place} />
                                {errors.paymentReceiverFirstName && (<div className="invalid-feedback d-block">{genralLan?.payment?.owner_firstname_err}</div>)}
                            </div>
                            <div className="col-lg-6">
                                <input type="text" className="form-control resforminp" name="paymentReceiverLastName" ref={register} placeholder={genralLan?.payment?.owner_lastname_place} />
                                {errors.paymentReceiverLastName && (<div className="invalid-feedback d-block">{genralLan?.payment?.owner_lastname_err}</div>)}
                            </div>
                            <div className="col-lg-12">
                                <input type="text" className="form-control resforminp" name="addressLine1" ref={register} placeholder={genralLan?.payment?.account_owner_address_place} />
                                {errors.addressLine1 && (<div className="invalid-feedback d-block">{genralLan?.payment?.account_owner_address_err}</div>)}
                            </div>
                            <div className="col-lg-6">
                                <input type="text" className="form-control resforminp" name="city" ref={register} placeholder={genralLan?.payment?.city_place} />
                                {errors.city && (<div className="invalid-feedback d-block">{genralLan?.payment?.city_err}</div>)}
                            </div>
                            <div className="col-lg-6">
                                <select className="form-control resforminp" name="country" ref={register}>
                                    {Countries.map(item => <option value={item.name}>{item.name}</option>)}
                                </select>
                                {errors.country && (<div className="invalid-feedback d-block">{genralLan?.payment?.country_err}</div>)}
                            </div>
                            <div className="col-lg-6">
                                <input type="text" className="form-control resforminp" name="stateProvince" ref={register} placeholder={genralLan?.payment?.state_place} />
                                {errors.stateProvince && (<div className="invalid-feedback d-block">{genralLan?.payment?.state_err}</div>)}
                            </div>
                            <div className="col-lg-6">
                                <input type="text" className="form-control resforminp" name="postalCode" ref={register} placeholder={genralLan?.payment?.postal_code_place} />
                                {errors.postalCode && (<div className="invalid-feedback d-block">{genralLan?.payment?.postal_code_err}</div>)}
                            </div>
                            <div className="col-lg-6">
                                <input type="text" className="form-control resforminp" name="bankAccountId" ref={register} placeholder={genralLan?.payment?.account_number_place} />
                                {errors.bankAccountId && (<div className="invalid-feedback d-block">{genralLan?.payment?.account_number_err}</div>)}
                            </div>
                            <div className="col-lg-6">
                                <input type="text" className="form-control resforminp" name="bankName" ref={register} placeholder={genralLan?.payment?.bank_name_place} />
                                {errors.bankName && (<div className="invalid-feedback d-block">{genralLan?.payment?.bank_name_err}</div>)}
                            </div>
                            <div className="col-lg-6">
                                <input type="text" className="form-control resforminp" name="branchId" ref={register} placeholder={genralLan?.payment?.aba_rtn_place} />
                                {errors.branchId && (<div className="invalid-feedback d-block">{genralLan?.payment?.aba_rtn_err}</div>)}
                            </div>
                            <div className="col-lg-6">
                                <select
                                    className="form-control resforminp mt-0"
                                    id="transferMethodCountry"
                                    name="transferMethodCountry"
                                    ref={register}
                                >
                                    <option selected disabled value="">
                                        Country where the bank is located
                                    </option>
                                    {Countries.map((c) => (
                                        <option value={c.name}>{c.name}</option>
                                    ))}
                                </select>
                                {errors.transferMethodCountry && (<div className="invalid-feedback d-block">{genralLan?.payment?.bank_country_err}</div>)}
                            </div>
                            <div className="col-lg-12">
                                <button type="submit" data-toggle="modal" data-target="#paymentInfoModal" className="btn btn-default btnviewprojnext float-right text-capitalize">
                                    {genralLan?.general?.update_info}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </>

    )
}

export default PaymentInfoSection