import { ChangeEvent, useState, FunctionComponent, ForwardedRef, forwardRef, useEffect } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";

interface IAutoComplete {
    name: string;
    id: string;
    type: string;
    className: string;
    placeholder: string;
    defaultValue?: string;
}
export type Ref = any;
export const AutoComplete = forwardRef<Ref, IAutoComplete>(({ name, id, type, className, placeholder, defaultValue }, ref) => {
    const {
        ready,
        value,
        clearSuggestions,
        suggestions: { status, data },
        setValue
    } = usePlacesAutocomplete();
    const [showPlaces, setShowPlaces] = useState(false)
    const handleInput = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue(e.target.value);
        setShowPlaces(true)
    };

    const handleSelect = (val: string): void => {
        console.log(val)
        setValue(val, false);
        setShowPlaces(false)
        clearSuggestions()
    };
    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue)
        }
    }, [defaultValue])
    const renderSuggestions = (): JSX.Element => {
        const suggestions = data.map(({ place_id, description }: any) => (
            <a key={place_id} onClick={() => handleSelect(description)}>{description} </a>
        ));

        return (
            <div className="dropdown-content">
                {suggestions}
            </div>
        );
    };

    return (
        <>
            <div className="dropdown">
                <input
                    name={name}
                    id={id}
                    type={type}
                    ref={ref}
                    placeholder={placeholder}
                    className={className}
                    value={value}
                    defaultValue={defaultValue}
                    onChange={handleInput}
                    disabled={!ready}
                ></input>
                {status === "OK" && showPlaces && renderSuggestions()}
            </div>
        </>
    );
})

