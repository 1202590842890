import dayjs from "dayjs";
import React, { FunctionComponent } from "react";
import { ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../store";
import { CartItem } from "../store/Cart";

interface BookingDetailsProps {
  cartItem: CartItem;
}
const BookingDetails: FunctionComponent<BookingDetailsProps> = ({ cartItem }) => {
  const {
    projectName,
    tourName,
    startDate,
    endDate,
    numberOfNights,
    participants,
    accommodation,
    travelSafetyUrl,
    levelOfDifficulty,
    nearAirport,
    choiceOfFood,
    natureOfEvent,
    spokenLanguages,
    voucherCode,
    additionalPrice

  } = cartItem;
  const title = projectName || tourName;
  const isProject = Boolean(projectName);
  const Language = useSelector((state: RootState) => state.language.language?.content)
  return (
    <div className="row paddformupdwn borderpay m-0">
      <div className="col-md-6 col-lg-6 ">
        <div className="swt-headmain com-font text-uppercase bluetext">
          {Language?.general?.your + ' ' + Language?.general?.booking + ' ' + Language?.general?.details}
        </div>
        <div className="row com-font paddupdownpay">
          <div className="col-lg-12 ">
            <div className="projectnavhead txt-black">{title}</div>
          </div>
          <div className="col-lg-12">
            <img src="images/suv-car.svg" className="img-fluid" alt="" />
            <span className="datetxt">{dayjs(startDate).format("D")}</span>
            <span className="monthtxt">{dayjs(startDate).format("MMMM")}</span>
            <span className="posplaneic">
              
              <span className=" mb-0 loctxt" style={{ paddingLeft: "15px" }}>
                <img src="/images/blackmap.svg" className="img-fluid" alt="" />{" "}
                {travelSafetyUrl}
              </span>
              <span className="small daytxtmod txt-black">
                {numberOfNights + 1} {Language?.general?.days}
              </span>
            </span>
          </div>
        </div>
        <div className="row textimgprojpaddud com-font">
          <div className="col-lg-12 proj-detail-text text-justify">
            <form id="projectBooking">
              <div className="form-group">
                <label htmlFor="selectPartipants">{Language?.general?.select}{' '}{Language?.general?.participants}</label>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text input-group-icon"
                      id="basic-addon1"
                    >
                      <i className="fa fa-user-plus" />
                    </span>
                  </div>
                  <input
                    readOnly
                    value={participants}
                    type="text"
                    className="form-control input-group-border-let-none"
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="datePicker">{Language?.general?.select} {Language?.general?.date}</label>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text input-group-icon"
                      id="calander"
                    >
                      <i className="fa fa-calendar-o" />
                    </span>
                  </div>
                  <input
                    readOnly
                    type="text"
                    name="daterange"
                    className="form-control input-group-border-let-none"
                    id="datePicker"
                    aria-describedby="calander"
                    value={`${dayjs(startDate).format('DD/MM/YYYY')} - ${dayjs(endDate).format('DD/MM/YYYY')}`}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="selectcategory">{Language?.general?.accomodation}{' '}{Language?.general?.category}</label>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text input-group-icon"
                      id="category"
                    >
                      <i className="fa fa-bed" />
                    </span>
                  </div>
                  <input
                    readOnly
                    value={accommodation}
                    type="text"
                    name="daterange"
                    className="form-control input-group-border-let-none"
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="selectcategory">{Language?.general?.type_of}{' '}{Language?.general?.food}</label>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text input-group-icon"
                      id="category"
                    >
                      <i className="fa fa-cutlery" />
                    </span>
                  </div>
                  <input
                    readOnly
                    value={choiceOfFood}
                    type="text"
                    name="daterange"
                    className="form-control input-group-border-let-none"
                  />
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-6">
        <div className="swt-headmain com-font text-uppercase bluetext">
          {Language?.general?.choosen} {projectName ? Language?.general?.project : Language?.general?.tour}{' '} {Language?.general?.Details}
        </div>
        <div className="row textimgprojpaddud com-font px-5">
          <div className="col-lg-12 proj-detail-text text-justify p-0">
            <div className="col">
              <label>
                {Language?.general?.risk_level}<span className="text-danger">*</span>
              </label>
              <ProgressBar>
                {levelOfDifficulty === 'easy' && <ProgressBar style={{ backgroundColor: '#A3A3A3' }} now={25} label={'Easy'} />}
                {levelOfDifficulty === 'moderate' && <ProgressBar style={{ backgroundColor: '#5AFF15' }} now={50} label={'Moderate'} />}
                {levelOfDifficulty === 'difficult/ risky' && <ProgressBar style={{ backgroundColor: '#6A66A3' }} now={75} label={"Difficult"} />}
                {levelOfDifficulty === 'extreme' && <ProgressBar style={{ backgroundColor: '#F52F57' }} now={100} label={'Extreme'} />}
              </ProgressBar>
              <p className="text-muted">
                <span className="text-danger">*</span>
                {Language?.general?.risk_level_helper}
              </p>
            </div>
            <ul className="p-0">

              <li>{participants} {Language?.general?.person}</li>
              <li>
                {dayjs(startDate).format("DD.MM.YYYY")} -{" "}
                {dayjs(endDate).format("DD.MM.YYYY")}
              </li>
              <li>{numberOfNights} {Language?.general?.nights}</li>
              <li>{Language?.general?.type_of} {projectName ? Language?.general?.project : Language?.general?.tour}: {natureOfEvent} </li>

              <li>{Language?.tour?.spoken_languages}: {spokenLanguages} </li>
              <li>
                {Language?.tour?.near_airport}: {' '}
                {nearAirport}
              </li>
              <li>
                {Language?.tour?.additional_cost}: {' '}
                {additionalPrice}
              </li>
              <li>
                {Language?.general?.type_of} {' '}{Language?.general?.food}
                {choiceOfFood}
              </li>
              <li>
                {!accommodation?.includes('accommodation') ? accommodation?.concat(' accommodation') : accommodation}*
                <ul style={{ paddingLeft: "0px" }} className="list-none ">
                  {isProject ?

                    Language?.project?.standards[accommodation]?.map((item: any) =>
                      <li>
                        <img alt="" src={item?.included ? '/images/checkmark-red.svg' : "/images/crossx.svg"} style={{ height: '13px', width: '15px' }} className="img-fluid" />{" "}
                        {item.value}
                      </li>
                    ) :
                    Language?.tour?.standards[accommodation]?.map((item: any) =>
                      <li>
                        <img alt="" src={item?.included ? '/images/checkmark-red.svg' : "/images/crossx.svg"} style={{ height: '13px', width: '15px' }} className="img-fluid" />{" "}
                        {item.value}
                      </li>
                    )
                  }
                </ul>
              </li>
              {/* <li>Personal Wishes: {""}</li> */}
              <li className="includeprojtxt list-unstyled pt-3 pb-0">{Language?.general?.included_standards}</li>
              {isProject ? Language?.general?.projectStandards?.map((item: any) =>
                <li className="list-none">
                  <img alt="" src={item?.included ? '/images/checkmark-red.svg' : "/images/crossx.svg"} style={{ height: '13px', width: '15px' }} className="img-fluid" />{" "}
                  {item.text}
                </li>
              ) : Language?.general?.tourStandards?.map((item: any) =>
                <li className="list-none">
                  <img alt="" src={item?.included ? '/images/checkmark-red.svg' : "/images/crossx.svg"} style={{ height: '13px', width: '15px' }} className="img-fluid" />{" "}
                  {item.text}
                </li>
              )}

              <div className="includeprojtxt pt-3 pb-2">{Language?.general?.travel_and_safety}</div>
              <Link target="blank" to={`/travel-and-safety/${travelSafetyUrl}`}>{`${window.location.host}/travel-and-safety/${travelSafetyUrl}`}</Link>
            </ul>

          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
