import { AuthActionTypes, AuthState, SET_ERROR_MSG, SET_USER_DETAILS } from "./type"

const initialState: AuthState = {
    UserDetails: undefined,
    errorMsg: ''
}

export function authReducer(state = initialState, action: AuthActionTypes): AuthState {
    switch (action.type) {
        case SET_USER_DETAILS:
            return {
                ...state,
                UserDetails: action.payload
            }
        case SET_ERROR_MSG:
            return {
                ...state,
                errorMsg: action.payload
            }
        default:
            return state
    }
}