export interface DraftState {
    drafts: any[]
}
export const SET_DRAFTS = "SET_DRAFTS"


export interface SetDraftsAction {
    type: typeof  SET_DRAFTS,
    payload: any[]
}

export type DraftsActionTypes = SetDraftsAction