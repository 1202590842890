import React, { FunctionComponent } from 'react'

export const LatestNews: FunctionComponent = ({ }) => {
    return (
        <>
            <div className="container-fluid">
                <div className="row no-gutter aligncomrowpadd">
                    <div className="d-none d-md-flex col-md-6 col-lg-6 paddlr0">
                        <img src="/images/projects_bg.jpg" className="img-fluid imgcom leftimgbor bgaboutagn" alt="" />
                    </div>
                    <div className="col-md-8 col-lg-6">
                        <div className="login d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 com-font">
                                        <div className="ourhead com-font text-red fw700">Latest News</div>
                                        <ul className="timeline">
                                            <li>
                                                <a target="_blank" href="https://www.totoprayogo.com/#" className="newstitle">New Web Design</a>
                                                <a href="#" className="float-right datecol">21 March, 2014</a>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque diam non nisi semper, et elementum lorem ornare. Maecenas placerat facilisis mollis. Duis sagittis ligula in sodales vehicula....</p>
                                                <a href="#" className="btn btnviewproj">Read More</a>
                                            </li>
                                            <li>
                                                <a href="#" className="newstitle">21 000 Job Seekers</a>
                                                <a href="#" className="float-right datecol">4 March, 2014</a>
                                                <p>Curabitur purus sem, malesuada eu luctus eget, suscipit sed turpis. Nam pellentesque felis vitae justo accumsan, sed semper nisi sollicitudin...</p>
                                                <a href="#" className="btn btnviewproj">Read More</a>
                                            </li>
                                            <li>
                                                <a href="#" className="newstitle">Awesome Employers</a>
                                                <a href="#" className="float-right datecol">1 April, 2014</a>
                                                <p>Fusce ullamcorper ligula sit amet quam accumsan aliquet. Sed nulla odio, tincidunt vitae nunc vitae, mollis pharetra velit. Sed nec tempor nibh...</p>
                                                <a href="#" className="btn btnviewproj">Read More</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}