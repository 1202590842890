import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../store";
import { fetchDrafts } from "../store/Drafts";
import { setCurrentSideBar, SideBarEnum } from "../store/SideBar";

export default function WorkWithUs() {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [businessCategory, setBusinessCategory] = useState("company");
  const [eventType, setEventType] = useState("project");
  const userDetails = useSelector(
    (rootState: RootState) => rootState.authReducer.UserDetails
  );
  const language = useSelector(
    (rootState: RootState) => rootState.language.language?.content?.workWithUs!
  );
  const genralLan = useSelector(
    (rootState: RootState) => rootState.language.language?.content?.general!
  );
  console.log(genralLan)

  const onWorkClick = async () => {
    if (userDetails) {
      try {
        const drafts = await dispatch(fetchDrafts());
        if (drafts?.length > 0) {
          const draft = (drafts as any)[0]
          if (draft.businessCategory && draft.type) {
            push(`/provider-application?businessCategory=${draft.businessCategory}&eventType=${draft.type}`)
          } else {
            setShowModal(true);
          }
        } else {
          setShowModal(true);
        }
      }
      catch (e: any) {
        console.log('error in api GetUserDraft: ', e);
      }
    }
    else {
      dispatch(setCurrentSideBar(SideBarEnum.Login));
      setShowModal(false)
    }
  };

  const proceed = () => {
    push(`/provider-application?businessCategory=${businessCategory}&eventType=${eventType}`)
    setShowModal(false)
  };
  const getModal = () => {
    if (!language)
      return <></>
    return <Modal show={showModal} backdrop="static">
      <Modal.Header>
        <Modal.Title>{language?.work_with_us}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row">
          <div className="col-lg-6">
            <label
              htmlFor="businessCategory"
              className="mb-0 small text-capitalize"
            >
              {language?.business_type}
            </label>
            <select
              className="form-control resforminp mt-0"
              id="businessCategory"
              name="businessCategory"
              style={{ height: "3rem" }}
              value={businessCategory}
              onChange={(e) => setBusinessCategory(e.target.value)}
            >
              <option value="company">{genralLan?.company}</option>
              <option value="freelancer">{genralLan?.freelancer}</option>
            </select>
          </div>
          <div className="col-lg-6">
            <label htmlFor="eventType" className="mb-0 small text-capitalize">
              {genralLan?.event_type}
            </label>
            <select
              className="form-control resforminp mt-0"
              id="eventType"
              name="eventType"
              style={{ height: "3rem" }}
              value={eventType}
              onChange={(e) => setEventType(e.target.value)}
            >
              <option value="project">{genralLan?.for} {genralLan?.project}</option>
              <option value="tour">{genralLan?.for} {genralLan?.tour}</option>
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btnSaveandStart" onClick={() => setShowModal(false)}>{genralLan?.close}</button>
        <button onClick={proceed} className="btn btnviewproj">{genralLan?.proceed}</button>
      </Modal.Footer>
    </Modal>
  }

  return (
    <>
      {getModal()}
      <section>
        <div className="col-lg-12 bgstrapcta">
          <div className="container">
            <div className="text-center com-font footerctapadd">
              <div className="worktxthead">{language?.grow_your_business}</div>
              <div className="small text-muted" style={{ paddingTop: "15px" }}>
                {language?.grow_your_business_sub}
              </div>
              <button
                onClick={onWorkClick}
                className="btn btnfinalbook mt-4"
              >
                {language?.apply}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
