import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axiosInstance from "../../utilities/axiosInstance";

export const fetchAllProviders = (): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await axiosInstance.get('/api/users/providerUsers',{
     headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
  })
    return res.data.users
}

export const fetchProvidersEvents = (eventID: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await axiosInstance.get(`/api/users/providerEvents/${eventID}`,{
     headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
  })
    return res.data.events
}
