import { FAQsState, SET_PUBLISHED_FAQS, FAQsActionTypes, SET_ALL_FAQS, SET_PAGE_TITLE } from "./type"

const initialState: FAQsState = {
    pageTitle: 'FAQs',
    publishedFAQs: [],
    allFAQs: []
}

export function faqsReducer(state = initialState, action: FAQsActionTypes): FAQsState {
    switch (action.type) {
        case SET_PUBLISHED_FAQS:
            return {
                ...state,
                publishedFAQs: action.payload
            }
        case SET_ALL_FAQS:
            return {
                ...state,
                allFAQs: action.payload
            }
        case SET_PAGE_TITLE:
            return {
                ...state,
                pageTitle: action.payload
            }
        default:
            return state
    }
}