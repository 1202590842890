import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axiosInstance from "../../utilities/axiosInstance";
import { Home, HomeActionTypes, SET_HOME_CONTENT } from "./type";

export const setHomePage = (contents: Home): HomeActionTypes => {
    return {
        type: SET_HOME_CONTENT,
        payload: contents
    }
}

export const fetchHomeContent = (lan: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await axiosInstance.get(`/api/homepage?populate=Hero.footer_item_1.image,Hero.footer_item_2.image,Hero.footer_item_3.image,Hero.project_section.image,Hero.project_section.Images,Hero.tour_section.image,Hero.tour_section.Images,Hero.upcoming_proj_and_tours.image,Hero.upcoming_proj_and_tours.Images,Hero.vision_section.image,Hero.vision_section.Images,Hero.first_hero_img&locale=${lan}`);
    dispatch(setHomePage(res.data.data.attributes))
}
