import { Article } from "../Articles";
import { Project } from "../Projects";
import { Tours } from "../Tours";

export enum SideBarEnum {
  Cart = "Cart",
  Login = "Login",
  News = "News",
  Projects = "Projects",
  Search = "Search",
  Signup = "Signup",
  None = "None",
  ProfileMenu = "ProfileMenu",
  Contact = "Contact",
  DayToDay = "DayToDay",
  Otp = "Otp",
  Forgot = "Forgot"
}

export interface SideBarState {
  currentSideBar: SideBarEnum;
  currentEvent?: Project | Tours;
  currentArticle?: Article;
  currentDayToDayProgram?: Project | Tours;
}

export const SET_SIDEBAR = "SET_SIDEBAR";
export const SET_CURRENT_EVENT = "SET_CURRENT_EVENT";
export const SET_CURRENT_ARTICLE = "SET_CURRENT_ARTICLE";
export const SET_CURRENT_DAYTODAYPROGRAM = "SET_CURRENT_DAYTODAYPROGRAM";

export interface SetSideBarAction {
  type: typeof SET_SIDEBAR;
  payload: SideBarEnum;
}

export interface SetCurrentEventAction {
  type: typeof SET_CURRENT_EVENT;
  payload: Project | Tours;
}

export interface SetCurrentArticleAction {
  type: typeof SET_CURRENT_ARTICLE;
  payload: Article;
}

export interface SetCurrentDayToDayProgram {
  type: typeof SET_CURRENT_DAYTODAYPROGRAM;
  payload: Project | Tours | undefined;
}

export type SideBarActionTypes =
  | SetSideBarAction
  | SetCurrentEventAction
  | SetCurrentDayToDayProgram
  | SetCurrentArticleAction;
