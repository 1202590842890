export interface Lan {
    content: any;
    language: string;
}

export interface LanguageState {
    language?: Lan
}
export const SET_LANGUAGE = "SET_LANGUAGE"

export interface SetLanguageAction {
    type: typeof SET_LANGUAGE,
    payload: Lan
}

export type LanguageActionTypes = SetLanguageAction