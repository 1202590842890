import pick from "lodash/pick";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axiosInstance from "../../utilities/axiosInstance";
import { deleteDraft } from "../Drafts";
import { createProject, Project } from "../Projects";
import { createTours, Tours } from "../Tours";
import {
  RequestActionTypes,
  SET_FOR_APPROVAL_PROJECTS,
  SET_FOR_APPROVAL_PROVIDERS,
  SET_FOR_APPROVAL_TOURS,
  SET_FOR_REMOVAL_PROJECTS,
  SET_FOR_REMOVAL_TOURS
} from "./type";

export const setForApprovalProjects = (
  projects: Project[]
): RequestActionTypes => {
  return {
    type: SET_FOR_APPROVAL_PROJECTS,
    payload: projects,
  };
};

export const setForApprovalTours = (tours: Tours[]): RequestActionTypes => {
  return {
    type: SET_FOR_APPROVAL_TOURS,
    payload: tours,
  };
};

export const setForApprovalProviders = (
  providers: any[]
): RequestActionTypes => {
  return {
    type: SET_FOR_APPROVAL_PROVIDERS,
    payload: providers,
  };
};

export const setForRemovalProjects = (
  projects: Project[]
): RequestActionTypes => {
  return {
    type: SET_FOR_REMOVAL_PROJECTS,
    payload: projects,
  };
};

export const setForRemovalTours = (tours: Tours[]): RequestActionTypes => {
  return {
    type: SET_FOR_REMOVAL_TOURS,
    payload: tours,
  };
};

export const fetchAllRequests = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch) => {
  const [
    projectRes,
    toursRes,
    providerRes,
    removalProjectRes,
    removalTourRes,
  ] = await Promise.all([
    axiosInstance.get("/api/requests",{
     headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
  }),
    axiosInstance.get("/api/requests/tours", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
    }),
    axiosInstance.get("/api/requests/provider",{
     headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
  }),
    axiosInstance.get("/api/requests/removed",{
     headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
  }),
    axiosInstance.get("/api/requests/removedTours",{
     headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
  }),
  ]);

  dispatch(setForApprovalProjects(projectRes.data.projects));
  dispatch(setForApprovalTours(toursRes.data.tours));
  dispatch(setForApprovalProviders(providerRes.data.requests));
  dispatch(setForRemovalProjects(removalProjectRes.data.projects));
  dispatch(setForRemovalTours(removalTourRes.data.tours));
};

export const requestForProjectRemoval = (
  projectID: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  const userEmail = getState().authReducer.UserDetails?.email;
  const res = await axiosInstance.post(`/api/requests/${projectID}`, {
    removed: "pending",
    recipient: userEmail,
  }, {
     headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
  });
  return res.data;
};

export const requestForTourRemoval = (
  tourID: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  const userEmail = getState().authReducer.UserDetails?.email;
  const res = await axiosInstance.post(`/api/requests/tour/${tourID}`, {
    removed: "pending",
    recipient: userEmail,
  }, {
    headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
  });
  return res.data;
};

export const approveProject = (
  projectId: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  const userEmail = getState().authReducer.UserDetails?.email;
  const res = await axiosInstance.post(`/api/requests/${projectId}`, {
    status: "approved",
    recipient: userEmail,
  }, 
    {
     headers: {
        Authorization: "Bearer " + localStorage.getItem('swt-token')
      }
  });
  return res.data;
};

export const rejectProject = (
  projectId: string,
  message: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  const userEmail = getState().authReducer.UserDetails?.email;
  const res = await axiosInstance.post(`/api/projects/${projectId}`, {
    status: "rejected",
    email: userEmail,
    message,
  }, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem('swt-token')
    }
  });
  return res.data;
};

export const approveTour = (
  tourId: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  const userEmail = getState().authReducer.UserDetails?.email;
  const res = await axiosInstance.post(`/api/requests/tour/${tourId}`, {
    status: "approved",
    recipient: userEmail,
  },{
    headers: {
      Authorization: "Bearer " + localStorage.getItem('swt-token')
    }
  });
  return res.data;
};

export const rejectTour = (
  tourId: string,
  message: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  const userEmail = getState().authReducer.UserDetails?.email;
  const res = await axiosInstance.post(`/api/tours/${tourId}`, {
    status: "rejected",
    email: userEmail,
    message,
  }, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem('swt-token')
    }
  });
  return res.data;
};

export const approveProvider = (
  draft: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  console.log('in approve Provider');
  const userID = getState().authReducer.UserDetails?.id;
  const copyOfDraft = {
    ...draft,
    createdBy: draft.user_id
  };
  delete copyOfDraft.id;
  delete copyOfDraft.requested;
  await axiosInstance.post(`/api/users/${userID}`, {
    userInfo: copyOfDraft,
    isNewProvider: true,
  }, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem('swt-token')
    }
  });
  if (draft.type === "project") {
    await dispatch(createProject(pickProject(copyOfDraft)));
  } else {
    await dispatch(createTours(pickTour(copyOfDraft)));
  }
  await dispatch(deleteDraft(draft.id));
};

function pickProject(draft: any): Project {
  return pick(draft, [
    "projectPictures",
    "qualifications",
    "extraAccommodation",
    "accommodationPicture",
    "transportationPicture",
    "available",
    "status",
    "removed",
    "feedback",
    "customId",
    "projectName",
    "contactEmail",
    "projectOwnerPicture",
    "projectOwnerName",
    "ownerDescription",
    "projectDescription",
    "program",
    "localSupervisorName",
    "localSupervisorPhone",
    "location",
    "startDate",
    "endDate",
    "capacity",
    "minCapacity",
    "category",
    "accommodationDescription",
    "price",
    "pricePerDay",
    "pricePerMonth",
    "additionalcost",
    "foodDescription",
    "transportation",
    "additionalInfo",
    "createdBy",
    "typicalDay",
    "noticePeriod",
    "continent",
    "createdBy",
  ]) as any;
}

function pickTour(draft: any): Tours {
  return pick(draft, [
    "tourName",
    "contactEmail",
    "tourOwnerPicture",
    "tourOwnerName",
    "ownerDescription",
    "tourPictures",
    "tourDescription",
    "program",
    "qualifications",
    "localSupervisorName",
    "localSupervisorPhone",
    "location",
    "startDate",
    "endDate",
    "capacity",
    "category",
    "price",
    "pricePerDay",
    "pricePerMonth",
    "accommodationDescription",
    "accommodationPicture",
    "foodDescription",
    "transportation",
    "transportationPicture",
    "additionalInfo",
    "dayToDay",
    "status",
    "dayOne",
    "possibleAdditionalCost",
    "standardAccomodation",
    "extraAccommodation",
    "noticePeriod",
    "minParticipants",
    "duration",
    "createdBy",
    "customId",
    "minCapacity",
    'includedEventsDates'
  ]) as any;
}
