import Axios from "axios";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axiosInstance from "../../utilities/axiosInstance";

interface postQuickFeedBackParams {
    eventId: string,
    eventType: string,
    impression: number,
    bookingExp: number,
}
export const postQuickFeedBack = (feedbackData: postQuickFeedBackParams): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await axiosInstance.post(`/api/feedback/quick`, feedbackData, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('swt-token')
        }
    })
    return res.data
}

export const postFeedBack = (feedbackData: any): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await axiosInstance.post(`/api/feedback`, feedbackData, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('swt-token')
        }
    })
    return res.data
}

export const getFeedBackByEvent = (eventId: string, eventType: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await axiosInstance.get(`/api/feedback/byEvent/${eventType}/${eventId}`, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('swt-token')
        }
    })
    return res.data.feedback
}

export const getFeedbackById = (id: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    const res = await axiosInstance.get(`/api/feedback/${id}`, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('swt-token')
        }
    })
    return res.data.feedback
}