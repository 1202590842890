import dayjs from "dayjs";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Swal from "sweetalert2";
import { RootState } from "../store";
import {
  addItemToCart,
  addTourItemToCart,
  CartItem,
  deleteItemFromCart,
  deleteTourItemFromCart
} from "../store/Cart";
import { fetchProjectById } from "../store/Projects";
import { setCurrentDayToDayProgram } from "../store/SideBar";
import { fetchTourById } from "../store/Tours";
import { getLatestBookingDate } from "../util";
import calculateEventPrice from "../utilities/calcProjectPrice";


export const Cart: FunctionComponent = ({ }) => {
  let history = useHistory();
  let CHECK_OVERLAPPING: boolean = true;

  const dispatch = useDispatch();
  const cart = useSelector(((rootState: RootState) => rootState.cart.Cart) || [])
  const cartItems =
    useSelector((rootState: RootState) => rootState.cart.Cart?.items) || [];
  const Language = useSelector((state: RootState) => state.language.language?.content)
  const selectedLan = useSelector((state: RootState) => state.language.language?.language)

  const removeItemFromCart = (itemId: number, eventType: string) => {

    if (eventType === "Project") {
      dispatch(deleteItemFromCart(itemId));
    } else if (eventType === "Tour") {
      dispatch(deleteTourItemFromCart(itemId));
    }
  };
  const getEventType = (cartItem: CartItem) => {
    return cartItem.projectId ? "Project" : "Tour";
  };
  const [expiredEvent, setExpiredEvent] = useState<any | undefined>(undefined)

  const events = useMemo(() => {
    return cartItems.map((cartItem) => {
      const eventType = getEventType(cartItem);
      const startDate = dayjs(getLatestBookingDate(cartItem.startDate)).toDate()
      const endDate = dayjs(getLatestBookingDate(cartItem.startDate)).add(1, 'd').toDate()
      const basePrice = calculateEventPrice('tour', 1, cartItem.category, startDate, endDate, cartItem, 0 )
      const commonProps = {
        numberOfNights: cartItem.numberOfNights,
        participants: cartItem.participants,
        isOverlapping: cartItem.isOverlapping,
        forceConfirmed: cartItem.forceConfirmed,
        accommodation: cartItem.accommodation,
        price: cartItem.price,
        eventType,
        dateOfTravel: cartItem.startDate,
        endDateOfTravel: cartItem.endDate,
        basePrice: basePrice.price
      };
      if (eventType === "Project") {
        return {
          id: cartItem.projectId!,
          name: cartItem.projectName!,
          imageUrl: cartItem.projectImage!?.imageURL,
          ...commonProps,
        };
      } else {
        return {
          id: cartItem.tourId!,
          name: cartItem.tourName!,
          imageUrl: cartItem.tourImage!?.imageURL,
          ...commonProps,
        };
      }
    });
  }, [cartItems])

  useEffect(() => {
    if (events) {
      CHECK_OVERLAPPING = events.every((value: any) => !('forceConfirmed' in value) || value.forceConfirmed === undefined);
    }
  }, [events])


  const onEventDetailsOverlay = async (eventType: string, id: number) => {
    let event: any = null;
    if (eventType === "Project") {
      event = await dispatch(fetchProjectById(id));
    } else {
      event = await dispatch(fetchTourById(id));
    }
    dispatch(setCurrentDayToDayProgram(event));
  };



  const _HandleChangeOverlapFlag = async (item: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Confirm to create event with overlapping time (once confirmed can't be undone) or cancel to remove item from cart",
      icon: 'warning',
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(item.eventType === 'Project' ? addItemToCart(item) : addTourItemToCart(item));
      }
      else {
        dispatch(item.eventType === 'Project' ? deleteItemFromCart(item.id) : deleteTourItemFromCart(item.id))
      }
    })
  }
  const handleConfirmBooking = () => {
    if (!CHECK_OVERLAPPING) return
    const expired = events.find((item: any) => dayjs(item.dateOfTravel).isBefore(dayjs()))
    if (expired) {
      return setExpiredEvent(expired)
    } else {
      history.push('/checkout')
    }
  }

  return (
    <>
      {
        expiredEvent && <SweetAlert
          title="Event start date have already passed, please select another date"
          confirmBtnBsStyle="danger"
          confirmBtnCssClass="btnfinalbook"
          onConfirm={() => history.push(`/${expiredEvent?.eventType}s/${expiredEvent?.id}`)}
        />
      }
      <div className="container">
        <div className="row m-0">
          <div className="col-lg-12 col-md-12 paddcardtb">
            <h2 className="font-weight-bold mb-2 com-font text-red ">
              {Language?.general?.cart}{" "}
              <img
                className="img-fluid shopcarticon"
                src="/images/addcart.svg"
                alt=""
              />
            </h2>
            {cartItems.length === 0 && (
              <p className="font-italic text-muted mb-4 small">
                {Language?.general?.cart_is_empty}
              </p>
            )}
            <div className="row cartswt">
              <div className="col-lg-12 col-md-12 mb-4 mb-lg-0">
                {events.map((item) => (
                  <div className="card  shadow-sm  com-font">
                    <div className="card-body p-3 p-sm-4">
                      <div className="row">
                        <div className="col-md-2 col-lg-2">
                          <img
                            src={item.imageUrl}
                            className="img-fluid d-block cartimg"
                            alt=""
                          />
                        </div>
                        <div className="col-lg-6">
                          <div className="d-block d-lg-none">
                            <button
                              onClick={() =>
                                removeItemFromCart(item.id, item.eventType)
                              }
                              className="btn btn-sm btn-link btnscart float-right"
                            >
                              <img
                                src="/images/delete_icon.svg"
                                className="img-fluid carticon"
                                alt=""
                              />
                            </button>
                            <HashLink
                              scroll={(el: any) =>
                                el.scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                })
                              }
                              to={`/${item.eventType === "Project"
                                ? "projects"
                                : "tours"
                                }/${item.id}#details`}
                              className="btn btn-sm btn-link btnscart float-right"
                            >
                              <img
                                src="/images/edit_cart.svg"
                                className="img-fluid carticon"
                                alt=""
                              />
                            </HashLink>
                          </div>
                          <h4>
                            <p
                              onClick={() =>
                                onEventDetailsOverlay(item.eventType, item.id)
                              }
                              className="text-dark fw700 cursor-pointer text-link"
                            >
                              {item.name}
                            </p>
                          </h4>
                          <div className="row">
                            <div className="col-lg-6">
                              <label className="text-muted small mb-0 fw300">
                                {Language?.general?.days}
                              </label>
                              <div className="fw700">
                                {item.numberOfNights + 1} {Language?.general?.days} &amp;{" "}
                                {item.numberOfNights} {Language?.general?.nights}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <label className="text-muted small mb-0 fw300">
                                {Language?.general?.travellers}
                              </label>
                              <div className="fw700">{item.participants}</div>
                            </div>
                          </div>
                          <div className="row paddcarditemlab">
                            <div className="col-lg-6">
                              <label className="text-muted small mb-0 fw300">
                                {Language?.general?.accommodation} {Language?.general?.category}
                              </label>
                              <div className="fw700">{item.accommodation}</div>
                            </div>
                            <div className="col-lg-6">
                              <label className="text-muted small mb-0 fw300">
                                {Language?.general?.date_of_travel}
                              </label>
                              <div className="fw700">{dayjs(item.dateOfTravel).format("DD-MM-YYYY")}{' - '}{dayjs(item.endDateOfTravel).format("DD-MM-YYYY")}</div>
                            </div>
                          </div>
                          {item.isOverlapping && !item.forceConfirmed && (
                            <div className="row paddcarditemlab">
                              <div className="col-lg-12">
                                <label className="fw700 small mb-0">
                                  {Language?.general?.note}:
                                </label>
                                <span className="row">
                                  <input
                                    checked={item.forceConfirmed}
                                    type="checkbox"
                                    onClick={() => _HandleChangeOverlapFlag(item)}
                                    className="form-check-input"
                                    id="exampleCheck1"
                                  />
                                  <label
                                    className="form-check-label fw700 text-justify text-danger"
                                    htmlFor="exampleCheck1"
                                  >
                                    {Language?.general?.cart_overlap_dates}
                                  </label>
                                </span>
                                {/* <div className="fw700 text-justify text-danger"></div> */}
                              </div>

                            </div>
                          )}
                        </div>
                        <div className="col-lg-4">
                          <div className="row">
                            <div className="d-none d-lg-block col-lg-12">
                              <button
                                onClick={() =>
                                  removeItemFromCart(item.id, item.eventType)
                                }
                                className="btn btn-sm btn-link btnscart float-right"
                              >
                                <img
                                  src="/images/delete_icon.svg"
                                  className="img-fluid carticon"
                                  alt=""
                                />
                              </button>
                              <HashLink
                                scroll={(el: any) =>
                                  el.scrollIntoView({
                                    behavior: "smooth",
                                    block: "center",
                                  })
                                }
                                to={`/${item.eventType === "Project"
                                  ? "projects"
                                  : "tours"
                                  }/${item.id}#details`}
                                className="btn btn-sm btn-link btnscart float-right"
                              >
                                <img
                                  src="/images/edit_cart.svg"
                                  className="img-fluid carticon"
                                  alt=""
                                />
                              </HashLink>
                            </div>
                            <div className="col-lg-12">
                              <div className="priceitemcard text-right">
                                {item.price}€
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="col-lg-12">
                  {cartItems.length === 0 ? (
                    <Link
                      to="/events"
                      className="btn btn-default float-right btn-continue-shopping"
                    >
                      {Language?.general?.continue_shopping}
                    </Link>
                  ) : (
                    <Link
                      onClick={handleConfirmBooking}
                      // onClick={(event: any) => CHECK_OVERLAPPING === false ? event.preventDefault() : ''}
                      className="btn btn-default float-right btnfinalbook com-font"
                    >
                      {Language?.general?.confirm_booking}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
